.ucipt-responsive-bg-video {
    width: 100%;
    height: /*527px*/524px;
    left: 0;
    top: 0;
    overflow: hidden;

    &__video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (--viewport-ms-max) {
            display:none;
        }
        &.top{object-position: top;}
        &.bottom{object-position:bottom;}
    }
    .video-bg-image{
        display:none;
        @media (--viewport-ms-max) {
            display:block;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
