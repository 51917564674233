.document-upload-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex-wrap: wrap;
    margin-top: 16px;
    @media (--viewport-ms-max) {
        flex-direction: row;
        gap:30px;
    }
    @media (--viewport-xs-max) {
        gap:24px;
    }
    &__item {
        display: flex;
        flex-flow: nowrap;
        justify-content: space-between;
        position:relative;
        line-height:50px;
        @media (--viewport-ms-max) {
            width: calc(50% - 15px);
        }
        @media (--viewport-xs-max) {
            width: 100%;
        }
    }
}