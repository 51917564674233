.access-report {
    padding: 100px 0;

    @media (--viewport-xs-max) {
        margin-left: -20px;
        margin-right: -19px;
    }

    &_bg-color {
        &_yellow {
            background-color: var(--color-accent-light);
        }
    }

    &__nav {
        overflow-x: scroll;
        scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox*/
        -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge*/
        margin-bottom: 80px;
        text-align: center;

        &::-webkit-scrollbar {
            display: none; /* Hide the scrollbar in Chromium */
        }

        &-list {
            display: inline-flex;
        }

        &-item {
            margin-right: 40px;

            @media (--viewport-sm-min) {
                margin-right: 75px;
            }

            &:first-child {
                padding-left: 20px;

                @media (--viewport-ms-min) {
                    padding-left: 60px;
                }

                @media (--viewport-sm-min) {
                    padding-left: 109px;
                }
            }

            &:last-child {
                padding-right: 20px;
                margin-right: 0;

                @media (--viewport-ms-min) {
                    padding-right: 60px;
                }

                @media (--viewport-sm-min) {
                    padding-right: 103px;
                }
            }
        }

        &-input {
            position: absolute;
            left: -9999px;
            opacity: 0;

            &:hover,
            &:focus {
                + .access-report__nav-label {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        &-label {
            position: relative;
            display: block;
            padding-bottom: 5px;
            transition: opacity 0.5s;

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 0;
                height: 1px;
                background-color: var(--color-accent);
                transition: all 0.3s;
                content: '';
            }

            &:hover,
            &:focus {
                &::after {
                    width: 100%;
                }
            }

            &.visible {
                &::after {
                    width: 100%;
                }
            }
        }

        &-title {
            position: relative;

            &::after {
                position: absolute;
                bottom: -5px;
                left: 0;
                display: block;
                width: 0;
                height: 1px;
                background-color: var(--color-accent);
                content: "";
            }
        }
    }

    &__content {
        @media (--viewport-xs-max) {
            padding: 0 20px;
        }
    }

    .report {
        display: none;

        &.visible {
            display: block;
        }
    }
}
