.delete-document {
    text-align: center;

    &__icon {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;
    }

    &__description {
        margin-top: 48px;
        padding:0 20%;
        span.resaltado{color: var(--color-primary-brand);}
    }

    &__helperText{
        color: var(--color-gray-100);
        margin-top: 16px;
        .bodycopy-m{
            color: var(--color-text-subtle);
        }
    }
}