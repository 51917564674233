.star {
    position: relative;
    display: block;
    width: 0;
    height: 0;
    margin-left: 0.9em;
    margin-right: 0.9em;
    margin-bottom: 1.2em;
    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid var(--color-accent);
    border-left: 0.3em solid transparent;

    /* Controls the size of a star */
    font-size: 7px;

    &::before,
    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0.6em;
        left: -1em;
        border-right: 1em solid transparent;
        border-bottom: 0.7em solid var(--color-accent);
        border-left: 1em solid transparent;
        transform: rotate(-35deg);
    }

    &::after {
        transform: rotate(35deg);
    }

    &_hollow {
        border-bottom-color: var(--color-accent-light);

        &::before,
        &::after {
            border-bottom-color: var(--color-accent-light);
        }
    }

    &-wrapper {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}
