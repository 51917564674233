.bodycopy-l {
    color: var(--color-text-tertiary);
    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }

    &.bodycopy {

        &_color {

            &_light {
                color: var(--color-text-light);
            }
        }   
        
        &--color {
            &-dark{
                color: var(--color-accent-dark);
            }
        }

        &--strong {
            font-weight: 600;
        }

    }
}
