.header-highlight {position: relative;
    margin-top: 100px;
    margin-bottom:50px;

    @media (--viewport-ms-max) {
        margin-top:55px;
    }

    @media (--viewport-ms-max) {
        display:flex;
        flex-direction: column;
    }

    @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
    }

    .wrap-img{min-height:450px;position: relative;width: 100%;top:0;overflow: hidden;display: flex; align-items: flex-start;justify-content: center;
        /*@media (--viewport-lg-min) {
            height:625px;
        }
        @media (--viewport-md-max) {
            height:550px;
        }
        @media (--viewport-sm-max) {
            height:500px;
        }*/
        @media (--viewport-ms-max) {
            min-height: auto;
            align-items: flex-end;
        }
        @media (--viewport-xs-max) {
            top:0;
        }
        img{width: 100%;margin-bottom: -8px;}
    }

    &__tile {position: absolute;bottom:0;width: 100%;
        margin: 0 0 -50px 0;

    @media (--viewport-ms-max) {
        position:relative;
        margin-top:0;
        margin-right:0;
        padding-right:0;
    }

        /*&_reversed {
            @media (--viewport-ms-only) {
                padding-right: 0;
                padding-left: 30px;
            }
        }*/
    }
}
