.interest-rates-simple {
    margin-bottom: 40px;

    &_whiteBackground {
        .interest-rates-simple {
            &__tile-interest-rate {
                .tile-simple-interest-rate {
                    background-color: var(--color-primary);
                }
            }
        }
    }

    &__oneRateWrapper {
        display: flex;
        flex-flow: row;

        @media(--viewport-ms-max) {
            flex-flow: column;
        }

        &-textBlock {
            display: flex;
            flex-flow: column;
            width: 50%;

            @media(--viewport-sm-min) {
                padding-right:100px
            }

            @media(--viewport-ms-max) {
                width: 100%;
                padding-bottom:40px;
            }

            .title-xl {
                text-align: left;
                color: var(--color-text) !important;
                padding-bottom: 30px;
            }

        }

        &-rateBlock {
            display: flex;
            justify-content: flex-end;
            width: 50%;

            @media(--viewport-ms-max) {
                width: 100%;
            }

            .tile-simple-interest-rate {
                @media(--viewport-sm-min) {
                    width: 400px;
                }
            }

        }

    }

    &__head {

        padding-bottom:80px;
        padding-top:80px;

        .title-xl {
            text-align: left;
            color: var(--color-text) !important;
        }

    }

    &__tiles {
        margin-bottom: -40px;

    }

    &__tile-interest-rate {
        height: 100%;
        margin-bottom: 40px;
    }

}