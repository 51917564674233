.ucipt-description {
    font-family: "Jornada Sans Book", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
    letter-spacing: 0;
    color: var(--color-text-tertiary);
    transition: all 0.3s;

    &--color {
        &-light {
            color: var(--color-text-light);
        }
    }
}
