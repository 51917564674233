.number-xl {
    font-family: 'Jornada News Book';
    font-size: 80px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0;
    color: var(--color-number-accent);

    @media (--viewport-ms-min) {
        font-size: 100px;
    }

    @media (--viewport-sm-only) {
        font-size: 80px;
    }

    @media (--viewport-lg-min) {
        font-size: 130px;
    }

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}
