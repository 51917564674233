.error-component {
    text-align: center;

    &__icon {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;
        .icon{width: auto;height: auto;}
    }

    &__title{
        color: var(--color-text-accent);
    }
    
    &__btn{
        margin-top: 16px;
    }

    &__description {
        margin-top: 48px;
        padding:0 20%;
        span.resaltado{color: var(--color-primary-brand);}
    }

    &__helperText{
        color: var(--color-gray-100);
        margin-top: 16px;
        .bodycopy-m{
            color: var(--color-text-subtle);
            a{
                color: var(--color-icon-link-primary);
            }
        }
    }

    &__helperTextContacto{
        color: var(--color-gray-100);
        margin-top: 48px;
        .bodycopy-m{
            color: var(--color-text-subtle);;
            a{
                color: var(--color-icon-link-primary);
            }
        }
    }

    &__contacto {
        margin-top: 48px;
        padding:16px 48px;
        li{
            padding-top: 4px;
            &:first-child{
                padding-top: 0;
            }
        }
    }

    .error-component__helperTextContacto + .error-component__contacto{margin-top: 0;}
}