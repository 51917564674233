.radio{display: flex; flex-direction: row; align-items: center;position:relative;
    .radio-input {opacity: 0;

        &:hover,
        &:focus {
            + .radio-label {
                &::before {
                    border-color: var(--color-secondary);
                }
            }
        }

        &:checked {
            + .radio-label {
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .radio-label {
        padding-top: 4px;
        padding-left: 22px;
        min-height: 25px;
        display: flex;
        align-items: center;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 25px;
            height: 25px;
            border: 1px solid var(--color-grey3);
            border-radius: 50%;
            transition: border-color 0.3s;
            content: '';
        }

        &::after {
            opacity: 0;
            position: absolute;
            top: 8px;
            left: 8px;
            display: block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: var(--color-primary-brand);
            transition: opacity 0.3s;
            content: '';
        }

        &:hover,
        &:focus {
            &::before {
                border-color: var(--color-secondary);
            }
        }

        &_light {
            .text-label-m {
                color: var(--color-text-light);
            }
        }

        &_icon {
            padding-top: 0;
            &::before {
                top: 50%;
                transform: translate(0, -50%);
            }

            &::after {
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        .icon {
            width: 54px;
            height: 54px;
        }
    }
}

