.tile-highlight {
    &__wrapper {
        background-color: var(--color-accent);
        
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 450px;
        padding: 40px 20px;

        @media (--viewport-ms-min) {
            min-height: 428px;
            padding: 60px;
        }

        @media (--viewport-sm-min) {
            min-height: 384px;
            padding: 40px 60px;
        }

        @media (--viewport-md-min) {
            min-height: 407px;
            padding: 60px;
        }

        @media (--viewport-ms-max) {
            min-height: auto;
        }
    }

    &__label {
        margin-bottom: 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 20px;
        }
    }

    &__title {
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 40px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 30px;
        }

        &_header {
            margin-bottom: 10px;


            @media (--viewport-ms-min) {
                margin-bottom: 20px;
            }

            @media (--viewport-md-min) {
                margin-bottom: 10px;
            }
        }
    }

    &__bodycopy {
        margin-top: 15px;

        @media (--viewport-ms-min) {
            margin-top: 20px;
        }

        @media (--viewport-sm-min) {
            margin-top: 20px;
        }
        p + p{margin-top: 20px;}
    }

    &__btn {
        margin-top: 30px;
    }

    &.line-visible{
        .tile-highlight__content {
            padding: 40px 20px 20px;
    
            @media (--viewport-ms-min) {
                padding: 45px;
            }
    
            @media (--viewport-sm-min) {
                padding: 45px;
            }
    
            @media (--viewport-md-min) {
                padding: 45px;
            }
        }
        .tile-highlight__title {
            margin-bottom: 10px;
            &_header {
                margin-bottom: 10px;
            }
        }
        .tile-highlight__btn {
            margin-top: 19px;
        }
    }

    &.accent-dark{
        .tile-highlight__content {
            padding: 40px 20px;
    
            @media (--viewport-ms-min) {
                padding: 45px;
            }
    
            @media (--viewport-sm-min) {
                padding: 45px
            }
    
            @media (--viewport-md-min) {
                padding: 45px;
            }
        }
        .tile-highlight__wrapper {
            background-color: var(--color-accent-dark);
            
        }

        .tile-highlight__title {
            margin-bottom: 10px;
            &_header {
                margin-bottom: 10px;
            }
        }

        .tile-highlight__btn {
            margin-top: 19px;
        }

        .tile-highlight__label {
        margin-bottom: 3px;

            @media (--viewport-ms-min) {
                margin-bottom: 3px;
            }
        }
    }

    &.green{
        .tile-highlight__content {
            padding: 40px 20px;
    
            @media (--viewport-ms-min) {
                padding: 45px;
            }
    
            @media (--viewport-sm-min) {
                padding: 45px
            }
    
            @media (--viewport-md-min) {
                padding: 45px;
            }
        }
        .tile-highlight__wrapper {
            background-color: #00635B;
            
        }

        .tile-highlight__title {
            margin-bottom: 10px;
            &_header {
                margin-bottom: 10px;
            }
        }

        .tile-highlight__btn {
            margin-top: 19px;
        }

        .tile-highlight__label {
        margin-bottom: 3px;

            @media (--viewport-ms-min) {
                margin-bottom: 3px;
            }
        }
    }

    &.small-padding{
        .tile-highlight__content {
            padding: 40px 20px;

            @media (--viewport-ms-min) {
                padding: 45px;
            }

            @media (--viewport-sm-min) {
                padding: 45px;
            }

            @media (--viewport-md-min) {
                padding: 45px;
            }
        }

        .tile-highlight__title {
            margin-bottom: 10px;
            &_header {
                margin-bottom: 10px;
            }
        }
        .tile-highlight__btn {
            margin-top: 19px;
        }

        .tile-highlight__label {
        margin-bottom: 3px;

            @media (--viewport-ms-min) {
                margin-bottom: 3px;
            }
        }
    }

}

