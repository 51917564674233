.brands {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    .title-m{text-align: center; width: 100%;display: block; margin-bottom: 58px;}

    ul{
        display: flex; flex-direction: row; gap: 120px; justify-content: center; align-items: center;
        @media (--viewport-ms-max) {
            flex-direction: column;
            gap: 40px;
        }
    }
}