.table {
    &__head {
        margin-bottom: 60px;
    }

    &__content {
        @media (--viewport-ms-max) {
            padding-bottom: 35px;
        }

        &_col {
            &_1 {
                th,
                td {
                    width: 100%;
                    min-width: 700px;
                }
            }

            &_2 {
                th,
                td {
                    width: 50%;
                    min-width: 350px;
                }
            }

            &_3 {
                th,
                td {
                    width: 33.333333333%;
                    min-width: 233.333333333px;
                }
            }

            &_4 {
                th,
                td {
                    width: 25%;
                    min-width: 256px;
                }
            }

            &_5 {
                th,
                td {
                    width: 20%;
                    min-width: 204.8px;
                }
            }

            &_6 {
                th,
                td {
                    width: 16.666666667%;
                    min-width: 170.666666667px;
                }
            }

            &_7 {
                th,
                td {
                    width: 14.285714286%;
                    min-width: 146.285714286px;
                }
            }

            &_8 {
                th,
                td {
                    width: 12.5%;
                    min-width: 128px;
                }
            }

            &_9 {
                th,
                td {
                    width: 11.111111111%;
                    min-width: 113.777777778px;
                }
            }

            &_10 {
                th,
                td {
                    width: 10%;
                    min-width: 102.4px;
                }
            }
        }

        table {
            width: 100%;
            max-width: 100%;
            border-collapse: collapse;
            background-color: transparent;

            thead {
                vertical-align: middle;

                tr{
                    th {
                        vertical-align: top;
                        padding-bottom: 15px;
                        text-align: right;
    
                        &:first-child {
                            text-align: left;
                        }
    
                        .text-label-m, .text-label-s{display: inline;}
                        .icon{bottom: -2px; position: relative;}
                    }

                    &.secondaryBrandBG{
                        background-color: var(--color-secondary-brand);
                        color: var(--color-white);
                        .text-label-m, .text-label-s{color: var(--color-white);}
                        .icon{fill: var(--color-white);}
                        th{padding-top: 15px;}
                    }
                }

            }

            tbody {
                tr {
                    border-top: 1px solid var(--color-secondary-light);

                    &:first-child {
                        border-top: 2px solid var(--color-secondary);
                    }

                    &:last-child {
                        border-bottom: 2px solid var(--color-secondary);
                    }
                }
            }

            td {
                vertical-align: top;
                padding: 20px 0;
                text-align: right;

                &:first-child {
                    text-align: left;
                }

                &.accentLightBG{
                    background-color: var(--color-accent-light);
                    border-top: solid 1.5px var(--color-secondary-brand);
                }
            }
        }
    }
}
