.journey-slider {
    display: flex;
    flex-flow: row;

    height: 505px;

    /* MOBILE STYLIZATION, FROM ROW TO COLUMN SO .DISPLAYINFO GETS ABOVE THE .SLIDER */
    @media (--viewport-ms-max) {
        flex-flow: column;
        height: auto;

        margin-right: -40px;
        margin-left: -40px;
    }

   

    @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
    }

    &.sticky-clone {
        flex-direction: column;
        height: 65px;
        box-shadow: none;
        margin: 0;

        .journey-slider {

            &__displayInfo {
                display: none;
            }

            &__slider {
                display: none;
            }

            &__stepsBar {
                display: block;





            }

            &__progress {
                display: block;
            }
        }
    }

    &__stepsBar {

        display: none;

        &-stepsBarRow {
            display: flex;
            flex-flow: row;

            justify-content: space-around;
            align-items: center;

            height: 65px;
            background-color: var(--color-accent);


            @media (--viewport-ms-max) {
                margin-right: -40px;
                margin-left: -40px;
            }


        }

        &-stepBlock {
            display: inline-flex;
            flex-wrap: nowrap;
            flex-flow: row;
            flex-grow: 1;
            justify-content: center;
            align-items: center;


            &.active {
                .journey-slider {
                    &__stepsBar {
                        &-stepBlock {
                            flex-grow: 10;
                        }

                        &-link {
                            &-textLink {
                                @media(--viewport-ms-max) {
                                    display: block;
                                }

                                @media(--viewport-xs-max) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

        }

        &-linksDivider {
            display: block;
            font-family: 'Jornada Sans Book';
            font-size: 20px;
            opacity:0.9;
            color: var(--color-primary);

            &:last-child {
                display: none;
            }
        }

        &-link {
            display: block;

            .link {
                color: var(--color-primary);
                font-size: 16px;
                line-height: 26px;
                font-family: 'Jornada Sans Book';
                &:hover {
                    transition: all 0.3s;
                }
            }

            &-textLink {
                @media(--viewport-ms-max) {
                    display: none;

                }

                .link {
                    color: var(--color-primary);
                    font-size: 16px;
                    line-height: 26px;
                    font-family: 'Jornada Sans Book';
                    &:hover {
                        transition: all 0.3s;
                    }
                }
            }

        }

    }
    &.underHeaderFixed.sticky-clone.visible{
        @media (--viewport-sm-min) {
            .journey-slider__stepsBar{
                position: relative;
                top:100px;
            }
        }
    }

    &__progress {
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
        width: 100%;

        .progress-bar {
            background-color: var(--color-tertiary);
            height: 5px;
        }


    }

    /* STYLE OF LEFT CONTAINER, WICH IS A COLUMN COMPOSED BY 3 TEXT ELEMENTS */
    &__displayInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        min-width: 400px;
        background-color: var(--color-accent);

        /* this padding exists so the infoColumn doesn't get compromised in small desktop viewports */
        @media (--viewport-sm-only) {
            width: 500px;
        }

        /* MOBILE STYLIZATION */
        @media (--viewport-ms-max) {
            width: 100%;
            min-width: unset;
            height: fit-content;
            padding: 80px 0px;
            min-width: unset;
        }

        &-infoColumn {
            /* SIDE PADDINGS FOR THE TEXTS */
            padding-left: 60px;
            padding-right: 30px;

            /* MOBILE PADDINGS STYLIZATION */
            @media (--viewport-ms-max) {
                padding-left: 30px;
                padding-right: 30px;
            }

            &-question {
                padding-top: 10px;
                @media (--viewport-ms-max) {
                    padding-top: 50px;
                }
            }

            &-title {
                padding-top: 14px;
                padding-bottom: 20px;
            }

            &-desc {
                padding-bottom: 10px;
            }

        }
    }

    /* STYLE OF RIGHT CONTAINER, WICH IS A ROW COMPOSED BY 3 TO 6 IMAGE BOXES */
    &__slider {
        display: flex;
        flex-direction: row;
        width: 64%;

        /* MOBILE STYLIZATION */
        @media (--viewport-ms-only) {
            width: 100%;
            height: 200px;
            padding: 10px 0px 0px;
        }

        @media (--viewport-xs-max) {
            width: 100%;
            height: 120px;
            padding: 10px 0px 0px;
        }

        &:hover {

            /* WHEN THE SLIDER IS HOVERED, THIS HIDES THE INDEX NUMBER OF THE OUTER IMAGE BOXES */
            .journey-slider__slider-imageBox-index-number {
                opacity: 0;
                transition: opacity .2s;
            }
        }

        &-imageBox {
            position: relative;
            flex: 1;
            overflow: hidden;
            background: center / cover no-repeat;

            height: 100%;

            margin: 0 .5%;
            transition: 0.5s;

            &:last-child {
                margin-right: 0px;
            }

            @media (--viewport-ms-max) {
                padding: 0px;

                &:first-child {
                    margin-left: 0px;
                }


            }

            &-backgroundMask {
                height: 100%;
                padding: 320px 20px 30px 50px;
                background: linear-gradient(to bottom, transparent 60%, black 99%);

                @media (--viewport-ms-max) {
                    height: 100%;
                    padding: 0px;
                    background: linear-gradient(to bottom, transparent 30%, black 99%);
                }
            }

            &-index {
                display: flex;
                flex-flow: column;

                width: fit-content;

                /* MOBILE STYLIZATION */
                @media (--viewport-ms-max) {
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                }

                &-number {
                    display: flex;

                    /* MOBILE STYLE CHANGES */
                    @media (--viewport-ms-max) {
                        justify-content: center;
                    }

                    .number-xl {
                        color: var(--color-primary);
                        line-height: 1;

                        @media (--viewport-ms-only) {
                            font-size: 70px;
                        }

                        @media (--viewport-xs-max) {
                            font-size: 50px;
                        }

                    }

                }

                &-description {
                    /* HIDDEN, TURNS TO VISIBLE WHEN HOVERED, AS DEVELOPED BELOW */
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity .2s, visibility .2s;

                    @media (--viewport-sm-only) {
                        font-size: 22px;
                    }

                    /* MOBILE STYLE CHANGES */
                    @media (--viewport-ms-max) {
                        display: none;
                    }

                    .title-m {
                        color: var(--color-primary);
                    }

                }
            }

            &:hover {
                flex: 4;

                /* INDEX PADDINGS FOR WHEN HOVERED */
                .journey-slider__slider-imageBox-index {
                    @media (--viewport-ms-max) {
                        padding-left: 20px;
                        padding-right: 15px;
                    }
                }

                /* WHEN HOVERED, MAKE THE DESCRIPTION VISIBLE */
                .journey-slider__slider-imageBox-index-description {
                    visibility: visible;
                    opacity: 1;

                    @media (--viewport-ms-max) {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }

                /* WHEN HOVERED, MAKE THE INDEX NR VISIBLE, THIS IS NEEDED BECAUSE OF THE SLIDER HOVER, WICH HIDES THEM INDEX NUMBERS OF ALL THE IMAGE BOXES */
                .journey-slider__slider-imageBox-index-number {
                    opacity: 1;
                    justify-content: flex-start;
                }
            }

        }



    }

}