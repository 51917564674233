.tile-figures-module {
    text-align: center;

    &__figure {
        margin-top: 40px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: first baseline;

        &-percentage {
            font-family: 'Jornada News Book';
            font-size: 80px;
            font-weight: normal;
            line-height: 1.2;
            letter-spacing: 0;
            color: var(--color-number-accent);

            @media (--viewport-ms-min) {
                font-size: 100px;
            }

            @media (--viewport-sm-min) {
                font-size: 50px;
            }
        }
    }

    &__line {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        width: 60px;
        height: 3px;
        background-color: var(--color-accent);
    }

    &__description {
        padding-left: 20px;
        padding-right: 19px;
    }
}
