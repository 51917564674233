.alpha-menu {
    padding: 10px 0;
    background-color: var(--color-accent-light);

    @media (--viewport-xs-max) {
        margin: 0;
        overflow: hidden;
    }

    @media (--viewport-ms-min) {
        padding: 11.5px 30px;
    }

    @media (--viewport-sm-min) {
        padding: 20px 33px;
    }

    &__list {
        overflow-x: scroll;
        scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox*/
        -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge*/
        display: flex;
        margin: 0 -12.5px;

        &::-webkit-scrollbar {
            display: none; /* Hide the scrollbar in Chromium */
        }

        @media (--viewport-ms-min) {
            flex-wrap: wrap;
            margin: 0 -12px;
        }

        @media (--viewport-sm-min) {
            margin: 0 -10px;
        }
    }

    &__item {
        &:first-child {
            @media (--viewport-xs-max) {
                padding-left: 20px;
            }
        }

        &:last-child {
            @media (--viewport-xs-max) {
                padding-right: 20px;
            }
        }
    }

    &__input {
        position: absolute;
        left: -9999px;
        opacity: 0;

        &:hover,
        &:focus {
            + .alpha-menu__label {
                color: var(--color-accent);
            }
        }

        &:disabled {
            + .alpha-menu__label {
                color: var(--color-disabled);
            }
        }
    }

    &__label {
        display: block;
        padding: 10px 12.5px;
        text-transform: uppercase;
        font-family: "Jornada Sans Medium";
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1.4px;
        color: var(--color-secondary);
        transition: all 0.3s;

        @media (--viewport-ms-min) {
            padding: 8.5px 12px;
            font-family: "Jornada Sans Book";
            font-size: 13px;
            line-height: 23px;
            letter-spacing: 0;
        }

        @media (--viewport-sm-min) {
            padding: 10px;
            font-family: "Jornada Sans Medium";
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 1.4px;
        }

        &.visible {
            color: var(--color-accent);
        }
    }
}
