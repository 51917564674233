.advantages-comparison {

    &__title {
        display: block;
        text-align: center;
        margin-bottom: 40px;

        .title-l {
            color: var(--color-text);
        }

        @media (--viewport-ms-min) {
            margin-bottom: 60px;
        }

        @media (--viewport-ms-max) {
            font-size: 26px;
        }


    }

    &__table {
        display: flex;
        flex-flow: column;

        &-duplex {

            display: flex;
            flex-flow: row;
            justify-content: flex-end;

            @media(--viewport-ms-max) {
                display: none;
            }

            &-ourBank {
                display: flex;
                flex-direction: column;
                width: 33%;
                padding: 20px 0px;
                background-color: var(--color-accent);

                &-label {
                    text-align: center;
                    padding: 0rem 1rem;

                }

                &-desc {
                    text-align: center;
                    padding: 0rem 1rem;
                }

            }

            &-otherBanks {

                display: flex;
                flex-direction: column;

                width: 33%;
                padding: 20px 0px;

                color: var(--color-text);

                &-label {
                    text-align: center;
                    padding: 0rem 1rem;
                }

                &-desc {
                    text-align: center;
                    font-size: 18px;
                    font-weight: lighter;

                    padding: 0rem 1rem;
                }

            }

        }

        &-triplet {

            display: flex;
            flex-flow: row;
            justify-content: flex-end;

            min-height: 55px;

            background-color: var(--color-primary);

            &:nth-child(even) {
                filter: brightness(95%);
            }

            @media(--viewport-ms-max) {
                flex-flow: column;
                padding-bottom: 15px;

                &:nth-child(even) {
                    filter: brightness(100%);
                }
            }


            &-accCell {
                width: 33%;

                @media(--viewport-ms-max) {
                    width: 100%;
                }
            }

            &-ourBankCheckCell {
                display: flex;
                justify-content: center;
                width: 33%;

                background-color: var(--color-accent);

                @media(--viewport-ms-max) {
                    display: none;
                }

                &-check {
                    display: block;
                    width: 22px;
                    height: auto;
                }

                &-cross {
                    display: block;
                    width: 18px;
                    height: auto;
                }
            }

            &-otherBanksCell {
                display: flex;
                justify-content: center;
                width: 33%;

                @media(--viewport-ms-max) {
                    display: none;
                }

                &-check {
                    display: block;
                    width: 20px;
                    height: auto;
                }

                &-cross {
                    display: block;
                    width: 16px;
                    height: auto;
                }

            }

            /*INFORMATIVE CELL ONLY DISPLAYED IN MOBILE PORTS */
            &-informativeCell {
                display: none;
                background-color: #F5F5F5;

                @media(--viewport-ms-only) {
                    height: 60px;
                }

                @media(--viewport-ms-max) {
                    display: flex;
                    min-height: 40px;
                    justify-content: space-around;
                    align-items: center;
                }

                &-ourBankMobileCell {
                    display: flex;
                    flex-flow: row;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    min-height: 40px;

                    @media(--viewport-ms-only) {
                        height: 60px;
                    }

                    background-color: var(--color-accent);

                    &-text {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        padding-left: 7px;
                        padding-top:4px;

                        .bodycopy-l {
                            color: var(--color-primary);
                            font-size: 16px;
                            line-height: 1;

                            @media(--viewport-xs-max) {
                                font-size: 14px;
                            }
                        }

                    }

                    &-iconCross {
                        display: block;
                        width: 14px;
                        height: 14px;

                        @media(--viewport-xs-max) {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    &-iconCheck {
                        display: block;
                        width: 16px;
                        height: 16px;

                        @media(--viewport-xs-max) {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }

                &-otherBanksMobileCell {
                    display: flex;
                    flex-flow: row;
                    justify-content: center;
                    align-items: center;
                    min-height:40px;

                    width: 50%;
                    height: 100%;

                    @media(--viewport-ms-only) {
                        height: 60px;
                    }

                    &-text {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        padding-left: 7px;
                        padding-top:4px;

                        .bodycopy-l {
                            color: var(--color-text);
                            font-size: 16px;
                            line-height: 1;

                            @media(--viewport-xs-max) {
                                font-size: 14px;
                            }
                        }
                    }

                    &-iconCheck {
                        display: block;
                        width: 15px;
                        height: 15px;

                        @media(--viewport-xs-max) {
                            width: 13px;
                            height: 13px;
                        }
                    }

                    &-iconCross {
                        display: block;
                        width: 13px;
                        height: 13px;

                        @media(--viewport-xs-max) {
                            width: 11px;
                            height: 11px;
                        }
                    }

                }

            }

        }

    }


}