.resources-center {
    display: flex;
    flex-flow: column;

    &_results {
        .resources-center {
            &__title {
                display: none;
            }
            &__resultsText{
                display:flex;
                flex-flow:column;
                align-items: flex-start;
                /*padding-bottom:50px;*/
            }
        }
    }

    &__resultsText {
        display:none;
        margin-bottom: 2rem;
        &-generic {
            margin-bottom: var(--gutter-ms-vertical);
            .bodycopy-l {
                font-family: 'Jornada Sans Book';
            }
        }

        &-chosen {
            margin-bottom: var(--gutter-ms-vertical);
            .bodycopy-l {
                font-family: 'Jornada Sans Medium';
                display: inline;
            }

            .clear-filter{
                color: var(--color-accent-dark);
                margin-left: var(--gutter-width);
                
            }
        }
    }

    &__title {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-bottom: 50px;

        .title-l {
            color: var(--color-text);
        }
    }

    &__list {
        display: block;

        @media(--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }
    }


    &__card {
        display: block;
        padding-bottom: 20px;



        @media(--viewport-ms-max) {
            display: flex;
            justify-content: center;

            width: 100%;
            padding: 0px 0px;

        }

    }





}