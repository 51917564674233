.login-form {

    .title-l {
        margin-bottom: 40px;
    }

    .login-form__content {
        position: relative;
        .forget {
            position: absolute;
            right: 0;
            margin-top: -58px;
            @media (--viewport-ms-max) {
                margin-top: -56px;
            }
        }

        .form-checkbox-group {
            margin-top: -20px;
        }

        .form-btn {
            margin-top: 8px;
        }
    }
}