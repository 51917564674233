[class*='title-'] {
    font-family: var(--font-family__title);
    letter-spacing: var(--font-letter-spacing-wide);
}
.title {
    &-xl, &-XL {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-11);
            line-height: var(--font-line-height-8);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-12);
            line-height: var(--font-line-height-9);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-12);
            line-height: var(--font-line-height-9);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-14);
            line-height: var(--font-line-height-11);
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-14);
            line-height: var(--font-line-height-11);
        }
    }
    &-l, &-L {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-9);
            line-height: var(--font-line-height-6);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-10);
            line-height: var(--font-line-height-7);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-10);
            line-height: var(--font-line-height-7);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-11);
            line-height: var(--font-line-height-8);
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-11);
            line-height: var(--font-line-height-8);
        }
    }
    &-m, &-M {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-6);
            line-height: var(--font-line-height-3);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-7);
            line-height: var(--font-line-height-4);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-7);
            line-height: var(--font-line-height-4);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-8);
            line-height: var(--font-line-height-5);
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-8);
            line-height: var(--font-line-height-5);
        }
    }
    &-s, &-S {
        /*No responsive scaling*/
        font-family: var(--font-family__title-s);
        letter-spacing: var(--font-letter-spacing-wide);
        font-size: var(--font-size-5);
        line-height: var(--font-line-height-3);
    }
}
