.application-form-lite {
    &__description {
        .bodycopy-m{color: var(--color-accent);}
    }

    &__head {
        margin-bottom: 80px;
        @media (--viewport-ms-max) {
            margin-bottom: 40px;
        }
    }

    &__text {
        h2{
            color: var(--color-text-accent);
            margin-bottom: 32px;
        }
    }

    .form-list__field {
        margin-bottom: 0; /* para evitar el margen del componente form-list*/
        
        h4{
            color: var(--color-text-accent);
            margin: 56px 0 32px;
        }
    }

    .legal {
        margin: 36px 0 56px;
    }
    .form-btn {
        margin-top: 0;
        @media (--viewport-ms-max) {
            margin-bottom: 64px;
        }
    }

    &.ico{
        .application-form-lite__head {
            margin-bottom: 104px;
            @media (--viewport-ms-max) {
                margin-bottom: 40px;
            }
            @media (--viewport-xs-max) {
                margin-bottom: 32px;
            }
            .quota-simulation-summary{
                @media(--viewport-ms-max) {
                    padding: 32px 40px;
                }
                @media(--viewport-xs-max) {
                    padding: 24px 20px;
                }
            }
        }
    }
}