.number-l {
    font-family: 'Jornada News Book';
    font-size: 30px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0;
    color: var(--color-text-quaternary);

    @media (--viewport-ms-min) {
        font-size: 32px;
    }

    @media (--viewport-sm-min) {
        font-size: 50px;
    }

    @media (--viewport-md-min) {
        font-size: 70px;
    }

    @media (--viewport-lg-min) {
        font-size: 90px;
    }

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}
