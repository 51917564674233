.tags-links {
    &__head {
        margin-bottom: 30px;

        @media (--viewport-ms-min) {
            margin-bottom: 34px;
        }
    }

   

    &__list {
        overflow-x: scroll;
        scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox */
        -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
        display: flex;
        margin-right: -19px;
        flex-wrap: wrap;


        
        align-content: space-between;
        row-gap: 10px;
        column-gap: 10 px;

        @media (--viewport-ms-min) {
            margin-right: -31px;
        }

        @media (--viewport-md-min) {
            margin-right: -32px;
        }

        @media (--viewport-lg-min) {
            margin-right: -50px;
        }

        &::-webkit-scrollbar {
            display: none; /* Hide the scrollbar in Chromium */
        }
    }

    &__item {
        flex-shrink: 0;
        margin-right: 20px;

        @media (--viewport-ms-only) {
            margin-right: 30px;
        }

        &:last-child {
            padding-right: 19px;
            margin-right: 0;

            @media (--viewport-ms-min) {
                padding-right: 31px;
            }

            @media (--viewport-md-min) {
                padding-right: 32px;
            }

            @media (--viewport-lg-min) {
                padding-right: 50px;
            }
        }
    }

    &__link {
        display: flex;
        padding: 5px 20px 4px;
        background-color: var(--color-bg-1);
        transition: all 0.3s;
        .text-label-s{color: var(--color-txt-quinary);}

        &:hover,
        &:focus {
            background-color: var(--color-accent);
            .text-label-s{color: var(--color-text-light);}
        }
    }
}



