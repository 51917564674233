.dedicated-links {

    &__text {
        margin-bottom: 48px;
        text-align: center;
    }
    &__label {
        margin-bottom: 20px;
        text-align: center;
    }
    &__pagination {
        margin-top: 48px;
        .pagination{
            display:flex;
            justify-content: center;
        }
    }
    &__bottom-link {
        margin-top: 48px;
        text-align: center;
    }

    &__title {
        &.secondary-color{
            .title-l{color: var(--color-secondary);}
        }
    }


    &__desc {
        margin-top: 20px;
    }

    &__list {
        @media (--viewport-sm-min) {
            margin-bottom: -30px;
        }

        .col {
            &:last-child {
                .dedicated-links__item {
                    @media (--viewport-ms-max) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 30px;
    }

    &__related-link{
        text-align: center; margin-top: 60px;
    }
    
    &.accent-light-bg{background-color: var(--color-accent-light);
        @media (--viewport-xs-max) {
            padding: 0 20px;
            margin: 0 -20px;
        }
        .dedicated-links__title {margin-top:50px;}
        .dedicated-links__related-link {margin-bottom:50px;}
        .dedicated-link .link{border-color: var(--color-accent-dark);}
    }
}
