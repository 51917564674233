.graph-progress-bar {
    &-content {
        width:100%;
        height:100%;
        /* transform: rotate(30deg); */
    }
    .donuts {
        stroke-width:1.4px;
        &.ring {
            stroke:var(--color-accent-dark);
        }
        &.segment {
            stroke:var(--color-accent);
        }
    }
}
.line-graph {
    .base {
        stroke:var(--color-gray-10);
        position: relative;
    }
    .line {
        stroke:var(--color-accent-dark);
        position: absolute;
        z-index: 1;
    }
}