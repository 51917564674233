.search-engine {
    position:absolute;
    bottom:23px;
    width: 100%;
    &.fractal{ /* Esta clase es para la previsualización en Fractal */
        position: relative; top:auto; right:auto;
    }

    .fieldset{
        label{
            color: var(--color-white);
            font: normal 26px var(--base-font-family);
            text-transform:none;
            left:0;
        }
    
        .input {
            padding-top:5px;
            padding-bottom:5px;
            padding-right: 40px;
            padding-left: 0;
            border:0;
            border-bottom: 1px solid var(--color-white);
            color: var(--color-white);
            font: normal 26px var(--base-font-family);
        
    
            @media (--viewport-ms-min) {
                padding-right: 50px;
            }
            &::placeholder{
                opacity: 1;
                color: var(--color-white);
                font: normal 26px var(--base-font-family);
            }
        }
    }
    &__icon {
        position: absolute;
        top: 20px;
        right: 15px;
        transform: translate(0, -50%);
        display: flex;
        .icon{
            width: 18px; 
            height: 18px; 
            fill:var(--color-white);
        }
    }
    &__dropdown {
        position:absolute;
        width: calc(100% - 30px);
        background-color: var(--color-white);
        z-index: 9999;
        top:62px;
        left:15px;
        padding: 20px 31px 0;
        color: var(--color-accent-dark);
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
        li{line-height: 120%;padding-bottom:20px;}
    }    
}