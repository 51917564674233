.newsletter {
    @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
    }

    &__box {
        display: flex;
        align-items: center;
        min-height: 437px;
        padding: 40px 20px;
        background-color: var(--color-accent);

        @media (--viewport-ms-min) {
            min-height: 475px;
            padding: 60px 0;
        }

        @media (--viewport-sm-min) {
            min-height: 370px;
            padding: 80px 0;
        }

        .row {
            flex-grow: 1;
        }
    }

    &__text {
        @media (--viewport-ms-max) {
            margin-bottom: 40px;
        }
    }

    &__description {
        margin-top: 10px;

        @media (--viewport-ms-min) {
            margin-top: 20px;
        }
    }

    &__fieldset {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__form-list {
        .form-list{
            .field-top label{
                color: var(--color-white);
            }
            .error-message{
                color: var(--color-white);
            }
            .form-list__helper-text .bodycopy-xs{
                color: var(--color-accent-light);
            }
            .form-list__valid-indicator{
                top: 21px;
            }
        }
        .legal__bodycopy{
            .bodycopy-s{
                color: var(--color-white);
            }
            a.link{
                color: var(--color-white);
                span:after{
                    background-color: var(--color-white);
                }
            }
            .legal__link{
                .tooltip{
                    .icon{
                        fill: var(--color-white);
                    }
                    .msg-tooltip{
                        .bodycopy-s{
                            color: var(--color-secondary);
                        }
                        a.link{
                            color: var(--color-secondary);
                            span:after{
                                background-color: var(--color-accent);
                            }
                        }
                    }
                }
            }
        
        }
        .legal__checkbox{
                .checkbox-label{
                    background-color: var(--color-white);
                    margin-right: 10px;
                    margin-left: -12px;
                    width: 24px;
                }
            
            .checkbox-wrapper_regular .checkbox:hover+.checkbox-label:before,
            .checkbox-wrapper_regular .checkbox:focus+.checkbox-label:before{
                border-color: var(--color-secondary-light);
            }
            .error-message{
                color: var(--color-white);
            }
        }
        .recaptcha{
            margin-top: 0;
            .error-message{
                color: var(--color-white);
            }
        }
        
        @media (--viewport-sm-min) {
            .form-btn{
                margin-bottom: -20px;
            }
        }
        
    }

    @media (max-width: 350px) {
        .recaptcha{
            .g-recaptcha div{
                width: 100%!important; /* Para machacar los estilos del propio recaptcha, que a 320px estiraba el formulario (CA) */
                float: left;
                iframe{
                    width: 100%;
                }
            }
        }
    }

}
