.btn {
    cursor:pointer;
    display: inline-block;
    padding: 13px 30px;
    outline: none;
    border: none;
    text-align: center;
    text-decoration: none;
    color: var(--color-text-button-primary);
    background-color: var(--color-button-primary);
    transition: all 0.3s;
    /* quitar cuando se cambién el texto a .caption-call-to-action (CA)*/ 
    display: var(--font-letter-spacing-wide);
    font-size: var(--font-size-4);
    line-height: var(--font-line-height-3);
    font-family: var(--font-family-sans-mid);
    /* fin de comentario */

    &:hover,
    &:focus {
        background-color: var(--color-button-primary-dark);
    }

    &_fullwidth {
        width: 100%;
    }

    &.justIcon{
        padding: 17px;
        line-height: 0.5;
    }

    &_accent {
        background-color: var(--color-accent);

        &:hover,
        &:focus {
            background-color: var(--color-accent-dark-tertiary);
        }
    }

    &_light {
        background-color: var(--color-accent-light);
        color: var(--color-black-90);

        &:hover,
        &:focus {
            background-color: var(--color-hover-accent-light);
        }
    }

    &_dark {
        color: var(--color-text-light);
        background-color: var(--color-accent-dark);

        &:hover,
        &:focus {
            background-color: var(--color-hover-accent-dark);
        }
    }

    &_small {
        padding: 5px 20px 4px;
        font-size: 9px;
        text-transform: uppercase;
        line-height: 19px;
        letter-spacing: 1.2px;
        color: var(--color-black-90);
        background-color: var(--color-accent-light);

        &:hover,
        &:focus {
            background-color: var(--color-accent);
        }
    }

    &_secondary {
        padding: 12px 29px;
        color: var(--color-text-button-secondary);
        background-color: transparent;
        border: solid 1px var(--color-border-button-secondary);
        transition: all 0.3s;

        &:hover,
        &:focus {
            border-color: var(--color-border-button-secondary-dark);
            color: var(--color-text-button-secondary-dark);
            background-color: transparent;
        }
    }

    &:disabled, &.disabled {
        color: var(--color-text-disabled);
        background-color: var(--color-background-cta-secondary-disabled);
    }
    &:disabled {
        cursor: not-allowed;
    }

    .icon {
        position: relative;
        &-before {
            padding:0;
            right: 10px;
        }
    
        &-after {
            padding:0;
            left: 10px;
        }
    }
    &_bottomless {
        background-color: transparent;
        padding:0;
        .icon {
            fill: var(--color-grey2);
        }
        &:hover,
        &:focus {
            background-color: transparent;
            .icon {
                fill: var(--color-accent);
            }
        }
    }
}
