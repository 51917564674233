.bullet-point {
    display: flex;
    flex-flow: row;

    min-height: 700px;
    height: fit-content;

    @media(--viewport-ms-max) {
        flex-flow: column;
    }

    &_accentLightTheme {
        background-color: var(--color-accent-light);

        .bullet-point .swiper-slide {
            background-color: var(--color-accent-light);
        }

        .bullet-point .swiper-navigation {
            background-color: var(--color-accent-light);
        }

        .swiper-container {
            background-color: var(--color-accent-light);
        }

    }

    &_onlyOneImgTheme {
        .bullet-point .swiper-navigation {
            display: none;
        }
    }

    &__head {
        display: flex;
        flex-flow: column;
        width: 50%;

        padding-bottom: 25px;

        @media (--viewport-sm-min) {
            padding-right: 30px;
        }

        @media(--viewport-ms-max) {
            width: 100%;
        }

        .title-l {
            color: var(--color-text);
            padding-bottom: 40px;

            @media(--viewport-ms-max) {
                padding-bottom: 22px;
            }
        }


        &-list {
            display: flex;
            flex-flow: column;

            @media (--viewport-sm-min) {
                padding-right: 15px;
            }

            &-item {
                display: flex;
                flex-flow: row;
                flex-grow: 1;
                margin-top: 30px;

                .icon {
                    margin-right: 10px;
                    min-height: 20px;
                    min-width: 20px;

                    @media (--viewport-sm-min) {
                        min-height: 26px;
                        min-width: 26px;
                    }
                }


                &-description {
                    text-align: left;
                }

            }
        }


    }


    &__slider {
        text-align: center;
        width: 50%;
        height: fit-content;

        @media(--viewport-ms-max) {
            width: 100%;
            padding-top: 40px;
        }

        &-img {
            height: 650px;
            width: 440px;

            align-self: center;
            object-fit: cover;

            @media (--viewport-sm-only) {
                height: 550px;
                width: 340px;
            }

            @media (--viewport-sm-min) {
                margin-left: 30px;
            }

            @media(--viewport-ms-max) {
                width: 100%;
                object-fit: cover;
            }

            @media(--viewport-xs-max) {
                max-height: 420px;
                width: 100%;
                object-fit: cover;
            }

        }

    }


}

.bullet-point .swiper-wrapper {
    height: fit-content;
}

.bullet-point .swiper-slide {
    margin-bottom: 0px;
    background-color: transparent;
}

/*.bullet-point {
    margin: 0;
    height: fit-content;
}*/

.bullet-point .swiper-navigation {
    /* max-width: 267px;
    margin-left: auto;
    margin-right: auto; */

    max-width: 523px !important;
    padding: 30px 0 10px 0px;
    margin-left: 53px;
    margin-right: 23px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: unset;
    bottom: 0;
    right: 0;

    @media(--viewport-ms-max) {
        padding: 30px 20px 10px 20px;
        max-width: unset !important;
        margin: 0;
    }

}