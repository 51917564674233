.ucipt-higlight-multi-slide {
    background-color: #fff;
    position: relative;
    padding-top: 100px;
    margin-bottom: 50px;
    z-index: 0;

    @media (--viewport-ms-max) {
        padding-top: 0;
        margin-top:55px;
    }
    
    .swiper-slide{
        background-color:var(--color-white);
    }

    .swiper-navigation {
        max-width: 267px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;

            &::after {
                display: none;
            }

            .icon {
                height: 20px;
                width: 28px;
                fill:var(--color-accent)
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 0;
            width: 28px;
            height: 20px;
            margin-top: 0;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }
    }
}
