.label-m {
    font-family: 'Jornada Sans Medium';
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: 1.2px;
    color: var(--color-text-tertiary);
    transition: color 0.3s;

    /* Comentado para evitar que se desborden algunos labels del application form (CR)
    @media (--viewport-lg-min) {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1.4px;
    }*/

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}