.subsidy-finder {
    .row.aling-items{
        align-items: center;
    }
    &__withoutresults div {
        display: flex; justify-content: center; align-items: center;
        img{
            width: 30%;
            @media (--viewport-sm-max) {
                width: 50%;
            }
            @media (--viewport-sm-max) {
                width: 30%;
            }
            @media (--viewport-xs-max) {
                width: 50%;
            }
        }
    }
    &__notfound div {
        display: flex; justify-content: center; align-items: center;flex-direction:column;
        @media (--viewport-ms-max) {
            align-items:  flex-start;
        }
        img{
            width: 30%;
            @media (--viewport-sm-max) {
                width: 50%;
            }
            @media (--viewport-sm-max) {
                width: 30%;
            }
            @media (--viewport-xs-max) {
                width: 50%;
            }
            @media (--viewport-ms-max) {
                margin-top: 70px;
            }
        }
        .title-xl, .bodycopy-m {
            text-align: left;
        }
        .title-xl{
            margin-top: 20px;
            margin-bottom: 30px;
            color: var(--color-text-primary);
        }
        @media (--viewport-sm-min) {
            .title-xl, .bodycopy-m {
                width: 60%;
                margin-left: 30%;
                margin-right: 10%;
            }
            .title-xl{
                margin-top: 0;
            }
        }
    }
    &__results {
        margin-bottom: 60px;
        @media (--viewport-ms-max) {
            margin-top: 60px;
        }

        .accordion ol li .accordion__trigger{
            width: calc(100% - 3em);
        }

        .accordion__item-description{
            margin-left: 3em;
        }
    }
    &__preloader {
        position: relative;
        height: 0;
        top: 200px;
    }

    &__accordion {
        margin-bottom: 40px;
    }

    &__pagination {
        .pagination__list {
            justify-content: center;
            padding-top: 40px;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 46px;

        @media (--viewport-ms-min) {
            margin-bottom: 40px;
        }
        @media (--viewport-ms-max) {
            margin-top: 0;
        }
    }
}
