.steps-header {
    overflow-x: clip;
    margin-bottom: -102px;
    overflow-x: clip;

    /*@media (--viewport-lg-min) {
        margin-bottom: -207px;
    } Lo quito porque provoca que se solape en el simulador el ancho completo header (CA)*/

    &__head {
        background-color: var(--color-accent);

        &-wrapper {
            padding: 13px 0 12px;
        }
    }

    &__logo {
        display: flex;

        &-link {
            display: block;
            height: 25px;
            transition: all 0.3s;

            &:hover,
            &:focus {
                opacity: 0.8;
            }
        }

        &-img {
            display: block;
            width: auto;
            max-height: 100%;
        }
    }

    &__language {
        position: relative;

        @media (--viewport-lg-min) {
            margin-right: -26px;
        }

        .popup {
            &__trigger {
                color: var(--color-text-light);

                &:hover,
                &:focus,
                &.active {
                    opacity: 0.6;
                }
            }
            &__click{
                @media (--viewport-sm-max) {
                    left:auto;right: -5px;transform:inherit;
                }
            }
        }
    }

    &__body {
        &-wrapper {
            @media (--viewport-xs-max) {
                margin-left: -20px;
                margin-right: -19px;
            }

            @media (--viewport-ms-min) {
                margin-right: -31px;
            }

            @media (--viewport-sm-min) {
                margin-right: -29px;
            }

            @media (--viewport-md-min) {
                margin-right: -32px;
            }

            @media (--viewport-lg-min) {
                margin-right: -50px;
            }
        }
    }

    &__back-link {
        padding: 14px 0;

        @media (--viewport-xs-max) {
            display: none;
        }
    }

    &__steps {
        text-align: right;

        &-list {
            display: inline-flex;

            @media (--viewport-xs-max) {
                display: flex;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 13px 31px;
            background-color: var(--color-accent-dark);

            &.active {
                padding: 12px 30px;
                background-color: var(--color-quaternary);

                @media (--viewport-xs-max) {
                    flex-grow: 1;
                }

                .steps-header__steps-step {
                    font-family: 'Jornada Sans Book';
                    font-size: 16px;
                    line-height: 26px;
                    color: var(--color-text);
                }
            }

            &:first-child {
                @media (--viewport-xs-max) {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
        }

        &-step {
            font-family: 'Jornada Sans Medium';
            font-size: 14px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0;
            color: var(--color-text-light);

            @media (--viewport-sm-only) {
                font-size: 13px;
                line-height: 23px;
            }
        }
    }
}
.landing-header{
    overflow-x: clip;
}
