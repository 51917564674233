.contact-cards {
    &__title{
        margin-bottom: 32px;
    }
    &__additional{
        a{color: var(--color-accent);text-decoration: underline;font-weight: 700;}
        margin-top: 32px;
    }
    @media (--viewport-ms-max) {
        &__additional{
            margin-top: 8px;
        }
        &__item{
            margin-bottom: 24px;
        }
    }
}