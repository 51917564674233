.access-select {
    position: relative;

    &__option {
        display: block;
        width: 100%;
        padding: 15px 20px 15px 15px;
        outline: none;
        border-right: 1px solid var(--color-secondary-light);
        border-left: 1px solid var(--color-secondary-light);
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 24px;
        color: var(--color-text);
        background-color: transparent;

        @media (--viewport-ms-min) {
            padding-right: 30px;
        }
    }

    &__item:first-child{
        .access-select__option{padding-top: 25px;}
    }
    &__item:last-child{
        .access-select__option{padding-bottom: 25px;}
    }

    &__chosen {
        position: relative;
        cursor: pointer;

        &:hover {
            .access-select {
                &__chosen {
                    &-option {
                        border-color: var(--color-secondary);
                    }
                }
            }
        }

        &-option {
        height: 50px;
        padding-top: 14px;
        padding-right: 40px;
        border: 1px solid var(--color-grey3);
        transition: border-color 0.3s;
        opacity: 1;
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 24px;
        color: var(--color-grey2);

            @media (--viewport-ms-min) {
                padding-right: 50px;
            }
            
        }

        .access-select__chosen-option_hidden.valid + .access-select__chosen-option{
            color: var(--color-text);
        }

        &-icon {
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translate(0, -50%);
            transition: all 0.1s;
            .icon{
                color: var(--color-grey2);
            }
        }

        &.list-is-open {
            .access-select__chosen {
                &-option {
                    border-color: var(--color-secondary);
                }

                &-icon {
                    transform: translate(0, -50%) rotate(-180deg);
                }
            }
            & + .access-select__list.overflow{
                border-top:solid 1px var(--color-secondary);
                margin-top:-1px;
            }
        }

        &.active {
            .access-select {
                &__chosen {
                    &-label {
                        top: 13px;
                        transform: none;
                        color: var(--color-accent-dark);
                    }
                }
            }
        }
    }

    &__list {
        position: absolute;
        z-index: 9999;
        right: 0;
        left: 0;
        overflow: hidden;
        height: 0;
        background-color: var(--color-primary);

        .access-select {
            &__option {
                border-color: var(--color-secondary);
            }
        }

        &.overflow{
            min-width: min-content;
            li{
                white-space: nowrap;
            }
        }
    }

    &__item {
        position: relative;

        &:last-child {
            border-bottom: 1px solid var(--color-secondary);
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;

        &:hover,
        &:focus {
            + .access-select__label {
                text-decoration: underline;
            }
        }

        &.checked {
            + .access-select__label {
                color: var(--color-accent);
            }
        }
    }

    &__label {
        transition: color 0.3s;
    }

    &.disabled {
        .access-select__chosen-option{
        background-color: var(--color-grey-light);
        border-color: var(--color-quinary);
        }
    }
    input.input-validation-error + .access-select__chosen-option{border-color:var(--color-danger);}

    &.disabled{
        .access-select__list{display:none;}
        .list-is-open {
            .access-select__chosen {

                &-icon {
                    transform: translate(0, -50%) rotate(0deg);
                }
            }
        }
    }
}
