.calculator-form-list {
    li{
        &.col-sm-4, &.col-md-4, &.col-lg-4{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field{
                    @media (--viewport-ms-min) {margin-top: 0;}
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
            &:nth-child(3) .form-list__field {
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
        }
        &.col-sm-6, &.col-md-6, &.col-lg-6{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field{
                    @media (--viewport-ms-min) {margin-top: 0;}
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
        }
        &.onecolumn{
        }
        .form-list__field{
            position: relative;
        }
        .form-range-slider {
            flex-grow: 0;
        }
        /*.access-select__chosen-option, .form-fieldset {
            margin-top: 32px;
            @media (--viewport-xs-max) {margin-top: 0;}
        }*/
        &:last-child{
            .form-list__field{margin-bottom: 0;}
        }
    }
    /*.form-list__error-message {
        opacity: 1;
    }
    .input-validation-error {
         border-color: #e4002b;
        color: #e4002b;
    }*/
    .hidden {
        display: none;
    
        &-xs {
            @media (--viewport-xs-max) {
                display: none;
            }
    
            &-ms {
                @media (--viewport-ms-max) {
                    display: none;
                }
            }
        }
    
        &-ms {
            @media (--viewport-ms-only) {
                display: none;
            }
    
            &-min {
                @media (--viewport-ms-min) {
                    display: none;
                }
            }
        }
    
        &-sm {
            @media (--viewport-sm-only) {
                display: none;
            }
    
            &-min {
                @media (--viewport-sm-min) {
                    display: none;
                }
            }
        }
    }
}