.number-xs {
    font-family: 'Jornada Sans Book';
    font-size: 10px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0;
    color: var(--color-text);

    @media (--viewport-md-min) {
        font-size: 12px;
    }

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}

