.aggregator {
    &__title {
        margin-bottom: 60px;
        text-align: center;
    }

    &__item {
        display: flex;
        flex: 1;
    }

    &__list {
        margin-bottom: -30px;

        .col {
            margin-bottom: 30px;
        }
    }
}
