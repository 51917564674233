.lightbox-new {
    .modal {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        z-index: 8887;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: rgba(0, 0, 0, .3);
        transition: all .15s ease-in-out;

        &.fade-in {
            visibility: visible;
            opacity: 1;
        }

        &.fade-out {
            opacity: 0;
            visibility: hidden;
        }

        &-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            /*max-height: calc(100vh - 60px);*/
            @media (--viewport-ms-max) {
                .container {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            @media (--viewport-xs-max) {
                .container {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            /*@media (--viewport-xs-max) {
                max-height: 100vh;
                width: 100vw;

                &>.container>.row {
                    margin-right: -19px;
                    margin-left: -20px;
                }
            }*/

            .modal-box {
                position: relative;
                background-color: #fff;
                overflow: auto;
                max-height: calc(100vh - 116px);
                height: auto;
                /*padding-right: calc(20px - var(--half-gutter-width));
                padding-left: calc(20px - var(--half-gutter-width));*/
                padding: 0 40px 0;

                @media (--viewport-md-max) {
                    max-height: calc(100vh - 119px);
                }

                @media (--viewport-sm-max) {
                    max-height: calc(100vh - 30px);
                }
                
                @media (--viewport-ms-max) {
                    height: 100vh;
                    max-height: 100vh;
                    padding: 0 0 0;
                }

                .modal-header {

                    padding: 40px 72px 0;

                    @media (--viewport-md-max) {
                        padding: 40px 64px 0;
                    }

                    @media (--viewport-sm-max) {
                        padding: 40px 44px 0;
                    }

                    @media (--viewport-ms-max) {
                        padding: 40px 30px 0;
                    }

                    @media (--viewport-xs-max) {
                        padding: 40px 20px 0;
                    }

                    .title-l + .bodycopy-m {
                        margin-top: 32px;
                    }
                }

                .modal-footer {
                    padding: 0 72px;
                    @media (--viewport-md-max) {
                        padding: 0 64px;
                    }

                    @media (--viewport-sm-max) {
                        padding: 0 44px;
                    }

                    @media (--viewport-ms-max) {
                        padding: 0 30px;
                    }

                    @media (--viewport-xs-max) {
                        padding: 0 20px;
                    }

                    ul {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 40px;
                        margin-bottom: 64px;

                        @media (--viewport-ms-max) {
                            margin-bottom: 74px;
                        }

                        @media (--viewport-xs-max) {
                            justify-content: center;
                            flex-direction: column;
                            margin-bottom: 40px;

                            li {
                                margin-bottom: 40px;
                                &:last-of-type{
                                    margin-bottom: 0;
                                }
                            }

                            li + li {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &.fixed {
                        position: sticky;
                        bottom: 0;
                        width: 100%;
                        background-color: var(--color-background);
                        z-index: 9;
                    }
                }

                .modal-container {
                    min-height: auto;
                    padding: 40px 72px 64px;

                    /*max-height: calc(100vh - 140px);*/
                    @media (--viewport-md-max) {
                        padding: 40px 64px 64px;
                    }

                    @media (--viewport-sm-max) {
                        padding: 40px 44px 64px;
                    }

                    @media (--viewport-ms-max) {
                        padding: 40px 30px 64px;
                    }

                    @media (--viewport-xs-max) {
                        padding: 40px 20px 64px;
                    }

                    &.withFooter {
                        padding-bottom: 0;
                    }

                    &.empty-state{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media (--viewport-sm-max) {
                            padding-top:40px;
                            padding-bottom:40px;
                        }
                    }

                    .modal-body {

                        /*.row{background-color: var(--color-white);} Lo comento porque genera error al meter dentro del splitter un componente producto, por ejemplo (CA) */
                        &.legals {
                            margin-bottom: 20px;

                            p a {
                                text-decoration: underline;
                                color: var(--color-secondary);
                            }
                        }

                        &.legals {
                            margin-bottom: 20px;

                            p a {
                                text-decoration: underline;
                                color: var(--color-secondary);
                            }
                        }

                        .modal-success {
                            margin-bottom: 20px;
                        }

                    }

                    .modal-head {
                        margin-bottom: var(--gutter-width);
                    }

                    .splitter {
                        .container {
                            margin: 0;
                            padding: 0;

                            .splitter__wrapper {
                                margin: 0;
                                /*padding: 0; lo comento para que se pueda poner espacio entre splitters (CA)*/
                                background: none;
                                @media (--viewport-ms-max) {
                                    .preloader.as-component {
                                        height: calc(100vh - 152px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-top {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            right: 0;
            top: 0;
            background-color: var(--color-background);
            padding: 0;
            position: sticky;
            z-index: 9;
            height: 48px;
        }

        &-close {
            position: relative;
            line-height: 0;
            margin-top: 24px;

            @media (--viewport-ms-max) {
                margin-right: 30px;
            }

            @media (--viewport-xs-max) {
                margin-right: 20px;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                cursor: pointer;
            }
        }

        &-title {
            color: white;
        }

        &__title {
            &.small {
                margin-bottom: 20px;

                .title-m {
                    color: var(--color-secondary);
                }
            }
        }


        &.empty {
            .modal-box {
                background: none;
                padding: 0;

                .modal-top {
                    height: auto;
                    background: none;
                    justify-content: flex-end;

                    .modal-close {
                        position: relative;
                        top: 0;
                        height: 40px;
                        right: 0;
                        float: right;

                        .icon {
                            background-color: var(--color-secondary);
                            width: 30px;
                            height: 30px;
                            fill: var(--color-white);
                            border-radius: 50%;
                        }
                    }
                }

                .modal-container {
                    padding: 0;

                    .container {
                        padding: 0;

                        .col-xs-12,
                        .col-ms-12,
                        .col-sm-12,
                        .col-lg-12 {
                            padding: 0;
                        }

                        h2,
                        .bodycopy-m {
                            display: none;
                        }
                    }

                    .splitter__wrapper {
                        .row {
                            margin: 0;

                            .media {
                                .row {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*.modal-toggle {
    cursor:pointer
}*/