.progress-bar-documents {
    &__content {
        display: flex;
        flex-flow: column;
        max-width: 100%;
        width: 100%;
        align-content: center;
        margin-top: var(--gutter-lg-vertical);
        margin-bottom: var(--gutter-ms-vertical);
        @media(--viewport-xxs-max) {
            display: block;
        }
        &__graph {
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0;
            &-pending {
                position: relative;
                width:250px;
                height:13px;
                justify-content: flex-start;
            }
        }
        &__text{
           
            display: flex;
            align-items:center;
            justify-content:  fles-start;
            gap: 10px;
            .stepper {
                padding: 8px 16px 4px 16px;
                background-color: var(--color-tertiary-brand);
            }
            .number-m {
                color: #21272A;
                margin-bottom: 8px;
            }
        }
    }
}