.ucipt-responsive-bg-image {
    width: 100%;
    height: /*527px*/524px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
