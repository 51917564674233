.description-list {
    .text-label-m{text-align: left;padding:0 10px;}
    dl{display: flex; flex-direction:row; width: 100%; flex-wrap:wrap;margin-top: 0;
        dt{border-bottom: solid 1px var(--color-border-accent-bold); width: 70%;padding: 15px 10px 7px 20px; text-align: left;
            .bodycopy-m{color: var(--color-text);overflow-wrap: break-word;}
            .bodycopy-l{color: var(--color-text);overflow-wrap: break-word; font-weight: bold;}
            &.total{border-bottom: 0;background-color: var(--color-background-accent);}
            @media (--viewport-sm-max){width: 60%;}
        }
        dd{border-bottom: solid 1px var(--color-border-accent-bold); width: 30%; margin: 0;padding: 15px 20px 7px 10px; text-align: right;
            .bodycopy-m{color: var(--color-text); font-weight: bold;}
            .bodycopy-l{color: var(--color-text); font-weight: bold;}
            &.total{border-bottom: 0;background-color: var(--color-background-accent);}
            @media (--viewport-sm-max){width: 40%;}
            &.grey{color: var(--color-grey2);
                .bodycopy-m{color: var(--color-grey2);}
            }
        }
    }
    @media (--viewport-sm-max){
        &:nth-of-type(1n) {
            margin-top: 30px;
        }
    }

    &.text-list{
        .description{
            margin: 32px 0;
        }
        dl{
            .wrap{display: flex; width: 100%; align-items: flex-start;column-gap:16px; margin-bottom: 24px;
                
                dt{border-bottom: none; width:fit-content;padding: 0; text-align: left;float:left; clear: left;white-space:nowrap;
                    .bodycopy-m{color: var(--color-text);overflow-wrap:normal;font-weight: bold; white-space:nowrap;}
                    &.ok{
                        .icon{
                            fill:var(--color-icon-success);
                            margin-right: 16px;
                        }
                    }
                    &.ko{
                        .icon{
                            fill:var(--color-icon-error);
                            margin-right: 16px;
                        }
                    }
                    
                }
                dd{border-bottom: none; width: auto; margin: 0;text-align: left;padding: 0;float:left;
                    .bodycopy-m{color: var(--color-text); font-weight: normal;color: var(--color-text-subtle);}
                }
                @media (--viewport-xs-max){
                    display: block;
                    dt, dd{width: 100%;}
                }
            }
        }
    }
}
