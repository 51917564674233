.card-contact {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: solid 1px var(--color-grey3);
    padding: 16px 48px;

    &__pic {
        overflow: hidden;
        margin-bottom: 10px;

        img {
            width: 40px;
            height: auto;
        }

        svg.icon {
            width: 40px;
            height: 40px;

            .inactive & {
                filter: grayscale(100%) contrast(0);
            }
        }
    }

    &__content {
        flex-grow: 1;
        text-align: center;
    }

    &__label {
        margin-bottom: 10px;
    }

    &__title {
        margin-bottom: 6px;
    }

    &__link,
    &__link2 {
        padding-top: 4px;

        .link {
            font-family: 'Jornada Sans Book';
            font-size: 16px;
            font-weight: normal;
            line-height: 26px;
            letter-spacing: 0;

            &.link_has-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 0;

                .icon {
                    top: auto;
                    position: relative;
                    margin-right: 15px;
                }
            }
        }
    }

    &__more-info {
        padding-top: 4px;

        .bodycopy-s {
            color: var(--color-grey2);
        }
    }

    &.inactive {
        border: solid 1px var(--color-border);

        .card-contact__title {
            .title-m {
                color: var(--color-text-disabled);
            }
        }

        .card-contact__link {
            .bodycopy-m {
                color: var(--color-text-disabled);
            }
        }
    }
}