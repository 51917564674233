.sitemap {
    padding-bottom: 160px;

    &__section {
        margin-bottom: 80px;

        &:last-child {
            margin-bottom: 0;
        }

        &-header {
            padding-bottom: 20px;
            margin-bottom: 60px;
            border-bottom: 1px solid var(--color-secondary-light);
        }
    }

    &__page {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        &-name {
            &-link {
                display: inline-block;

                &:hover,
                &:focus {
                    .title-s {
                        color: var(--color-accent);
                    }
                }

                .title-s {
                    font-family: 'Jornada Sans Medium';
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        &-content {
            margin: 20px 0 -20px;
        }

        &-level {
            &-1,
            &-2 {
                margin-bottom: 20px;
            }

            &-2 {
                &-list {
                    margin-bottom: -10px;
                }

                &-item {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
