.icon {
    width: 16px;
    height: 16px;
    fill: var(--color-secondary);
    transition: fill 0.3s;
    &.icon-big{
        width:auto;
        height:auto;
    }
    &.accent{fill: var(--color-accent);}
    &.accent-dark{fill: var(--color-accent-dark);}
    &.icon-16 {
        width: 16px;
        height: 16px;
    }
    &.icon-40 {
        width: 40px;
        height: 40px;
    }
    &.icon-56 {
        width: 56px;
        height: 56px;
    }
    &.icon-80 {
        width: 80px;
        height: 80px;
    }
}
[class*='icon-16_'] {
    width: 16px;
    height: 16px;
}
[class*='icon-24_'] {
    width: 24px;
    height: 24px;
}
[class*='icon-32_'] {
    width: 32px;
    height: 32px;
}
[class*='icon-40_'] {
    width: 40px;
    height: 40px;
}
[class*='icon-56_'] {
    width: 56px;
    height: 56px;
}
[class*='icon-80_'] {
    width: 80px;
    height: 80px;
}
