.form-select {
    margin-bottom: 20px;
    position: relative;
    

    .access-select {background-color: var(--color-white);
        .list-is-open + .access-select__list {
            max-height: 350px;
            overflow-y: auto;
        }
    }

    &.disabled {
        .access-select {
            background-color: var(--color-grey-light);
            border-color: var(--color-quinary);
            &__chosen-option {
                border-color: var(--color-quinary);
            }
        }
    }
    &.bgtransparent {
        .access-select {
            background: transparent;
        }
    }
    &.bgaccentlight {
        .access-select {
            background-color: var(--color-accent-light);
            &__chosen-option {
                border-color: var(--color-accent-light);
                &:hover,
                &:focus {
                    border-color: var(--color-secondary);
                }
            }
            .list-is-open + .access-select__list {
                background-color: var(--color-accent-light);
                border-color: var(--color-accent-light);
            }
            .access-select__item:last-child {
                border-color: var(--color-accent-light);
            }
            .access-select__option {
                border-color: var(--color-accent-light);
                &:hover,
                &:focus {
                    border-color: var(--color-secondary);
                }
            }
        }
    }
    &.error {
        .access-select {
            border-color: var(--color-danger);
            color: var(--color-danger);
            &__chosen-option {
                border-color: var(--color-danger);
            }
        }
        .field-bottom {
            &__error-message {
                display:block;
                position:relative;
            }
            &__helper-text{display:none;}
        }
    }

}
