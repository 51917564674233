.header-submenu {
    margin: 5px 0 35px;

    @media (--viewport-ms-max) {
        padding: 0 20px;
    }

    @media (--viewport-ms-only) {
        margin-top: 15px;
    }

    @media (--viewport-sm-min) {
        margin: 40px 0 30px;
    }

    &__products {
        @media (--viewport-sm-min) {
            display: flex;
        }

        &-featured {
            @media (--viewport-sm-min) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                padding: 20px;
                background-color: var(--color-accent-light);
            }

            @media (--viewport-md-min) {
                padding: 30px;
                margin-right: 20px;
            }

            .header-submenu__products-tile {
                /* Dejo comentadas estos estilos exclusivos para UCI que de ser necesarios requerirían de una variante en el hbs... (CA)
                &-title {
                    .text-label-m{
                        .bodycopy-m{
                            font-size:13px;
                            line-height:18px;
                        }
                    }
                }

                &-label {
                    .text-label-m{
                        color: var(--color-accent-dark);
                        font-family: 'Jornada News Book';
                        font-size:17px;
                        text-transform: none;
                    }
                }*/

                &-link {
                    @media (--viewport-ms-max) {
                        display: flex;
                    }
                }

                &-icon {
                    margin-bottom: 0;

                    @media (--viewport-sm-min) {
                        margin: 0 auto 10px;
                    }
                }

                &-text {
                    @media (--viewport-xs-max) {
                        margin-left: 20px;
                    }

                    @media (--viewport-ms-only) {
                        margin-left: 40px;
                    }

                    @media (--viewport-sm-min) {
                        text-align: center;
                    }
                }
            }

            &-desc {
                margin-top: 10px;
                text-align: center;
            }
        }

        &-main {
            &-desc {
                font-family: 'Jornada Sans Medium';
                font-size: 13px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0;
                color: var(--color-text);

                @media (--viewport-xs-max) {
                    margin-top: 30px;
                }

                @media (--viewport-ms-only) {
                    margin-top: 40px;
                }

                @media (--viewport-sm-min) {
                    margin-bottom: 20px;
                }
            }
        }

        &-list {
            @media (--viewport-xs-max) {
                margin-top: 20px;
            }

            @media (--viewport-ms-only) {
                margin-top: 30px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: -30px;
            }
        }

        &-item {
            margin-bottom: 30px;

            @media (--viewport-ms-min) {
                margin-bottom: 40px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 30px;
            }
        }

        &-tile {
            &-link {
                display: block;

                &:hover,
                &:focus {
                    .header-submenu__products-tile-title {
                        .bodycopy-m {
                            color: var(--color-accent);
                        }
                    }
                }
            }

            &-icon {
                max-width: 40px;
                margin-bottom: 10px;
            }

            &-img {
                display: block;
                max-width: 100%;
                height: auto;
            }

            &-label {
                margin-bottom: 5px;
            }
        }
    }

    &__sidebar {
        position: relative;
        height: 100%;
        padding-top: 20px;
        margin-left: 10px;

        @media (--viewport-md-min) {
            margin-left: 20px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: -25px;
            display: block;
            width: 1px;
            height: 100%;
            background-color: var(--color-quinary);
            content: '';

            @media (--viewport-md-min) {
                left: -35px;
            }
        }

        &-text {
            a {
                color: var(--color-accent);
                transition: all 0.3s;

                &:hover,
                &:focus {
                    color: var(--color-text);
                }
            }
        }
    }

    &__footer {
        @media (--viewport-ms-min) {
            margin-top: -10px;
        }

        @media (--viewport-sm-min) {
            margin-top: 30px;
        }

        &-link {
            @media (--viewport-sm-min) {
                text-align: right;
            }
        }
    }

    &__links {
        @media (--viewport-xs-max) {
            margin-bottom: 10px;
        }

        &-list {
            @media (--viewport-sm-min) {
                margin-bottom: -30px;
            }
        }

        &-item {
            margin-bottom: 20px;

            @media (--viewport-ms-min) {
                margin-bottom: 40px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 30px;
            }
        }

        &-title {
            display: block;
            .title-m{font-size: 16px; /*antes sin tamaño de fuente definido, ni la clase*/}

            &:hover,
            &:focus {
                .title-m {
                    color: var(--color-accent);
                }
            }
        }

        &-subitem {
            margin-top: 10px;

            @media (--viewport-sm-min) {
                &:first-child {
                    margin-top: 20px;
                }
            }
        }

        &-link {
            .link {
                display: block;
                color: var(--color-accent);

                &:hover,
                &:focus {
                    color: var(--color-text);
                }
            }
        }

        &-text {
            margin-top: 10px;
        }
    }
}