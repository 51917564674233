.image-carrousel {

    &__overlay {
        position: fixed;
        z-index: 1;
        top: 0;
        left:15px;
        width: 100%;
        height: 100vh;
        background:var(--color-blanket);
        @media (--viewport-xs-max) {
            left:10px;
        }
        & + .image-carrousel__content {
            height: 100vh;
            justify-content: center;
            align-items: center;    
            @media (--viewport-xs-max) {
                padding:0 10px;
                margin-top:0;
                margin-bottom:0;
            }
            @media (--viewport-ms-min) {
                margin-top:0;
                margin-bottom:0;
            }
            .image-carrousel__leyend{
                .bodycopy-s {
                    color:var(--color-text-inverse);
                }
            }
            .swiper-navigation {
                .swiper-pagination,
                .swiper-button-prev,
                .swiper-button-next {
                    .icon {
                        fill:var(--color-icon-inverse);
                    }
                }
            }
        }
        &__close {
            width:50px;
            height:50px;
            position:absolute;
            top:15px;
            right:15px;
            @media (--viewport-xs-max) {
                right:0;
            }
            &:hover {
                cursor:pointer;
            }
            .icon {
                fill:var(--color-icon-inverse);
                width:24px;
                height:24px;
            }
        }
    }
    &__content {
        position:relative;
        z-index: 2;
        margin-top:32px;
        margin-bottom:32px;
        @media (--viewport-xs-max) {
            margin-top:16px;
            margin-bottom:16px;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }        
    &__leyend {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap:10px; 
        @media (--viewport-xs-max) {
            flex-flow: column;
            align-items: flex-start;
        }
        @media (--viewport-sm-min) {
            flex-flow: nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__label {
        position:absolute;
        right:0;
        top:0;
        z-index:2;
        background:var(--color-blanket);
        padding: 8px;
        grid-gap: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            color:var(--color-text-inverse);
        }
        .icon {
            fill:var(--color-icon-inverse);
        }
    }
    /* Swiper Variation*/
    .swiper-wrapper{
        flex-flow: row;
        justify-content: flex-start;
        @media (--viewport-xs-max) {
            height: 188px;
            max-width: 375px;
        }
        @media (--viewport-ms-min) {
            height: 329px;
            max-width: 764px;
        }
        @media (--viewport-sm-min) {
            height: 452px;
            max-width: 1280px;
        }
    }
    .swiper-slide{
        overflow: hidden;
        margin-bottom: 0 !important;
        @media (--viewport-xs-max) {
            height: 188px;
            max-width: 375px;
        }
        @media (--viewport-ms-min) {
            height: 329px;
            max-width: 764px;
        }
        @media (--viewport-sm-min) {
            height: 452px;
            max-width: 1280px;
        }
    }
    .swiper-navigation{
        display: flex;
        flex-flow: nowrap;
        width: 74px;
        justify-content: space-between;
        align-items: center;
        gap:30px;
        @media (--viewport-xs-max) {
            align-self: flex-end;
        }
        .swiper-pagination {
            display:none;
        }

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;
            right:0;
            &::after {
            display: none;
            }

            .icon {
                height: 24px;
                width: 24px;
                fill:var(--color-accent)
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 0;
            width: 24px;
            height: 24px;
            margin-top: 0;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }    
    }
}