.call-me {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;

    .callme-button {
        position: fixed;
        z-index: 999;
        bottom: 10px;
        right: 15px;
        width: 76px;
        height: 76px;
        border-radius: 50%;
        background-color: var(--color-button-primary);
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:focus {
            outline: none !important;
        }
        img {
            width: 100%;
            height: 100%;
        }

        @media (--viewport-xs-max) {
            width: 66px;
            height: 66px;
        }
    }

    .callme-button:hover {
        background-color: var(--color-button-primary-dark);
        animation-iteration-count: infinite;
        animation: shake 0.5s;
    }

    .container-floating {
        position: relative;
        height: 106px;
        .wrap {
            position: absolute;
            overflow: hidden;
            bottom: 97px;
            right: 10px;
            transition: all .15s ease-in-out;
            &.fade-in {
                visibility: visible;
                opacity: 1;
                max-height: 500px;
            }
            &.fade-out {
                opacity: 0;
                visibility: hidden;
                max-height: 200px;
            }
            max-height: 0;
            @media (--viewport-xs-max) {
                bottom: 87px;
            }

            .bots {
                width: 86px;
                background-color: var(--color-button-primary);
                padding: 10px 5px;
                @media (--viewport-xs-max) {
                    width: 76px;
                }

                .nds {
                    width: 76px;
                    position: relative;
                    z-index: 300;
                    text-align: center;
                    margin: 10px 0;
                    cursor: pointer;
                    overflow: hidden;
                    @media (--viewport-xs-max) {
                        width: 66px;
                    }

                    .bodycopy-s {
                        color: var(--color-text-button-primary);
                        text-align: center;
                        line-height: 100%;
                    }

                    img {
                        width: 46px;
                        height: 46px;
                        @media (--viewport-xs-max) {
                            width: 41px;
                            height: 41px;
                        }
                    }
                }
            }

            .arrow {
                float: left;
                margin-left: 38px;
                bottom: 5px;
                position: relative;
                background-color: var(--color-button-primary);
                padding: 5px;
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                @media (--viewport-xs-max) {
                    margin-left: 32px;
                }
            }
        }
    }

    &.active {
        .container-floating {
            height: auto;

            .wrap {
                max-height: 500px;
            }
        }
    }
    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }

}








