.card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__document {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding: 20px;
        gap: 40px;
        height: 80px;
        width: 100%;
        padding: 0 30px 0 20px;

        @media (--viewport-ms-max) {
            height: 226px;
        }

        --color-feedback_success: #2EC988;
        --color-feedback_error: #E4002B;
        --color-feedback_earring:#E2E2E2;

        .card {

            &__options,
            &__heading,
            &__content {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            &__options {
                @media (--viewport-xs-max) {
                    .dropdown-menu__overlay.dropdown-menu--open {
                        width: 100%;
                    }
                }
            }

            &__heading {
                display: flex;
                flex-flow: nowrap;
                justify-content: space-between;
                align-items: center;
                flex-basis: 100%;

                @media (--viewport-xs-min) {
                    justify-content: space-between;
                    flex-basis: 30%;

                }

                .title-m {
                    color: #21272A;
                }

                .state {
                    text-transform: uppercase;
                    display: flex;
                    flex-flow: nowrap;
                    justify-content: center;
                    padding: 5px;
                    align-items: center;

                    &.earring {
                        background-color: var(--color-feedback_earring);
                        color: var(--color-text);
                    }

                    &.validated {
                        background-color: var(--color-feedback_success);
                        color: #fff;
                    }

                    &.error {
                        background-color: var(--color-feedback_error);
                        color: #fff;
                    }
                }
            }

            &__content {
                background: none;
                padding: 0;
                flex-basis: 100%;

                @media (--viewport-xs-min) {
                    flex-direction: row;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-basis: 30%;
                    text-align: left;
                }
            }

            &__btn {
                background: none;
                padding: 0;
                flex-basis: 100%;
                position: relative;

                @media (--viewport-xs-min) {
                    flex-direction: row;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    flex-basis: 120px;
                    text-align: left;
                    margin-top: 0;
                }
            }

            &__footer {
                display: flex;
                flex-flow: column;
                justify-content: space-between;

                &__total-file {
                    display: flex;
                    flex-flow: nowrap;
                    justify-content: space-between;
                    gap: 10px;

                    .item {
                        height: 10px;
                        flex-basis: 100%;

                        &.empty {
                            background-color: #FBEFE7;
                        }

                        &.filled {
                            background-color: var(--color-icon-success);
                        }

                        &.rejected {
                            background-color: var(--color-background-accent-bold);
                        }
                    }
                }

                &__info {
                    display: flex;
                    flex-flow: nowrap;
                    justify-content: space-between;
                    margin-top: 8px;
                    gap: 24px;

                    .caption-label {
                        color: var(--color-text-subtle);
                        white-space: nowrap;
                    }

                    .bodycopy-s {
                        color: var(--color-text-subtlest);
                    }
                }
            }
        }

        &.upload {
            border-bottom: 1px solid #cdcdcd;

            .card__options {
                .icon {
                    width: 20px;
                    height: 20px;
                    fill: var(--color-icon-cta-primary);
                }

                @media (--viewport-xs-max) {
                    position: absolute;
                    right: -10px;
                    top: 12px;
                }
            }

            .card__heading {
                @media (--viewport-xs-max) {
                    margin-right: 30px;
                }
            }

            .card__content {
                p {
                    text-align: left;
                }
            }

            .card__btn {
                margin-top: 0;

                @media (--viewport-ms-max) {
                    .button-upload .btn.btn_file {
                        left: 0;
                        right: auto;
                    }
                }
            }

            @media (--viewport-ms-max) {
                height: auto;
                padding: 24px 17px 28px 36px;
                grid-gap: 0;
                row-gap: 30px;

                .card__options {
                    order: 2;
                    flex-basis: auto;
                    position: initial;
                    right: auto;

                    .dropdown-menu__btn {
                        padding: 15px;

                        .btn {
                            line-height: 0;
                        }
                    }
                }

                .card__heading {
                    order: 1;
                    flex-basis: auto;
                    justify-content: space-between;
                    width: calc(100% - 80px);
                    margin-right: 0;
                }

                .card__content {
                    order: 3;
                    width: 100%;
                    flex-basis: auto;
                }

                .card__btn {
                    order: 4;
                    flex-basis: auto;

                    .button-upload .btn.btn_file {
                        position: initial;
                    }
                }
            }
        }

        &.uploaded {
            flex-flow: column;
            height: 264px;
            padding: 30px;
            border-left: 1px solid #cdcdcd;
            align-items: normal;

            .card__heading {
                flex-flow: column-reverse;
                align-items: flex-start;
                padding-bottom: 12px;
            }

            .card__options {
                position: absolute;
                right: 30px;
                top: 33px;

                .icon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &__pic {
        overflow: hidden;

        &-link {
            display: block;
            width: 100%;
            height: 220px;
            background: center / cover no-repeat;
            transition: all 0.5s;

            @media (--viewport-ms-min) {
                height: 200px;
            }

            &:hover,
            &:focus {
                transform: scale(1.1);
            }
        }
    }

    &__icon {
        overflow: hidden;
        padding-top: 40px;

        @media (--viewport-ms-min) {
            padding-top: 30px;
        }

        margin-bottom: 11px;

        @media (--viewport-sm-min) {
            padding-top: 40px;
        }

        &-link {
            display: block;
            transition: all 0.5s;

            @media (--viewport-ms-min) {}
        }

        .icon {
            width: 54px;
            height: auto;
        }

        &.small {
            margin-bottom: 0;
            padding-top: 0;
            line-height: 0;

            .icon {
                width: 40px;
            }
        }
    }

    &__content {
        flex-grow: 1;
        padding: 40px 20px;
        text-align: center;
        background-color: var(--color-accent-light);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (--viewport-ms-min) {
            padding: 30px;
        }

        @media (--viewport-sm-min) {
            padding: 40px 30px;
        }

        &.noBG {
            background: none;
        }
    }

    &.hasIcon {
        align-items: center;
        justify-content: center;

        .card__content {
            padding-top: 0;
        }
    }

    &.noBG {
        .card__content {
            background-color: transparent;
        }
    }

    &__label {
        margin-bottom: 10px;
    }

    &__title {
        &-link {
            display: block;

            &:hover,
            &:focus {
                .title-m {
                    color: var(--color-accent);
                }
            }

            .title-m {
                transition: color 0.3s;
            }
        }

        .bodycopy-m {
            color: var(--color-secondary);

            a {

                color: var(--color-text-link-secondary);
            }
        }
    }

    &__secondary-link,
    &__category {
        margin-top: 20px;

        &-link {
            display: block;

            &:hover,
            &:focus {
                .text-label-s {
                    color: var(--color-accent-dark-tertiary);
                }
            }

            .text-label-m {
                transition: color 0.3s;
            }
        }
    }

    &__btn,
    &__link {
        margin-top: 30px;
    }

    &__reference {
        margin-top: 20px;

        .text-label-m {
            transition: color 0.3s;
        }
    }

    &__list {
        margin-top: 32px;
        text-align: left;

        .list li {
            display: flex;
            align-items: center;

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 16px;
                flex-shrink: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

    }

    &__clarification {

        .bodycopy-xs {
            color: var(--color-grey2);
        }

        .link {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: max-content;
            margin: 0 auto;

            .icon {
                fill: var(--color-icon);
                margin-top: 65px;
            }

            &::before {
                content: "";
                float: left;
                width: 80px;
                height: 1px;
                padding: 1px 80px 0 0;
                margin: 32px auto 32px;
                background-color: var(--color-border);
            }
        }
    }

    &.accentBG {
        background-color: var(--color-accent);

        .card__content {
            background-color: var(--color-accent);

            .title-l,
            .title-m,
            .text-label-m {
                color: var(--color-white);
            }
        }
    }

    &.lightBG {
        background-color: var(--color-accent-light);
    }

    &.clarification {
        .card__btn {
            margin: 40px 0;
        }
    }

    &.thick {
        padding-top: 30px;

        .card__content {
            padding-bottom: 70px;
        }
    }

    .card__multiple-photos {

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            li {
                width: calc(25% - 9px);
                position: relative;

                @media (--viewport-sm-max) {
                    width: calc(33.3% - 9px);
                }

                @media (--viewport-ms-max) {
                    width: calc(15% - 9px);
                }

                @media (--viewport-xs-max) {
                    width: calc(33.3% - 9px);
                }

                img {
                    width: 100%;
                    height: 112px;
                    object-fit: cover;

                    @media (--viewport-sm-max) {
                        height: 104px;
                    }

                    @media (--viewport-ms-max) {
                        height: 100px;
                    }

                    @media (--viewport-xs-max) {
                        height: 80px;
                    }
                }

                span {
                    text-overflow: ellipsis;
                    display: block;
                    overflow-x: hidden;
                    white-space: nowrap;
                    color: var(--color-text-subtle);
                    margin-top: 4px;
                }

                .icon {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                }
            }
        }
    }

    &.upload-lightbox {
        border: 1px dashed var(--color-border);
        padding: 40px 24px;
        gap: 32px;
        min-height: 320px;

        .card__content {
            padding: 0;
            flex-grow: unset;
            width: 100%;

            .card__list {
                display: flex;
                justify-content: center;

                .list li {
                    align-items: flex-start;

                    .icon {
                        line-height: 0;
                    }

                    .bodycopy-s {
                        width: auto;

                    }
                }
            }
        }

    }

    &.upload-lightbox-multiple {
        padding: 40px 40px 56.6px;
        @media (--viewport-sm-max) {
            padding: 40px 40px 22.57px;
        }
        @media (--viewport-ms-max) {
            padding: 40px 40px 26.43px;
        }
        .card__title {
            display: flex;
            justify-content: space-between;

            .wrap {
                display: flex;
                flex-direction: column;
                gap: 8.5px;
                margin-bottom: 24px;

                .bodycopy-xs {
                    color: var(--color-text-subtle);
                }
            }

            .icon {
                width: 50px;
                height: 50px;
                border: 1px solid var(--color-background-cta-secondary);
                padding: 13px 15px;
            }
        }

        .title-m {
            color: var(--color-text);
        }

        .card__content {
            text-align: left;
        }
    }
}