.fieldset {
    position: relative;
    .icon-eye {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        left: initial;
        right: 12px;
    }
    &_textarea {
        .label {
            top: 20px !important;
            transform: none !important;
        }
    }

    &_error {
        .input,
        .textarea {
            color: var(--color-accent);
        }
    }

    .label {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
        transition: all 0.3s;

        @media (--viewport-xs-max) {
            left: 20px;
        }
    }

    .input {
        padding-top: 27px;
        padding-bottom: 8px;

        @media (--viewport-sm-max) {
            padding-bottom:3px; /*lo añado para equilibrar la altura entre los diferentes inputs*/
        }

        &::placeholder {
            opacity: 0.5;
            font-family: 'Jornada Sans Book';
            text-transform: inherit;
            font-size: 18px;
            line-height: 28px !important;
            letter-spacing: 0;
            color: var(--color-text);

            @media (--viewport-ms-min) {
                font-size: 18px;
                line-height: 28px !important;
            }
        }
    }

    .textarea-wrapper {
        padding-top: 26px;
        padding-bottom: 9px;
    }

    .error-message {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
    }

    &.active {
        .label {
            top: 13px !important;
            transform: none;
            color: var(--color-accent-dark);
        }
    }
}
