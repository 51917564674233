.footer-post {
    .border{
        border-top: solid 1px var(--color-grey3); padding-bottom: 15px;display: block;
    }

    .text-label-s{
        padding-left: 12px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -5px 0;
    }

    &__item {
        margin: 5px 45px 5px 0;

        @media (--viewport-ms-min) {
            margin-right: 20px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link-social {
        position: relative;
        display: flex;
        padding:12px;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 40px;
            height: 40px;
            content: '';
        }

        .icon {
            fill: var(--color-primary-brand);
            width: 20px;
            height: 20px;

            @media (--viewport-ms-min) {
                width: 16px;
                height: 16px;
            }
        }

        &:hover,
        &:focus {
            .icon {
                fill: var(--color-secondary-brand);
            }
        }

        
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 20px 0 34px;
    }

    &__link {
        margin: 0 0 30px 0;
    }
}