.title-m {
    color: var(--color-secondary-brand);
    transition: all 0.3s;
    &_color {
        &_light {
            color: var(--color-text-light);
        }

        &_dark {
            color: var(--color-secondary)
        }

        &_accent-dark {
            color: var(--color-accent-dark)
        }
    }


}