.sidebar-layout {
    &-text {
        &.text-legal{
            @media (--viewport-xs-max) {
            overflow-x: scroll;
            }
        }
    }

    &-aggregator {
        @media (--viewport-xs-only) {
            margin: 0 -20px;
        }
        @media (--viewport-xs-max) {
            margin-bottom: 60px;
        }

        @media (--viewport-ms-only) {
            margin-bottom: 80px;
        }
    }
}
