.banner-ancho {
    margin-right: -19px;
    margin-left: -20px;
    margin-top: 100px;

    @media (--viewport-ms-max) {
        margin-top:55px;
    }

    @media (--viewport-ms-min) {
        margin-left: 0;
        margin-right: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        min-height: 400px;
        padding: 110px 20px 60px;
        background-color: var(--color-accent);

        @media (--viewport-ms-min) {
            min-height: 573px;
            padding-right: 0;
            padding-left: 0;
        }

        @media (--viewport-sm-min) {
            min-height: 362px;
            padding: 50px 0 60px;
        }

        @media (--viewport-ms-max) {
            padding-top: 80px;
        }

        &-wrapper {
            width: 100%;
        }

        &-main {
            @media (--viewport-ms-max) {
                margin-bottom: 40px;
            }
        }
    }

    &__breadcrumbs {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 40px;
    }

    &__support {
        &-bodycopy {
            margin-bottom: 20px;

            @media (--viewport-sm-min) {
                margin-bottom: 30px;
            }
        }
    }

    &__description {
        margin-bottom: 15px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }
    }
}
