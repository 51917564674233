.access-blog {
    @media (--viewport-sm-max) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__header {
        margin-bottom: 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 60px;
        }
    }

    &__title {
        margin-bottom: 20px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 24px;
        }
    }

    &__link {
        text-align: center;
    }

    &__image {
        padding-top: 30px;

        @media (--viewport-xs-max) {
            background-image: none !important;
        }

        @media (--viewport-ms-min) {
            padding-top: 140px;
            min-height: 400px;
            background-size: 75%;
            background-repeat: no-repeat;
        }

        @media (--viewport-md-min) {
            background-size: 50%;
        }

        &-mobile {
            margin-left: -30px;
            margin-right: -29px;
            min-height: 220px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0;
            margin-top: -40px;
            position: relative;
            z-index: 1;

            @media (--viewport-ms-min) {
                display: none;
            }
        }
    }

    &__nav {
        display: flex;

        @media (--viewport-xs-max) {
            justify-content: space-between;
            padding: 0 20px;

            &-desktop-wrapper {
                display: none;
            }

            &-mobile-wrapper {
                background-color: var(--color-accent-light);
                position: relative;
                z-index: 2;
            }

            &-back,
            &-forward {
                margin-bottom: 30px;
            }
        }

        &-desktop-wrapper {
            @media (--viewport-lg-min) {
                position: relative;
                left: -15px;
            }
        }

        @media (--viewport-ms-min) {
            margin-bottom: 30px;

            &-mobile-wrapper {
                display: none;
            }
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }

    &__tiles {
        @media (--viewport-md-min) {
            position: relative;
            left: -15px;
        }

        @media (--viewport-lg-min) {
            left: -35px;
        }

        .access-blog-swiper {
            @media (--viewport-xs-max) {
                background-color: var(--color-accent-light);
            }
        }

        .swiper-slide {
            background-color: var(--color-accent-light);
        }
    }

    &__tile {
        .card__content {
            @media (--viewport-ms-min) {
                min-height: 297px;
            }
        }
    }
}
