.tile-aggregator {
    flex: inherit;
    padding: 40px 20px 26px;
    background-color: var(--color-accent-light);

    @media (--viewport-ms-min) {
        padding: 40px;
    }

    &__title {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color-accent-dark);
            content: '';
        }
    }

    &__item {
        position: relative;

        .tile-aggregator__link {
            .link {
                padding-bottom: 16px;
                line-height: 20px;
            }
        }

        .tile-aggregator__item{
            .link{color: var(--color-text-subtle); padding-left:24px;}
        }

    }

    &__link {
        width: 100%;

        .link {
            display: block;
            width: inherit;
            padding-bottom: 20px;

            @media (--viewport-sm-min) {
                padding-bottom: 15px;
            }
            
            span{display: inline;}
        }
    }

    &__faq {
        &-item {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-title {
            margin-bottom: 10px;
        }
    }

    &__extra {
        margin-top: 14px;

        .link {
            display: block;
            width: 100%;
        }
    }
    .singleAccordion__trigger {
        padding: 0;
        &:before{
            display: none;
        }
    }
    
    &.contentTable{
        padding: 40px;
        max-height: 600px;
        @media (--viewport-sm-max) {
            max-height: 480px;
        }
        @media (--viewport-ms-max) {
            max-height: 400px;
        }
        overflow-y: auto;
        .tile-aggregator__title {
            padding-bottom: 24px;
            margin-bottom: 32px;
        }
        .tile-aggregator__item {
    
            .link{
                color: var(--color-text-subtle);
            }

            .tile-aggregator__link.active{
                .link{
                    color: var(--color-text);
                }
            }
    
        }
    }
}
