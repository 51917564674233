.latest {
    &__head {
        margin-bottom: 10px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 60px;
        }

        .latest__description {
            margin-top: 20px;
    
            @media (--viewport-ms-min) {
                margin-top: 30px;
            }
        }

        .latest__link {
            margin-top: 20px;
    
            @media (--viewport-ms-min) {
                margin-top: 30px;
            }
        }

        .latest__title {
            margin-bottom: 20px;

            @media (--viewport-ms-min) {
                margin-bottom: 30px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 24px;
            }
        }
    }

    &__cards {
        .card {
            margin-top: 30px;
            padding-bottom:30px;

            @media (--viewport-sm-min) {
                margin-top: 0;
            }

            @media (--viewport-ms-max) {
                margin-top: 15px;
            }
            &.accentBG{
                margin-bottom:30px;
                padding-bottom:0;
            }
        }
    }

    &.AccentLight{background-color: var(--color-accent-light); padding: 40px;
    }
    
    .latest__link,
    .latest__bottom-link,
    .latest__bottom-btn {
        text-align: center;
    }

    .latest__pagination .pagination .pagination__list {
        justify-content: center;
    }

    /* ESTILOS PARA QUE NO HAYA ERRORES EN VIEJAS INSTANCIAS DEL LATEST (CA) */
    
    &__list {
        margin-bottom: -30px;

        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }
    }

    &__item {
        height: 100%;
        margin-bottom: 30px;
    }

    &__footer {
        margin-top: 30px;
        text-align: center;
    }
}