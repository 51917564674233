.contact-info {
    &__title{margin-bottom: 30px;}
    ul{
        li{
            a.link{
                span{padding-left: 5px;padding-right:5px;}
            }
            &:first-child{flex-wrap: wrap;flex-direction: row;
                a.link{display:inline-flex;width:auto;
                    span{display:inline;padding-left: 5px;padding-right:0;}
                    &:first-child span{padding-right: 5px;}
                }
            }
            &.two-lines{
                div{clear:left;width: 100%;}
                &:nth-of-type(2n) {
                    .icon{display:none;}
                }
            }
            &.one-line{
                flex-direction: row;
                padding-bottom: 20px;
                .icon-reloj{margin: 3px 14px 0 0; display: inline-flex;  width: 12px; height:12px;}
            }
        }
    }
}