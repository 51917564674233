.distributor {
    &__description {
        margin-bottom: 40px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 60px;
        }
    }

    &__label {
        margin-bottom: 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 20px;
        }
    }

    &__bodycopy {
        margin-top: 10px;

        @media (--viewport-ms-min) {
            margin-top: 20px;
        }
    }

    &__link {
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }

    &__bottomlink {
        text-align: center;
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }

    &__tiles {
        margin-bottom: -30px;

        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }

        &_three-column {
            .tile-distributor {
                &__content {
                    @media (--viewport-sm-min) {
                        padding: 40px 50px;
                        margin-right: 30px;
                    }
                }

                &__title {
                    &.justtitle{
                        margin-bottom:0;
                    }
                }
            }
        }

        &_three-columns-always {
            @media (--viewport-ms-max){
               margin-left: 0; margin-right: 0;
            }

        }

        .tile-distributor {
            margin-bottom: 30px;
        }
    }

    &.accentlight{
        background-color: var(--color-accent-light);
        padding:40px 0;
        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
            .distributor__description {
                padding: 0 20px;
            }
        }
        &__tiles {
            @media (--viewport-xs-max) {
            margin-right: 0;
            margin-left: 0;
            }
        }
    }

    @media (--viewport-ms-max){
        .swiper-wrapper{
          padding-left: 10px;
        }
    }
  .swiper-container{
    padding-bottom: 50px;
    @media (--viewport-ms-max) {
      padding-bottom:40px;
    }
  }
  .swiper-slide{
    /*background-color:var(--color-accent-light);*/
    height: auto;
    width: 80%;
    padding-left:10px;
    padding-right:10px;
  }
  .swiper-navigation{
    display: flex;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    justify-content: center;
    align-items: center;

    .swiper-pagination {
      margin: 0 40px;
    }

    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;

      &::after {
        display: none;
      }

      .icon {
        height: 20px;
        width: 28px;
        fill:var(--color-accent)
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 0;
      width: 28px;
      height: 20px;
      margin-top: 0;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .swiper-pagination-bullet-active {
      background: var(--color-accent);
    }

    @media (--viewport-ms-max) {
      display:flex;
    }

  }
}
