.contact-form {
    &__content{
        ul{display:flex;flex-wrap:wrap;
            li{width: 50%;
                &:nth-child(1),&:nth-child(3){padding-right: 30px;}
                &:nth-child(5),&:nth-child(6){width: 100%;}
                &:nth-child(2){
                    .form-list__field{margin-top: 0;}
                }
                @media (--viewport-ms-max){width: 100%;
                    &:nth-child(1),&:nth-child(3){padding-right: 0;}
                    /*&:nth-child(2){margin-top:35px;} borramos esta línea ya que genera un bug*/
                }
                .form-list__field{height:auto;
                    .form-select-distinguisher_call-time, .form-select-distinguisher_prefix{
                        ul{
                            li{width: 100%;padding-right:0;margin-top:0;
                                input{border:0;}
                            }
                        }
                    }
                }
                li.access-select__item{
                    padding-right: 0;
                }
                li{
                    width:100%;
                }
            }
            &.radio-group{margin-top: -10px;
                li{width: auto;flex-direction: row;padding-right:30px;padding-top:10px;
                    &:nth-child(3){color: var(--color-tertiary-dark); padding-right: 0;
                        .text-label-m{color: var(--color-tertiary-dark); }
                    }
                    @media (--viewport-ms-max){
                        &:nth-child(2){margin-top:0;}
                    }
                }
            }
            /*.checkbox-wrapper{margin-top:40px;}*/
        }

        
    }

    &.isUciMortgages{
        .contact-form__content{
            ul{
                li{
                    &:nth-child(7){width: 100%;}
                }
            }
        }
    }
    
    &__legal{
        a{color: var(--color-accent-dark);}
    }
    
    .legal{
        .checkbox-wrapper{margin-top:0;}
        ul li:first-child{padding-right:0;}
    }
}