.form-list {
    &__item {
        &:first-child {
            .form-list {
                &__field {
                    margin-top: 0;
                }
            }
        }
    }

    &__field {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 0;

        @media (--viewport-ms-min) {
            margin-bottom: 0;
        }
    }
    &__show-password {
        opacity: 0;
        display: none;
    }
    &__valid-indicator {
        position: absolute;
        top: 67px;
        /*top: 50%;*/
        right: 10px;
        opacity: 0;
        transform: translate(0, -50%);
        display: flex;
        transition: opacity 0.3s;

        @media (--viewport-ms-min) {
            right: 20px;
        }

        .icon {
            fill: var(--color-valid);
        }
    }

    &__error-message {
        position: absolute;
        left: 0;
        opacity: 0;
        display: flex;
        /*padding-top: 8px;*/
        transition: opacity 0.3s;
    }
    
    &__helper-text {
        position: relative;
        left: 0;
        display: flex;
        /*padding-top: 8px;*/
        .bodycopy-xs{color: var(--color-grey2);}
    }

    &__tooltip-label {
        &_option {
            &_2 {
                display: none;
            }
        }
    }
    .hidden {
        display: none;
    
        &-xs {
            @media (--viewport-xs-max) {
                display: none;
            }
    
            &-ms {
                @media (--viewport-ms-max) {
                    display: none;
                }
            }
        }
    
        &-ms {
            @media (--viewport-ms-only) {
                display: none;
            }
    
            &-min {
                @media (--viewport-ms-min) {
                    display: none;
                }
            }
        }
    
        &-sm {
            @media (--viewport-sm-only) {
                display: none;
            }
    
            &-min {
                @media (--viewport-sm-min) {
                    display: none;
                }
            }
        }
    }
}
