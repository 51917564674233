.especial-m {
    font-family: 'Jornada Slab Book';
    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
    letter-spacing: 0;
    color: var(--color-text-tertiary);

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}
