.lightbox-content-generic {
    .modal-body {
        /*.row {
            background-color: var(--color-white); Lo comento porque genera error al meter dentro del splitter un componente producto, por ejemplo (CA) 
        }*/
        .splitter{
            .container{
                padding:0;
            }
        }

        & > .row{
            margin: 0;
        }
        & > .row > .col-xs-12, 
        & > .row > .col-ms-12{
            padding: 0;
        }
    }
    

    .modal-head {
        margin-bottom: var(--gutter-width);
        
        & > .row{
            margin: 0;
        }
        & > .row > .col-xs-12, 
        & > .row > .col-ms-12{
            padding: 0;
        }
    }

    .modal__title {
        &.small {
            margin-bottom: 20px;

            .title-m {
                color: var(--color-secondary);
            }
        }
    }
}