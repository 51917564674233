.news-external {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 40px 20px;
    text-align: center;
    background-color: var(--color-accent);

    @media (--viewport-ms-min) {
        padding: 30px;
    }

    @media (--viewport-sm-min) {
        padding: 40px 30px;
    }

    &__pic {
        max-width: 54px;
        margin: 0 auto 20px;
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__title {
        margin-top: 10px;
    }

    &__btn {
        margin-top: 30px;
    }
}
