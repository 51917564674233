.media {
    .title-l{margin-bottom: 20px; text-align:center;
        @media (--viewport-xs-max) {
            margin-bottom:10px;
        }
    }
    .bodycopy-m{margin-bottom: 30px; text-align: center; padding-left: 60px; padding-right: 60px;
        @media (--viewport-xs-max) {
            margin-bottom:30px;padding-left: 30px; padding-right: 30px;
        }
    }
    .wrap{
        img, video{width: 100%;height:auto;margin-bottom:-9px;
        }
    }
    &.AccentLight{
        background-color: var(--color-accent-light); padding-top: 40px;
    }
    &.cover{
        display: flex;
        justify-content: center;
        overflow: hidden;
        .wrap{width: 100%;height:auto;display: flex;justify-content: center;
            img, video{
                @media (--viewport-ms-max) {
                    /*transform: scale(2);*/
                    width: 200%;
                    display: block;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    .video-responsive {
        overflow:hidden;
        padding-bottom:56.3%; /* Adecua este valor para controlar la altura del video */
        position:relative;
        height:0;
    }
    .video-responsive iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }
}