.switch {
    position: absolute;
    opacity: 0;
    left: -9999px;

    &:hover,
    &:focus {
        + .switch-label {
            border-color: var(--color-secondary);
        }
    }

    &.disabled{
        &:hover,
        &:focus {
            + .switch-label {
                border-color:var(--color-secondary-light);
            }
        } 
    }

    &:checked {
        + .switch-label {
            border-color: var(--color-accent-dark);
            background-color: var(--color-accent-dark);

            &:hover,
            &:focus {
                opacity: 0.6;
            }

            .text-label-m {
                color: var(--color-text-light);
            }
        }

        &:hover,
        &:focus {
            + .switch-label {
                opacity: 0.6;
            }
        }
    }

    &.checked {
        + .switch-label {
            border-color: var(--color-accent-dark);
            background-color: var(--color-accent-dark);

            &:hover,
            &:focus {
                opacity: 0.6;
            }

            .text-label-m {
                color: var(--color-text-light);
            }
        }

        &:hover,
        &:focus {
            + .switch-label {
                opacity: 1;
            }
        }
    }

    &.checked.disabled {
        + .switch-label {
            border-color: var(--color-accent-dark);
            background-color: var(--color-accent-dark);

        }
    }

    &-group {
        display: flex;
    }

    &-wrapper {
        position: relative;
        display: flex;
        min-height: 25px;
    }

    &-label {
        padding: 9px;
        min-width: 62px;
        border: 1px solid var(--color-secondary-light);
        text-align: center;
        transition: all 0.3s;

        @media (--viewport-sm-min) {
            min-width: 45px;
        }

        &:hover,
        &:focus {
            border-color: var(--color-secondary);
        }
    }
}
