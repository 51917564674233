.ucipt-title {
    font-family: "Jornada News Book", sans-serif;
    font-size: 44px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: 0;
    color: var(--color-text-quaternary);

    @media (--viewport-sm-max) {
        font-size: 38px;
        line-height: 50px;
    }

    @media (--viewport-ms-max) {
        font-size: 32px;
        line-height: 42px;
    }

    &--color {
        &-light {
            color: var(--color-text-light);
        }
    }
}
