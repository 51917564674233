.tile-figure-price{
    display:flex;
    flex-flow:wrap;
    width:100%;
    max-width:100%;
    justify-content: space-between;
    row-gap: 30px;
    @media (--viewport-xs-max) {
        row-gap:10px;
    }
    &__item {
        text-align: center;
        border-left:3px solid var(--color-accent);
        row-gap:20px;
        display:flex;
        flex-flow:column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        @media (--viewport-lg-min) {
            width:25%;
        }
        @media (--viewport-md-max) {
            width:25%;
        }
        @media (--viewport-sm-max) {
            width:50%;
        }
        @media (--viewport-ms-max) {
            width:50%;
        }
        @media (--viewport-xs-max) {
            width:100%;
            flex-flow: wrap;
            justify-content: space-between;
            row-gap:10px;
            border:0 none;
            padding-left: 0;
        }
        
    }


    
    &__figure {
        display: flex;
        justify-content: center;
        align-items: first baseline;
        .number-m {
            color: var(--color-text);            
            @media (--viewport-xs-max) {
                font-family: 'Jornada Sans Medium';
                font-size: 12px;
                font-weight: normal;
                line-height: 14px;
                letter-spacing: 0;
            }
            @media (--viewport-ms-min) {
                font-size: 26px;
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: 0;
            }

            
        }

    }
    &__description {
        .text-label-m {
            color:var(--color-grey2);
            @media (--viewport-sm-min) {

            }
            @media (--viewport-ms-min) {

            }
            
            
        }
        
        

    }
}