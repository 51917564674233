.card-result-payment {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    background-color: var(--color-tertiary-brand);
    padding: 32px;

    @media (--viewport-sm-max) {
        padding: 32px 16px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            margin-bottom: 16px;
        }

    }

    &__head {
        background-color: var(--color-background-accent-bold);
        margin: -32px -32px 32px;
        padding: 32px;

        @media (--viewport-sm-max) {
            margin: -32px -16px 32px;
        }

        .title-l {
            color: var(--color-text-inverse);
        }

        .label {
            color: var(--color-text-inverse);
            margin-bottom: 0;
        }

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(309deg) brightness(107%) contrast(99%);
        }

        &+.bodycopy-s {
            text-align: center;
            min-height: 46px;
        }
    }

    &__form-radio-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 72px;
        gap: 16px;
        margin-bottom: 32px;

        @media (--viewport-sm-max) {
            height: 96px;
        }

        .bodycopy-m {
            text-align: center;
        }

        .form-radio-group {
            display: flex;
            justify-content: center;
            margin-bottom: 0;

            .field-top {
                display: none;
            }

            ul {
                .radio-group__item {
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__financing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0 0;

        .bodycopy-m {
            color: var(--color-text-accent);
        }
    }

    .wrap-items {
        height: 438px;
        position: relative;
    }

    ul.items {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*@media (--viewport-ms-max) {
            height: 168px;
        }*/

        .title-xl {
            color: var(--color-text-primary);
        }

        /*.title-with-tooltip {
            display: block;
            text-align: center;
            margin: 0 10% 16px;

            .title-s {
                display: inline;
            }

            .tooltip {
                top: 22px;
                right: 0;
                left: 8px;
                display: inline;
            }
        }*/

        li {
            width: 100%;
            text-align: center;

            /*&:last-child {
                margin-bottom: 4px;
            }*/

            .title-with-tooltip {
                margin-bottom: 4px;
            }

            li {
                margin-top: 0;
                width: auto;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        ul.tin-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 24px;

            li {
                line-height: normal;

                &.withTooltip {
                    display: flex;
                    align-items: center;
                }

                .tooltip {
                    top: -8px;
                    right: -8px;
                }
            }
        }

        .card-result-payment__item-number {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: 24px;

            li {
                padding: 0 3px;
                line-height: normal;

                .bodycopy-s {
                    padding-bottom: 11px;
                }

                .title-l {
                    color: var(--color-text);
                }
            }
        }
    }



    &__item-clarification {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;
        }
    }

    &__tae {
        display: flex;
        flex-direction: column;
        padding: 0;

        li.tin-tae-link {
            position: absolute;
            bottom: 16px;
            justify-content: center;
            display: flex;
            width: 100%;

            .icon {
                fill: var(--new-color-text);
                top: 3px;
                right: -5px;
            }
        }

        li.tae {

            .bodycopy-m,
            .title-xl {
                color: var(--color-text-error);
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                display: flex;
                line-height: normal;
                align-items: flex-end;

                .bodycopy-m:last-child {
                    position: relative;
                    top: -10px;
                    margin-left: 4px;
                }

                &.withTooltip {
                    align-items: center;

                    .tooltip {
                        top: -8px;
                        right: -8px;
                    }
                }
            }
        }
    }

    &__additional {
        display: flex;
        flex-direction: column;
        border-top: solid 1px var(--color-border);
        border-bottom: solid 1px var(--color-border);
        margin-top: 32px;
        margin-bottom: 16px;

        li.tit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 16px 0;
            align-items: center;

            .link_inline {
                color: var(--color-text-link-secondary);
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 12px;
                line-height: normal;

                &:last-of-type {
                    white-space: nowrap;
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }
        }
    }

    .btn {
        float: left;
        margin-top: 30px;
        align-self: start;
    }


    .wrap-img {
        height: 37px;
        display: flex;
        align-items: center;
    }

    &.error {
        justify-content: center;
        text-align: center;
        padding: 56px 32px;
        align-items: center;
        background-color: var(--color-background-accent);

        @media (--viewport-ms-max) {
            padding: 40px 60px;
        }

        @media (--viewport-xs-max) {
            padding: 40px;
        }

        .title-m {
            margin-bottom: 32px;
        }

        .icon {
            width: 40px;
            height: 40px;
            margin-bottom: 32px;
        }
    }

    &.fade-out {
        opacity: .5;
    }

    &__item {
        text-align: center;

        &+.card-result-payment__item {
            /*margin-top: 24px;*/
        }

        .title-xl,
        .title-m {
            color: var(--color-text-primary);
        }

        .title-with-tooltip {
            display: block;
            text-align: center;
            margin: 0 10% 16px;

            .title-s {
                display: inline;
            }

            .tooltip {
                top: 22px;
                right: 0;
                left: 8px;
                display: inline;
            }
        }
    }

    &__item-number {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .bodycopy-xs {
                padding-bottom: 13px;
            }

            .title-m {
                padding-bottom: 9px;
                color: var(--color-text-primary);
            }

            .bodycopy-l {
                padding-bottom: 9px;
            }

            @media (--viewport-md-max) {
                .bodycopy-xs {
                    padding-bottom: 10px;
                }

                .bodycopy-l {
                    padding-bottom: 6px;
                }

                .title-m {
                    padding-bottom: 4px;
                }
            }

            @media (--viewport-sm-max) {
                .bodycopy-xs {
                    padding-bottom: 7px;
                }

                .bodycopy-l {
                    padding-bottom: 3px;
                }

                .title-m {
                    padding-bottom: 3px;
                }
            }

            @media (--viewport-ms-max) {
                .bodycopy-xs {
                    padding-bottom: 11px;
                }

                .bodycopy-l {
                    padding-bottom: 6px;
                }

                .title-m {
                    padding-bottom: 6px;
                }
            }

            @media (--viewport-xs-max) {
                .bodycopy-xs {
                    padding-bottom: 8px;
                }

                .bodycopy-l {
                    padding-bottom: 3px;
                }

                .title-m {
                    padding-bottom: 4px;
                }
            }
        }


        &:nth-of-type(2) {
            background-color: aqua;

            li:last-of-type {
                .bodycopy-l {
                    padding-bottom: 3px;
                }
            }
        }
    }

    &__item+.card-result-payment__item {
        ul.card-result-payment__item-number {
            li+li {
                .bodycopy-l {
                    padding-bottom: 3px;
                }
            }
        }
    }

    &__item-clarification {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .title-s {
                padding-bottom: -3px;
                color: var(--color-text-primary);
            }

            .bodycopy-xs {
                margin-bottom: -3px;
            }
        }
    }




    &__additional2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border-top: solid 1px var(--color-secondary-brand);
        padding: 20px 0 0;

        li {
            &:first-of-type {
                margin-bottom: 20px;

                .label-m {
                    color: var(--color-text-subtle);
                }
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                &:last-of-type {
                    white-space: nowrap;
                }

                &:first-of-type {
                    margin-bottom: 20px;

                    .label-m {
                        color: initial;
                    }
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }

            .tooltip {
                left: 0;
                top: -6px;
                margin-right: 20px;
            }
        }

    }

    &.fade-out {
        opacity: .5;
    }

    &__nav {
        display: flex;

        @media (--viewport-xs-max) {
            justify-content: space-between;
            padding: 0 20px;
        }

        &-back,
        &-forward {
            margin-top: 30px;
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }

    .wrap-btn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        .icon {
            fill: var(--color-icon);
        }
    }

    /* Swiper Variation*/
    &-swiper {
        position: sticky;
        top: 0;
    }

    &-swiper.scroll {
        position: fixed;
        width: 100%;
        /*bottom: -64px;
        left: 20px;*/
        bottom: -64px;
        left: 0;
        top: initial;
        z-index: 12;
    }

    &.swiper-slide {
        position: relative;
        padding: 32px;

        @media (--viewport-sm-max) {
            padding: 32px 16px;
        }

    }


    &.swiper-slide.scroll {
        ul.items {
            height: 72px;
        }

        @media (--viewport-ms-max) {
            padding: 32px 20px 24px;
        }

        .card-result-payment__head {
            background-color: unset;
            margin: 0;
            padding: 0 20px 16px;

            .title-l {
                color: var(--color-text-accent);
            }

            .icon {
                fill: var(--color-icon-accent);
                margin-bottom: 16px;
            }

            &+.bodycopy-s {
                text-align: center;
                min-height: 46px;
            }
        }

        .wrap-items {
            height: 108px;
            justify-content: flex-start;

            ul.items {
                height: 56px;
            }
        }

        .card-result-payment__item+.card-result-payment__item {
            margin-top: 8px;
            margin-bottom: 0;
        }

        .card-result-payment__item-number-scroll {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding: 0 32px 8px;

            @media (--viewport-xxs-max) {
                padding: 0 0 8px;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            li {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin: 0;
                gap: 8px;

                p.title-m {
                    /*line-height: 20px;*/
                    color: var(--color-text);
                    margin-right: 8px;
                }
            }
        }

        .card-result-payment__tae {
            width: 100%;
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            border-bottom: 0;
            margin: 12px 0 0;
            align-self: flex-end;
            justify-content: center;

            li {
                width: 50%;

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 4px;

                    li {
                        width: auto;
                        margin-bottom: 0;
                        padding: 0 2px;
                    }
                }

                &.tae {
                    ul {
                        justify-content: flex-end;
                        border-right: solid 1px var(--color-text);

                        .caption-label {
                            color: var(--color-text-error);
                        }
                    }
                }

                &.tin-tae-link {
                    position: relative;
                    bottom: auto;
                    flex: none;
                    width: 100%;
                    margin-bottom: 0;

                    .link {
                        color: var(--color-text-link-secondary);

                        span {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .wrap-btn {
            justify-content: center;
            padding-top: 8px;
            padding-bottom: 32px;
            flex-direction: row;
            align-items: unset;
            gap: unset;

            .btn {
                margin-top: 0
            }
        }

        .wrap-link-detalle {
            border-top: solid 1px var(--color-secondary-brand);
            display: flex;
            justify-content: center;
            padding-top: 16px;

            .icon {
                fill: var(--color-icon);
            }

        }
    }

    @media (--viewport-ms-max) {
        .swiper-wrapper {
            padding-left: 10px;
        }
    }

    .swiper-container {
        padding-bottom: 50px;

        @media (--viewport-ms-max) {
            padding-bottom: 40px;
        }
    }

    .swiper-slide {
        /*background-color:var(--color-accent-light);*/
        height: auto;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    &__nav {
        display: flex;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        padding: 24px 20px 0;
        justify-content: center;
        align-items: center;

        .swiper-pagination {
            margin: 0 40px;
        }

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            top: 60px;

            &::after {
                display: none;
            }

            .icon {
                fill: var(--color-icon-inverse)
            }

            &.scroll {
                top: 22px;

                .icon {
                    fill: var(--color-icon-accent)
                }
            }

            &.inactive {
                .icon {
                    fill: var(--color-icon-link-primary-disabled)
                }
            }
        }

        .swiper-button-prev {
            margin-top: 0;
            left: 32px;

            &.scroll {
                left: 20px;
            }
        }

        .swiper-button-next {
            margin-top: 0;
            right: 32px;

            &.scroll {
                right: 20px;
            }
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }

        @media (--viewport-ms-max) {
            display: flex;
        }

        &-tabs {
            .menu-tab {
                margin: 40px 0;

                &-scroller__scroll-content {
                    width: 100%;

                    .menu-tab-item {
                        width: 33.3%;
                    }
                }
            }

            .title-m {
                text-align: center;
                display: block;
            }
        }
    }
}