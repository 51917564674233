.tile-header-submenu {
    display: block;
    width: max-content;
    margin-bottom: 10px;

    &__title {
        margin-bottom: 20px;
        .title-s {
            font-size: 16px;
            font-family: 'Jornada Sans Medium';
            font-weight: 800;
        }
    }

    &__item {
        @media (--viewport-ms-min) {
            margin-bottom: 15px;
        }
    }

    &__linksRow {
        display: flex;
        flex-flow: column wrap;
        width: max-content;

        &-link {
            display: block;
            margin-bottom: 15px;

            @media (--viewport-ms-max) {
                margin-bottom: 20px;
            }

            .link {
                color: var(--color-text);
                font-family: 'Jornada Sans Book';
                font-size: 16px;

                &:hover,
                &:focus {
                    color: var(--color-text);
                    span {
                        &::after {
                            background-color: var(--color-text);
                        }
                    }
                }
            }
        }
        
        &-cta-mobile{

            display: none; 
            
            @media (--viewport-ms-max) { 
                        display: block; 
                
            }
        }
    }
}