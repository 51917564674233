.report {
    text-align: center;

    &__label {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__bodycopy {
        margin-bottom: 30px;
    }
}
