.steps {
    &.accent-light{
        background-color: var(--color-accent-light);
        margin-top: -60px;
        padding-top: 60px;
        margin-bottom: -30px;
        padding-bottom: 30px;
        padding-right: 19px;
        padding-left: 20px;
        @media (--viewport-xs-min) {
            margin-top: -50px;
            padding-top: 50px;
            margin-bottom: -50px;
            padding-bottom: 50px;
        }
        @media (--viewport-md-min) {
            margin-top: -80px;
            padding-top: 80px;
            margin-bottom: -40px;
            padding-bottom: 40px;
        }
        .steps__item {
            &:last-child {
                &::after{
                    background-color: transparent;
                    height:0;
                }
            }
        }
    }
    &__description {
        margin-bottom: 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }
    }

    &__item {
        position: relative;
        padding: 30px 0;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color-secondary-light);
            content: '';
        }
    }

    .step {
        &__number {
            @media (--viewport-xs-max) {
                margin-bottom: 10px;
            }
        }

        &__title {
            @media (--viewport-ms-max) {
                margin-bottom: 10px;
            }
        }
    }

  /* Swiper Variation */

  @media (--viewport-ms-max){
    .swiper-wrapper{
      padding-left: 10px;
    }
  }

  .swiper-container{
    padding-bottom: 50px;
    @media (--viewport-ms-max) {
      padding-bottom:40px;
    }
  }

  .swiper-slide {
    /*background-color:var(--color-accent-light);*/
    height: auto;
    width: 100%;
    padding-left:10px;
    padding-right:10px;
  }

  .swiper-navigation {
    display: flex;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    justify-content: center;
    align-items: center;

    .swiper-pagination {
      margin: 0 40px;
    }

    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;

      &::after {
        display: none;
      }

      .icon {
        height: 20px;
        width: 28px;
        fill:var(--color-accent)
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 0;
      width: 28px;
      height: 20px;
      margin-top: 0;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .swiper-pagination-bullet-active {
      background: var(--color-accent);
    }

    @media (--viewport-ms-max) {
      display:flex;
    }
  }
}
