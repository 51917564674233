.reviews {
    &__content {
        margin-bottom: 40px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 60px;
        }
        .reviews__ekomi{
            background-color: var(--color-white);
        }
    }

    &__label {
        margin-bottom: 20px;
    }
    
    &__link {
        text-align: center;
        margin-top: 30px;
        @media (--viewport-xs-max) {
            margin-top: 20px;
        }
    }

    &__main {
        @media (--viewport-sm-min) {
            position: relative;
            min-height: 314px;
        }

        @media (--viewport-lg-min) {
            min-height: 352px;
        }
    }

    &__ekomi {
        @media (--viewport-ms-max) {
            margin-bottom: 40px;
        }

        @media (--viewport-sm-min) {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 0 20px;
        }

        .ekomi-rating {
            @media (--viewport-sm-min) {
                margin-bottom: 30px;
            }
        }

        .reviews__nav {
            display: none;

            @media (--viewport-sm-min) {
                display: flex;
            }
        }
    }

    &__swiper {
        position: relative;
        z-index: 0;
        margin-bottom: 30px;

        @media (--viewport-sm-min) {
            padding-top: 74px;
            margin-bottom: 0;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (--viewport-ms-min) {
            justify-content: center;
        }

        @media (--viewport-sm-min) {
            display: none;
        }
    }
    &.accent-light-bg{background-color: var(--color-accent-light);
        padding: 40px 20px 30px;
        @media (--viewport-xs-max) {
            margin: 0 -20px;
        }
        .reviews__ekomi{
            background-color: var(--color-accent-light);
        }
    }
}


