.success {
    text-align: center;

    &__icon {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
        img, svg {
            width: 54px;
            height: 54px;
        }
    }

    &__description {
        margin-top: 32px;
        padding:0 20%;
        span.resaltado{color: var(--color-primary-brand);}
    }

    &__helpertext{
        color: var(--color-grey2);
        margin-top: 16px;
        .bodycopy-m{
            color: var(--color-grey2);
        }
    }

    &.small{
        .success__title{
            color: var(--color-accent);
            .title-m{
                color: var(--color-accent);
            }
        }
        .success__description{
            padding:0;
        }
        .success__icon{
            img, svg{
                width: 38px;
                height: 35px;
            }
        }
    }
}