.resources {
    &__header {
        margin-bottom: 10px;text-align:center;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 60px;
        }
    }

    &__title {
        margin-bottom: 30px;
        text-align: center;
        .title-l{
            color: var(--color-secondary);
        }

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 24px;
        }
    }

    &__link {
        text-align: center;
        margin-top: 20px;

        .link{
            cursor: pointer;
        }
    }

    &__options {
        .tile {
            margin-top: 30px;

            @media (--viewport-sm-min) {
                margin-top: 0;
            }
        }
    }
    .tile-resources{
        margin-bottom: 30px;
        @media (--viewport-xs-max) {
            margin-bottom: 20px;
        }
    }
}