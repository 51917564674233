.tile-review {
    text-align: center;

    &__rating {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }
    }

    &__bodycopy {
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }
    }
}
