.range-slider {
    width: 100%;


    &__fieldset {
        display: flex;flex-direction: row;justify-content: flex-end;align-items: flex-end;
        position: relative;background-color: var(--color-white);
        border: 1px solid var(--color-grey3);
        &:hover,
        &:focus {
            border-color: var(--color-secondary);
        }
    }

    &__input {
        height: 48px;
        width: 100%;
        padding: 17px 5px 11px 14px;
        text-align: right;
        font-family: 'Jornada News Book';
        font-size: 29px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0;
        border: 0;
        color: var(--color-text);

        &:hover,
        &:focus {
            border-color: var(--color-secondary);
        }
    }

    &__symbol {
        padding-bottom: 7px;
        padding-right: 14px;
        white-space: nowrap;
        text-transform: uppercase;
        .bodycopy-xs{
            color: var(--color-grey2);
        }
    }

    &__slider {
        margin-top: 19px;
        height: 4px;
        border:0;

        .noUi {
            &-connects {
                border: none;
                background-color: var(--color-quinary);
                cursor: pointer;
            }

            &-connect {
                background-color: var(--color-primary-brand);
            }

            &-handle {
                top: -14px;
                width: 32px;
                height: 32px;
                border: 10px solid var(--color-white);
                border-radius: 50%;
                box-shadow: none;
                outline: none;
                background-color: var(--color-primary-brand);
                cursor: ew-resize;
                transition: all 0.3s;
                outline: 1px solid var(--color-quinary);

                &::before,
                &::after {
                    content: none;
                }

                &:hover,
                &:focus {
                    outline: 1px solid var(--color-secondary);
                }
            }
        }
    }

    &__legend {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 19px;
        text-transform: uppercase;
    }

    &.bg-color{
        &_yellow {
            .range-slider__fieldset{background-color: var(--color-accent-light);border-color:var(--color-accent-light);
                &:hover,
                &:focus {
                    border-color: var(--color-secondary);
                }
            }
        }
    }
}
