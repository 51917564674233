.resources-card {

  display: flex;
  flex-flow: column;

  min-width: fit-content;
  height: fit-content;

  padding-bottom:40px;


  @media(--viewport-ms-max) {
    width: 100%;
    padding-bottom:50px;
  }



  &:hover,
  &:focus {
    .resources-card__icon {
      opacity: 1;
    }

    .resources-card__img-link {
      transform: scale(1.1);
    }
  }


  &__img {
    overflow: hidden;

    &-link {
      display: block;
      width: 100%;
      height: 220px;
      background: center / cover no-repeat;
      transition: all 0.5s;

    }
  }

  &__category {
    display: flex;
    width: fit-content;

    margin: 10px 0px;
    padding: 0px 10px;

    background-color: #DDDDDD;
    border-radius: 15px;

    cursor: pointer;

    .text-label-s {
      padding-top: 3px;
    }

    @media (--viewport-xs-max) {
      margin: 10px 20px;
    }

  }

  &__core {
    display: flex;
    flex-flow: row;
    justify-content:space-between;
    @media (--viewport-xs-max) {
      padding: 0px 20px;
    }
  }

  &__text {
    margin-right: 20px;

    &-title {
      padding-bottom: 10px;

      .title-m {
        color: var(--color-text);
      }
    }

    &-date {
      .text-label-m {
        color: #8B8B8B;
      }
    }

  }

  &__icon {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    opacity: 0;
  }





}