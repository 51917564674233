.form-radio-group {
    margin-bottom: 20px;
    position: relative;
    .radio-group{
        li{ width: auto;
            &:first-child{
                padding:0;
            }
            .radio-label{
                &:before{
                    background-color:var(--color-white);
                }
            }
        }
    }

    &.bgtransparent {
        .radio-group{
            li{
                .radio-label{
                    &:before{
                        background-color:transparent;
                    }
                }
            }
        }
    }
    &.bgaccentlight {
        .radio-group{
            li{
                .radio-label{
                    &:before{
                        background-color: var(--color-accent-light);
                        border-color: var(--color-accent-light);
                    }
                    &:hover,
                    &:focus {
                        &:before{
                            border-color: var(--color-secondary);
                        }
                    }
                }
            }
        }
    }

    .form-list {
        &__helper-text{padding-top: 15px;}
    }

    &.inColumn{
        .radio-group ul{
            flex-direction: column;
            li{padding-bottom: 10px;
                &:last-child{padding-bottom: 0;}
            }
        }
    }

    &.error {
        .radio .radio-label:before {
            border-color: var(--color-danger);
            color: var(--color-danger);

            &:focus {
                color: var(--color-text);
            }
        }

        .field-bottom {
            &__error-message {
                display: block;
                position: relative;
            }
            &__helper-text{display: none;}
        }
    }
}
