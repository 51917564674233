.title-s {
    color: var(--color-text-tertiary);
    transition: all 0.3s;

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}
