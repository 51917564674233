.contact {
    &__title {
        text-align: center;
    }
    &__subtitle {
        text-align: center;margin-top: 32px;
    }

    &__head {
        margin-bottom: 40px;

        @media (--viewport-ms-min) {
            margin-bottom: 56px;
        }
    }

    &__body {
        position: relative;

        /*@media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        } Lo quito porque provoca un bug en mobile en la modal */

        &::before,
        &::after {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color-secondary-light);
            content: '';
        }

        &.no-top-border {
            &::before {
                height: 0;
            }
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    &__list {
        padding: 20px;

        @media (--viewport-ms-min) {
            padding: 40px 0;
        }

        @media (--viewport-sm-min) {
            padding: 56px 0;
        }

        &>.row {
            justify-content: center;
        }
    }

    &__item {
        padding: 20px 0;
        text-align: center;

        &-link {
            margin-top: 20px;
        }

        &-desc {
            margin-top: 5px;

            @media (--viewport-sm-min) {
                margin-top: 5px;
            }

            &:last-of-type {
                margin-top: 20px;

                @media (--viewport-sm-min) {
                    margin-top: 20px;
                }
            }
        }

        &-lightbox {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    &__pic {
        max-width: 54px;
        margin: 0 auto 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 20px;
        }
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
    }
    .button{
        text-align: center;
        .btn{
            width: auto;
            margin-bottom: 56px;
        }
    }
}
