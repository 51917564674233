.advantages {
    padding: 40px 0 10px;

    @media (--viewport-ms-min) {
        padding: 80px 0 20px;
    }

    @media (--viewport-sm-min) {
        padding: 60px 0 30px;
    }
}
