.list {
    li{display: flex; align-items:flex-start; padding:0 0 16px; 
        .icon{min-width:24px; width: 24px; height:24px; margin-right: 10px;}
        .bodycopy-s {display:inline; width: calc(100%-44px);color: var(--color-text-subtle);}  
    }
    &.twocols{
        ul{width: 100%;display: flex; flex-wrap: wrap;
            li{width: 50%; padding-right: 10px;
                &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10), &:nth-child(12), &:nth-child(14), &:nth-child(16), &:nth-child(18), &:nth-child(20), &:nth-child(22), &:nth-child(24), &:nth-child(26), &:nth-child(28){padding-left: 10px; padding-right:0;}
                @media (--viewport-xs-max) {
                    width:100%; padding-right: 0;
                    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10), &:nth-child(12), &:nth-child(14), &:nth-child(16), &:nth-child(18), &:nth-child(20), &:nth-child(22), &:nth-child(24), &:nth-child(26), &:nth-child(28){padding-left: 0;}
                }
            }
        }
    }
    
    &__title {
        margin-bottom: 24px;
    }
    &__multiple {
        display:flex;
        justify-content: flex-start;
        align-items: flex-start;  
        @media (--viewport-xs-min) {
            flex-flow: column;
            gap: 40px;
        }

        @media (--viewport-ms-min) {
            flex-flow: nowrap;
            gap: 30px;
        }
        &.fullspace {
            flex-flow: wrap;
            .list {
                flex-basis: 100%;
            }
        }
        .list {
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;     
            flex-basis: 50%;
        }
    }
}