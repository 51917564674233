.text {
    &__bodycopy {
        margin-top: 20px;

        @media (--viewport-sm-min) {
            margin-top: 0;
        }
    }
    &__paragraph {
        margin-top: 20px;
    }

    &__subtitle {
        margin-top: 40px;
    }

    &__link {
        margin-top: 21px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }
    &.bg-color{
        &_yellow {background-color: var(--color-accent-light);padding:50px 0;
            @media (--viewport-xs-max) {
                padding:20px;
                margin: 0 -20px;
            }
        }
        &_bordeau {background-color:var(--color-accent-dark);padding:50px 0;
            @media (--viewport-xs-max) {
                padding:20px;
                margin: 0 -20px;
            }
            .title-xl, .bodycopy-m, .title-s, .link{ color:var(--color-white);}
            .link span:after{background-color:var(--color-white);}
        }
        &_green {background-color: #00635B;padding:50px 0;
            @media (--viewport-xs-max) {
                padding:20px;
                margin: 0 -20px;
            }
            .title-xl, .bodycopy-m, .title-s, .link{ color:var(--color-white);}
            .link span:after{background-color:var(--color-white);}
        }
        &_grey {background-color: var(--color-grey);padding:50px 0;
            @media (--viewport-xs-max) {
                padding:20px;
                margin: 0 -20px;
            }
        }
        &_accent-dark {background-color: var(--color-accent-dark);padding:50px 0;
            @media (--viewport-xs-max) {
                padding:20px;
                margin: 0 -20px;
            }
        }
        &_accent {background-color: var(--color-accent);padding:50px 0;
            @media (--viewport-xs-max) {
                padding:20px;
                margin: 0 -20px;
            }
        }
    }
    &__btn {
        margin-top: 21px;
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
            margin-bottom: 0;
        }
    }
    &__bullets-list{margin-top: 30px;
        li{display: flex; align-items:top; padding:10px 0; 
            .icon{width: 24px; height:26px; margin-right: 10px;}
            .text-label-m {display:inline; padding-top: 8px; width: calc(100%-44px);}  
        }
        &.twocolslist{
            ul{width: 100%;display: flex; flex-wrap: wrap;
                li{width: 50%; padding-right: 10px;
                    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10), &:nth-child(12), &:nth-child(14), &:nth-child(16), &:nth-child(18), &:nth-child(20), &:nth-child(22), &:nth-child(24), &:nth-child(26), &:nth-child(28){padding-left: 10px; padding-right:0;}
                    @media (--viewport-xs-max) {
                        width:100%; padding-right: 0;
                        &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10), &:nth-child(12), &:nth-child(14), &:nth-child(16), &:nth-child(18), &:nth-child(20), &:nth-child(22), &:nth-child(24), &:nth-child(26), &:nth-child(28){padding-left: 0;}
                    }
                }
            }
        }
    }
    &__smalltext{margin-top: 30px;
    }
}
