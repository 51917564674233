.figures-module {
    padding-left: 20px;
    padding-right: 19px;
    &.accentlight{
        background-color: var(--color-accent-light);padding: 0 0 40px;
        @media (--viewport-sm-max) {
            padding: 0 20px 40px;
        }
        @media (--viewport-ms-max) {
            padding: 40px 20px 40px;
        }
        @media (--viewport-xs-max) {
            margin: 0 -20px;
        }
    }

    &__head {
        text-align: center;
        margin-bottom: 30px;

        @media (--viewport-sm-min) {
            margin-top: 75px;
        }
    }

    &__nav {
        display: flex;

        @media (--viewport-xs-max) {
            justify-content: space-between;
            padding: 0 20px;
        }

        &-back,
        &-forward {
            margin-top: 30px;
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }
}
