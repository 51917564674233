.popup {
    &__trigger {
        font-family: 'Jornada Sans Book';
        font-size: 11px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0;
        color: var(--color-text);
        transition: all 0.3s;

        @media (--viewport-ms-max) {
            font-size: 13px;
            line-height: 18px;
        }

        &:hover,
        &:focus,
        &.active {
            color: var(--color-accent-dark);

            +.popup__window {
                @media (--viewport-sm-min) {
                    left: 50%;
                }

                &_right {
                    @media (--viewport-sm-min) {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
            height: 40px;
            content: '';
        }
    }
    .icon{
        width: 16px; height: 16px; margin-right: 4px; top: 4px; position: relative;
    }

    &.fractal{ /* Esta clase es para la previsualización en Fractal */
        .popup__window {
            position: relative; top:auto; left: 0 !important;
        }
    }
    &__window {
        position: absolute;
        z-index: 11;
        top: calc(100% + 14px);
        left: -9999px;
        transform: translate(-50%, 0);
        padding: 20px 30px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        background-color: var(--color-primary);

        &:hover,
        &:focus {
            left: 50%;
        }

        &::before {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 80px;
            height: 30px;
            content: '';
            cursor: pointer;
        }

        &::after {
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 0;
            height: 0;
            border-right: 4px solid transparent;
            border-bottom: 4px solid var(--color-primary);
            border-left: 4px solid transparent;
            content: '';
        }

        &_right {
            right: -9999px;
            left: auto;
            transform: none;

            &:hover,
            &:focus {
                right: 0;
                left: auto;
            }

            &::before {
                right: 0;
                left: auto;
                transform: none;
                width: 100%;
            }

            &::after {
                right: 20px;
                left: auto;
                transform: none;
            }
        }
        
    }

    &__lang {
        &-list {
            display: flex;
        }

        &-item {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        &-link {
            position: relative;
            font-family: 'Jornada Sans Book';
            font-size: 13px;
            font-weight: normal;
            line-height: 23px;
            letter-spacing: 0;
            color: var(--color-secondary-light);
            transition: all 0.3s;

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 40px;
                height: 30px;
                content: '';
            }

            &:hover,
            &:focus {
                color: var(--color-accent);
            }

            &_active {
                color: var(--color-text);
            }
        }
    }

    &__contact {
        @media (--viewport-sm-min) {
            min-width: 220px;
        }

        &-item {
            margin-bottom: 10px;

            @media (--viewport-ms-max) {
                margin-bottom: 20px;
            }

            &:first-child {
                @media (--viewport-ms-only) {
                    margin-bottom: 30px;
                }

                @media (--viewport-sm-min) {
                    margin-bottom: 25px;
                }
            }

            &:last-child {
                margin-bottom: 0;

                @media (--viewport-sm-min) {
                    margin-top: 25px;
                }
            }
        }

        &-info {
            padding-left: 23px;
            margin-top: 5px;
        }
    }

    &__info {

        display: flex;
        width: max-content;

        &-list {
            flex-direction: column;
        }

        &:hover,
        &:focus {
            right: 0;
            left: auto;
        }

        &-head {

            margin-bottom: 10px;
            margin-top: 10px;
            border-bottom: 1px solid hsl(0deg 0% 51% / 25%);

            &-title {
                display: block;
                text-align: left;
                color: #00635B;
                font-size: 18px;
                margin-right: 10px;
                margin-left: 11px;

                &-accent {
                    color: var(--color-accent);
                }

            }

            &-schedule {
                display: block;
                text-align: left;
                color: #8B8B8B;
                ;
                font-size: 13px;
                font-weight: lighter;
                margin-right: 10px;
                margin-left: 11px;
                margin-bottom: 5px;
            }

        }

        &-contacts {
            margin-bottom: 15px;
    
            &-phone{
                display: block;
                text-align: left;
                color: var(--color-text);
                font-size:18px;
                margin-right: 10px;
                margin-left: 11px;
    
                &-desc{
                    color: var(--unnamed-color-191919);
                    font-size:13px;
                    font-weight: lighter;
                    margin-bottom: 10px;
                }
            }
    
        }

    }
}