.related-article {
    padding:16px 0;
    @media(--viewport-xs-max) {
        margin-right: 0;
        margin-left: 0;
    }
    &__line {
        border-left: solid 2px var(--color-accent);
        padding:20px;
        background-color: var(--color-background-subtle); 
    }
    &__text .bodycopy-m{
        color:var(--color-text-link-secondary);
    }
}