.tile-resources {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;

    a{
        position:absolute;
        height:100%;
        width:100%;
    }
    
    &__pic {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 149px;
        background: center / cover no-repeat;
        transition: all 0.5s;
    }
    
    &__content {
        position: relative;
        z-index: 0;
        flex: 1;
        padding: 25px 17px;
        text-align: center;
        background-color: var(--color-accent-dark);
    }
    
    &__bodycopy {
        margin-top: 17px;
    }
    
    &__link {
        .link_not-a-link{
            margin-top: 20px;
            color: var(--color-accent-light);
            position:relative;
            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 1px;
                background-color: var(--color-accent-light);
                transition: all 0.3s;
                content: '';
            }
        }
    }

    &:hover{
        overflow: hidden;
        .tile-resources__pic {
            /*width: 150%;
            margin-left:-25%;*/
            transform: scale(1.1);
        }
        .tile-resources__title {
            text-decoration: underline;
            color: var(--color-white);
        }
        .tile-resources__link {
            .link_not-a-link{
                &::after {
                    width: 100%;
                }
            }
        }
    }
    &.transparent{
        .tile-resources__content{
            background:none;
        }

        .tile-resources__title {
            color: var(--color-secondary-brand);
        }

        .tile-resources__link {
            .link_not-a-link{
                color: var(--color-secondary);
                &::after {
                    background-color: var(--color-secondary-brand);
                }
            }
        }
    }
    &.accent-light{
        .tile-resources__content{
            background-color: var(--color-accent-light);
        }

        .tile-resources__title {
            color: var(--color-secondary-brand);
        }

        .tile-resources__link {
            .link_not-a-link{
                color: var(--color-secondary);
                &::after {
                    background-color: var(--color-secondary-brand);
                }
            }
        }
    }
}