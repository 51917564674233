.lightbox-content-result-form {
    .result-form{padding-bottom: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{margin-bottom:20px;}
        .title-m{color: var(--color-secondary); margin-bottom:40px;}
    }
}