.tile-step {
    display: flex;
    flex-direction: column;
    flex:1;

    min-width:230px;
    max-width:270px;

    &__pic {
        position: relative;
        overflow: hidden;
        min-width: 215px;
        height: 150px;
        margin-left: 15px;
        background: 50% / cover no-repeat;

    }

    &__number {
        display: flex;
        position: relative;
        overflow: hidden;
        width: 23%;
        height: 50px;
        background-color: var(--color-accent);
        margin-top: -40px;
        justify-content: center;
        align-items: center;
    }

    &__label {
        margin-bottom: 10px;

        .bodycopy-l {
            font-family: 'Jornada News Book';

            @media(--viewport-lg-min) {
                font-size: 22px;
            }
        }
    }

    &__description {
        margin-top: 20px;
    }

    &__content {
        position: relative;
        text-align: left;
        margin-top: 20px;
        margin-right: -20px;
        width: 100%;

    }

    &__link {
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }

    &-color-light {
        background-color: var(--color-accent-light);
    }
}