.form-search {
    margin-bottom: 20px;
    position: relative;
    .input{background-color: var(--color-white);}
    
    .icon-close, .icon-search {
        position: absolute;
        transform: translateY(-50%);
        cursor: pointer;
        right: 15px;
    }
    .icon-close {
        fill: var(--color-grey2);
        width: 15px;
        height: 15px;
        top: 63px;
    }
    .icon-search {
        fill: var(--color-grey2);
        width: 13px;
        height: 13px;
        top: 65px;
    }
    &.filled {
        .input {
            border-color: var(--color-secondary);
        }
    }
    &.disabled {
        .input {
            background-color: var(--color-grey-light);
            border-color: var(--color-quinary);
            color: var(--color-grey2);

            &:focus {
                color: var(--color-text);
            }
        }
    }
    &.bgtransparent {
        .input {
            background: transparent;

            &:focus {
                color: var(--color-text);
            }
        }
    }
    &.bgaccentlight {
        .input {
            background-color: var(--color-accent-light);
            border-color: var(--color-accent-light);
            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }

            &:focus {
                color: var(--color-text);
            }
        }
    }
    &.error {
        .input {
            border-color: var(--color-danger);
            color: var(--color-danger);

            &:focus {
                color: var(--color-text);
            }
        }

        .field-bottom {
            &__error-message {
                display:block;
                position: relative;
            }
            &__helper-text{display:none;}
        }
    }
}