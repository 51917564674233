.button-upload {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    height: 50px;
    &__input-file {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: wrap;
        top: 0;
        right: 0;
        margin-top: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
        opacity: 0;
        width: 133px;
        height: 50px;
    }
    .btn.btn_file {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        cursor: pointer;

        &:focus {
            outline: 0;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $color_primario;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: $color_primario;
        }

        &:-ms-input-placeholder { /* IE 10+ */
            color: $color_primario;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $color_primario;
        }
    }
    
}

