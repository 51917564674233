.dropdown-menu {
    display: none;
    position: absolute;
    box-sizing: border-box;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);
    margin: 0;
    padding: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 0;
    overflow: auto;
    will-change: transform, opacity;
    z-index: 8;
    transition: opacity 0.03s linear, height 250ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    background-color: #fff;
    color: #000;
    box-shadow: 0px 20px 20px 0px #1919190D;
    @media (--viewport-xs-max) {
        position:fixed;
    }
  
    &:focus {
        outline: none;
    }
    &__overlay {
        display:none;
        &.dropdown-menu--open {
            @media (--viewport-xs-max) {
                display: flex;
                position: fixed;
                width: 100%;
                height: 100vh;
                background-color: var(--color-black-90);
                z-index: 1;
                opacity: .8;
                top:0;
                left:0;
            }
        }
    }
    &--open {
        display: inline-block;
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
        z-index: 3;
        right: -15px;
        width: 250px;
        z-index: 5;
        top: 35px;
        
        @media (--viewport-xs-max) {
            width:100%;
            max-width: 100%;
            bottom:0;
            left:0;
            top:auto;
            right:auto;
        }
    }
    &__overlay.dropdown-menu--open {
        width: auto;
    }
    [dir=rtl] &, &[dir=rtl] {
        /* @noflip */
        transform-origin-left: bottom right;
        /* @noflip */
        transform-origin-right: bottom left;
    }
    
    &--anchor {
        position: relative;
        overflow: visible;
    }
    
    &--fixed {
        position: fixed;
    }
    
    &--fullwidth {
        width: 100%;
    }
    &__close {
        display:none;
        @media (--viewport-xs-max) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding: 15px 30px;
        }
    }
    &__btn {
        overflow: visible;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width:100%;
        display:flex;
        justify-content: flex-end;
    }
    &__heading {
        display:none;
        @media (--viewport-xs-max) {
            display:flex;
            padding: 15px 30px;
            border-bottom: 1px solid var(--color-gray-40); 
        }
    }
    &__footer {
        padding: 15px 30px;
    }
    &__list {
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-flow: column;
        margin: 0;
        max-width: 100%;
        width: 100%;
        gap: 5px;
        padding-top: 15px;
        padding-bottom: 15px;
        &-item {
            max-width: 100%;
            width: 100%;
            min-height:50px;
            padding:  15px 20px 15px 20px;
            color: var(--color-secondary);  
            display: flex;
            flex-flow: column;
            @media (--viewport-xs-max) {
                padding: 15px 30px;
            }
            &--active {
                color: var(--color-secondary);
            }
            &--disabled {
                color: var(--color-disabled);
            }
        }
    }
}

