.form-range-slider { 
    margin-bottom: 20px;
    width: 100%;position: relative;
    .range-slider__fieldset{
        display: flex;flex-direction: row;justify-content: flex-end;align-items: flex-end;
        position: relative;background-color: var(--color-white);
        border: 1px solid var(--color-grey3);
        &:hover,
        &:focus {
            border-color: var(--color-secondary);
        }
    }
    &.bgtransparent{
        .range-slider__fieldset{
            background: transparent;
        }
    }
    &.bgaccentlight{
        .range-slider__fieldset{
            background-color: var(--color-accent-light);
            border-color: var(--color-accent-light);
            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }
        }
    }
}
