.share-page {
    /* el estilo definido para el title-l es un apaño para corregir la visualización y aplicar diferentes estilos a los que lleva por defecto el h2*/
    .title-l{
        text-align: center;
        color:var(--color-accent);
        margin-bottom:20px;
        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }
        font-size: 18px;
        font-weight: normal;
        line-height: 28px;
        letter-spacing: 0;

        @media (--viewport-ms-min) {
            font-size: 24px;
            line-height: 34px;
        }

        @media (--viewport-sm-min) {
            font-size: 24px;
            line-height: 34px;
        }

        @media (--viewport-md-min) {
            font-size: 26px;
            line-height: 36px;
        }

        @media (--viewport-lg-min) {
            font-size: 30px;
            line-height: 38px;
        }
    }
        &__list {
            display: flex;
            flex-wrap: nowrap;
            margin: -5px 0;
            justify-content: center;
        }
    
        &__item {
            margin: 5px 20px 5px 0;
    
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
    
            &:last-child {
                margin-right: 0;
            }
        }
    
        &__link {
            position: relative;
            display: flex;
    
            .icon {
                width: 54px;
                height: 54px;
                fill: var(--color-tertiary);
    
                @media (--viewport-xs-max) {
                    width: 30px;
                    height: 30px;
                }
            }
    
            &:hover,
            &:focus {
                .icon {
                        fill: var(--color-tertiary-dark);
                }
            }
        }
}