.checkbox {display: flex; flex-direction: row; align-items: center;position:relative;
    .checkbox-input{opacity: 0;

        &:hover,
        &:focus {
            + .checkbox-label {
                &::before {
                    border-color: var(--color-secondary);
                }
            }
        }

        &:checked {
            + .checkbox-label {
                &::after {
                    opacity: 1;
                }
            }
            +.icon{
                opacity: 1;
            }
        }
    }
    &-label {
        padding-top: 3px;
        padding-left: 22px;
        min-height: 25px;
        display: flex;
        align-items: center;

        .text-label-m{
            padding-top: 2px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 25px;
            height: 25px;
            border: 1px solid var(--color-grey3);
            transition: border-color 0.3s;
            content: '';
        }
        &:hover,
        &:focus {
            &:before{
                border-color: var(--color-secondary);
            }
        }

        a {
            text-decoration: underline;
            color: var(--color-text);

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &_light {
            .bodycopy-s {
                color: var(--color-text-light);
            }

            a {
                color: var(--color-text-light);
            }
        }
    }
    .icon {
        opacity: 0;
        position: absolute;
        top: 4px;
        left: 4px;
        display: block;
        width: 18px;
        height: 18px;
        transition: opacity 0.3s;
        fill: var(--color-primary-brand);
    }
}
