.ancho-completo-header {
    position: relative;
    z-index: 0;
    padding: 40px 0 60px;
    &.nomargin{
        padding-bottom: 0;
    }

    @media (--viewport-ms-min) {
        padding: 60px 0;
    }

    &_before {
        padding: 0;
        margin-bottom: 20px;

        @media (--viewport-sm-min) {
            margin-bottom: 60px;
        }

        .ancho-completo-header {
            &__title {
                @media (--viewport-xs-max) {
                    margin-bottom: 20px;
                }

                @media (--viewport-ms-only) {
                    margin-bottom: 40px;
                }
            }
        }
    }


    &__title {
        margin-top: 20px;
    }

    &__back {
        @media (--viewport-ms-max) {
            display: none;
        }

        &_before {
            text-align: right;
        }

        a {
            white-space: nowrap;
        }
    }

    &__breadcrumbs {
        overflow-x: hidden;
        width: 50%;
        
        @media (--viewport-sm-max) {
            width: 60%;
        }
        
        @media (--viewport-ms-max) {
            width: 100%;
            padding: 0;
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            @media (--viewport-ms-max) {
                flex-wrap: wrap;
            }

            li {
                white-space: nowrap;
                @media (--viewport-ms-max) {
                    white-space: normal;
                }

                &:last-child {
                    white-space: normal;
                    min-width: 0;
                }
            }
        }
    }

    

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap:40px;

        &.languages {
            @media (--viewport-ms-max) {
                flex-direction: column;
                align-items: flex-start;
                .ancho-completo-header__breadcrumbs{
                    max-width: 100%;
                }
            }
        }
    }

    &__languages {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 40px;

        @media (--viewport-ms-max) {
            margin-top: 20px;
            margin-left: 0;
        }

        .icon {
            margin-right: 8px;
            width: 16px;
            height: 16px;
        }

        .bodycopy-m {
            margin-right: 32px;
        }

        ul {
            display: flex;

            li {
                border-left: solid 1px var(--color-grey3);
                padding: 0 16px;

                &:first-child {
                    border-left: none;
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                .link {
                    color: var(--color-grey3);
                }

                .link:hover {
                    color: var(--color-primary-brand);
                }

                .link_active {
                    color: var(--color-secondary);
                }
            }
        }
    }
    &.full{
        .ancho-completo-header__back {
            @media (--viewport-ms-max) {
                display: block;
            }
        } 
        
        @media (--viewport-ms-max) {
            .ancho-completo-header__nav {
                justify-content: flex-start;
                align-items: flex-start;
                grid-gap: 24px;
                flex-direction: column;
            }
            .ancho-completo-header__title{
                margin-top: 40px;
            }
        }
    }
}