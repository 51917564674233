.steps-nav__list {
  ul {
    display: flex;
    flex-flow: nowrap;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    overflow-x: scroll;
    scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox*/
    -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge*/

    &::-webkit-scrollbar {
      display: none; /* Hide the scrollbar in Chromium */
    }
    &.auto-width {
      .steps-nav__list-item {
        width: auto;
        flex-basis: auto;
      }
    }
    .steps-nav__list-item {
      padding: var(--gutter-vertical) var(--gutter-width-ms-min);
      width: 100%;
      min-width: max-content;
      height: 50px;
      justify-content: center;
      align-items: center;
      color: var(--color-text);
      cursor: pointer;
      background-color: var(--color-background-disabled);
      &.success {
        background-color: var(--color-background-disabled);
        .state {
          display: flex;
          .icon {
            fill: var(--success-100);
          }
        }
      }
      &.reject {
        background-color: var(--color-background-error);
        color: var(--color-text-error);
        .state {
          display: flex;
          .icon {
            fill: var(--color-icon-error);
          }
        }
      }
      &.active {
        background-color: var(--color-background-accent-bolder);
        color: var(--color-white);
        .state {
          background-color: var(--color-white);
          .icon {
            fill: var(--color-accent-dark);
          }
        }
        &.success {
          background-color: var(--color-accent-dark);
          color: var(--color-white);
          .state {
            background-color: var(--color-white);
            display: flex;
            .icon {
              fill: var(--color-accent-dark);
            }
          }
        }
        &.reject {
          background-color: var(--color-danger);
          color: var(--color-white);
          .state {
            background-color: var(--color-white);
            display: flex;
            .icon {
              fill: var(--color-error);
            }
          }
        }
      }
      &.disabled {
        opacity: 0.7;
        &:hover {
          cursor: default;
        }
      }
      &-step {
        display: flex;
        flex-flow: nowrap;
        justify-content: center;
        .state {
          display: none;
        }
        .state {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          transition: border-color 0.3s;
          position: relative;
          flex-flow: wrap;
          justify-content: center;
          align-items: center;
          margin-right: var(--half-gutter-width);

          .icon {
            fill: var(--color-white);
          }
        }
        .sequence + .text {
          margin-left: calc(var(--half-gutter-width) * 0.5);
        }
        @media (--viewport-sm-max) {
          .sequence {
            margin-left: 0;
            display: flex;
          }
          .text {
            display: none;
            &.visibility {
              display: flex;
            }
          }
        }
      }
    }
  }
  .steps-nav__breadcrumb {
    display: flex;
    flex-flow: nowrap;
    justify-content: center;
    align-content: center;
    padding: var(--gutter-md-vertical) var(--gutter-width);
  }

  &.oneLine {
    margin: 66px 0 40px;
    @media (--viewport-sm-max) {
      margin: 32px 0 24px;
    }
    &.sticky {
      @media (--viewport-ms-max) {
        padding: 32px 30px 0;
        margin: 0 -30px 24px;
        width: calc(100% + 60px);

        &.sticking {
          box-shadow: 0 25px 20px 0 rgba(0, 0, 0, 0.1);
          background-color: white;
          z-index: 13;

          .mobile-menu-is-open & {
            z-index: 0;
          }
        }
      }
      @media (--viewport-xs-max) {
        width: calc(100% + 40px);
        padding: 32px 20px 0;
        margin: 0 -20px 24px;

        .menu-tab-content & {
          width: calc(100% + 80px);
          padding: 32px 20px 0;
          margin: 0 -40px 24px;
        }
      }
    }
    .steps-nav__list-item {
      padding: 0 0 16px;
      height: auto;
      justify-content: flex-start;
      background-color: transparent;
      color: var(--color-text-error);
      display: none;

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:first-child {
        span.link {
          visibility: hidden;
        }
      }

      .number-step {
        margin-right: 20px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        background-color: transparent;
      }
      &.active {
        background-color: transparent;
        color: var(--color-text-error);
        display: block;
      }
    }

    @media (--viewport-ms-max) {
      &.sticky {
        position: sticky;
      }
    }
  }
}

.steps-nav__in-step {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 30px;
  width: 100%;

  .btn {
    padding-left: 50px;
    padding-right: 50px;
  }
}
