.calculator-form-with-result {
  position: relative;

  .conditions {
    margin-top: 40px;
  }

  .steps-nav__list {
    display: none;

    @media (--viewport-ms-max) {
      display: block;
      position: fixed;
      z-index: 13;
      background-color: var(--color-background);
      box-shadow: 0px 10px 5px 0px #0000000d;
      width: 100%;
      padding: 16px 20px 69px; /*aumentamos padding bottom para tapar los botones del header en scroll */
      margin: 0 -31px 32px;
      top: 0;
      left: 31px;

      ul {
        .steps-nav__list-item.active {
          .steps-nav__list-item-step {
            .text {
              display: block;
              position: absolute;
              top: 80px;
              left: 20px;
            }
          }
        }
      }
    }

    @media (--viewport-xs-max) {
      left: 20px;
      margin: 0 -20px 32px;
    }
  }

  .block {
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }

    .subtitle {
      margin-bottom: 32px;

      .icon {
        width: 54px;
        height: 54px;
      }
    }

    .form-list {
      .prelabel {
        .title-s {
          color: var(--color-text-accent-bold);
        }
      }

      .alert.inline .alert__wrap {
        margin: 30px 0;
      }
    }
  }

  @media (--viewport-ms-max) {
    form {
      margin-bottom: 40px;
    }
  }

  @media (--viewport-xs-max) {
    .card-result {
      margin: 0 -20px;

      &.swiper-slide {
        margin: 0;
      }
    }
  }

  #month {
    text-transform: capitalize;
  }

  .prelabel {
    display: flex;
    justify-items: flex-start;
    align-items: flex-end;

    .tooltip {
      margin-bottom: -45px;
      margin-left: -25px;
    }
  }

  .card-result-swiper.scroll {
    @media (--viewport-ms-max) {
      margin: 0 -20px;
    }
  }
}
