
.textarea {
    &-field{
        width: 100%;
        min-height: 95px;
        padding: 15px;
        border: 1px solid var(--color-grey3);
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;
        color: var(--color-text);
        background-color: transparent;
        resize: none;

        @media (--viewport-ms-min) {
            padding: 15px;
        }

        &:disabled, &.disabled{
            background-color: var(--color-quaternary);
            border-color: var(--color-quinary);
        }

        &-field {
            transition: border-color 0.3s;
        }
    }
}
