.splitter {
    &_bg-color {
        &_yellow {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: var(--color-accent-light);
                }
            }
        }

        &_bordeau {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: #910048;
                }
            }
        }

        &_green {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: #00635B;
                }
            }
        }

        &_white {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: var(--color-white);
                }
            }
        }

        &_grey {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: var(--color-grey);
                }
            }
        }
        &_gray-05 {
            .splitter {

                &__wrapper {
                    background-color: var(--color-quaternary);
                    
                    @media (--viewport-ms-min) {
                        margin-top: 20px;
                    }

                    @media (--viewport-sm-min) {
                        margin-top: 60px;
                    }

                    @media (--viewport-md-min) {
                        margin-top: 80px;
                    }

                    @media (--viewport-lg-min) {
                        margin-top: 100px;
                    }
                }
            }
        }

        &_accent {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: var(--color-accent);
                }
            }
        }

        &_accent_dark {
            .splitter {

                &__wrapper,
                &-placeholder {
                    background-color: var(--color-accent-dark);
                }
            }
        }
    }

    &_padding {
        &_top {
            &_xxsmall {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-top: 7.5px;

                        @media (--viewport-ms-min) {
                            padding-top: 12.5px;
                        }

                        @media (--viewport-sm-min) {
                            padding-top: 10px;
                        }

                        @media (--viewport-md-min) {
                            padding-top: 15px;
                        }

                        @media (--viewport-lg-min) {
                            padding-top: 17.5px;
                        }
                    }
                }
            }

            &_xsmall {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-top: 15px;

                        @media (--viewport-ms-min) {
                            padding-top: 25px;
                        }

                        @media (--viewport-sm-min) {
                            padding-top: 20px;
                        }

                        @media (--viewport-md-min) {
                            padding-top: 30px;
                        }

                        @media (--viewport-lg-min) {
                            padding-top: 35px;
                        }
                    }
                }
            }

            &_small {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-top: 30px;

                        @media (--viewport-ms-min) {
                            padding-top: 50px;
                        }

                        @media (--viewport-sm-min) {
                            padding-top: 40px;
                        }

                        @media (--viewport-md-min) {
                            padding-top: 60px;
                        }

                        @media (--viewport-lg-min) {
                            padding-top: 70px;
                        }
                    }
                }
            }

            &_40-60 {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-top: 20px;

                        @media (--viewport-ms-min) {
                            padding-top: 30px;
                        }
                    }
                }
            }

            &_medium {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-top: 20px;

                        @media (--viewport-ms-min) {
                            padding-top: 30px;
                        }

                        @media (--viewport-sm-min) {
                            padding-top: 50px;
                        }
                    }
                }
            }

            &_large {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-top: 50px;

                        @media (--viewport-ms-min) {
                            padding-top: 80px;
                        }

                        @media (--viewport-sm-min) {
                            padding-top: 60px;
                        }

                        @media (--viewport-md-min) {
                            padding-top: 80px;
                        }

                        @media (--viewport-lg-min) {
                            padding-top: 100px;
                        }
                    }
                }
            }
        }

        &_bottom {
            &_xxsmall {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-bottom: 7.5px;

                        @media (--viewport-ms-min) {
                            padding-bottom: 12.5px;
                        }

                        @media (--viewport-sm-min) {
                            padding-bottom: 10px;
                        }

                        @media (--viewport-md-min) {
                            padding-bottom: 15px;
                        }

                        @media (--viewport-lg-min) {
                            padding-bottom: 17.5px;
                        }
                    }
                }
            }

            &_xsmall {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-bottom: 15px;

                        @media (--viewport-ms-min) {
                            padding-bottom: 25px;
                        }

                        @media (--viewport-sm-min) {
                            padding-bottom: 20px;
                        }

                        @media (--viewport-md-min) {
                            padding-bottom: 30px;
                        }

                        @media (--viewport-lg-min) {
                            padding-bottom: 35px;
                        }
                    }
                }
            }

            &_small {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-bottom: 30px;

                        @media (--viewport-ms-min) {
                            padding-bottom: 50px;
                        }

                        @media (--viewport-sm-min) {
                            padding-bottom: 40px;
                        }

                        @media (--viewport-md-min) {
                            padding-bottom: 60px;
                        }

                        @media (--viewport-lg-min) {
                            padding-bottom: 70px;
                        }
                    }
                }
            }

            &_40-60 {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-bottom: 20px;

                        @media (--viewport-ms-min) {
                            padding-bottom: 30px;
                        }
                    }
                }
            }

            &_medium {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-bottom: 20px;

                        @media (--viewport-ms-min) {
                            padding-bottom: 30px;
                        }

                        @media (--viewport-sm-min) {
                            padding-bottom: 50px;
                        }
                    }
                }
            }

            &_large {
                .splitter {

                    &__wrapper,
                    &-placeholder {
                        padding-bottom: 50px;

                        @media (--viewport-ms-min) {
                            padding-bottom: 80px;
                        }

                        @media (--viewport-sm-min) {
                            padding-bottom: 60px;
                        }

                        @media (--viewport-md-min) {
                            padding-bottom: 80px;
                        }

                        @media (--viewport-lg-min) {
                            padding-bottom: 100px;
                        }
                    }
                }
            }
        }
    }

    &_header-before {
        padding-top: 160px;

        &.active {
            position: relative;
            z-index: 11;
            background-color: var(--color-primary);
        }

        /* Quito este estilo porque provoca un espacio exagerado entre el componente ancho completo header y el header (CA) @media (--viewport-lg-min) {
            padding-top: 260px;
        }*/
    }

    &-placeholder {
        position: relative;
        height: 100px;
        width: 100%;
        background-color: var(--color-accent-light);

        &__size {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Jornada Sans Book';
            font-size: 24px;
            line-height: 1;
            color: var(--color-text);
        }
    }
}

.splitter_bg-color_bordeau .bodycopy-l,
.splitter_bg-color_bordeau .bodycopy-m,
.splitter_bg-color_bordeau .bodycopy-s,
.splitter_bg-color_bordeau .bodycopy-xs,
.splitter_bg-color_bordeau .number-l,
.splitter_bg-color_bordeau .number-m,
.splitter_bg-color_bordeau .number-xl,
.splitter_bg-color_bordeau .number-xs,
.splitter_bg-color_bordeau .text-label-m,
.splitter_bg-color_bordeau .text-label-s,
.splitter_bg-color_bordeau .text-link,
.splitter_bg-color_bordeau .title-l,
.splitter_bg-color_bordeau .title-m,
.splitter_bg-color_bordeau .title-s,
.splitter_bg-color_bordeau .title-xl,
.splitter_bg-color_bordeau .title-xxl,
.splitter_bg-color_bordeau a,
.splitter_bg-color_green .bodycopy-l,
.splitter_bg-color_green .bodycopy-m,
.splitter_bg-color_green .bodycopy-s,
.splitter_bg-color_green .bodycopy-xs,
.splitter_bg-color_green .number-l,
.splitter_bg-color_green .number-m,
.splitter_bg-color_green .number-xl,
.splitter_bg-color_green .number-xs,
.splitter_bg-color_green .text-label-m,
.splitter_bg-color_green .text-label-s,
.splitter_bg-color_green .text-link,
.splitter_bg-color_green .title-l,
.splitter_bg-color_green .title-m,
.splitter_bg-color_green .title-s,
.splitter_bg-color_green .title-xl,
.splitter_bg-color_green .title-xxl,
.splitter_bg-color_green a {
    color: var(--color-white);
}

.splitter_bg-color_bordeau .featured__title-link .title-xl,
.splitter_bg-color_bordeau .featured__title-link .title-xl {
    color: var(--color-white);
}

.splitter_bg-color_bordeau .featured__category .text-label-s,
.splitter_bg-color_bordeau .featured__category .text-label-s {
    color: var(--color-white);
}

.splitter_bg-color_bordeau .text .title-xl,
.splitter_bg-color_bordeau .text .title-xl {
    color: var(--color-white);
}