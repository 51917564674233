.footer {

    &__menu {
        background-color: var(--color-accent-dark);

        @media (--viewport-xs-max) {
            padding: 20px;
            margin-right: -19px;
            margin-left: -20px;
        }

        /* the next 2 paddings define the margins for all the components that are called in the Footer menu */

        @media (--viewport-sm-min) {
            padding: 0 40px;
        }

        @media (--viewport-md-min) {
            padding: 0 60px;
        }

        &-tiles {
            padding: 40px 0 20px;
        }

        &-tile {
            @media (--viewport-xs-max) {
                margin-bottom: 20px;
            }

            @media (--viewport-ms-only) {
                margin-bottom: 25px;
            }
        }

        &-section {

            border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            &:last-child {
                border-bottom: none;
            }

        }

        &-legal {

            @media (--viewport-ms-min) {
                padding: 30px 0 15px;
            }

            &-list {
                @media (--viewport-ms-min) {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-left: -15px;
                }

                @media (--viewport-sm-min) {
                    justify-content: space-between;
                }
            }

            &-item {
                margin-bottom: 20px;

                @media (--viewport-ms-min) {
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-bottom: 15px;
                }

                @media (--viewport-ms-only) {
                    flex-basis: 50%;
                }

                &.lg {
                    @media (--viewport-sm-only) {
                        flex-basis: 50%;
                    }
                }
            }

            &-link {
                display: block;
                transition: all 0.3s;

                &:hover,
                &:focus {
                    opacity: 0.6;
                }
            }
        }

        &.row-4-cols {
            ul {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding-bottom: 20px;
                flex-wrap: wrap;

                /* one item */
                li:first-child:nth-last-child(1) {
                    /* -or- li:only-child { */
                    width: 100%;
                }

                /* two items */
                li:first-child:nth-last-child(2),
                li:first-child:nth-last-child(2)~li {
                    width: 50%;
                }

                li:first-child:nth-last-child(2)~li.tile-footer-menu__reduced-third-col-item {
                    width: auto;
                }

                /* three items */
                li:first-child:nth-last-child(3),
                li:first-child:nth-last-child(3)~li {
                    width: 33.3333%;
                }

                /* four items */
                li:first-child:nth-last-child(4),
                li:first-child:nth-last-child(4)~li {
                    width: 25%;
                }

                @media (--viewport-sm-max) {

                    /* three items */
                    li:first-child:nth-last-child(3),
                    li:first-child:nth-last-child(3)~li {
                        width: 50%;
                    }

                    /* four items */
                    li:first-child:nth-last-child(4),
                    li:first-child:nth-last-child(4)~li {
                        width: 50%;
                    }
                }

                @media (--viewport-xs-max) {

                    /* two items */
                    li:first-child:nth-last-child(2),
                    li:first-child:nth-last-child(2)~li {
                        width: 100%;
                    }

                    li:first-child:nth-last-child(2)~li.tile-footer-menu__reduced-third-col-item {
                        width: auto;
                    }

                    /* three items */
                    li:first-child:nth-last-child(3),
                    li:first-child:nth-last-child(3)~li {
                        width: 100%;
                    }

                    /* four items */
                    li:first-child:nth-last-child(4),
                    li:first-child:nth-last-child(4)~li {
                        width: 100%;
                    }
                }

                li {
                    padding: 0 10px;

                    @media (--viewport-ms-min) {
                        padding: 0 15px;
                    }

                    @media (--viewport-sm-min) {
                        padding: 0 20px;
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    @media (--viewport-sm-max) {
                        &:nth-of-type(3) {
                            padding-left: 0;
                        }
                    }

                    @media (--viewport-xs-max) {
                        padding-left: 0;
                    }


                    .tile-footer-menu__reduced-first-col {
                        margin-bottom: 0;
                    }
                    .tile-footer-menu__reduced-fourth-col {
                        margin-top: 8px;
                    }


                    ul {
                        flex-direction: column;
                        justify-content: flex-start;

                        li {
                            margin-top: 0;

                            &:last-child {
                                padding: 0;
                            }

                            div.tile-footer-menu__reduced-info.flex {
                                display: flex;
                                flex-direction: row;
                            }

                            div.tile-footer-menu__reduced-info.flex ul {
                                display: flex;
                                flex-direction: row;
                                padding-bottom: 0;

                                .bodycopy-s {
                                    display: inline;
                                }

                                .icon {
                                    fill: var(--color-txt-secondary);
                                    margin-left: 8px;
                                    width: 16px;
                                    height: 16px;
                                }

                                li {
                                    padding: 0 8px;
                                    width: auto;

                                    &::before {
                                        content: "/";
                                        color: var(--color-txt-secondary);
                                        margin-left: -8px;
                                        margin-right: 8px;
                                    }

                                    &:first-child {
                                        padding-left: 0;

                                        &::before {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .tile-footer-menu__reduced-info,
                            &.tile-footer-menu__reduced-third-col-item {
                                margin-top: 8px;
                                margin-bottom: 0;
                            }
                            &.tile-footer-menu__reduced-fourth-col-item{
                                margin-bottom: 0;
                            }

                            @media (--viewport-sm-min) {
                                &.tile-footer-menu__reduced-third-col-item:last-child {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }

                    .bodycopy-s {
                        .bodycopy__link {
                            color: var(--color-txt-secondary);

                            span::after {
                                display: none;
                            }

                            span {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

    }

    &__main {
        background-color: var(--color-accent);

        @media (--viewport-xs-max) {
            padding: 0 20px;
            margin-right: -19px;
            margin-left: -20px;
        }

        @media (--viewport-sm-min) {
            padding: 0 40px;
        }

        @media (--viewport-md-min) {
            padding: 0 60px;
        }

        &-section {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            &:last-child {
                border-bottom: none;
            }
        }

        &-info {
            padding-top: 40px;

            @media (--viewport-ms-min) {
                padding-top: 60px;
            }

            &-head {
                margin-bottom: 20px;

                &-link {
                    display: block;
                    transition: all 0.3s;

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                    }
                }
            }

            &-img {
                display: block;
                max-width: 100%;
                height: auto;
            }

            &-item {
                margin-bottom: 40px;
            }

            &-link {
                margin-bottom: 30px;

                @media (--viewport-ms-min) {
                    margin-bottom: 20px;
                }

                .link {
                    transition: all 0.3s;

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                        color: var(--color-text-light);
                    }
                }
            }

            &-general-link {
                .link {
                    transition: all 0.3s;

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                        color: var(--color-text-light);
                    }
                }
            }

            &-sponsors {
                margin-top: 20px;
                margin-bottom: -20px;
            }

            &-sponsor {
                margin-top: auto;
                margin-bottom: 20px;

                &-link {
                    display: block;
                    transition: all 0.3s;

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                    }
                }
            }
        }

        &-share {
            padding-top: 40px;

            &-list {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                @media (--viewport-ms-min) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: -15px;
                    margin-left: -15px;
                    /* No se está aplicando (CA)
                    &.right{
                        justify-content: flex-end;
                    }
                    */
                }
            }

            &-item {
                margin-bottom: 40px;

                @media (--viewport-ms-min) {
                    padding-right: 15px;
                    padding-left: 15px;
                }

                &:last-child {
                    @media (--viewport-ms-only) {
                        flex-basis: 100%;
                    }
                }
            }

            &-link {
                display: block;
                transition: all 0.3s;

                &:hover,
                &:focus {
                    opacity: 0.6;
                }
            }

            &-img {
                display: block;
                max-width: 100%;
                height: auto;
                max-height: 40px;
            }

            &-socials {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: -5px 0;
            }

            &-social {
                margin: 5px 30px 5px 0;

                @media (--viewport-sm-min) {
                    margin-right: 20px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &-link {
                    position: relative;
                    display: flex;
                    transition: all 0.3s;

                    &::after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: block;
                        width: 40px;
                        height: 40px;
                        content: '';
                    }

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                    }
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    fill: var(--color-primary);
                }
            }

            &-certificates {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: -5px 0;
            }

            &-certificate {
                margin: 5px 20px 5px 0;

                @media (--viewport-ms-max) {
                    margin: 5px 15px 5px 0;
                }

                @media (--viewport-xs-max) {
                    margin: 5px 18px 5px 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &-link {
                    display: block;
                    transition: all 0.3s;

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                    }
                }

                &-img {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
        }

        &-legal {
            padding: 40px 0 20px;

            @media (--viewport-ms-min) {
                padding: 30px 0 15px;
            }

            &-list {
                @media (--viewport-ms-min) {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-left: -15px;
                }

                @media (--viewport-sm-min) {
                    justify-content: space-between;
                }
            }

            &-item {
                margin-bottom: 20px;

                @media (--viewport-ms-min) {
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-bottom: 15px;
                }

                @media (--viewport-ms-only) {
                    flex-basis: 50%;
                }

                &.lg {
                    @media (--viewport-sm-only) {
                        flex-basis: 50%;
                    }
                }
            }

            &-link {
                display: block;
                transition: all 0.3s;

                &:hover,
                &:focus {
                    opacity: 0.6;
                }
            }
        }

        &-copyright {
            padding: 40px 0 20px;
            text-align: center;

            @media (--viewport-ms-min) {
                padding: 30px 0 15px;
            }
        }

        &-company {
            padding: 40px 0 20px;

            @media (--viewport-ms-min) {
                padding: 30px 0 15px;
            }

            color: white;
            font-size: 12px;
            display: flex;
            justify-content: center;
        }
    }
}