.recaptcha {
    margin-top: 40px;display:flex; flex-direction: column;
    #errorcaptchaContact {
        display: none;
    }
    .recaptcha-checkbox{
        cursor:pointer;
    }
}

.grecaptcha-badge { visibility: hidden; }
