.lightbox-content-resources-form {
    .resources-form{padding-bottom:40px;
        .tooltip-label{
            height: auto;
        }
        /*input, .access-select__chosen-option{border-color: var(--color-accent-dark);}
        .access-select__chosen-icon .icon{fill: var(--color-accent);} LO QUITAMOS POR PETICION DE DS (CA)*/
        .recaptcha{margin-top: 0;}
        .form-list__valid-indicator{
            top: 31px;
        }
    }
}