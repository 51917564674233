.tile-interest-rate {
    flex-grow: 1;
    height: 100%;
    padding: 40px 20px;
    background-color: var(--color-accent-light);
    &.without-bg{
        background-color: transparent;
    }

    @media (--viewport-ms-min) {
        padding: 40px 60px;
    }

    @media (--viewport-sm-min) {
        padding: 40px;
    }

    &__head {
        margin-bottom: 10px;
        text-align: center;
    }

    &__pretitle{
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &__pic {
        display: flex;
        align-items: center;
        max-width: 150px;
        min-height: 40px;
        margin: 0 auto 10px;
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__quote {
        margin-top: 10px;
    }

    &__description {
        margin-top: 10px;
        margin-bottom: 30px; /*provisional hasta que se vuelvan a incluir las tarifas*/
        &.with-items{
            margin-bottom: 0;
        }
    }

    &__item {
        display: none; /*provisional hasta que se vuelvan a incluir las tarifas*/
        padding: 20px 0;
        border-bottom: 1px solid var(--color-accent-dark);

        &:last-child {
            border: none;
        }

        &-title {
            display: flex;
            margin-bottom: 10px;

            &-subindex {
                margin-left: 5px;
                margin-top: -5px;
                font-size: 14px;
                position: absolute;
            }
        }

    }

    &__main.with-items{
        ul.tile-interest-rate__items{
            li.tile-interest-rate__item{display:block;}
        }
    }

    &__numbers {
        display: flex;
        margin-bottom: 20px;

        &_three-items {
            .tile-interest-rate {
                &__numbers {
                    &-item {
                        @media (--viewport-sm-min) {
                            margin-right: 40px;
                        }
                    }
                }

                &__number {
                    &_plus {
                        .tile-interest-rate {
                            &__number {
                                &-value {
                                    &::before {
                                        @media (--viewport-sm-min) {
                                            left: -10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-item {
            margin-right: 40px;

            @media (--viewport-sm-only) {
                margin-right: 18px;
            }

            @media (--viewport-lg-min) {
                margin-right: 60px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__lightbox{
        &-button {
            color: var(--color-accent);
        }
    }

    &__summary {
        color: var(--color-accent);
    }

    &__number {
        &_plus {
            .tile-interest-rate {
                &__number {
                    &-value {
                        position: relative;

                        &::before {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            content: '+';
                        }
                    }
                }
            }
        }

        &-title {
            display: flex;
            margin-bottom: 2px;
            position: relative;

            &-tae {
                color: var(--color-accent-dark);
            }
            .tooltip{position:relative;top: 0;left: 5px;}
        }

        &-var {
            margin-left: 5px;
        }
    }

    &__tooltip-trigger {
        margin-left: 8px;
    }

    &__footer {
        margin-top: 10px;
    }

    &__btn {
        margin-top: 0px;
        text-align: center;
    }
}
