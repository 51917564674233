.states {
    background-color: var(--color-grey-light);
    position: relative;
    margin-bottom: 24px;
    padding: 45px 24px 24px;

    .title-m,
    .bodycopy-s {
        color: var(--color-grey2);
    }

    .bodycopy-s {
        margin-top: 8px;
    }

    .label-m {
        background-color: var(--color-gray-20);
        padding: 6.5px 10px;
        position: absolute;
        top: 0;
        right: 0;
    }

    &.active {
        background-color: var(--color-secondary-brand);

        .title-m,
        .bodycopy-s {
            color: var(--color-white);
        }

        span.triangle {
            position: absolute;
            bottom: -24px;
            left: 0;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 24px 24px 0 0;
            border-color: var(--color-secondary-brand) transparent transparent transparent;
            transform: rotate(0deg);
        }
    }

    &.progress {
        .label-m {
            background-color: var(--color-background-accent);
            color: var(--color-text-accent);
        }
    }

    &.complete {
        .label-m {
            background-color: var(--color-icon-success);
            color: var(--color-white);
        }
    }

    &.error {
        background-color: var(--color-background-error);
        -webkit-box-shadow: inset 0 0 0 1px var(--color-text-error);
        -moz-box-shadow: inset 0 0 0 1px var(--color-text-error);
        box-shadow: inset 0 0 0 1px var(--color-text-error);
        .title-m,
        .bodycopy-s {
            color: var(--color-text);
        }

        .label-m {
            background-color: var(--color-text-error);
            color: var(--color-white);
        }
    }

    &.active.error {

        span.triangle {
            position: absolute;
            bottom: -23px;
            left: 1px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 24px 23px 0 0;
            border-color: var(--color-background-error) transparent transparent transparent;
            transform: rotate(0deg);
            z-index: 2;
        }

        span.border-triangle {
            z-index: 1;
            bottom: -24px;
            --b: 1px;
            width: 24px;
            height: 24px;
            aspect-ratio: 1;
            clip-path: polygon(0 0, 100% 0, 0 100%, 0 0, var(--b) var(--b), var(--b) calc(100% - var(--b)/tan(22.5deg)), calc(100% - var(--b)/tan(22.5deg)) var(--b), var(--b) var(--b));
            background:  var(--color-text-error);
            position: absolute;
            left:0;
        }
    }
}