.field-top {
    display: flex;
    margin-bottom: 4px;
    height: 36px;
    align-items: flex-end;
    justify-content: space-between;
    line-height: 2px;

    &.fieldset {
        margin-bottom: 17px;
        justify-content: flex-start;
        gap:10px;
    }

    &__wrap {
        display: flex;
        margin-bottom: 3px;
        flex-direction: row-reverse;
    }

    .tooltip {
        position: relative;
        top: -12px;
        padding-right: 12px;
        right: 0;
        line-height: inherit;

        .icon {
            fill: var(--color-primary-brand);
        }

        .msg-tooltip {
            .arrow {
                top: -25px;
            }
        }
    }
    a.link + .tooltip {
        top: 1px;
        padding-right: 20px;
    }

    .link_inline {
        color: var(--color-primary-brand);
    }

    &__trigger {
        margin-left: 8px;
        transition: all 0.3s;

        &:hover,
        &:focus {
            opacity: 0.6;
        }
    }

    .label-m {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    /* PARA CAMPOS CON LABEL CONDICIONADO POR OTROS CAMPOS, COMO EN EL APPLICATION FORM (CA)*/

    &.form-list__tooltip-label_option_2 {
        display: none;
    }

    /* FIN PARA CAMPOS CON LABEL CONDICIONADO POR OTROS CAMPOS, COMO EN EL APPLICATION FORM (CA)*/
}