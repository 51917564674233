.tile-testimonial {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (--viewport-ms-only) {
        width: 523px;
    }
    
    @media (--viewport-sm-only) {
        width: 700px;
    }

    @media (--viewport-xs-max) {
        width: auto;
    }

    &__box {
        position: relative;
        flex-grow: 1;
        min-height: 289px;
        padding: 40px 20px 94px;
        text-align: center;
        background-color: var(--color-accent);

        @media (--viewport-ms-min) {
            min-height: 315px;
            padding: 60px 80px 106px;
        }

        /*@media (--viewport-sm-min) {
            min-height: 275px;
            padding: 40px 40px 86px;
        }*/

        @media (--viewport-md-min) {
            min-height: 355px;
            padding: 80px 40px 126px;
        }
        @media (--viewport-xs-max) {
            padding-top:40px;
            padding-bottom: 40px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__quote-mark {
        position: relative;
        width: 23px;
        height: 17px;
        margin: 0 auto 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        span {
            position: absolute;
            top: 0;
            left: -2px;
            font-size: 70px;
            line-height: 1;
            color: var(--color-text-light);
        }
    }

    &__bodycopy {
        position: relative;
        z-index: 1;
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }
    }

    &__author {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__name {
        width: 50%;
        text-align: right;
    }

    &__organization {
        position: relative;
        width: 50%;
        padding-left: 11px;
        margin-left: 10px;
        text-align: left;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            width: 1px;
            height: 10px;
            background-color: var(--color-text-light);
            content: '';
        }
    }

    &__nav {
        position: absolute;
        bottom: 40px;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 20px;

        @media (--viewport-ms-min) {
            bottom: 60px;
            justify-content: center;
        }

       /* @media (--viewport-sm-min) {
            bottom: 40px;
        } */

        @media (--viewport-md-min) {
            bottom: 80px;
        }
    }

    &__line {
        position: relative;
    }
}
