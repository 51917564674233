.wysiwyg-block {
    .img-col{
        @media (--viewport-xs-max) {
            margin-bottom: 64px;
        } 
    }
    a {
        color: var(--color-accent-dark); text-decoration:underline;
    }
    &__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 40px 0 20px;

            @media (--viewport-ms-only) {
                margin-top: 60px;
            }

            @media (--viewport-md-min) {
                margin-top: 80px;
            }
        }
    }

    &__subtitle {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 40px 0 20px;

            @media (--viewport-ms-only) {
                margin-top: 60px;
            }
        }
    }

    &__is-two-columns {
        > .col {
            .bodycopy-m,
            .bodycopy-s {
                @media (--viewport-ms-min) {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @media (--viewport-sm-min) {
                    margin-top: 60px;
                    margin-bottom: 60px;
                }
            }

            figure {
                @media (--viewport-ms-min) {
                    margin: 40px 0;
                }

                @media (--viewport-sm-min) {
                    margin: 60px 0;
                }
            }
        }
    }

    &__is-two-columns + &__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 40px 0 20px;

            @media (--viewport-ms-only) {
                margin-top: 60px;
            }

            @media (--viewport-md-min) {
                margin-top: 20px;
            }
        }
    }

    &__title + &__is-two-columns {
        > .col {
            .bodycopy-m,
            .bodycopy-s {
                @media (--viewport-ms-min) {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @media (--viewport-sm-min) {
                    margin-top: 0;
                    margin-bottom: 60px;
                }
            }

            figure {
                @media (--viewport-ms-min) {
                    margin: 0 0 40px;
                }

                @media (--viewport-sm-min) {
                    margin: 0 0 60px;
                }
            }
        }
    }
    p {
    font-size: 16px;
    line-height: 26px;
    /*fuente e interlineado para forzar un bodycopy-m CR*/
    }
    p,
    .bodycopy-m,
    .bodycopy-s {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: var(--color-accent-dark); text-decoration:underline;
        }
        
    }

    ul {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        > li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 20px;

            @media (--viewport-ms-min) {
                padding-left: 50px;
            }

            &::before {
                position: absolute;
                top: 11px;
                left: 0;
                display: block;
                width: 15px;
                height: 1px;
                background-color: var(--color-accent);
                content: '';

                @media (--viewport-ms-min) {
                    left: 20px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            > ul {
                margin-top: 20px;
                margin-bottom: 0;

                > li {
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 10px;

                    &::before {
                        position: absolute;
                        top: 10px;
                        left: 0;
                        display: block;
                        width: 10px;
                        height: 1px;
                        background-color: var(--color-accent);
                        content: '';
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    ol {
        margin-bottom: 20px;
        counter-reset: custom-counter;

        li {
            position: relative;
            padding-left: 21px;
            margin-bottom: 15px;
            counter-increment: custom-counter;

            &::before {
                position: absolute;
                top: 7px;
                left: 0;
                font-family: 'Jornada Sans Book';
                font-size: 10px;
                font-weight: normal;
                line-height: 1;
                letter-spacing: 0;
                content: counter(custom-counter) ' /';
            }

            @media (--viewport-ms-min) {
                padding-left: 28px;
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    figure {
        margin: 40px -19px 40px -20px;

        @media (--viewport-ms-min) {
            margin: 40px 0;
        }

        @media (--viewport-sm-min) {
            margin: 60px 0;
        }

        @media (--viewport-sm-min) {
            margin: 80px 0;
        }

        &:first-child {
            @media (--viewport-sm-min) {
                margin: 50px 0 80px;
            }
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }

        figcaption {
            margin-top: 15px;
            font-family: 'Jornada Sans Book';
            font-size: 16px;
            font-weight: normal;
            line-height: 26px;
            letter-spacing: 0;
            color: var(--color-text);

            @media (--viewport-xs-max) {
                padding: 0 19px 0 20px;
            }

            @media (--viewport-sm-min) {
                margin-top: 30px;
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &__common-list {
        margin-top: 20px;
        margin-bottom: 20px;

        ul {
            > li {
                position: relative;
                padding-left: 20px;

                @media (--viewport-ms-min) {
                    padding-left: 30px;
                }

                &::before {
                    position: absolute;
                    top: 11px;
                    left: 0;
                    display: block;
                    width: 15px;
                    height: 1px;
                    background-color: var(--color-accent);
                    content: '';
                }

                &:last-child {
                    margin-bottom: 0;
                }

                > ul {
                    margin-top: 20px;
                    margin-bottom: 0;

                    > li {
                        position: relative;
                        padding-left: 20px;
                        margin-bottom: 10px;

                        &::before {
                            position: absolute;
                            top: 10px;
                            left: 0;
                            display: block;
                            width: 10px;
                            height: 1px;
                            background-color: var(--color-accent);
                            content: '';
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        ol {
            counter-reset: custom-counter;

            li {
                position: relative;
                padding-left: 21px;
                margin-bottom: 15px;
                counter-increment: custom-counter;

                &::before {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    font-family: 'Jornada Sans Book';
                    font-size: 10px;
                    font-weight: normal;
                    line-height: 1;
                    letter-spacing: 0;
                    content: counter(custom-counter) ' /';
                }

                @media (--viewport-ms-min) {
                    padding-left: 28px;
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__featured-text {
        margin-top: 40px;
        margin-bottom: 40px;

        @media (--viewport-ms-min) {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        &-wrapper {
            font-size: 40px;
            line-height: 52px;
            padding-top: 30px;
            padding-bottom: 30px;
            border-top: 1px solid var(--color-secondary-light);
            border-bottom: 1px solid var(--color-secondary-light);

            @media (--viewport-ms-min) {
                font-size: 44px;
                line-height: 56px;
                padding-top: 61px;
                padding-bottom: 61px;
            }
        }
    }

    &__featured-text + &__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0 0 20px;
        }
    }

    &__links {
        margin-top: 40px;
        margin-bottom: 40px;

        .link {
            display: inline-block;
        }

        .link + .link {
            margin-top: 20px;
        }
    }

    h2 {
        color: var(--color-accent-dark);
        margin-top: 40px;
        margin-bottom: 20px;
        @media (--viewport-xs-min) {
            font-size: 26px;
        }
        @media (--viewport-ms-min) {
            font-size: 32px;
        }
        @media (--viewport-sm-min) {
            font-size: 22px;
        }
        @media (--viewport-md-min) {
            font-size: 32px;
        }
        @media (--viewport-lg-min) {
            font-size: 30px;
        }
        @media (--viewport-xl-min) {
            font-size: 30px;
        }
    }
    h3 {
        color: var(--color-accent-dark);
        margin-top: 20px;
        margin-bottom: 15px;
        @media (--viewport-xs-max) {
            font-size: 18px;
        }
        @media (--viewport-ms-min) {
            font-size: 24px;
        }
        @media (--viewport-sm-min) {
            font-size: 18px;
        }
        @media (--viewport-md-min) {
            font-size: 26px;
        }
        @media (--viewport-lg-min) {
            font-size: 20px;
        }
        @media (--viewport-xl-min) {
            font-size: 20px;
        }
    }
}
