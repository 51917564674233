.nav-arrow {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 40px;
        height: 40px;
        content: '';
    }

    .icon {
        display: block;
        width: 24px;
        height: 24px;
        fill: var(--color-accent);

        @media (--viewport-ms-min) {
            width: 16px;
            height: 16px;
        }
    }

    &_small {
        .icon {
            width: 16px;
            height: 16px;
        }
    }

    &_light {
        .icon {
            fill: var(--color-text-light);
        }
    }
}
