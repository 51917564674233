.process-states-wrap {
    .process-states{
        overflow-x:scroll;
        scrollbar-width: none;
        ul {
            width: 1098px;
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 30px;

            li {
                width: 249px;
                display: flex;
                height: 100%;
            }
        }
    }
    
    .pagination{
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: solid 1px var(--color-border-disable);
        @media (--viewport-md-min) {
            display:none;
        }
        .pagination__list{
            display: flex;
            justify-content: center;
            width: auto;
            gap: 30px;
            li{padding:0;
                width: auto;margin: 0;
                .pagination__link:before, .pagination__link:after{display: none;}
            }
        }
    }
}