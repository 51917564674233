.line {
    width: 100%;
    height: 50px;
    background-color: var(--color-accent-dark);

    &.line-full-width { /* esta clase no aparece en el hbs */
        @media (--viewport-xs-only) {
            margin-left: -20px;
            width: calc(100% + 39px);
        }
    }
}
