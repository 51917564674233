.number-m {
    font-family: 'Jornada News Book';
    font-size: 26px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0;
    color: var(--color-text);

    @media (--viewport-sm-min) {
        font-size: 30px;
    }

    @media (--viewport-md-min) {
        font-size: 40px;
    }

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}
