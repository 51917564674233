.prequalifier-form-main{
    .prequalifier-form {
        .col-steps{padding-right:0; padding-left:0;margin-right:-25px;
            @media (--viewport-md-max) {
                margin-right:-17px;
            }
            @media (--viewport-xs-max) {
                margin-right:0;
            }
        }
        .form-btn{text-align: center;}
        
        .checkbox-wrapper{margin-top:30px;}
        .recaptcha{display: flex;justify-content: center; }
        .radio-label .icon {width:35px;height:35px; margin-right: 20px;}
        #titular1, #titular2{
            /*margin-top: 45px;*/
            .tooltip-label{margin: 50px 0 -10px;}
            .form-list{
                .tooltip-label{margin: 0 0 10px;}
            }
        }
        ul.bots{display:flex; flex-direction: row; justify-content: center; width: 100%; margin-top: 40px;
            li{ margin: 0 15px;
                .form-btn:hover{
                    cursor: pointer;
                }
            }
        }
        .legal{padding-top: 30px;
            .bodycopy-xs p{padding-top:0;
                &:first-child{}
                /*&:nth-of-type(1){padding-top: 0;}*/
            }
        }
        .recaptcha{margin-top: 20px;}
    }
    #step1, #step2, #step3{
        .prequalifier-form-list{
            margin-bottom: -40px;
            @media (--viewport-xs-max) {
                margin-bottom: -30px;
            }
        }
    }
    
    .inactive{display: none;}
    .active{display:block;}
}


