.breadcrumbs {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: flex;

        &::after {
            display: block;
            padding: 0 5px;
            font-family: 'Jornada Sans Medium';
            text-transform: uppercase;
            font-size: 10px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 1.2px;
            color: var(--color-accent);
            content: "/";

            @media (--viewport-lg-min) {
                font-size: 12px;
                /*line-height: 22px; Se desalinea con el volver en el ancho completo header (CA)*/
            }
        }

        &:last-child {
            &::after {
                content: none;
            }
            .inactive{ /*la ellipsis se aprecia dentro del componente ancho completo header (CA)*/
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .text-label-m {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    &__link {
        display: block;
        .text-label-m{
            color: var(--color-accent);
            &.text-label-m_color_light{
                color: var(--color-white);
            }
        }


        &:hover,
        &:focus {
            .breadcrumbs__label {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                span,
                div {
                    color: var(--color-accent-dark);
                }
            }
        }
    }

    &__label {
        &.inactive {
            display: block;
            .text-label-m{
                color: var(--color-accent-dark);
            }
        }

        span {
            transition: all 0.3s;
        }
    }

    &_light {
        .breadcrumbs {
            &__item {
                &::after {
                    color: var(--color-text-light);
                }
            }

            &__link {
                &:hover,
                &:focus {
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    span,
                    div {
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }
        }
    }
}
