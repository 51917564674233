.upload-other-documents {
    width: 40%;
    @media (--viewport-xs-max) {
        width: 100%;
    }
    .wrap{
        display: flex;
        align-items: flex-end;
        gap: 30px;
        .form-select{
            margin-bottom: 0;
            flex-grow: 1;
        }
    }
}