.form-switch {
    margin-bottom: 20px;
    &.error {
        .field-bottom {
            &__error-message {
                display:block;
                position: relative;
            }
            &__helper-text{display:none;}
        }
    }
}