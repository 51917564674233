.mortgage-steps {
    /*display: flex;
    flex-direction: column;*/

    /* ACCENT LIGHT THEME */ 
    &_accentLight {
        background-color: var(--color-accent-light);
        .slider-highlight {
            background-color: var(--color-accent-light);
            .swiper-slide {
                background-color: var(--color-accent-light);
            }
        }
    }

    &__title {
        text-align: center;
        padding-bottom: 75px;

        @media(--viewport-ms-only) {
            padding-bottom: 45px;
        }

        @media(--viewport-xs-max) {
            padding-bottom: 25px;
        }

        .title-l {
            color: var(--color-text);
        }

    }

    .swiper-container{
        @media (--viewport-ms-max) {
            padding-bottom:40px;
        }
    }
    .swiper-slide{
        /*background-color:var(--color-accent-light);*/
        height: auto;
        @media (--viewport-ms-max) {
            align-items: flex-start;
        }
        @media (--viewport-xs-max) {
            align-items: center;
        }
    }
    .swiper-navigation{
        display: none;
        /*max-width: 267px;*/
        margin-left: auto;
        margin-right: auto;
        padding: 20px 0;
        justify-content: space-between;
        align-items: center;
        @media (--viewport-ms-only) {
            justify-content: center;
            gap: 0 40px;
        }
        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;

            &::after {
                display: none;
            }

            .icon {
                height: 20px;
                width: 28px;
                fill:var(--color-accent)
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 0;
            width: 28px;
            height: 20px;
            margin-top: 0;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }

        @media (--viewport-ms-max) {
            display:flex;
        }
    }

    /*&__items {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        
        @media(--viewport-sm-min) {
            justify-content: space-evenly;
        }

        &-item {
            width: fit-content;
            padding-right: 30px;

            @media(--viewport-sm-only) {
                padding-right: 15px;
            }

            &:last-child {
                padding-right: 0px;
            }
        }

    }*/



}

/* SLIDER-HIGHLIGHT REFERENCES FOR 'MORTGAGE-STEPS__SLIDER' ITEM */

/*.mortgage-steps .swiper-slide {
    display:flex;
    justify-content:center;
    margin: 0;
}*/

