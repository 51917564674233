.landing-form-list {
    /*li{
        &:first-child .form-list__field,
        &:nth-child(2) .form-list__field{
                @media (--viewport-ms-min) {margin-top: 0;}
                @media (--viewport-sm-min) {margin-top: 0;}
        }
        &:nth-child(3) .form-list__field {
                @media (--viewport-sm-min) {margin-top: 0;}
        }
        .form-list__field{
            position: relative;
        }
    }*/
    li{
        &.col-sm-4, &.col-md-4, &.col-lg-4{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field{
                    @media (--viewport-ms-min) {margin-top: 0;}
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
            &:nth-child(3) .form-list__field {
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
        }
        &.col-sm-6, &.col-md-6, &.col-lg-6{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field{
                    @media (--viewport-ms-min) {margin-top: 0;}
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
        }
        &.onecolumn{
        }
        .form-list__field{
            position: relative;
        }
        
    }
    .form-fieldset .form-fieldset__valid-indicator{ /* esto hay que dejarlo para que funcionen las validaciones de sitecore forms */
        opacity:0;
    }
    .form-fieldset input.valid + .form-fieldset__valid-indicator{ /* esto hay que dejarlo para que funcionen las validaciones de sitecore forms */
        opacity:1;
    }
    
    .form-list__error-message { /* esto hay que dejarlo para que funcionen las validaciones de sitecore forms */
        opacity: 1;
    }
}