.error-message {
    font-family: 'Jornada Sans Book';
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0;
    color: var(--color-danger);

    &-wrapper {
        display: flex;
        margin-top: 22px;

        &_accent {
            .bodycopy-s {
                color: var(--color-text-light);
            }

            .icon {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                margin: 2px 6px 0 0;
                fill: var(--color-text-light);
            }
        }
    }
}
