.glossary {
    &__desc {
        margin-bottom: 40px;

        @media (--viewport-ms-min) {
            margin-bottom: 60px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 20px;
        }
    }

    &__search {
        margin-bottom: 40px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 20px;
        }
    }

    &__content {
        padding: 30px 0 20px;

        @media (--viewport-ms-min) {
            padding: 30px 0 40px;
        }

        @media (--viewport-sm-min) {
            padding: 30px 0 0;
        }
    }

    &__extra {
        @media (--viewport-ms-max) {
            margin-bottom: 40px;
        }

        &-404 {
            display: none;
        }

        &-desc {
            margin-top: 20px;
        }

        &-link {
            margin-top: 30px;
        }
    }

    &-faq {
        @media (--viewport-xs-only) {
            margin: 0 -19px 0 -20px;
        }
    }
}
