.ekomi-rating {
    text-align: center;

    &__pic {
        max-width: 88px;
        margin: 0 auto 20px;
    }

    &__link {
        display: block;
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__number {
        margin-bottom: 10px;
    }
}