.header-fixed-submenu {

    display: flex;

    min-height: 75%;


    @media (--viewport-ms-only) {
        margin-top: 15px;
    }

    @media (--viewport-sm-min) {
        margin: 40px 40px 40px;
        justify-content: center;
    }

    &__blocks {

        width: fit-content;
        /*as in mock-up */

        /* DESKTOP STYLIZATION */
        @media (--viewport-sm-min) {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        @media (--viewport-ms-max) {
            width: 100%;
        }


        &-tiles {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            flex-grow: 1;

            min-height: 250px;
            overflow: auto;

            color: var(--color-secondary);

            @media (--viewport-ms-max) {
                flex-direction: column;
                min-height: fit-content;
            }

            @media (--viewport-xs-max) {
                margin-bottom: 20px;
                margin-right: 20px;
            }

            @media (--viewport-ms-only) {
                margin-bottom: 25px;
                margin-right: 20px;
            }

        }

        &-list {
            @media (--viewport-xs-max) {
                margin-top: 20px;
            }

            @media (--viewport-ms-only) {
                margin-top: 30px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: -30px;
            }
        }

        &-item {
            margin-bottom: 30px;

            @media (--viewport-ms-min) {
                margin-bottom: 40px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 30px;
            }
        }

        &-tile {
            padding-left: 10px;

            @media (--viewport-sm-min) {
                padding-right: 60px;
            }

            &-link {
                display: block;

                &:hover,
                &:focus {
                    .header-fixed-submenu__products-tile-title {
                        .bodycopy-m {
                            color: var(--color-accent);
                        }
                    }
                }
            }


            &-label {
                margin-bottom: 5px;
            }
        }
    }

    &__cardSection {
        display: none;

        /* ONLY DISPLAYED IN DESKTOP VIEWS */
        @media (--viewport-sm-min) {
            display: flex;
            justify-content: flex-start;
        }


        @media (--viewport-xs-max) {
            margin-bottom: 20px;
        }

        @media (--viewport-ms-only) {
            margin-bottom: 25px;
        }


    }


    &__links {
        @media (--viewport-xs-max) {
            margin-bottom: 10px;
        }

        &-list {
            @media (--viewport-sm-min) {
                margin-bottom: -30px;
            }
        }

        &-item {
            margin-bottom: 20px;

            @media (--viewport-ms-min) {
                margin-bottom: 40px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 30px;
            }
        }

        &-title {
            display: block;

            .title-m {
                font-size: 16px;
                /*antes sin tamaño de fuente definido, ni la clase*/
            }

            &:hover,
            &:focus {
                .title-m {
                    color: var(--color-accent);
                }
            }
        }

        &-subitem {
            margin-top: 10px;

            @media (--viewport-sm-min) {
                &:first-child {
                    margin-top: 20px;
                }
            }
        }

        &-link {
            .link {
                display: block;
                color: var(--color-accent);

                &:hover,
                &:focus {
                    color: var(--color-text);
                }
            }
        }

        &-text {
            margin-top: 10px;
        }
    }
}
