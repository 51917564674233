.field-bottom {
    &__helper-text{padding-top: 6px;margin-top: 3px;
        .bodycopy-xs{color: var(--color-grey2);}
    }
    &__error-message {margin-top: 3px;
        display: none;
        position: relative;
    }
    &.error{
        .field-bottom__helper-text{display: none;}
        .field-bottom__error-message {display: block;} 
    }
}