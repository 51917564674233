.banner {
    position: relative;
    &__wrapper {
        position:relative;
        display:flex;
        flex-flow: nowrap;
        align-items: center;        
        @media (--viewport-xs-min) {
            flex-flow: column;
            margin: 0 -20px;
        }
        @media (--viewport-ms-min) {
            flex-flow: column;
            margin: 0;
        }
        @media (--viewport-sm-min) {
            flex-flow: nowrap;
            margin: 0;
        }
        @media (--viewport-md-min) {
            flex-flow: nowrap;
            margin: 0
        }
        @media (--viewport-lg-min) {
            flex-flow: nowrap;
            margin: 0
        }
        &.overhead {
            height: 100%;
            @media (--viewport-xs-min) {
                margin-top: 0;
                align-items: flex-start;
                padding-bottom: 104px;

            }
            @media (--viewport-ms-min) {
                margin-top: 0;
                align-items: flex-start;
                padding-bottom: 104px;
            }
    
            @media (--viewport-sm-min) {
                align-items: stretch;
                padding-bottom: 0;
            }
    
            @media (--viewport-md-min) {
                align-items: stretch;
                padding-bottom: 0;
            }
            @media (--viewport-lg-min) {
                align-items: stretch;
                padding-bottom: 0;
            }
        }
    }
    &_reversed {
        .banner {
            &__wrapper {
                flex-flow: row-reverse;
                @media (--viewport-ms-max) {
                    flex-flow: column;
                }
            }
            &__content {
                @media (--viewport-ms-only) {
                    padding: 11px 11px 11px 30px;
                }
                &.overhead {
                    
                    @media (--viewport-xs-min) {
                        margin-left: 16.66666667%;
                    }
                    @media (--viewport-sm-min) {
                        margin-right: -16.66666667%;
                    }
                }
            }
        }
    }
    &__support {
        position: relative;
        width:100%;
        height: 100%;
        overflow: hidden; /* para evitar espacio bajo la imagen en reversed */
        line-height: 0;
        padding:0;
        background-repeat: no-repeat;
        background-position: center;
        @media (--viewport-xs-min) {
            max-height:256px;
            min-height:256px;
            background-size: contain;
            background-position: center;
        }
        @media (--viewport-ms-min) {
            max-height:256px;
            min-height:256px;
            background-size: contain;
            background-position: center;
        }

        @media (--viewport-sm-min) {
            max-height: 100%;
            min-height:256px;
            background-size: contain;
            background-position: center;

        }
        &.overhead {
            background-position: left;
            background-size: cover;
        }
        img {
            display: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (--viewport-ms-max) {
                display: block;
            }

            &.isContain {
                height: auto;
                object-fit: contain;
            }
        }     

        &-label-wrapper {
            position: relative;

            .row {
                padding: 0;
                position: relative;
            }
        }

        &-text {
            position: relative;
        }
    }
    &__content {
        width: 100%;
        /* height: 346px;   */
        position:relative;
        margin-top: 0;
        @media (--viewport-xs-min) {
            padding: 56px 40px;
        }
        @media (--viewport-ms-min) {
            padding: 64px 64px;
        }
        @media (--viewport-sm-min) {
            padding: 64px;
        }
        @media (--viewport-md-min) {
            padding: 64px;
        }
        &.overhead {
            @media (--viewport-xs-min) {
                margin-left: 0;
                height: auto;  
                margin-top: -104px;
                padding: 56px 40px 0 40px;
            }
            @media (--viewport-ms-min) {
                margin-left: 0;
                height: auto;  
                margin-top: -104px;
                padding: 56px 40px 0 40px;
            }
    
            @media (--viewport-sm-min) {
                margin-left: -16.66666667%;
                height: auto;  
                margin-top: 104px;
                padding: 56px 40px;
            }
    
            @media (--viewport-md-min) {
                margin-left: -16.66666667%;
                height: auto; 
                margin-top: 104px;
            }
        }  
    }
    &__bodycopy {
        margin-top: 24px;
    }
    &__toolbar {
        display: flex;
        gap:32px;
    }
    &__btn,
    &__link {
        margin-top: 24px;
    }
    .row.mobileReverse {
        @media (--viewport-xs-max) {
            flex-direction: column-reverse;
        }
    }
    .bg-color {
        &_bridesmade {
            background-color: var(--color-background-accent);
        }
        &_torch-red {
            background-color: var(--color-background-accent-bold);
            .banner__title {
                .title-l {
                    color:var(--color-text-inverse);
                }
            }
            .banner__bodycopy {
                .bodycopy-m {
                    color:var(--color-text-inverse);
                }
            }
            .link {
                Color: var(--color-text-link-primary-inverse);
            }
        }
        &_eggplant {
            background-color: var(--color-background-accent-bolder);
            .banner__title {
                .title-l {
                    color:var(--color-text-inverse);
                }
            }
            .banner__bodycopy {
                .bodycopy-m {
                    color:var(--color-text-inverse);
                }
            }
            .link {
                Color: var(--color-text-link-primary-inverse);
            }
        }
    }
}