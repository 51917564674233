.data-query-form {
    form{width: 100%;}
    .wrap-bloque {
        margin-bottom: 48px;
        @media (--viewport-xs-max) {
            margin-bottom: 20px;
        }
        .tit-bloque {
            margin-bottom: 16px;
            .col {
                flex-direction: row;
                justify-content: space-between;
            }
            .link_has-icon .icon{fill:var(--color-secondary);top:auto;margin-left:5px; margin-top: 2px;}
        }
    }
    .alert{margin-top: 12px;
        @media (--viewport-xs-max) {
            margin-top:0;
        }
    }
}