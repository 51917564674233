.testimonials {
    &__content {
        @media (--viewport-xs-max) {
            margin-bottom: 40px;
        }

        @media (--viewport-sm-max) {
            margin-bottom: 30px;
            text-align: center;
        }

        @media (--viewport-sm-min) {
            flex-shrink: 0;
            text-align: left;
        }
    }

    &__bodycopy {
        margin-top: 20px;
    }

    &__link {
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }

    &__swiper {

        @media (--viewport-sm-max) {
            width: calc(100% + 62px);
            margin-left: -30px;
        }

        @media (--viewport-xs-max) {
            width: calc(100% + 39px);
            margin-left: -20px;
        }

        .swiper {
            &-slide {
                width: 100%;
                height: auto;
            }
        }

        &-background {
            position: relative;
            z-index: 0;
            width: 100%;
            width: calc(100% + 39px);
            margin-left: -20px;

            @media (--viewport-sm-min) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    &__background {
        width: 100%;
        height: 220px;
        background: center / cover no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (--viewport-sm-min) {
            height: 360px;
        }

        @media (--viewport-md-min) {
            height: 605px;
        }

        /*@media (--viewport-sm-min) {
            height: auto;
        }*/

        @media (--viewport-sm-max) {
            height: 500px;
        }
        @media (--viewport-ms-max) {
            height: 350px;
        }
        @media (--viewport-xs-max) {
            height: auto;
            max-height: 250px;
        }
        
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .col {
        &-text,
        &-swiper,
        &-background {
            @media (--viewport-md-min) {
                display: flex;
                justify-content: center;
            }
        }

        &-swiper{
            @media (--viewport-sm-max) {
                margin-top: -50px;order:3;
            }
            @media (--viewport-sm-only) {
                margin-top: -100px;
            }
            @media (--viewport-ms-only) {
                margin-top: -100px;
            }
        }

        &-background {
            @media (--viewport-sm-max) {
                margin-top: 0;
            }

            /*@media (--viewport-xs-max) {
                margin-top: -578px;
            }*/

            /*@media (--viewport-sm-only) {
                margin-left: -33.33333333%;
            }*/

            @media (--viewport-md-min) {
                margin-left: -25%;
            }
        }
    }
}
