.alert {
    width: 100%;

    &__wrap {
        position: relative;
        padding: 32px;
        display: flex;
        justify-content: space-between;
        line-height: 0;
        @media (--viewport-xs-max) {
            flex-direction: column;
        }

        &.not-alert {
            background-color: var(--color-alert);
        }

        &.not-error {
            background-color: var(--color-error);
        }

        &.not-informative {
            background-color: var(--color-informative);
        }

        &.not-success {
            background-color: var(--color-success);
        }
    }

    &.banner {
        position: relative;
        z-index: 12;
        background-color: white;
        
        .alert__wrap {
            padding: 16px 32px;
            background-color: var(--color-informative);

            a {
                color: var(--color-accent);
            }

            @media (--viewport-xs-max) {
                padding: 32px;
            }
        }
    }



    &.toast {
        min-height: 86px;
        position: fixed;
        bottom: 40px;
        z-index: 9999;
        max-width: 1314px;
        left: auto;
        @media screen and (max-width: 1314px) {
            left: 0;
        }
        @media (--viewport-xs-max) {
            box-shadow:none;
            bottom: 0;
        }

        .alert__wrap {
            box-shadow: 0px 0px 40px 0px rgba(25, 25, 25, 0.05);
            @media (--viewport-xs-max) {
                box-shadow:none;
            }

            &.not-alert {
                border-top: solid 8px var(--color-alert-dark);
                padding-top: 24px;
            }

            &.not-error {
                border-top: solid 8px var(--color-error-dark);
                padding-top: 24px;
            }

            &.not-informative {
                border-top: solid 8px var(--color-informative-dark);
                padding-top: 24px;
            }

            &.not-success {
                border-top: solid 8px var(--color-success-dark);
                padding-top: 24px;
            }
        }
    }

    &.inline {
        .alert__wrap {

            min-height: 40px;
            margin: 10px 0;
        }
    }

    .icon {
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-right: 16px;
    }

    &__close {
        .icon {
            margin-right: 0;
        }

        @media (--viewport-xs-max) {
            position: absolute;
            right:32px;
        }
    }

    &__content {
        flex-grow: 2;

        .title-s+.bodycopy-s {
            margin-top: 16px;
        }

        .bodycopy-s{
            color: var(--new-color-text);
        }

        @media (--viewport-xs-max) {
            margin-top: 24px;
        }
    }

    &__links {
        display: flex;
        gap: 24px;
        margin-top: 24px;
        align-items: center;
        @media (--viewport-xs-max) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}