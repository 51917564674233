.ucipt-responsive-vimeo-video {
    width: 100%;
    height: /*527px*/524px;
    left: 0;
    top: 0;
    overflow: hidden;

    @media (--viewport-ms-min) {
        .vimeo-div{padding-top:47.9%;}
    }
    @media (--viewport-ms-max) {
        .vimeo-div{display:none;}
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .vimeo-bg-image{
        display:none;
        @media (--viewport-ms-max) {
            display:block;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}