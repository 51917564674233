.search-input {
    position: relative;

    .input {
        padding-right: 40px;

        @media (--viewport-ms-min) {
            padding-right: 50px;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        display: flex;

        @media (--viewport-ms-min) {
            right: 30px;
        }
    }
}
