.input {
    width: 100%;
    padding: 17.5px 15px;
    outline: none;
    border: 1px solid var(--color-grey3);
    font-family: 'Jornada Sans Book';
    font-size: 14px;
    line-height: 24px;
    text-transform: none;
    letter-spacing: 0;
    color: var(--color-text);
    background-color: transparent;
    transition: border-color 0.3s;
    height: 50px;
    display: flex;
    align-items: center;

    &.input-validation-error{
        border-color: var(--color-danger);
    }

    &:disabled, &.disabled{
        background-color: var(--color-grey-light);
        border-color: var(--color-quinary);
        color: var(--color-grey2);
    }

    &::placeholder {
        opacity: 1;
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 24px;
        color: var(--color-grey2);
    }

    &:hover,
    &:focus {
        border-color: var(--color-secondary);
        color: var(--color-secondary);
    }

    &_submit {
        padding-right: 40px;
    }

    &_regular {
        padding: 17.5px 20px;
    }

    &_light {
        color: var(--color-text-light);

        &::placeholder {
            color: var(--color-text-light);
        }
    }

    &-wrapper {
        position: relative;
    }

    &-submit {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        transition: all 0.3s;

        &:hover,
        &:focus {
            right: 10px;
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 40px;
            height: 40px;
            content: '';
        }

        &_light {
            .icon {
                fill: var(--color-text-light);
            }
        }

        .icon {
            transform: rotate(180deg);
        }
    }
}

