.tile-footer-detailed {
    &__title {
        line-height: 28px;
        letter-spacing: 0;
        color: var(--color-text);
        margin-bottom: 14px;

        .bodycopy-l {
            color: var(--color-text);
            font-weight:800;
        }

        .link{
            font-weight:800;
        }

    }

    &__list {
        display: flex;
        flex-flow: column;
    }

    &__item {
        margin-bottom: 7px;
    }

    &__link {
        .link {
            color: var(--color-secondary);

            &:hover,
            &:focus {
                color: var(--color-accent);

                span {
                    &::after {
                        background-color: var(--color-text);
                    }
                }

                .icon {
                    fill: var(--color-secondary);
                }
            }
        }
    }


}