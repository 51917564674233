.go-top {

    display: none;
    float: right;
    width: 40px;

    &.active{
        display: block;
    }

    &__inner {
        position: fixed;
        bottom: 10px;
        width: 40px;
        height: 40px;
        line-height: 45px;
        text-align: center;
        text-decoration: none;
        font-size: 35px;
        background-color: var(--color-accent-light);
        font-weight: 100;
        z-index: 10;
        background-image: url(/dist/images/arrow-uci.png);
        background-repeat: no-repeat;
        background-size: 24px;
        background-position-x: 50%;
        background-position-y: 50%;
        right:calc(50%-20px);
        &.hipotecas{
            background-image: url(/dist/images/arrow.png);
        }
    }
}
