.flap {
    width: 100%;
    background-color: var(--color-background-accent);
    padding: 40px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 120px;

    @media (--viewport-sm-max) {
        padding: 40px;
        flex-direction: column;
        gap: 56px;
        text-align: center;
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
        @media (--viewport-sm-max) {
            flex-direction: column;
        }

        .icon {
            width: 80px;
            height: 80px;
            flex-shrink: 0;
        }

        ul.text {
            li {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: var(--color-text-link-secondary);
                }
            }
        }
    }

    ul.actions {
        display: flex;
        gap: 32px;
        white-space: nowrap;
        align-items: center;
        @media (--viewport-sm-max) {
            flex-direction: column;
        }
    }
}