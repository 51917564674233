.layout-example {
    .container{
        background-color: var(--color-accent-light);
        .row{margin-bottom: 40px;
            &.no-margin{margin-bottom: 0;}
            .col{
                background-color: var(--color-accent-dark);
                p{
                    background-color: var(--color-accent);text-align: center; color:#fff;padding:20px 0;
                }
            }
        }
    }
    .leyenda{ text-align: center; margin: 50px 0 30px;
        span{padding:5px 10px; margin: 0 10px 0 5px;
            &.c-container{background-color: var(--color-accent-light);}
            &.c-col{background-color: var(--color-accent-dark);}
            &.c-cont{background-color: var(--color-accent);}
        }
    }
}