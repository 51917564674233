
.tab-bar {
    display: none;

    @media only screen and (max-width: 1023px) {
        display: flex;
        position: fixed;
        top: auto;
        bottom: 0;
        height: 60px;
        width: 100%;
        max-width: 100%;
        padding: 0;
        z-index: 5;
        box-shadow: 0px -5px 10px 0px rgba(25,25,25, .05);
        border-right: 0 none transparent;
        background-color: var(--color-white);

        &__content {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
            overflow-y: overlay;
            height: 100%;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar:vertical {
                width: 5px;
            }

            &::-webkit-scrollbar-button:increment, ::-webkit-scrollbar-button {
                display: none;
            }

            &::-webkit-scrollbar:horizontal {
                height: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #f2f2f2;
                border-radius: 20px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
        }

        &__list {
            width: 100%;
            max-width: 100%;
            flex-flow: nowrap;
            display: flex;
            justify-content: space-around;
            height: 100%;
            column-gap: 10px;

            li {
                justify-content: center;
                flex-flow: nowrap;
                align-items: center;
                display: flex;
            }

            &-item {
                color: var(--color-grey2);
                justify-content: center;
                align-items: center;
                flex-flow: column;
                align-items: center;
                text-decoration: none;
                height: 100%;
                display: flex;
                row-gap: 4px;

                &__text {
                    color: var(--color-secondary);
                }

                .icons {
                    width: 16px;
                    height: 16px;

                    [class^="icon-"], [class*=" icon-"] {
                        fill: var(--color-grey2);
                    }
                }
                :not(.tab-bar__list-item--disabled)&__ripple {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: none;
                }
                &--activated {
                    color:var(--color-accent);
                    .tab-bar__list-item__text {
                        color: var(--color-accent) !important;
                    }
                    .icons {
                        [class^="icon-"], [class*=" icon-"] {
                            fill: var(--color-accent);
                        }
                    }
                }

                &:hover {
                    color: var(--color-accent);
                    .tab-bar__list-item__text {
                        color: var(--color-accent) !important;
                    }
                    .icons {
                        [class^="icon-"], [class*=" icon-"] {
                            fill: var(--color-accent);
                        }
                    }
                }

                &--disabled { /* COMENTO LOS CAMBIOS DE COLOR EN DISABLED YA QUE ES UNA OPCIÓN QUE NO FIGURA EN DS. CA*/
                    /*color: var(--color-grey3);*/
                    pointer-events: none;
                    /*opacity: .7;*/
                    &:hover {
                        /*color: var(--color-grey3);
                        .icons {
                            [class^="icon-"], [class*=" icon-"] {
                                fill: var(--color-grey3);
                            }
                        }*/
                    }
                }
            }
        }
       
    }
}

.drawer{

    &--modal {
        left: 0;
        right: initial;
        display: none;
        position: fixed;
        z-index:4;
        &.drawer--open {
        display: flex;
        }  
    }
    &__content {
        display: flex;
        gap: var(--gutter-md-vertical);
        justify-content: flex-start;
        flex-flow: column;
        align-items: flex-start;
        padding-top: var(--gutter-ms-vertical);
        padding-bottom: calc(var(--gutter-lg-vertical)*2);
        padding-left: var(--gutter-width);
        padding-right: var(--gutter-width);
        max-height: 100vh;
        height: 100%;
        overflow: hidden;
        overflow-y: overlay;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width:5px;
        }
        &::-webkit-scrollbar-button:increment,::-webkit-scrollbar-button {
            display: none;
        } 
        &::-webkit-scrollbar:horizontal {
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-gray-light);
            border-radius: 20px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;  
        }
        &-list  {
            gap: var(--gutter-md-vertical);
            .drawer__content-item__title {
                color: var(--color-secondary-brand);
            }
        }
        .subList  {
            gap: var(--half-gutter-ms-vertical);
        }
        &-list, .subList  {
            display: flex;
            justify-content: flex-start;
            flex-flow: column;
            align-items: flex-start;
            &-item, li {
                display: flex;
                gap: var(--half-gutter-ms-vertical);;
                justify-content: flex-start;
                flex-flow: column;
                align-items: flex-start;
                color:var(--color-text-primary);
            }
        }
    }
    &--modal + &-scrim {
        background-color: var(--color-white);
    }
    &-scrim {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition-property: opacity;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &--open + &-scrim {
        display: block;
    }
    &--animate + &-scrim {
        opacity: 0;
    }
    &--opening + &-scrim {
        transition-duration: 250ms;
        opacity: 1;
    }
    &--closing + &-scrim {
        transition-duration: 200ms;
        opacity: 0;
    }
}
