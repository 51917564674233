.product-button {
    min-width: 180px;
    text-align: center;

    &__link {
        display: block;

        &:hover,
        &:focus,
        &.active {
            .product-button__title {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                span,
                div {
                    color: var(--color-accent);
                }
            }
        }
    }

    &__pic {
        max-width: 80px;
        margin: 0 auto 15px;
        transition: all 0.3s;
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__label {
        margin-bottom: 10px;
    }

    &__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        div,
        span {
            transition: all 0.3s;
        }
    }
}
