.advantages-extra {
  padding: 40px 20px 0;
  margin-right: -19px;
  margin-left: -20px;

  &_bg-color {
    &_yellow {
      background-color: var(--color-accent-light);
    }
  }

  @media (--viewport-ms-min) {
    padding: 60px 30px 30px;
  }

  @media (--viewport-sm-min) {
    padding: 60px 50px 30px;
  }

  &__description {
    margin-bottom: 40px;
    text-align: center;

    @media (--viewport-ms-min) {
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 21px;

    @media (--viewport-ms-min) {
      margin-bottom: 30px;
    }

    @media (--viewport-sm-min) {
      margin-bottom: 24px;
    }
  }

    &__content {
      text-align: center;
      .row {
          @media (--viewport-sm-min) and (--viewport-md-max) {
              margin-right: -20px;
              margin-left: -20px;
          }
      }

      .col {
          margin-bottom: 40px;

          @media (--viewport-ms-min) {
              margin-bottom: 30px;
          }

          @media (--viewport-sm-min) {
              margin-bottom: 40px;
          }

          @media (--viewport-sm-min) and (--viewport-md-max) {
              padding-right: 20px;
              padding-left: 20px;
          }
      }

      .advantage {
          @media (--viewport-ms-min) {
              flex-direction: column;
              text-align: center;
          }

          &__icon {
              @media (--viewport-ms-min) {
                  margin-right: 0;
                  margin-bottom: 20px;
              }
          }
          &__bodycopy{
              @media (--viewport-sm-max) {
                  text-align: center;
              }
              @media (--viewport-md-min) {
                  margin-bottom:20px;
              }
          }
      }

  /* Swiper Variation*/

    .swiper-container{
      padding-bottom: 50px;
      @media (--viewport-ms-max) {
        padding-bottom:40px;
      }
    }
    .swiper-wrapper{
          flex-flow: row;
          justify-content: flex-start;
    }

    .swiper-slide{
      /*background-color:var(--color-accent-light);*/
      height: auto;
      //width: 80%;
      padding-left:10px;
      padding-right:10px;
      //max-width: 100%;
      margin-bottom: 0 !important;
      @media (--viewport-xs-max) {
        width: 80%;
        max-width: 100%;
        flex-basis: 50%;
      }
      @media (--viewport-ms-max) and (--viewport-ms-max) {
        width: 80%;
        max-width: 100%;
        flex-basis: 33%;
      }
    }

    .swiper-navigation{
      display: flex;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      padding: 20px;
      justify-content: center;
      align-items: center;
      gap:30px;
      .swiper-pagination {
        display:none;
      }

      .swiper-pagination,
      .swiper-button-prev,
      .swiper-button-next {
        position: relative;

        &::after {
          display: none;
        }

        .icon {
          height: 20px;
          width: 28px;
          fill:var(--color-accent)
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        top: 0;
        width: 28px;
        height: 20px;
        margin-top: 0;
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin-left: 5px;
        margin-right: 5px;
      }

      .swiper-pagination-bullet-active {
        background: var(--color-accent);
      }    
    }
  }
}



