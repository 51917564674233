[class*='display-'] {
    font-family: var(--font-family__display);
    letter-spacing: var(--font-letter-spacing-wide);
}
.display {
    &-XL, &-xl {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-19);
            line-height: var(--font-line-height-15);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-20);
            line-height: var(--font-line-height-16);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-20);
            line-height: var(--font-line-height-16);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-21);
            line-height: var(--font-line-height-17);
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-21);
            line-height: var(--font-line-height-17);
        }
    }
    &-L, &-l {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-16);
            line-height: var(--font-line-height-13);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-17);
            line-height: var(--font-line-height-14);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-17);
            line-height: var(--font-line-height-14);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-19);
            line-height: var(--font-line-height-15);
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-19);
            line-height: var(--font-line-height-15);
        }
    }
    &-M, &-m {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-13);
            line-height: var(--font-line-height-9);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-14);
            line-height: var(--font-line-height-10);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-14);
            line-height: var(--font-line-height-10);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-16);
            line-height: var(--font-line-height-13);
            
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-16);
            line-height: var(--font-line-height-16);
        }
    }
    &-S, &-s {
        @media (--viewport-xs-min) {
            font-size: var(--font-size-12);
            line-height: var(--font-line-height-9);
        }
        @media (--viewport-ms-min) {
            font-size: var(--font-size-13);
            line-height: var(--font-line-height-9);
        }
        @media (--viewport-sm-min) {
            font-size: var(--font-size-13);
            line-height: var(--font-line-height-9);
        }
        @media (--viewport-md-min) {
            font-size: var(--font-size-15);
            line-height: var(--font-line-height-12);
        }
        @media (--viewport-lg-min) {
            font-size: var(--font-size-15);
            line-height: var(--font-line-height-12);
            
        }
    }
    &-XS, &-xs {
        font-size: var(--font-size-1);
        line-height: var(--font-line-height-1);
    }
}



