.form-textarea {
    margin-bottom: 20px;
    position: relative;

    .textarea-field{
        background-color: var(--color-white);
    }
    

    &.disabled {
        .textarea-field {
            background-color: var(--color-grey-light);
            border-color: var(--color-quinary);
            color: var(--color-grey2);
            line-height: 0;

            &:focus {
                color: var(--color-text);
            }
        }
    }
    &.bgtransparent {
        .textarea-field {
            background: transparent;

            &:focus {
                color: var(--color-text);
            }
        }
    }
    &.bgaccentlight {
        .textarea-field {
            background-color: var(--color-accent-light);
            border-color: var(--color-accent-light);
            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }

            &:focus {
                color: var(--color-text);
            }
        }
    }
    &.password {
        .field-text__show-password.hide {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            left: initial;
            right: 15px;
            opacity: 1;
            display: block;
        }
    }
    &.error {
        .textarea-field {
            border-color: var(--color-danger);
            color: var(--color-danger);

            &:focus {
                color: var(--color-text);
            }
        }

        .field-bottom {
            &__error-message {
                display:block;
                position: relative;
            }
            &__helper-text{display:none;}
        }
    }
}