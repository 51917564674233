.documents-summary {
    &__filter {
        display:flex;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        @media (--viewport-xs-max) {
            .content__btn {
                width: 100%;
                .btn {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        
    }
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;   
        gap: 30px;  
        @media (--viewport-xs-max) {
            display:flex;
            flex-flow:column;
        }   
        &__item {
            flex-basis: 100%;
        }
    }
    &__link {
        margin-top: 60px;
    }
}