.card-result {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    background-color: var(--color-tertiary-brand);
    padding: 32px;

    @media (--viewport-sm-max) {
        padding: 32px 16px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tooltip {
            margin-bottom: -60px;
        }

        img {
            margin-bottom: 16px;
        }

        .label {
            margin-bottom: 16px;
        }

        .bodycopy-s {
            margin-top: 16px;
            text-align: center;
        }

    }

    &__form-radio-group {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        .form-radio-group {
            margin-bottom: 0;

            .field-top {
                display: none;
            }

            ul {
                .radio-group__item {
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__square {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0 0;
        border-top: solid 1px var(--color-border-accent-bold);
        border-bottom: solid 1px var(--color-border-accent-bold);
        padding: 16px 0 10px;

        .title-m {
            color: var(--new-color-text);
        }
    }

    &__item {
        margin-top: 32px;
        text-align: center;

        .title-s {
            color: var(--color-secondary-brand);
        }

        .title-xl {
            color: var(--color-text-primary);
        }

        .title-with-tooltip {
            display: block;
            text-align: center;
            margin: 0 10% 16px;

            .title-s {
                display: inline;
            }

            .tooltip {
                top: 22px;
                right: 0;
                left: 8px;
                display: inline;
            }
        }
    }

    &__item-number {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .bodycopy-s {
                padding-bottom: 11px;
            }
        }
    }

    &__item-clarification {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;
        }
    }

    &__taetin {
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px var(--color-secondary-brand);
        padding: 40px 0 0;
        margin-bottom: 20px;

        li.tae {
            .bodycopy-m,
            .title-xl {
                color: var(--color-text);
                line-height: 34px;
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                .tooltip {
                    top:-8px;
                    right:-8px;
                }

                &:first-child{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .bodycopy-m, .label-m{
                        min-width: 28px; /* para alinear los tooltips como en diseño (CA)*/
                    }
                }
            }
        }
    }

    &__additional {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        li.tit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
            .bodycopy-m {
                color: var(--color-text-accent);
            }

            .link_inline {
                color: var(--color-text-link-secondary);
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                &:nth-last-child(2) {
                    flex-shrink: 1;
                }
                &:last-of-type {
                    white-space: nowrap;
                    text-align: right;
                    flex-grow: 1;
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }

            .tooltip {
                left: 8px;
                top: -6px;
                margin-right: 20px;
            }
        }

    }

    &.error {
        justify-content: center;
        text-align: center;
        padding: 56px 32px;
        align-items: center;
        background-color: var(--color-background-accent);

        @media (--viewport-ms-max) {
            padding: 40px 60px;
        }

        @media (--viewport-xs-max) {
            padding: 40px;
        }

        .title-m {
            margin-bottom: 32px;
        }

        .icon {
            width: 40px;
            height: 40px;
            margin-bottom: 32px;
        }
    }

    &.fade-out {
        opacity: .5;
    }

    &__item {
        margin-top: 32px;
        text-align: center;

        .title-s {
            color: var(--color-secondary-brand);
        }

        .title-xl {
            color: var(--color-text-primary);
        }

        .title-with-tooltip {
            display: block;
            text-align: center;
            margin: 0 10% 16px;

            .title-s {
                display: inline;
            }

            .tooltip {
                top: 22px;
                right: 0;
                left: 8px;
                display: inline;
            }
        }
    }

    &__item-number {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .bodycopy-xs {
                padding-bottom: 13px;
            }

            .title-m {
                padding-bottom: 9px;
                color: var(--color-text-primary);
            }

            .bodycopy-l {
                padding-bottom: 9px;
            }

            @media (--viewport-md-max) {
                .bodycopy-xs {
                    padding-bottom: 10px;
                }

                .bodycopy-l {
                    padding-bottom: 6px;
                }

                .title-m {
                    padding-bottom: 4px;
                }
            }

            @media (--viewport-sm-max) {
                .bodycopy-xs {
                    padding-bottom: 7px;
                }

                .bodycopy-l {
                    padding-bottom: 3px;
                }

                .title-m {
                    padding-bottom: 3px;
                }
            }

            @media (--viewport-ms-max) {
                .bodycopy-xs {
                    padding-bottom: 11px;
                }

                .bodycopy-l {
                    padding-bottom: 6px;
                }

                .title-m {
                    padding-bottom: 6px;
                }
            }

            @media (--viewport-xs-max) {
                .bodycopy-xs {
                    padding-bottom: 8px;
                }

                .bodycopy-l {
                    padding-bottom: 3px;
                }

                .title-m {
                    padding-bottom: 4px;
                }
            }
        }
    }

    &__item-clarification {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .title-s {
                padding-bottom: -3px;
                color: var(--color-text-primary);
            }

            .bodycopy-xs {
                margin-bottom: -3px;
            }
        }
    }

    &__additional2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border-top: solid 1px var(--color-secondary-brand);
        padding: 20px 0 0;

        li {
            &:first-of-type {
                margin-bottom: 20px;

                .label-m {
                    color: var(--color-text-subtle);
                }
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                &:first-of-type {
                    margin-bottom: 20px;

                    .label-m {
                        color: initial;
                    }
                }

                &:nth-last-child(2) {
                    flex-shrink: 1;
                }
                &:last-of-type {
                    white-space: nowrap;
                    text-align: right;
                    flex-grow: 1;
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }

            .tooltip {
                left: 8px;
                top: -6px;
                margin-right: 20px;
            }
        }

    }

    &.fade-out {
        opacity: .5;
    }

    &__nav {
        display: flex;

        @media (--viewport-xs-max) {
            justify-content: space-between;
            padding: 0 20px;
        }

        &-back,
        &-forward {
            margin-top: 30px;
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }

    .wrap-btn{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        .icon{
            fill:var(--color-icon);
        }
    }
}