.caption-label {
    font-size: var(--font-size-1);
    line-height: var(--font-line-height-1);
    font-family: var(--font-family-sans-mid);
    letter-spacing: var(--font-letter-spacing-label);
    text-transform: uppercase;
}
.label {
    color: var(--color-text);
    @extend .caption-label;
}
