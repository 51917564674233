.iframe {
    &__label{
        text-align: center;
        padding: 0 0 20px;
    }
    &__title{
        text-align: center;
        padding: 0 0 20px;
    }
    &__bodycopy{
        text-align: center;
        padding: 0 0 20px;
    }
    &__link{
        text-align: center;
        margin: 30px 0;
        &.nomargin{
           margin-top: 0; 
        }
    }
    iframe{
        overflow: hidden;
    }
}