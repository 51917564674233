.form-fieldset {
    margin-bottom: 20px;
    position: relative;

    .input {
        background-color: var(--color-white);
        padding-right: 41px;
    }

    &.password{
        .input{
            padding-right: 67px;
        }
    }

    &__valid-indicator {
        opacity: 0;
        position: absolute;
        right: 15px;
        top: 57px;

        .icon {
            fill: var(--color-valid);
            height: 16px;
            width: 16px;
        }
    }
    
    &__show-password {
        position: absolute;
        right: 41px;
        top: 57px;

        .icon {
            fill: var(--color-icon);
            height: 16px;
            width: 16px;
        }
    }
    
    &__show-close {
        position: absolute;
        right: 15px;
        top: 57px;

        .icon {
            fill: var(--color-icon);
            height: 16px;
            width: 16px;
        }
    }

    &.valid {
        .form-fieldset__valid-indicator {
            opacity: 1;
        }

        /*.input{
            border-color: var(--color-valid);
        }*/
    }

    .icon-eye {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        left: initial;
        right: 12px;
    }

    &.disabled {
        .input {
            background-color: var(--color-grey-light);
            border-color: var(--color-quinary);
            color: var(--color-grey2);
            padding-right: 15px;

            &:focus {
                color: var(--color-text);
            }
        }
    }

    &.bgtransparent {
        .input {
            background: transparent;

            &:focus {
                color: var(--color-text);
            }
        }
    }

    &.bgaccentlight {
        .input {
            background-color: var(--color-accent-light);
            border-color: var(--color-accent-light);

            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }

            &:focus {
                color: var(--color-text);
            }
        }
    }

    &.password {
        .field-text__show-password.hide {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            left: initial;
            right: 15px;
            opacity: 1;
            display: block;

            .icon-eye {
                width: 15px;
                height: 15px
            }

            &.show {
                opacity: .3;

            }
        }
    }

    &.error {
        .input {
            border-color: var(--color-danger);
            color: var(--color-danger);

            &:focus {
                color: var(--color-text);
            }
        }

        .field-bottom {
            &__error-message {
                display: block;
                position: relative;
            }

            &__helper-text {
                display: none;
            }
        }
    }
}