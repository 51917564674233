.addresses {
    &__title {
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 40px;
        }
    }

    &__offices {
        @media (--viewport-sm-min) {
            margin-bottom: -40px;
        }

        .col {
            &:last-child {
                @media (--viewport-xs-max) {
                    margin-top: 20px;
                }
                .addresses__office {
                    @media (--viewport-ms-max) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__office {
        @media (--viewport-sm-min) {
            width: 90%;
        }
        @media (--viewport-lg-min) {
            width: 80%;
        }
        margin-bottom: 40px;

        &-head {
            margin-bottom: 30px;

            @media (--viewport-ms-min) {
                margin-bottom: 20px;
            }
        }

        &-title {
            margin-bottom: 5px;
        }

        &-links {
            &-item {
                margin-bottom: 20px;

                @media (--viewport-ms-min) {
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .link{
                    line-height: 16px;
                    .icon{
                        top: 0px;
                    }
                }
            }
        }
    }
}
