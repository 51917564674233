.radio-group {
    ul {
        display: flex;
        margin-bottom: 11px;
    
        .radio-group__item {
            margin-right: 32px;
    
            &:last-child {
                margin-right: 0;
            }
        }
        &.column{flex-direction: column;
            .radio-group__item {
                margin-right: 0;
                margin-bottom: 15px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
