.cards-grid {
    &__head {
        margin-bottom: 10px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 60px;
        }

        .cards-grid__description {
            margin-top: 20px;

            @media (--viewport-ms-min) {
                margin-top: 30px;
            }
        }

        .cards-grid__link {
            margin-top: 20px;

            @media (--viewport-ms-min) {
                margin-top: 30px;
            }
        }

        .cards-grid__title {
            margin-bottom: 20px;

            @media (--viewport-ms-min) {
                margin-bottom: 30px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 24px;
            }
        }
    }

    &__cards {
        .card {
            margin-top: 30px;
            padding-bottom: 30px;

            @media (--viewport-sm-min) {
                margin-top: 0;
            }

            @media (--viewport-ms-max) {
                margin-top: 15px;
            }

            &.accentBG {
                margin-bottom: 30px;
                padding-bottom: 0;
            }

            &.upload {
                margin-bottom: initial;
                padding-bottom: 40px;
            }
        }
    }

    &.AccentLight {
        background-color: var(--color-accent-light);
        padding: 40px;

        .cards-grid__cards .card {
            padding-bottom: 0;
        }
    }

    .cards-list__link,
    .cards-list__bottom-link,
    .cards-list__bottom-btn {
        text-align: center;
    }

    .cards-list__pagination .pagination .pagination__list {
        justify-content: center;
    }

    &.alignLeft {

        .cards-grid__title,
        .cards-list__bottom-link {
            text-align: left;
        }

        .cards-grid__head {
            margin-bottom: 40px;
        }
    }

    &__wrap {
        .cards-grid__cards__items {
            display: flex;
            gap: 32px;
            width: 100%;
            @media (--viewport-ms-max) {
                flex-direction: column;
            }

            .cards-grid__cards__item {
                width: 50%;

                @media (--viewport-ms-max) {
                    width: 100%;
                }
            }
        }
    }

    /* ESTILOS PARA QUE NO HAYA ERRORES EN VIEJAS INSTANCIAS DEL LATEST (CA)
    
    &__list {
        margin-bottom: -30px;

        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }
    }

    &__item {
        height: 100%;
        margin-bottom: 30px;
    }

    &__footer {
        margin-top: 30px;
        text-align: center;
    } */
}