.clarification {
    margin-bottom: 40px; margin-top: 40px;
    .text-label-s{
        color: var(--color-accent);
        margin-bottom:10px;
    }
    .bodycopy-m{
        color: var(--color-accent);
        p{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}