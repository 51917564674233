.bodycopy-s {
    color: var(--color-text);
    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }

    &.bodycopy {
        &_color {
            &_light {
                color: var(--color-text-light);

                .bodycopy__link {
                    color: var(--color-text-light);

                    span {
                        &::after {
                            background-color: var(--color-text-light);
                        }
                    }
                }
            }
        }

        &--color {
            &-dark{
                color: var(--color-accent-dark);
            }
        }

        &--strong {
            font-weight: 600;
        }

        &--medium {
            font-family: 'Jornada Sans Medium';
        }
    }

    .bodycopy {

        &__link {
            display: inline-block;
            font-family: 'Jornada Sans Book';
            font-size: 13px;
            font-weight: normal;
            line-height: 23px;
            letter-spacing: 0;
            color: var(--color-text);

            &:hover,
            &:focus {
                span {
                    &::after {
                        width: 100%;
                    }
                }
            }

            span {
                position: relative;
                display: inline-block;
                padding-bottom: 5px;
                transition: all 0.3s;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: var(--color-accent);
                    transition: all 0.3s;
                    content: '';
                }
            }
        }
    }
}
