.simulator-form {
    &.active {
        position: relative;
    }

    &__head {
        margin-bottom: 45px;
    }

    &__text {
        @media (--viewport-xs-max) {
            margin-top: 100px;
        }

        @media (--viewport-ms-only) {
            margin-top: 120px;
        }
    }
}
