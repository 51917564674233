.faq {
    &__content {
        margin-bottom: 60px;
    }

    &__icon .icon {
        width: 40px;
        height: 40px;
        margin-bottom: calc(var(--gutter-vertical)*4);
    }

    &__title {
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 40px;
        }
    }

    &__link {
        margin-top: 30px;
    }

    &__box {
        display: flex;
        align-items: center;
        min-height: 300px;
        padding: 60px 0;
        text-align: center;
        background-color: var(--color-accent);

        @media (--viewport-ms-min) {
            min-height: 293px;
        }

        @media (--viewport-sm-min) {
            min-height: 537px;
            padding: 60px;
        }

        &-bodycopy {
            margin-top: 20px;
        }

        &-link,
        &-btn {
            margin-top: 30px;
        }

        .row {
            flex-grow: 1;
        }

        &__media {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .faq__box-wrapper {
                height: 100%;
                width: 100%;

                &:before {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    z-index: 2;
                    content: "";
                    opacity: .2;
                    background: #000;
                }
            }


            &--content {
                display: flex;
                max-width: 100%;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-content: center;
                flex-flow: column;
                z-index: 2;
                position: relative;

                .faq__box-bodycopy {
                    margin-top: 20px;
                }

                .faq__box-link,
                .faq__box-btn {
                    margin-top: 30px;
                }
            }
        }
    }

    &.accent-version {
        .faq__title {
            text-align: center;

            .title-l {
                color: var(--color-secondary);
            }
        }

        .accordion__item {
            border-bottom-color: var(--color-accent-dark);

            .bodycopy-l {
                font-weight: bold;
            }

            .accordion__trigger .icon {
                width: 19px;
                height: 19px;
            }
        }

        .faq__link {
            text-align: center;
            margin-top: 40px;
        }
    }

    &.boxes-version {
        .faq__title {
            text-align: center;

            .title-l {
                color: var(--color-secondary);
            }
        }

        .accordion__item {
            border: solid 1px var(--color-accent-dark);
            padding: 0 30px;
            margin-bottom: 20px;

            .accordion__trigger .icon{
                width: 19px;
                height: 19px;
            }

            .bodycopy-l {
                font-weight: bold;
            }
        }

        .faq__link {
            text-align: center;
            margin-top: 40px;
        }
    }

    &.accent-light-bg {
        background-color: var(--color-accent-light);
        @media (--viewport-xs-max) {
            margin: 0 -20px;
            padding: 0 20px;
        }

        .faq__title {
            margin-top: 50px;
        }

        &.boxes-version {
            .accordion__item {
                border: solid 1px var(--color-accent-dark);
            }
        }
    }
}