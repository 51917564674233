.contact-highlight {
    position: relative;
    margin-bottom: 50px;

    @media (--viewport-ms-max) {
        margin-right: 0;
    }

    @media (--viewport-xs-max) {
        margin-left: -20px;
        margin-right: -20px;
    }

    @media (--viewport-ms-min) {
        margin-bottom: 50px;
    }

    @media (--viewport-sm-min) {
        margin-bottom: 50px;
    }

    @media (--viewport-md-min) {
    }

    &__tile {
        margin-bottom: -50px;background-color:var(--color-accent-light); padding: 70px;
        @media (--viewport-ms-max) {
            padding:40px;
        }
        @media (--viewport-xs-max) {
            padding:40px 20px;
        }
        .form-list{
            .form-checkbox-group{ /* para forzar un enlace a los textos legales en PT */
              .tooltip-label{
                justify-content: flex-start;
                }
            }
            /*input, .access-select__option, .textarea-field, .form-input__fieldset, .checkbox-label:before{border-color: var(--color-accent-dark);} LO QUITAMOS POR PETICION DE DS (CA)*/
        }
        &.without-tooltip-label{
            .form-list{margin-top: 26px;
                .tooltip-label{
                    display: none;
                }
                .form-checkbox-group{ /* para forzar un enlace a los textos legales en PT */
                  .tooltip-label{
                    justify-content: flex-start;
                    height: auto;
                    display:flex;
                    }  
                }
                &__valid-indicator{
                    top: 21px;
                }
                .form-radio-group { /* Para que en el caso de campo radio siempre se muestre (CA)*/
                    .tooltip-label{
                    justify-content: flex-start;
                    height: auto;
                    display:flex;
                    }  
                }
            }
        }
        &__header{
            margin-bottom: 20px; 
            .text-label-m{
                margin-bottom: 20px; 
            }
            /*.title-l{
            }*/
            .bodycopy-m{
                margin-bottom: 10px; /*margin-top: -20px;*/
                margin-top: 20px; 
            }
        }
        &.accent{
            background-color:var(--color-accent);
            .contact-highlight__tile__header{
                .text-label-m{
                    color: var(--color-text-light);
                }
                .bodycopy-m{
                    color: var(--color-text-light);
                }
            }
            .form-list{
                .text-label-m, .label-m{color: var(--color-text-light);}
            }
            .contact-form__legal{
                a{
                    color: var(--color-text-light);
                }
                .link span:after{
                    background-color: var(--color-text-light);
                }
                .bodycopy-s{color: var(--color-text-light);
                    a{
                        color: var(--color-accent-dark);
                    }
                }
            }
        }
        &.accent-dark{
            background-color:var(--color-accent-dark);
            .contact-highlight__tile__header{
                .text-label-m{
                    color: var(--color-text-light);
                }
                .bodycopy-m{
                    color: var(--color-text-light);
                }
            }
            .form-list{
                .text-label-m, .label-m{color: var(--color-text-light);}
            }
            .contact-form__legal{
                a{
                    color: var(--color-text-light);
                }
                .link span:after{
                    background-color: var(--color-text-light);
                }
                .bodycopy-s{color: var(--color-text-light);
                    a{
                        color: var(--color-accent);
                    }
                }
            }
            
        }
        .legal__checkbox{
            .checkbox-label{
                background-color: var(--color-white);
                margin-right: 10px;
                margin-left: -12px;
                width: 24px;
            }
        }
        .recaptcha{
            margin-top: 0;
            align-items: flex-start;
            @media only screen and (max-width: 320px) {
                margin-left:-10px;
            }
        }
        .legal{
            margin-top: 20px;
            a{color: var(--color-accent-dark); text-decoration:underline;}
        }
        .landing-legal-form{
            margin-bottom: 20px;
            a{color: var(--color-accent-dark); text-decoration:underline;}
        }
        .form-btn{
            margin-top: 20px;
            text-align: left;
        }
    }
    .wrap-img{height:550px;margin-top:0;width: 100%;top:0;overflow: hidden;display: flex; align-items:flex-start;justify-content: center;
        @media (--viewport-lg-min) {
            height:510px;
            margin-top:50px;
        }
        @media (--viewport-md-max) {
            height:550px;
            margin-top:50px;
        }
        @media (--viewport-sm-max) {
            height:550px;
            margin-top:0;
        }
        @media (--viewport-ms-max) {
            display:none;
        }
        img{width: 100%;}
        &.visible-all-views{
            @media (--viewport-ms-max) {
                display:block;
                height:auto;
                margin-bottom:-8px;
            }
        }
    }
    .content{
        /*width: calc(100% + 30px); Lo quito porque no entiendo que aporta y genera una diferencia en fullwidth que hace que no se centre bien (CA)*/
        position: relative; margin-top: -450px;
        @media (--viewport-lg-min) {
            margin-top:-410px;
        }
        @media (--viewport-md-max) {
            margin-top:-450px;
        }
        @media (--viewport-sm-max) {
            margin-top:-450px;
        }
        @media (--viewport-ms-max) {
            margin-top:0;

        }
        &.visible-all-views{
            @media (--viewport-ms-max) {
                margin-top:0;
            }
        }
        /*  Lo quito porque no entiendo que aporta y genera una diferencia en fullwidth que hace que no se centre bien (CA)
        @media (--viewport-xs-max) {
            width: calc(100% + 20px);
            
        }
        */
    }
    &.opening {
        .content{
            /*margin-top: -450px;
            @media (--viewport-lg-min) {
                margin-top:-410px;
            }
            @media (--viewport-md-max) {
                margin-top:-450px;
            }
            @media (--viewport-sm-max) {
                margin-top:-450px;
            }
            &.visible-all-views{
                @media (--viewport-ms-max) {
                    margin-top:0;
                }
            }*/
        }
        .wrap-img{
            margin-top: 0;
        }
    }
    &.under-header {
        margin-top: 100px;
        .content{
            @media (--viewport-ms-max) {
                margin-top:0;
    
            }
        }
    }
    .splitter{
        .container{
            margin: 0;
            padding: 0;
            .splitter__wrapper{
                margin: 0;
                padding: 0;
                background: none;
            }
        }
    }
}