.links-block {
    &__title {
        margin-bottom: 20px;
    }

    &__links {
        display: flex;
    }

    &__list {
        width: 50%;

        &:first-child {
            width: calc(56.5% - 20px);
            margin-right: 20px;

            @media (--viewport-ms-min) {
                width: calc(50.5% - 20px);
            }

            @media (--viewport-sm-min) {
                width: calc(54.5% - 20px);
            }
        }

        &:last-child {
            width: 43.5%;

            @media (--viewport-ms-min) {
                width: 49.5%;
            }

            @media (--viewport-sm-min) {
                width: 45.5%;
            }
        }
    }

    &__item {
        &:last-child {
            .link {
                padding-bottom: 0;
            }
        }

        .link {
            display: block;
            padding-bottom: 20px;

            @media (--viewport-sm-min) {
                padding-bottom: 10px;
            }
        }
    }
}
