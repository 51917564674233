.table-extra {
    .title-l{
        color: var(--color-secondary-brand);
        @media (--viewport-ms-max) {
            margin-bottom: 30px;
            text-align: center;
        }
        @media (--viewport-xs-max) {
            margin-bottom: 15px;
            text-align: center;
        }
    }
    .table__content{
        &_col {
            &_5 {
                th,
                td {
                    min-width:140px;
                    width:20%;
                    @media (--viewport-ms-max) {
                        min-width: 190px;
                    }
                    @media (--viewport-xs-max) {
                        min-width: 130px;
                    }
                }
            }
        }
        table{
            thead{
              tr{
                th{padding: 15px 20px; text-align: left;
                a{float:right;}
                }
              }  
            }
            tbody{
                tr{border-top: 1px solid var(--color-accent-light);
                    &:last-child{border-bottom: 1.5px solid var(--color-secondary-brand);}
                    td{padding: 15px 20px; text-align: left;}
                }
            }
        }
    }
}