/*.lightbox__window{padding-bottom:20px;} Esta clase no puede estar en este componente sin hacer referencia a su contenedor. Provoca un error en el resto de Lightbox (CA) */
.steps-form {
    margin-top: -40px;
    .step-form {
        display: none;
        &.steps-form__step1 {
            display: block;
        }
    }
    .inactive{display: none;}
    .active{display:block;}
    .tit-group{text-align:center;margin-bottom:0;margin-top:60px;
        @media (--viewport-ms-max) {
            margin-top:45px;
        }
        @media (--viewport-xs-max) {
            margin-top:30px;
        }
        .title-xxl{
            text-align: center;display:inline;
        }
        img{width: 45px;display:inline;margin-bottom:-14px;}
    }
    ul.form-list{width: 60%;margin:0 20%;
        @media (--viewport-sm-max) {
            width: 80%; margin:0 10%;
        }
        @media (--viewport-ms-max) {
            width: 100%; margin:0;
        }
        @media (--viewport-xs-max) {
            width: 90%; margin:0 5%;
        }
        li{width: 100%;
            ul.radio-group{flex-direction: column;
                li{padding:10px 0;}
            }
        }
    }
    .steps-form__nav {
        display: flex;margin-bottom: 40px;width: 100%;justify-content: center;
        position: absolute;bottom: 0;

        @media (--viewport-xs-max) {
            /*justify-content: space-between;*/
            padding: 0 20px;
            margin-bottom:10px;
        }

        &-back,
        &-forward {
            width: 30px;
            margin-top: 30px;
            /*margin-right: calc(50%-30px);
            margin-left:30px;*/
        }

        &-back {/*margin-left: calc(50%-30px); margin-right:30px;*/
            @media (--viewport-ms-min) {
                /*margin-right: 30px;*/
            }
        }
        &-forward{display: none;}
    }
    .steps-form__step1{
        .steps-form__nav{
            &-back{display: none;}
            &-forward{display: none;}
        }
    }
    .form-btn{text-align: center;}
    .tipos{
        ul.form-list{width: 100%;margin:0;
            @media (--viewport-sm-max) {
                width: 60%;margin:0 20%;
            }
            @media (--viewport-ms-max) {
                width: 90%; margin:0 5%;
            }
            li{
                ul.radio-group{flex-direction:row;
                    @media (--viewport-sm-max) {
                        flex-direction:column;
                    }
                    li{
                        &:first-child::before {
                            content: " ";
                            background: url(/dist/images/fijo.png);
                            background-size: contain;
                            display: block;
                            width: 100%;
                            height: 105px;
                            @media (--viewport-sm-max) {
                                width: 165px;
                                height: 80px;
                            }
                            @media (--viewport-xs-max) {
                                width: 145px;
                                height:70px;
                            }
                        }
                        &:nth-child(2n)::before {
                            content: " ";
                            background: url(/dist/images/mixto.png);
                            background-size: contain;
                            display: block;
                            width: 100%;
                            height: 105px;
                            @media (--viewport-sm-max) {
                                width: 165px;
                                height: 80px;
                            }
                            @media (--viewport-xs-max) {
                                width: 145px;
                                height:70px;
                            }
                        }
                        &:nth-child(3n)::before {
                            content: " ";
                            background: url(/dist/images/variable.png);
                            background-size: contain;
                            display: block;
                            width: 100%;
                            height: 105px;
                            @media (--viewport-sm-max) {
                                width: 165px;
                                height: 80px;
                            }
                            @media (--viewport-xs-max) {
                                width: 145px;
                                height:70px;
                            }
                        }
                        &:nth-child(4n)::before {
                            content: " ";
                            background: none;
                            display: block;
                            width: 100%;
                            height: 105px;
                            @media (--viewport-sm-max) {
                                width: 165px;
                                height:20px;
                            }
                            @media (--viewport-xs-max) {
                                width: 145px;
                            }
                        }
                    }
                }
            }
        }
    }
    .steps-form__step8{position:relative; padding-bottom: 70px;
        @media (--viewport-xs-max){padding-bottom:40px;}
        .steps-form__nav{margin-bottom: 0;}
        .checkbox-wrapper{margin:30px 0;}
        ul.form-list{margin-top: 40px;
            li{
                .form-list__field{/*margin-top: 30px;*/}
            }
        }
        p{text-align:left; padding-top:40px; /*padding:40px 0 30px;*/
            a{color: var(--color-accent-dark);}
        }
        /*.show{
            display: none;
            p{text-align: center; padding:10px 0 30px;
                @media (--viewport-ms-max){padding-bottom:0;}
            }
            ul.form-list{width: 90%;margin:0 5% 50px;flex-direction: row;display:flex;
                @media (--viewport-ms-max) {
                    flex-direction: column;
                    width: 90%; margin:0 5% 30px;
                }
                li{width: 50%;
                    @media (--viewport-ms-max){width:100%;}
                    .form-list__field{
                        @media (--viewport-sm-min){margin-top:0;}
                    }
                    &:first-child{
                        .form-fieldset:first-child{
                            @media (--viewport-sm-min){margin-right: 30px;}
                        }
                    }
                }
            }
        }*/
    }
    .steps-form__step1,
    .steps-form__step2,
    .steps-form__step3,
    .steps-form__step4,
    .steps-form__step5,
    .steps-form__step6,
    .steps-form__step7{
        height:410px;position:relative;
        @media (--viewport-sm-max){height:450px;}
        @media (--viewport-ms-max){height:450px;}
        @media (--viewport-xs-max){height:500px;}
        /*@media (max-width: 767px) and (max-height: 823px){height:auto; min-height:773px;padding-bottom: 70px;}
        @media (max-width: 767px) and (max-height: 812px){height:auto; min-height:762px;padding-bottom: 70px}
        @media (max-width: 767px) and (max-height: 736px){height:auto; min-height:686px;padding-bottom: 70px}
        @media (max-width: 767px) and (max-height: 731px){height:auto; min-height:681px;padding-bottom: 70px}
        @media (max-width: 767px) and (max-height: 667px){height:auto; min-height:617px;padding-bottom: 70px}
        @media (max-width: 767px) and (max-height: 640px){height:auto; min-height:590px;padding-bottom: 70px}
        @media (max-width: 767px) and (max-height: 568px){height:auto; min-height:518px;padding-bottom: 70px}*/
        @media (min-width: 520px) and (max-height: 720px){height:auto; min-height:400px;padding-bottom: 50px}
        @media (max-width: 280px) and (max-height: 653px){height:auto; min-height:620px;padding-bottom: 50px}
        }
}
