.prequalifier-form-list {
    display: flex; flex-direction: row;
    li{
        &.col-sm-6, &.col-md-6, &.col-lg-6{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field{
                    @media (--viewport-ms-min) {margin-top: 0;}
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
        }
        &.col-sm-4, &.col-md-4, &.col-lg-4{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field{
                    @media (--viewport-ms-min) {margin-top: 0;}
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
            &:nth-child(3) .form-list__field {
                    @media (--viewport-sm-min) {margin-top: 0;}
            }
        }
        &.col-md-3, &.col-lg-3{
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field, &:nth-child(3) .form-list__field, &:nth-child(4) .form-list__field, &:nth-child(5) .form-list__field{
                @media (--viewport-md-min) {margin-top: 0;}
            }
        }
        &.col-md-2_5, &.col-lg-2_5{ /*para las 5 columnas aleatorias*/
            @media (--viewport-md-min) {box-sizing: border-box;flex: 0 1 auto;flex-basis:20.888%;max-width:20.8333%;padding-right: var(--half-gutter-width-ms-min, 0.5rem);padding-left: var(--half-gutter-width-ms-min, 0.5rem);}
            @media (--viewport-lg-min) {box-sizing: border-box;flex: 0 1 auto;flex-basis:20.888%;max-width:20.8333%;padding-right: var(--half-gutter-width-lg-min, 0.5rem);padding-left: var(--half-gutter-width-lg-min, 0.5rem);}
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field, &:nth-child(3) .form-list__field, &:nth-child(4) .form-list__field, &:nth-child(5) .form-list__field{
                @media (--viewport-md-min) {margin-top: 0;}
            }
        }
        &.col-md-2_4, &.col-lg-2_4, &.col-md-2, &.col-lg-2, &.col-md-1_5, &.col-lg-1_5,
        &.col-md-1_5 + &.col-md-3, &.col-lg-1_5 +  &.col-lg-3{ /*para las 5 columnas aleatorias*/
            &:first-child .form-list__field,
            &:nth-child(2) .form-list__field, &:nth-child(3) .form-list__field, &:nth-child(4) .form-list__field, &:nth-child(5) .form-list__field{
                @media (--viewport-md-min) {margin-top: 0;}
            }
        }
        .form-list__field{
            position: relative;
        }
        /*&:last-child{
            .form-list__field{
                    @media (--viewport-xs-max) {margin-bottom:0;}
            }  
        }*/
    }
    li.col-md-1_5 + li.col-md-3, li.col-lg-1_5 +  li.col-lg-3{ /*para las 5 columnas aleatorias*/
        .form-list__field{
            @media (--viewport-md-min) {margin-top: 0;}
        }
    }
    /*.form-list__error-message {
        opacity: 0;
    }
    .input-validation-error {
         border-color: #e4002b;
        color: #e4002b;
    }*/
}