.quote {
    
    @media(--viewport-xs-max) {
        margin-right: 0;
        margin-left: 0;
    }
    &__icon{
       display:flex;
       align-items: center;
        @media(--viewport-xs-min) {
          gap:16px;
          padding:8px 16px 16px 16px;
        }
        @media(--viewport-ms-min) {
            gap:20px;
            padding:16px 32px 32px 32px;
        }
       .icon {
            transform: rotate(180deg);
            width:56px;
            height:56px;
            @media(--viewport-xs-min) {
                width:32px;
                height:32px;
            }
            @media(--viewport-ms-min) {
                width:40px;
                height:40px;
            }
            @media(--viewport-sm-min) {
                width:56px;
                height:56px;
            }
            &.accent {
                fill: var(--color-icon-accent);
            }
       }
       .quote__text {
            display:flex;
            flex-flow:column;
            align-items: center;
            @media(--viewport-xs-min) {
                gap:16px;
            }
            
            .title-m, .bodycopy-s {
                text-align:center;
                display: block;
                color: var(--color-text);
            }
       }
    }
    &__line {
        border-left: solid 2px var(--color-accent);
        padding:20px;
        gap:20px;
        padding:20px;
        .quote__text {
            display:flex;
            flex-flow: column;
            gap:20px;
            .title-m, .bodycopy-s {
                text-align:left;
            }
        }
    }
    &__text {
        .title-m, .bodycopy-s {
            color: var(--color-text);
        }
        a {
            color:var(--color-text-link-secondary);
            text-decoration:underline;
        }
   }
}