
.form-input {
    margin-bottom: 20px;
    width: 100%;position: relative;

    &__fieldset {
        display: flex;flex-direction: row;justify-content: flex-end;align-items: flex-end;
        position: relative;background-color: var(--color-white);
        border: 1px solid var(--color-grey3);
        &:hover,
        &:focus {
            border-color: var(--color-secondary);
        }
    }

    &__input {
        height: 48px;
        width: 100%;
        padding: 17px 5px 11px 14px;
        text-align: right;
        font-family: 'Jornada News Book';
        font-size: 29px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0;
        border: 0;
        color: var(--color-text);

        &:hover,
        &:focus {
            border-color: var(--color-secondary);
        }
    
    }
    &__symbol {
        padding-bottom: 7px;
        padding-right: 14px;
        white-space: nowrap;
        text-transform: uppercase;
        .bodycopy-xs{
            color: var(--color-grey2);
        }
    }

    &.disabled{
        .form-input__fieldset {
            background-color: var(--color-grey-light);
            border-color: var(--color-quinary);
            color: var(--color-grey2);
        }
        .form-input__input {
            color: var(--color-grey2);
        }
        .form-input__symbol .text-label-m{
            color: var(--color-grey2);
        }
    }
    &.bgtransparent{
        .form-input__fieldset {
            background: transparent;
            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }
        }
    }
    &.bgaccentlight{
        .form-input__fieldset {
            background-color: var(--color-accent-light);
            border-color: var(--color-accent-light);
            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }
        }
    }
    
    &.error {
        .form-input__fieldset {
            border-color: var(--color-danger);
        }

        .field-bottom {
            &__error-message {
                display:block;
                position: relative;
            }
            &__helper-text{display:none;}
        }
    }

    .date{
        .bodycopy-m{
            margin-top:10px;
            text-align: right;
        }
    }
}