.socials {
    .border{
        border-top: solid 1px var(--color-bg-2); padding-bottom: 15px;display: block;
    }

    .text-label-s{
        margin-bottom: 10px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -5px 0;
    }

    &__item {
        margin: 5px 45px 5px 0;

        @media (--viewport-ms-min) {
            margin-right: 20px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        position: relative;
        display: flex;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 40px;
            height: 40px;
            content: '';
        }

        &:hover,
        &:focus {
            .icon {
                fill: var(--color-accent);
            }
        }

        .icon {
            width: 20px;
            height: 20px;

            @media (--viewport-ms-min) {
                width: 16px;
                height: 16px;
            }
        }
    }
}
