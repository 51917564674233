.summary {margin-bottom: 20px;
    svg {
        margin: 0 0 10px;
        line-height: 0;
        width: 54px;
        height: 54px;
        fill: var(--color-primary-brand);
    }

    &__title-m {
        .title-m {
            color: var(--color-secondary);

        }

        h3 {
            color: var(--color-secondary);
            margin: 0;
        }

        &.accent {
            .title-m {
                color: var(--color-accent);
            }

            h3 {
                color: var(--color-accent);
                margin: 0;
            }
        }
    }

    &.with-line {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
        border-left: solid 2px var(--color-accent);

        &.accent-dark {
            border-color: var(--color-accent-dark);
        }

        .title-m {
            padding: 20px 0 20px 20px;
        }
    }
}