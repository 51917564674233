.products-list {
    display: none;
    
    &.visible {
        display: flex;
        flex-direction:column;
    }

    &__list{
        display: flex; flex-direction: row; flex-wrap: wrap;
    }
    
    &__item {
        width: 25%;
        margin-bottom: 40px;
    
        @media (--viewport-sm-min) {
            margin-bottom: 30px;
        }
    
        @media (--viewport-ms-max) {
            width: 50%;
        }
    
        @media (--viewport-xs-max) {
            width: 100%;
        }
    }
    
    &__link {display: flex;justify-content: center; margin: 40px 0;
        @media (--viewport-ms-max) {
            margin-bottom: 40px;
        }
    }
}