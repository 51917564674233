.ucipt-slide-highlight {
    &__wrapper {
        background-color:transparent;
        &.bg-accent {
            background-color:var(--color-accent);
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 402px;
        padding: 60 50px;

        @media (--viewport-ms-min) {
            padding: 50px;
        }

        @media (--viewport-sm-min) {
            padding: 60px 40px;
        }

        @media (--viewport-ms-max) {
            padding: 60px 40px;
        }

        @media (--viewport-xs-max) {
            min-height: auto;
            padding: 40px 20px 40px;
        }
    }

    &__content-holder {
        z-index: 2;
    }

    &__label {
        margin-bottom: 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 20px;
        }
    }

    &__title {
        margin-bottom: 20px;
        max-width: /*410px*/auto;

        @media (--viewport-ms-min) {
            margin-bottom: 40px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-xs-max) {
            max-width: 360px;
        }

        &_header {
            margin-bottom: 10px;

            @media (--viewport-ms-min) {
                margin-bottom: 20px;
            }

            @media (--viewport-md-min) {
                margin-bottom: 7px;
            }
        }
    }

    &__bodycopy {
        margin-top: 10px;
        max-width: /*410px*/auto;

        @media (--viewport-ms-min) {
            margin-top: 40px;
        }

        @media (--viewport-sm-min) {
            margin-top: 20px;
        }

        @media (--viewport-xs-max) {
            max-width: 360px;
        }

        p + p {
            margin-top: 20px;
        }
    }

    &__btnholder {
        display: block;
    }

    &__btn {
        margin-top: 20px;
        margin-right: 20px;
        display: inline-block;

        &:last-of-type {
            margin-right: 0;
        }

        @media (--viewport-xs-max) {
            width:90%;

            .btn{
                width:100%;
            }
        }
    }
}
