.lightbox-content-description-list {
    .description-list {
        margin-top: 48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 32px;
        @media (--viewport-md-max) {
            padding-bottom: 34px;
        }
        dl{
            .total{
                position: absolute;
                bottom:0;
            }
            dd.total{
                right:0;
            }
        }
    }
    .bodycopy-xs{
        color: var(--color-text-subtle); margin-top: 48px;display: block;
    }
}