.slider-highlight {
    background-color: #fff;
    position: relative;
    margin-top: 100px;
    margin-bottom: 20px;
    z-index: 0;
    
    @media (--viewport-ms-max) {
        margin-top:55px;
    }
    @media (--viewport-xs-max) {
        margin-left: -20px;
        margin-right: -19px;
    }

    .swiper-container{
        @media (--viewport-ms-max) {
            padding-bottom:40px;
        }
    }
    .swiper-slide{
        background-color:var(--color-white);
    }
    .swiper-navigation{
        max-width: 267px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;

            &::after {
                display: none;
            }

            .icon {
                height: 20px;
                width: 28px;
                fill:var(--color-accent)
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 0;
            width: 28px;
            height: 20px;
            margin-top: 0;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }

        &.simple {
            height: auto;
            max-width: 267px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom:0;
            @media (--viewport-ms-max) {
                padding: 20px 20px 0 20px;
            }
            @media (--viewport-xs-max) {
                width: 100%;
                max-width:100%;
            }
        
            .swiper-pagination,
            .swiper-button-prev,
            .swiper-button-next {
                position: relative;
                &::after {
                    display: none;
                }
            .icon {
                height: 20px;
                width: 28px;
                fill:var(--color-accent)
                }
            }
            .swiper-button-next,
            .swiper-button-prev {
                top: 0;
                width: 28px;
                height: 20px;
                margin-top: 0;
            }
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin-left: 5px;
                margin-right: 5px;
            }
            .swiper-pagination-bullet-active {
                background: var(--color-accent);
            }
            &.simple-right{
                right:0;
                padding: 20px 0 10px 20px;
            }
            &.simple-left{
                left:0;
                padding: 20px 20px 10px 0;
            }
        }

        
    }
    
    &.content-inside{
        .slide-highlight{margin-bottom: 0;display:flex; align-items:center;
            .slide-highlight__tile{bottom:auto;margin:0;}
        }
    }

    

    .tile-highlight__title {
        margin-bottom: 0px;
        /*margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 40px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 30px;
        }*/
    }   

    &.not-opening{margin-top: 0;
        .tile-highlight{
            &__title {
                margin-bottom: 0px;
            }
        }
    }

}