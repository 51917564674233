.faqs-component {
    float: left;
    position: relative;

    .wrapper{
        position: relative;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        @media(--viewport-ms-max) {
            flex-direction: column;
        }
        /*@media(--viewport-ms-only) {
            margin:0;
        }*/
    }

    &__stacker {
        float: left;
        top: 0;
        position: sticky;
        width: 30%;
        padding-top: 0px;
        padding-bottom: 0px;

        /* mobile stylization */
        @media(--viewport-ms-max) {
            width: 100%;
            position: relative;
        }

        &.sticky-clone {
            box-shadow: none;
        }


        &-dropdown {
            display: none;

            &.sticky-clone {


                .faqs-component {
                    &__stacker {
                        &-dropdown {
                            z-index: 12;
                        }
                    }
                }

            }

            @media(--viewport-ms-max) {
                display: block;

            }




            .icon {
                display: block;
                fill: var(--color-accent);
                width: 16px;
                height: 16px;
            }

            .access-select {
                z-index: 0;
            }

            .access-select__list {
                position: sticky;
                text-align: left;
            }

            .access-select__chosen {
                z-index: 2;

                &-option {
                    /*border: 1px solid var(--color-text);*/
                    text-align: left;
                }

                &-icon {
                    transition: all 0.4s;
                }

                &-label {
                    text-align: left;
                    left: 15px;
                }

            }

            .access-select__chosen.active .access-select__chosen-label {
                color: var(--color-accent);
            }

            .access-select__input {

                &:hover,
                &:focus {
                    +.access-select__label {
                        text-decoration: none;
                    }
                }
            }

        }

        &-categoriesBox {
            display: flex;
            flex-flow: column;

            height: fit-content;
            width: 100%;
            margin-bottom: 40px;

            background-color: var(--color-accent-light);

            /* mobile stylization */
            @media(--viewport-ms-max) {
                display: none;
            }

            &-categoriesList {
                padding: 30px 32px;

                &-categoryAccordion {

                    /*each trigger represents one Category*/
                    &__trigger {
                        position: relative;
                        width: 100%;

                        text-align: left;
                        /*font-family: "Jornada News Medium";
                        font-size: 26px;
                        line-height: 32px;
                        color: var(--color-text);*/

                        .title-m {
                            color: var(--color-text);
                        }

                        /*this leaves the Category with accent color when active, it also generates the  right red border */
                        &.active {

                            border-right: 3px solid var(--color-primary-brand);

                            .title-m {
                                color: var(--color-accent);
                            }

                        }
                    }

                    &__item {

                        padding-bottom: 10px;

                        /* this makes the first subCategoryList visible */
                        &:first-child {
                            .faqs-component__stacker-categoriesBox-categoriesList-categoryAccordion__item-content {
                                display: block;
                            }
                        }

                        /* content contains subCategoryList */
                        &-content {
                            display: none;

                            &-wrapper {
                                padding-bottom: 10px;
                            }
                        }

                        &-subCategoryList {
                            display: flex;
                            flex-flow: column;
                            padding-left: 10px;

                            &__trigger {
                                position: relative;
                                width: 100%;
                                text-align: left;
                                font-family: "Jornada Sans Medium";
                                font-size: 13px;
                                line-height: 18px;
                                color: var(--color-text);
                                padding-top: 10px;

                                &:focus {
                                    color: var(--color-accent);
                                }

                                &:hover {
                                    color: var(--color-accent);
                                }

                                &:active {
                                    color: var(--color-accent);

                                }
                            }
                        }
                    }


                }
            }
        }

        &-ctaBox {
            display: flex;
            flex-flow: column;
            height: fit-content;
            padding: 32px 32px;
            background-color: var(--color-accent);

            @media(--viewport-ms-max) {
                display: none;
            }

            &-title {
                /* font-family: 'Jornada News Book';
                font-size: 32px;
                line-height: 36px;
                color: var(--color-primary);*/

                .title-l {
                    color: var(--color-primary);
                }

                margin-bottom: 25px;
            }

        }

    }

    &__display {
        float:left;
        position: relative;
        top:0;
        padding-top: 20px;
        padding-left: 50px;
        padding-left: 50px;
        width: 70%;

        /* mobile stylization */
        @media(--viewport-ms-max) {
            width: 100%;
            padding-left: 0px;
        }

        &-subCategoryBox {
            display: flex;
            flex-flow: column;

            padding-top: 110px;




            @media(--viewport-ms-max) {
                padding-bottom: 0px;
                /*padding-top: 80px; */
                padding-top: 40px; 

                &:first-child {
                    padding-bottom: 0px;
                    /*padding-top: 80px;*/
                    padding-top: 40px;
                }
            }





            .title-m {
                color: var(--color-text);
            }

            /* &-title {
                font-family: "Jornada News Book";
                font-size: 35px;
                line-height: 42px;
                color: var(--color-text);

                /* mobile stylization
                @media(--viewport-ms-max) {
                    font-size: 26px;
                    line-height: 26px;
                }

            } */

            &-desc {
                /* font-family: "Jornada News";
                font-size: 20px;
                line-height: 28px;
                color: var(--color-text);*/

                .bodycopy-m {
                    color: var(--color-text);
                }

                /* mobile stylization */
                @media(--viewport-ms-max) {
                    font-size: 16px;
                    line-height: 18px;
                }

            }

            &-accordionList {
                display: flex;
                flex-flow: column;
                padding-top: 30px;


                &-singleAccordion {
                    display: block;

                    height: 100%;
                    border: 1px solid #E7E7E7;

                    margin-bottom: 20px;
                    padding: 0px 30px;

                    /* mobile stylization */
                    @media(--viewport-ms-max) {
                        padding: 0px 15px;
                    }

                    /* this limits number of single accordions to 3 */
                    &:nth-child(n+4) {}

                    &-trigger {
                        position: relative;

                        width: 100%;
                        padding: 15px 0;
                        text-align: left;

                        .bodycopy-l {
                            color: var(--color-text);
                            margin-right: 30px;
                        }

                        &-accTitle {
                            /*font-family: "Jornada Sans Book";
                            font-size: 20px;
                            line-height: 28px;*/

                            .bodycopy-l {
                                color: var(--color-text);
                                margin-right: 15px;
                            }

                            padding-right: 25px;

                            /* mobile stylization */
                            @media(--viewport-ms-max) {
                                font-size: 16px;
                                line-height: 18px;
                            }



                        }

                        &::after {
                            position: absolute;
                            top: 40%;
                            right: 0;
                            display: block;
                            width: 12px;
                            height: 12px;
                            background: url("/dist/images/red-arrow-down.svg") center / contain no-repeat;
                            content: '';
                            transition: all 0.4s;

                        }

                        &.active {
                            &::after {
                                transform: rotate(-180deg);
                            }
                        }
                    }

                    &-item {

                        &-content {
                            display: none;

                            &-wrapper {
                                padding-bottom: 20px;
                            }
                        }

                        &-description {
                            /* font-family: "Jornada Sans Book";
                            font-size: 18px;*/

                            .bodycopy-m {
                                color: #797979;
                            }

                            padding: 1rem 0rem;
                        }
                    }

                }
            }
        }
    }

    &__categoryDiv{
        display:block;
        @media(--viewport-sm-min) {
            &:first-child {
                margin-top: -110px;
            }

        }
    }

}
@media(--viewport-sm-min) {
    .header-fixed ~ * .faqs-component__stacker {
        top:100px;
    }
}
