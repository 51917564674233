.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  .googletranslate {
    margin: 20px 20px 0 110px;
    .goog-te-gadget-simple {
      padding-bottom: 1px;
      border-color: var(--color-accent-dark);
      padding-left: 5px;
      color: var(--color-accent);
      font-family: var(--base-font-family);
    }
    .goog-te-menu-value {
      color: var(--color-accent);
      bottom: -1px;
      position: relative;
    }
    @media (--viewport-md-max) {
      margin-top: 10px;
    }
    @media (--viewport-sm-max) {
      margin: 0 20px 0 80px;
    }
    @media (--viewport-ms-max) {
      margin-top: 40px;
      margin-left: 90px;
    }
    @media (--viewport-xs-max) {
      margin-left: 0;
    }
  }
  z-index: 1;

  &.is-experience-editor {
    margin-top: 89px;
    position: static !important;
  }

  @media (--viewport-sm-min) {
    z-index: 12;
  }

  @media (--viewport-ms-max) {
    position: static;
    margin-bottom: -105px;
  }

  &.active {
    z-index: 12;
    background-color: var(--color-primary);
  }

  &.visible {
    position: fixed;
    z-index: 12;

    @media (--viewport-sm-min) {
      background-color: var(--color-primary);
    }

    .header {
      &__body {
        &-main {
          &-btn {
            @media (--viewport-sm-min) {
              color: var(--color-text);
            }

            .icon {
              @media (--viewport-sm-min) {
                fill: var(--color-secondary);
              }
            }
          }
        }
      }
    }
  }

  &_accent {
    &--fullspace {
      .header__head {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          height: 100%;
          background-color: var(--color-accent);
          content: '';

          @media (--viewport-sm-min) {
            width: 29px;
          }

          @media (--viewport-md-min) {
            width: 30px;
          }

          @media (--viewport-cont-no-pad-min) {
            width: calc((100% - 1414px) / 2 + 50px);
          }
        }
      }
    }
    .header {
      &__head {
        position: relative;
        @media (--viewport-ms-max) {
          background-color: var(--color-accent);
        }

        &-wrapper {
          background-color: var(--color-accent);
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          height: 100%;
          background-color: var(--color-accent);
          content: '';

          @media (--viewport-sm-min) {
            width: 29px;
          }

          @media (--viewport-md-min) {
            width: 30px;
          }

          @media (--viewport-cont-no-pad-min) {
            width: calc((100% - 1414px) / 2 + 50px);
          }
        }
      }
      &__contact-info {
        .bodycopy-xs {
          color: var(--color-white);
          a,
          a:visited,
          a:focus {
            color: var(--color-white);
          }
        }
        .bodycopy-xs + .bodycopy-xs {
          color: var(--color-white);
        }
        .tooltip {
        }
      }

      &__hamburger {
        .icon {
          fill: var(--color-primary);
        }
      }

      &__popups-item {
        .popup__trigger {
          .icon {
            fill: var(--color-white);
          }
          @media (--viewport-sm-min) {
            color: var(--color-text-light);
          }
        }
      }

      &__search {
        &-btn {
          .icon {
            fill: var(--color-primary);
          }
        }
      }

      &__body {
        &-wrapper {
          @media (--viewport-sm-min) {
            background-color: var(--color-primary);
          }
        }

        &-main {
          &-btn {
            @media (--viewport-sm-min) {
              color: var(--color-text);
            }

            .icon {
              @media (--viewport-sm-min) {
                fill: var(--color-secondary);
              }
            }
          }
        }
      }
    }
  }

  &__head {
    position: relative;
    z-index: 12;
    .mobile-menu-is-open & {
      z-index: 0;
    }

    &-wrapper {
      position: relative;
      display: flex;
      background-color: var(--color-primary);
      @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
        padding: 0 20px;
      }
    }

    &-main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 28px 0 26px;

      @media (--viewport-ms-min) {
        padding: 30px 0;
      }

      @media (--viewport-sm-min) {
        padding: 13px 0 13px;
      }

      @media (--viewport-md-min) {
        padding: 13px 0 13px;
      }

      @media (--viewport-lg-min) {
        padding: 12px 0 11px;
      }

      /*@media (--viewport-lg-min) {
                padding: 30px 0;
            }*/

      .header {
        &__triggers {
          @media (--viewport-ms-max) {
            display: none;
          }
        }
      }
    }
  }

  &__logo {
    flex-grow: 1;
    display: flex;

    &-link {
      display: block;
      max-width: 200px;
      transition: all 0.3s;

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }

    &-img {
      display: block;
      max-width: 100%;
      height: auto;
    }
    &.before-contact-info {
      flex-grow: 0;
      @media (--viewport-ms-max) {
        flex-grow: 1;
      }
    }
  }

  &__contact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
    flex-grow: 1;
    .bodycopy-xs {
      margin-right: 10px;
      line-height: 11px;
      color: var(--color-accent-dark);
      a,
      a:visited,
      a:focus {
        color: var(--color-accent-dark);
      }
    }
    .bodycopy-xs + .bodycopy-xs {
      color: var(--color-secondary);
    }
    .tooltip {
      position: relative;
      top: 0;
      left: 0;
      z-index: 99999;
    }
    @media (--viewport-ms-max) {
      display: none;
    }
  }

  &__popups {
    @media (--viewport-sm-min) {
      margin-left: 30px;
    }

    @media (--viewport-md-min) {
      margin-left: 40px;
    }

    &-list {
      display: flex;

      @media (--viewport-xs-max) {
        justify-content: space-between;
      }
    }

    &-item {
      display: flex;
      align-items: center;

      @media (--viewport-ms-only) {
        margin-right: 60px;
      }

      @media (--viewport-sm-min) {
        position: relative;
        margin-right: 30px;
      }

      @media (--viewport-md-min) {
        margin-right: 40px;
      }

      &:last-child {
        margin-right: 0;
      }
      a.popup__trigger {
        padding-top: 2px;
        cursor: pointer;
      }
      .popup__trigger {
        font-size: 14px;
        @media (--viewport-ms-max) {
          font-size: 14px;
        } /*antes tamaño de fuente no definida para ninguna vista */
      }
      .googletranslate {
        margin: -1px 0;
      }
    }
  }

  &__search {
    margin-left: 40px;

    @media (--viewport-ms-only) {
      margin-left: 50px;
    }

    @media (--viewport-sm-only) {
      margin-left: 30px;
    }

    &-btn {
      position: relative;
      display: flex;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 40px;
        height: 40px;
        content: '';
      }

      &:hover,
      &:focus {
        .icon {
          fill: var(--color-accent-dark);
        }
      }

      .icon {
        @media (--viewport-ms-max) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__hamburger {
    position: relative;
    display: flex;
    margin-left: 40px;

    @media (--viewport-ms-min) {
      margin-left: 50px;
    }

    @media (--viewport-sm-min) {
      display: none;
    }
    .icon-menu {
      width: 25px;
      height: 25px;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      /* Nuevo diseño y nuevo icono a 16px
            width: 40px;
            height: 40px; */
      content: '';
    }

    &:hover,
    &:focus {
      .icon {
        fill: var(--color-accent-dark);
      }
    }
  }

  &__close {
    display: flex;

    @media (--viewport-ms-max) {
      position: absolute;
      top: 18px;
      right: 18px;
    }

    @media (--viewport-ms-only) {
      right: 28px;
    }

    @media (--viewport-sm-min) {
      display: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 40px;
      height: 40px;
      content: '';
    }

    &:hover,
    &:focus {
      .icon {
        fill: var(--color-accent-dark);
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__cta {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-end;

    @media (--viewport-sm-min) {
      margin-left: 30px;
    }

    @media (--viewport-md-min) {
      margin-left: 40px;
    }

    @media (--viewport-lg-min) {
      margin-left: 60px;
    }

    &-btn {
      /*min-width: 180px;*/

      .btn {
        width: 100%;
        transition: none;
        font-size: 16px; /*antes sin tamaño de fuente definido*/

        @media (--viewport-sm-min) {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 180px;
        }
        &.link {
          color: var(--color-text-light);
        }
      }

      &.sticky {
        display: flex;
        justify-content: flex-end;
        width: auto;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        .lightbox {
          display: none;
          @media (--viewport-ms-max) {
            display: inline-block;
          }
        }
        &.sticky-clone {
          /*min-width: 252px;*/
          background: none;
          left: auto;
          display: flex;
          width: auto;
          @media (--viewport-ms-max) {
            .btn {
              width: auto;
            }
          }
          &.visible {
            .lightbox {
              display: inline-block;
            }
            .btn {
              width: auto;
            }
          }
          /*right: calc(var(--gutter-width) - 1px);*/

          @media (--viewport-ms-min) {
            right: calc(var(--gutter-width-ms-min) + 1px);
          }

          @media (--viewport-sm-min) {
            right: calc(var(--gutter-width-ms-min) - 1px);
          }

          @media (--viewport-md-min) {
            /*right: calc(var(--gutter-width-ms-min) + 2px);*/
            right: auto;
            margin-left: -36px;
          }

          @media (--viewport-lg-min) {
            /*right:var(--gutter-width-lg-min);*/
            right: auto;
            margin-left: -36px;
          }
        }
        .btn {
          height: auto;
          font-size: 16px;
          padding-left: 20px;
          padding-right: 20px; /*antes 14px y sin padding left ni right definidos*/
          line-height: 24px;
          &:nth-child(3) {
            width: 180px;
          }
          @media (--viewport-ms-max) {
            width: auto;
            &:nth-child(2) {
            }
            &:nth-child(3) {
              width: auto;
              margin-left: 0;
            }
          }
        }
        @media (--viewport-ms-max) {
          display: flex;
        }
      }
    }
    &.hidden {
      display: none;

      &-xs {
        @media (--viewport-xs-max) {
          display: none;
        }

        &-ms {
          @media (--viewport-ms-max) {
            display: none;
          }
        }
      }

      &-ms {
        @media (--viewport-ms-only) {
          display: none;
        }

        &-min {
          @media (--viewport-ms-min) {
            display: none;
          }
        }
      }

      &-sm {
        @media (--viewport-sm-only) {
          display: none;
        }

        &-min {
          @media (--viewport-sm-min) {
            display: none;
          }
        }
      }
    }
  }

  &__body {
    &-wrapper {
      @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
        text-align: right;
        .header__cta-btn.sticky.stick-to-top {
          position: fixed;
          top: 0;
        }
      }

      @media (--viewport-sm-min) {
        position: relative;
        display: flex;
        justify-content: flex-end;
      }
    }
    .container__flex {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
    }
    &__nav {
      display: flex;
      align-items: center;

      &-list {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        gap: 30px;
      }
      &-item {
        display: flex;
        align-items: center;
        position: relative;
        .dropdown-menu {
          @media (--viewport-ms-max) {
            &__overlay,
            &--open {
              display: none;
            }
          }
        }
        &--notifications {
          .caption {
            /*var(--Text-color-text-subtle),*/
            color: #7d7d7d;
          }
          .dropdown-menu--open {
            display: block;
          }
        }
      }
      @media (--viewport-ms-max) {
        position: absolute;
        top: 30px;
        right: 40px;
        &-list {
          gap: 30px;
        }
        &-item {
          &:first-child {
            margin-right: 30px;
          }
          .btn_bottomless .icon {
            fill: var(--color-white);
          }
          &--profile {
            display: none;
          }
        }
      }
    }
    &.active {
      .header {
        &__body {
          &-wrapper {
            @media (--viewport-sm-min) {
              background-color: var(--color-primary);
            }
          }

          &-main {
            &-btn {
              @media (--viewport-sm-min) {
                color: var(--color-text);
              }

              .icon {
                @media (--viewport-sm-min) {
                  fill: var(--color-secondary);
                }
              }
            }
          }
        }
      }
    }

    &-main {
      @media (--viewport-ms-max) {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        /*background-color: var(--color-primary);*/
      }

      &-overlay {
        overflow: auto;
        /*flex-grow: 1;*/
        -ms-overflow-style: none;

        @media (--viewport-xs-max) {
          padding-left: 20px;
        }

        @media (--viewport-ms-max) {
          position: fixed;
          z-index: 11;
          top: 0;
          width: 100%;
          height: 100vh;
          transition: transform 0.5s;
          transform: translateX(100%);
          .header__body-main {
            background-color: var(--color-primary);
          }
        }

        @media (--viewport-ms-only) {
          padding-left: 92px;
        }

        &.mobile-menu-is-open {
          transform: translateX(0);
        }
      }

      &-content {
        @media (--viewport-xs-max) {
          padding: 20px 30px 30px;
        }

        @media (--viewport-ms-max) {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }

        @media (--viewport-ms-only) {
          padding: 56px 50px 56px 92px;
        }

        &-nav {
          /*flex-grow: 1;*/
        }
        &-btn {
          width: calc(100%);
          bottom: 0;
          @media (--viewport-xs-max) {
            width: calc(100%);
          }
        }
      }

      &-head {
        @media (--viewport-xs-max) {
          padding-right: 40px;
        }

        @media (--viewport-ms-max) {
          margin-bottom: 25px;
        }
      }
      &-profile {
        text-align: left;
        .accordion {
          &__item-description {
            ul {
              display: flex;
              flex-flow: column;
              gap: 24px;
              li {
                padding-left: 20px;
              }
            }
          }
        }
      }
      &-list {
        display: flex;
        justify-content: flex-end;
        margin-right: 114px;

        @media (--viewport-sm-max) {
          margin-right: 0;
        }

        @media (--viewport-ms-max) {
          flex-direction: column;
        }
      }

      &-item {
        border: none;

        @media (--viewport-sm-min) {
          margin-right: 30px;
        }

        @media (--viewport-lg-min) {
          margin-right: 40px;
        }

        &:first-child {
          .header {
            &__body {
              &-main {
                &-submenu {
                  @media (--viewport-ms-max) {
                    display: none;
                  }
                }
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &-link {
        &_trigger {
          button.popup__trigger {
            .icon {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              top: 4px;
              position: relative;
            }
          }
          &.active {
            .header__body-main-btn {
              .icon {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      &-btn {
        display: flex;
        align-items: center;
        font-family: 'Jornada Sans Book';
        font-size: 16px; /*antes 13px*/
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0;
        color: var(--color-accent); /* color enlaces menú */
        transition: all 0.3s;

        @media (--viewport-ms-max) {
          justify-content: space-between;
          width: 100%;
          padding: 15px 0;
          font-size: 16px;
          line-height: 26px;
        }

        @media (--viewport-sm-min) {
          position: relative;
          padding: 16px 0;
          /*color: var(--color-text-light);*/
          transition: all 0.1s;

          &::before {
            position: absolute;
            bottom: 11px;
            left: 0;
            display: block;
            width: 0;
            height: 1px;
            background-color: var(--color-accent);
            content: '';
            transition: all 0.1s;
          }

          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
            height: 100%;
            content: '';
          }
        }

        @media (--viewport-lg-min) {
          /*padding: 30px 0;*/

          &::before {
            /*bottom: 25px;*/
          }
        }

        &:hover,
        &:focus {
          color: var(--color-accent-dark) !important;

          &::before {
            background-color: var(--color-accent-dark) !important;
          }

          .icon {
            fill: var(--color-accent-dark) !important;
          }
        }

        &.active {
          @media (--viewport-sm-min) {
            &::before {
              width: 100%;
            }
          }

          .icon {
            @media (--viewport-sm-min) {
              transform: rotate(-180deg);
              margin-bottom: 0;
              fill: var(--color-secondary);
            }
          }
        }

        .icon {
          display: flex;
          margin: 0 0 4px 8px;
          transition: all 0.4s;

          @media (--viewport-sm-min) {
            transition: all 0.1s;
          }
        }
      }

      &-submenu {
        display: none;
        text-align: left;

        @media (--viewport-sm-min) {
          position: absolute;
          z-index: 10;
          top: 50px;
          left: 0;
          width: 100%;
          background-color: var(--color-primary);

          &::before,
          &::after {
            position: absolute;
            top: 0;
            display: block;
            height: 100%;
            background-color: var(--color-primary);
            content: '';

            @media (--viewport-sm-min) {
              width: calc(29px * 2);
            }

            @media (--viewport-md-min) {
              width: calc(30px * 2);
            }

            @media (--viewport-cont-no-pad-min) {
              width: calc((100vw - 1352px) / 2 + 30px);
            }

            @media (--viewport-lg-min) {
              width: calc((100vw - 1352px) / 2 + 50px);
            }
          }

          &::before {
            left: calc(-29px * 2);

            @media (--viewport-md-min) {
              left: calc(-30px * 2);
            }

            @media (--viewport-cont-no-pad-min) {
              left: calc(-1 * ((100vw - 1352px) / 2 + 30px));
            }

            @media (--viewport-lg-min) {
              left: calc(-1 * ((100vw - 1352px) / 2 + 50px));
            }
          }

          &::after {
            right: calc(-29px * 2);

            @media (--viewport-md-min) {
              right: calc(-30px * 2);
            }

            @media (--viewport-cont-no-pad-min) {
              right: calc(-1 * ((100vw - 1352px) / 2 + 30px));
            }

            @media (--viewport-lg-min) {
              right: calc(-1 * ((100vw - 1352px) / 2 + 50px));
            }
          }
        }

        @media (--viewport-lg-min) {
          top: 50px;
        }

        &.active {
          @media (--viewport-sm-min) {
            display: block;
          }
        }
        .header-submenu__sidebar-text {
          .title-m {
            font-size: 16px;
          } /*Antes esta clase no estaba definida*/
        }
      }

      &-popups {
        &-content {
          .header__body-main-submenu {
            margin-bottom: 40px;

            @media (--viewport-ms-min) {
              margin-bottom: 60px;
            }

            .popup {
              &__list {
                flex-wrap: wrap;
              }

              &__item {
                margin-right: 40px;
                margin-bottom: 10px;
              }

              &__link {
                display: block;
                padding-bottom: 5px;

                &::before {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: block;
                  width: 0;
                  height: 1px;
                  background-color: var(--color-accent);
                  content: '';
                  transition: all 0.1s;
                }

                &:hover,
                &:focus {
                  &::before {
                    width: 100%;
                    background-color: var(--color-accent-dark);
                  }
                }

                &_active {
                  &::before {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        &-contact-info {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          text-align: left;
          margin-bottom: 20px;
          margin-top: 60px;
          .title-m {
            color: var(--color-accent-dark);
            margin-bottom: 0;
            &::before {
              display: block;
              position: relative;
              bottom: 15px;
              left: 0;
              width: 60px;
              height: 3px;
              background-color: var(--color-accent-dark);
              content: '';
            }
          }
          .tel {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .bodycopy-m {
              color: var(--color-secondary);
              margin-bottom: 0;
              margin-top: 20px;
              &::before {
                display: none;
              }
              a,
              a:visited,
              a:focus {
                color: var(--color-secondary);
              }
            }
            .flag {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              margin-bottom: 6px;
            }
          }
          .bodycopy-s {
            color: var(--color-grey2);
          }
          .bodycopy-s + .bodycopy-s {
            color: var(--color-disabled);
          }
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: column;
          margin: 40px 0 0;

          @media (--viewport-ms-min) {
            justify-content: flex-start;
          }
        }

        &-item {
          margin-right: 40px;
          text-align: left;
          padding: 15px 0;

          @media (--viewport-ms-min) {
            margin-right: 60px;
          }

          &:last-child {
            margin-right: 0;
          }

          &.for-gt {
            .googletranslate {
              margin: -1px 0;
            }
          }

          .header__body-main-link {
            &.active {
              .popup {
                &__trigger {
                  &::before {
                    width: 100%;
                  }
                }
              }
            }
          }

          .popup {
            &__trigger {
              display: block;
              position: relative;
              font-size: 16px; /*antes sin tamaño de fuente definido*/

              &::before {
                position: absolute;
                bottom: -5px;
                left: 0;
                display: block;
                width: 0;
                height: 1px;
                background-color: var(--color-accent);
                content: '';
                transition: all 0.1s;
              }

              &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 100%;
                height: 40px;
                content: '';
              }

              &:hover,
              &:focus {
                &::before {
                  width: 100%;
                  background-color: var(--color-accent-dark);
                }
              }
            }
          }
        }
      }

      &-footer {
        @media (--viewport-ms-max) {
          margin-top: 0;
          ul.header__body-main-popups-list {
            /*li:last-child{margin-top:60px;}
                        li:last-child.for-gt{margin-top:0;}*/
          }
        }

        .header {
          &__triggers {
            @media (--viewport-sm-min) {
              display: none;
            }
          }
        }
      }
    }
  }

  &.relative {
    position: relative;
    @media (--viewport-ms-max) {
      position: static;
      margin-bottom: 0;
    }
  }
}

.header + .splitter_header-before {
  @media (--viewport-lg-min) {
    padding-top: 260px;
  }
}
