.singleAccordion {
    &__trigger {
        position: relative;
        width: 100%;
        padding: 15px 0;
        text-align: left;

        

        &-title{
            font-size: 20px;
            color: var(--color-text);
            text-decoration: none;

            @media (--viewport-ms-max) {
                font-size: 18px;
            }

            padding-left: 1.5rem;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            display: block;
            width: 12px;
            height: 12px;
            background: url("/dist/images/red-arrow-down.svg") center / contain no-repeat;
            content: '';
            transition: all 0.4s;
        }

        &.active {
            &::before {
                transform: translate(0, -50%) rotate(-180deg);
            }
        }
    }

    &__item {
       
        &-content {
            display: none;

            @media (--viewport-ms-max) {
                background-color: #F5F5F5;
            }

            &-wrapper {
                padding-bottom: 20px;
            }
        }

        &-description{
            font-size: 16px;
                color: #707070;
                padding:1rem 1.5rem;
        }
    }
}
