.interest-rates {
    &__head {
        margin-bottom: 40px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 60px;
        }
    }
    &__pretitle {
        margin-top: 40px;
    }

    &__lightbox {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }

    &__tiles {
        margin-bottom: -40px;

        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }
    }

    &__tile-interest-rate {
        height: 100%;
        margin-bottom: 40px;
        &.with-pagination{
            @media (--viewport-xs-max) {
                margin-bottom:0;
            }
        }
    }

    &__condition {
        
        &-text {
            margin-top: 40px;

            p {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }

    &__legal {

        &-item {
            margin-bottom: 40px;

            .title-l {
                margin-bottom: 15px;
            }
        }

        &-splitter {
            display: none;
            
            &.active {
                display: block;
            }
        }

    }

    .swiper-container{
        @media (--viewport-ms-max) {
            padding-bottom:40px;
        }
    }
    .swiper-slide{
        /*background-color:var(--color-accent-light);*/
        height: auto;
    }
    .swiper-navigation{
        display: none;
        max-width: 267px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        justify-content: center;
        align-items: center;

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;

            &::after {
                display: none;
            }

            .icon {
                height: 20px;
                width: 28px;
                fill:var(--color-accent)
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 0;
            width: 28px;
            height: 20px;
            margin-top: 0;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }

        @media (--viewport-ms-max) {
            display:flex;
        }
        
    }
}

