.call-form{
    .consultor{color: var(--color-primary-brand);}
    &__head{
        text-align: center;
        .title-l, .bodycopy-m{margin-bottom: 34px;}
        &.left{
            text-align: left;  
        }
    }
    &__form{
        .form-btn{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            @media (--viewport-ms-min) {
                margin-top: -3px;
            }
        }
        .form-textarea{
            .textarea-field{
                @media (--viewport-ms-min) {
                    height: 270px;
                }
            }
        }
    }
}