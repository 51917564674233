:root {
    --offset: -40px;
    --offset-xs: -60px;
} /* ¿PARA QUE ESTÁ ESTO AQUÍ? NO DEBERÍA ESTAR DENTRO DE UN COMPONENTE - REVISAR (CA) */

.ucipt-slide {
    min-height: 524px;
    margin-bottom: 40px;

    @media (--viewport-xs-max) {
        margin-bottom: 60px;
        min-height: 534px;
    }

    &__no-margin {
        margin-bottom: 0;

        &::before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 2;
            content: "";
            opacity: 0.6;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
        }

        @media (--viewport-xs-max) {
            margin-bottom: 0;
        }
        &.no-shadow{
            &::before {
                opacity: 0;
            }
        }
    }

    &__text-holder {
        bottom: var(--offset);
        width: 100%;
        z-index: 3;
        position: absolute;

        .col {
            z-index: 1;
        }

        @media (--viewport-md-min) {
            .col-sm-ml {
                margin-left: auto;
            }

            .col-sm-offset-right-1 {
                margin-right: 8.33333333%;
            }
        }

        @media (--viewport-sm-max) {
            padding-left: 5%;
            padding-right: 5%;
        }

        @media (--viewport-xs-max) {
            bottom: -60px;
        }
    }

    &__no-bottom {
        border-bottom: solid 40px var(--color-white);

        @media (--viewport-xs-max) {
            border-bottom: solid 60px var(--color-white);
        }
    }
}
