.blog-categories {
    overflow-x: scroll;
    scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */

    display: flex;
    flex-flow: column wrap;
   
   
    margin-right: -19px;

    &::-webkit-scrollbar {
        display: none; /* Hide the scrollbar in Chromium */
    }

    @media (--viewport-ms-min) {
        margin-right: -31px;
    }

    @media (--viewport-md-min) {
        margin-right: -32px;
    }

    @media (--viewport-lg-min) {
        margin-right: -50px;
    }

    &_hidden {
        position: absolute;
        top: 0;
        left: -9999px;
    }

    &.sticky-clone {
        padding: 19px 0 18px;

        @media (--viewport-sm-min) {
            padding: 39px 0 27px;
        }

        .blog-categories {
            &__wrapper {
                margin: 0 auto;
            }

            &__item {
                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                }
            }
        }
    }

    &__wrapper {
        flex-shrink: 0;
    }

    &__list {
        display: inline-flex;
        flex-wrap: wrap;
      row-gap: 10px;
      
    }

    &__item {
        flex-shrink: 0;
        margin-right: 25px;
        

        @media (--viewport-ms-min) {
            margin-right: 60px;
        }

        @media (--viewport-sm-min) {
            margin-right: 171.67px;
        }

        &:last-child {
            padding-right: 19px;
            margin-right: 0;

            @media (--viewport-ms-min) {
                padding-right: 31px;
            }

            @media (--viewport-md-min) {
                padding-right: 32px;
            }

            @media (--viewport-lg-min) {
                padding-right: 50px;
            }
        }

        &.active {
            .link {
                &:hover,
                &:focus {
                    color: var(--color-accent);
                }

                span {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}
