.accordion {
    &__trigger {
        position: relative;
        width: 100%;
        padding: 20px 20px 20px 0;
        text-align: left;

        .icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            display: block;
            width: 12px;
            height: 12px;
            transition: all 0.4s;
        }

        &.active {
            .icon {
                transform: translate(0, -50%) rotate(-180deg);
            }
        }
    }

    &__item {
        border-bottom: 1px solid var(--color-secondary-light);

        &:first-child {
            .accordion__item-content {
                display: block;
            }
        }

        &-content {
            display: none;

            &-wrapper {
                padding-bottom: 20px;
            }
        }
    }
    ol{
        li{float:left;width: 100%;list-style: decimal; list-style-position: inside; padding-top: 20px;
            &::marker{padding-top: 3px;}
            .accordion__trigger{
                width: calc(100% - 35px);
                float: right;
                padding-top: 0;
            }
            .accordion__item-content{
                float: left;
                width: 100%;
            }
            ul{
                li{float:none; list-style: none; padding-top: 0; width:50%;
                    @media (--viewport-ms-max) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
