.glossary-list {
    display: none;

    &.visible {
        display: block;
    }

    &__letter {
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    &__item {
        margin-bottom: 40px;

        @media (--viewport-sm-min) {
            margin-bottom: 30px;
        }
    }

    &__link {
        @media (--viewport-ms-max) {
            margin-bottom: 40px;
        }
    }
}
