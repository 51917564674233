.calculator-form-result {
    width: 100%;display: flex;flex-direction: column;justify-content: center;
    .gastos{
    
        &__fieldset {
            display: flex;flex-direction: row;justify-content:center;align-items:flex-end;
            position: relative;
            @media (--viewport-xs-max) {
                display:block; text-align: center; margin-bottom: 10px;
            }
            .tooltip-label{
                @media (--viewport-xs-max) {
                    justify-content:center;
                    margin-bottom:0;
                }
                .link{
                    position: relative;
                    top: -4px;
                    padding-left: 10px;
                    color: var(--color-accent-dark);
                    span{
                        text-decoration: underline;
                    }
                }
            }
        }
    
        &__input {
            padding: 10px 10px 14px;
            text-align: right;
            font-family: 'Jornada News Book';
            font-size: 26px;
            font-weight: normal;
            line-height: 1.2;
            letter-spacing: 0;
            border: 0;
            color: var(--color-accent);
            transition: border-color 0.3s;
    
            @media (--viewport-sm-min) {
                font-size: 30px;
            }
    
            @media (--viewport-md-min) {
                font-size: 40px;
            }

            @media (--viewport-sm-max) {
                padding-bottom:17px;
            }

            @media (--viewport-ms-max) {
                padding-bottom:19px;
            }

            @media (--viewport-xs-max) {
                padding: 0 5px 0 10px;
            }
    
            &:hover,
            &:focus {
                border-color: var(--color-secondary);
            }
        }
        &__label {
            transform: translate(0, -50%);
            padding-right: 10px;
            padding-bottom:8px;
            white-space: nowrap;
            .text-label-m{font-size:26px;font-family: var(--base-font-family);}

            @media (--viewport-ms-min) {
                padding-bottom:9.5px;
            }
            @media (--viewport-sm-min) {
                padding-bottom:9.5px;
            }
            @media (--viewport-md-min) {
                padding-bottom:10px;
            }
            @media (--viewport-lg-min) {
                padding-bottom:8px;
            }
            @media (--viewport-xs-max) {
                display:inline-flex;
                padding-bottom:0;
                position: relative;
                top: 10px;
            }
        }
    }
    .cuota{align-self: center;min-width: 30%;padding: 0;
        @media (--viewport-xs-max) {min-width:100%;flex-basis:auto;}
    
        &__fieldset {
            display: flex;flex-direction: column;justify-content:center;align-items:center;
            position: relative; padding: 10px 30px 15px;
            border: 1px solid var(--color-secondary-light);
            @media (--viewport-xs-max){padding-bottom: 5px;}
            .tooltip-label{margin-bottom: 0; height: auto;}
            .wrap{display: flex;flex-direction: row;
                .cuota__input {
                    padding: 10px 10px 5px 10px;
                    text-align: right;
                    font-family: 'Jornada News Book';
                    font-size: 36px;
                    font-weight: normal;
                    /*line-height: 1.2;*/
                    letter-spacing: 0;
                    border: 0;
                    color: var(--color-accent);
                    transition: border-color 0.3s;
            
                    @media (--viewport-sm-min) {
                        font-size: 40px;
                    }
            
                    @media (--viewport-md-min) {
                        font-size: 50px;
                    }
                    @media (--viewport-xs-max) {
                        font-size: 46px;
                    }
            
                    &:hover,
                    &:focus {
                        border-color: var(--color-secondary);
                    }
                }
                .cuota__label {
                    transform: translate(0, 43%);
                    padding-right: 10px;
                    white-space: nowrap;
                    .text-label-m{font-size: 22px;}
                    @media (--viewport-sm-max) {
                        transform: translate(0, 34%);
                    }
                    @media (--viewport-ms-max) {
                        transform: translate(0, 32%);
                    }
                    @media (--viewport-xs-max) {
                        transform: translate(0, 44.5%);
                    }
                }
                .asterisk{
                    color: var(--color-accent-dark);
                    font-size: 60px;
                    line-height:1.7;
                    @media (--viewport-ms-max) {
                        line-height:1.60;
                    }
                    @media (--viewport-xs-max) {
                        font-size: 50px;
                        line-height:1.85;
                    }
                }
            }
        }
        .link-cuota{margin-top: -35px;
            .bodycopy__link{color: var(--color-accent-dark); 
                span{text-decoration: underline;
                    &:after{display:none;}
                }
            }
            @media (--viewport-xs-max) {margin-top: -30px;}
        }

        &.alert{
            .cuota__fieldset{
                border-color: var(--color-danger);
                .wrap{
                    .cuota__input{
                        color: var(--color-danger);
                    }
                    .cuota__label{
                        .text-label-m{color: var(--color-danger);}
                        
                    }
                }
            }

        }
    }
    .nota{ text-align: center; margin-top: 20px; 
        .bodycopy-s{color: var(--color-secondary);}
    }
}