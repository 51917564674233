.link {
    display: inline-block;
    outline: none;
    text-decoration: none;
    font-family: 'Jornada Sans Medium';
    font-size: 13px;
    font-weight: normal;
    line-height: 23px;
    letter-spacing: 0;
    color: var(--color-text);
    cursor: pointer;

    span {
        position: relative;
        display: inline-block;
        padding-bottom: 5px;
        transition: all 0.3s;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 30px;
            height: 1px;
            background-color: var(--color-accent);
            transition: all 0.3s;
            content: '';
        }
    }

    &:hover,
    &:focus {
        span {
            &::after {
                width: 100%;
            }
        }
    }

    &_simple {

        &:hover,
        &:focus {
            color: var(--color-accent);
        }

        span {
            padding-bottom: 0;

            &::after {
                content: none;
            }
        }
    }

    &_inline {
        
        color: var(--color-accent-dark);
        margin: 0 0 0 5px;
        line-height: 100%;

        span {
            text-decoration: underline;
            padding-bottom: 0;
            transition: none;
            position: relative;
            bottom:-1px;

            &::after {
                width: 0;
                height: 0;
                transition: none;
                content: none;
            }
        }
    }

    &_has-icon {
        position: relative;

        .icon {
            position: absolute;
            top: 5px;
            fill: var(--color-accent);
        }

        &_before {
            padding-left: 22px;

            .icon-before {
                left: 0;
            }
        }

        &_after {
            padding-right: 22px;

            .icon-after {
                right: 0;
            }
        }
    }

    &_large {
        font-family: 'Jornada Sans Book';
        font-size: 16px;
        line-height: 26px;
    }

    &_detailedFooterLink {
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 17px;

    }

    &_largeBlog {
        font-family: 'Jornada Sans Medium';
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
    }

    &_titleAsLink {
        font-family: 'Jornada Sans Book';
        font-size: 18px;
        font-weight: normal;
        line-height: 28px;
        letter-spacing: 0;
        color: var(--color-text);
    }

    &_legalLink {
        font-family: 'Jornada Sans Book';
        font-size: 12px;
        line-height: 14px;

    }

    &_color {
        &_light {
            color: var(--color-text-light);

            span {
                &::after {
                    background-color: var(--color-text-light);
                }
            }
        }
    }

    &_submenu {
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 17px;
      
        &:hover{border-bottom: 1px solid var(--color-secondary);}
        
        span {
            padding-bottom: 0;
            &::after {
                content: none;
            }
        }
    }

    &_hidden-line {
        span {
            padding-bottom: 0;

            @media (--viewport-sm-min) {
                padding-bottom: 10px;
            }

            &::after {
                width: 0;
            }
        }
    }

    &_continue {
        .icon {
            transform: rotate(180deg);
        }
    }

    &_transparent {
        opacity: 0.3;
        transition: opacity 0.3s;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    &_not-a-link {
        span {
            padding-bottom: 0;

            &::after {
                content: none;
            }
        }
    }

    &_second-link {
        &::before {
            color: var(--color-text);
            content: '/';
        }
    }

    &.btn {
        color: inherit;
    }
}