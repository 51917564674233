.advantage {
    margin-bottom: 30px;

    @media (--viewport-ms-only) {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    &__icon {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        @media (--viewport-ms-only) {
            margin: 0 40px 0 0;
        }

        .icon {
            width: 54px;
            height: 54px;
        }
    }

    &__bodycopy {
        text-align: center;

        @media (--viewport-ms-only) {
            text-align: left;
        }
    }
}
