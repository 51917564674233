.menu-tab {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 8px;

  @media (--viewport-ms-max) {
    &.fixedOnScroll {
      background-color: var(--color-white);
      position: -webkit-sticky;
      position: sticky;
      top: var(--header-height, 0);
      z-index: 2;
    }
  }

  &-item {
    color: #191919;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0 32px;
    border: none;
    outline: none;
    background: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 1;
    width: auto;
    border-bottom: 1px solid var(--color-gray-80);

    .icon {
      fill: var(--color-gray-80);
    }

    &.active {
      border-bottom: 1px solid var(--color-secondary-brand);
      color: var(--color-secondary-brand);

      .menu-tab-indicator {
        border-bottom: 1px solid var(--color-secondary-brand);
        color: var(--color-secondary-brand);
      }

      .icon {
        fill: var(--color-secondary-brand);
      }
    }

    &--disabled {
      border-bottom: 1px solid var(--color-gray-40);
      color: var(--color-gray-40);
      pointer-events: none;

      .menu-tab-indicator {
        border-bottom: 1px solid var(--color-gray-40);
        color: var(--color-gray-40);
      }

      .icon {
        fill: var(--color-gray-40);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    &--underline {
      border-color: var(--color-text-primary);
      border-top-width: 0;
      align-self: flex-end;
      box-sizing: border-box;
      width: 100%;
      border-top-style: solid;
    }

    &--icon {
      color: var(--color-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }

  }

  &-indicator {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    border-bottom: 1px solid #191919;

    &--active {
      border-bottom: 1px solid #e4002b;
      color: #e4002b;
    }

    &--disabled {
      border-bottom: 1px solid #cdcdcd;
      color: #cdcdcd;
      pointer-events: none;
    }
  }

  &-scroller {
    overflow: hidden;
    width: 100%;

    &__scroll {
      display: flex;

      &-content {
        position: relative;
        display: flex;
        -webkit-transform: none;
        transform: none;
        will-change: transform;
        flex: 1 0 auto;
        @media (min-width: 1023px) and (max-width: 1078px){
          flex:auto;/*para evitar un bug a 1024*/
        }
        align-items: center;
        height: 50px;
        gap: 8px;
      }

      &--hug {
        &-start {
          .menu-tab-scroller__scroll-content {
            justify-content: flex-start;
          }
        }

        &-center {
          .menu-tab-scroller__scroll-content {
            justify-content: center;
          }
        }
      }

      &--fixed {
        overflow: hidden;
        @media (--viewport-ms-max) {
          width: calc(100% + 60px);
          padding: 0 30px;
          margin: 0 -30px;
          background-color: var(--color-white);
        }
        @media (--viewport-xs-max) {
          width: calc(100% + 40px);
          padding: 0 20px;
          margin: 0 -20px;
        }

        .menu-tab-scroller__scroll-content {
          justify-content: flex-start;
          flex-flow: nowrap;
        }

        .menu-tab-item {
          min-width: 90px;
          flex: 1 0 auto;
          width: auto;
        }

        &.swiper-container {
          overflow: visible;

          &+.swiper-navigation {
            padding: 17px 0;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
            justify-content: space-between;
            align-items: center;

            .swiper-button-prev,
            .swiper-container-rtl .swiper-button-next {
              background: var(--color-white);
            }

            .swiper-button-next,
            .swiper-container-rtl .swiper-button-prev {
              background: var(--color-white);
            }
          }
        }

        .swiper-wrapper {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  &-content {
    &__item {
      display: none;
      flex-flow: column;
      width: 100%;

      &.active {
        display: flex;
      }
    }  
    .splitter {
      .container {
        margin: 0;
        padding: 0;

        .splitter__wrapper {
          margin: 0;
          padding: 0;
          background: none;
        }
      }
    }
  }

  /* Swiper Variation*/
  .swiper {
    &-pagination {
      margin: 0 50px;

      &-bullet {
        width: 10px;
        height: 10px;
        margin-left: 5px;
        margin-right: 5px;
      }

      &-bullet-active {
        background: var(--color-accent);
      }
    }

    &-pagination,
    &-button-prev,
    &-button-next {
      position: relative;

      &::after {
        display: none;
      }

      .icon {
        height: 20px;
        width: 28px;
        fill: var(--color-accent);
      }
    }

    &-button-next,
    &-button-prev {
      top: 0;
      width: 50px;
      height: 50px;
      margin-top: 0;
      left: 0;

      &.swiper-button-disabled,
      &.swiper-button-disabled {
        display: none;
      }
    }
  }

  &.isCard {
    height: auto;

    .menu-tab-scroller__scroll-content {
      height: auto;
      grid-gap: 0;

      button {
        height: 100%;

        .card-contact {
          width: 100%;
          border: 1px solid var(--color-text-primary);
          padding: 40px 48px;

          &.inactive {
            border: solid 1px var(--color-border);
          }
        }
      }

      @media (--viewport-ms-max) {
        gap: 16px;
        flex-direction: column;
        flex: auto;

        button {
          flex-basis: fit-content;
          max-width: 100%;
          width: 100%;

          .card-contact {
            height: auto;

            .card-contact__title {
              span {
                display: none;
              }

              .title-m {
                font: 16px/24px 'Jornada Sans Medium';
              }
            }
          }
        }
      }
    }

    @media (--viewport-ms-max) {
      &.fixedOnScroll {
        .menu-tab-scroller {
          overflow: visible;
        }

        .menu-tab-scroller__scroll--fixed {
          overflow: visible;
        }

        .menu-tab-scroller__scroll-content {
          overflow-x: scroll;
          scrollbar-width: none;

          @media (--viewport-sm-max) {
            gap: 0;
            flex-direction: row;
            flex: auto;

            button {
              /*flex-basis: fit-content;
                        max-width: initial;
                        width: initial;*/
              max-width: 100%;
              width: 100%;
              flex: auto;

              .card-contact {
                height: auto;
                padding: 16px;

                .card-contact__content {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .card-contact__link {
                    display: none;
                  }

                  .card-contact__pic {
                    margin-bottom: 0;
                  }

                  .card-contact__title {
                    margin-bottom: 0;

                    span {
                      display: none;
                    }

                    .title-m {
                      font: 16px 'Jornada Sans Medium';
                      line-height: 0;
                      padding-top: 3px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}