.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;

  .googletranslate {
    margin: 40px 20px 0 110px;

    .goog-te-gadget-simple {
      padding-bottom: 1px;
      border-color: var(--color-accent-dark);
      padding-left: 5px;
      color: var(--color-accent);
      font-family: var(--base-font-family);
    }

    .goog-te-menu-value {
      color: var(--color-accent);
      bottom: -1px;
      position: relative;
    }

    @media (--viewport-md-max) {
      margin-top: 10px;
    }

    @media (--viewport-sm-max) {
      margin: 0 20px 0 80px;
    }

    @media (--viewport-ms-max) {
      margin-top: 40px;
      margin-left: 90px;
    }

    @media (--viewport-xs-max) {
      margin-left: 0;
    }
  }

  &.is-experience-editor {
    margin-top: 89px;
    position: static !important;
  }

  @media (--viewport-sm-min) {
    z-index: 12;
  }

  @media (--viewport-ms-max) {
    position: relative; /* si se pone static el z-index no funciona y da problemas en mobile (CA)*/
    margin-bottom: -105px;
  }

  &.active {
    z-index: 12;
    background-color: var(--color-primary);
  }

  &.visible {
    position: fixed;
    z-index: 13;

    /*  @media (--viewport-sm-min) {
            background-color: var(--color-primary);
        } */

    .header-fixed {
      &__body {
        &-main {
          &-btn {
            @media (--viewport-sm-min) {
              color: var(--color-text);
            }

            .icon {
              @media (--viewport-sm-min) {
                fill: var(--color-secondary);
              }
            }
          }
        }
      }
    }
  }

  &_notFixed {
    /* the position property is set on html, here we make  CTAs to not go down with the scroll */
    .header-fixed__cta-btn.sticky.sticky-clone {
      display: none;
    }
  }

  /* REDUCED HEADER STYLIZATIONS */
  &_reduced {
    .header-fixed {
      &__info {
        &-infoText {
          display: flex;

          @media (--viewport-ms-max) {
            display: none;
          }
        }

        &-infoIcon {
          &-infoBtn {
            @media (--viewport-ms-max) {
              display: none;
            }

            .icon {
              @media (--viewport-ms-max) {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }

      /* &__logo{
                &-img {
                    @media(--viewport-xs-max){
                        width:105px;
                        height:auto;
                    }
                }
            } */

      &__popups {
        &-list {
          li {
            /*visibility: hidden; ESTO SE USABA PARA OCULTAR EL MENU SUPERIOR Y LO DE DEBAJO PARA MOSTRAR SOLO EL ULTIMO ITEM: IDIOMA
                        @media(--viewport-ms-min) {
                            &:last-child{
                                visibility: visible;
                                li{
                                    visibility: visible;
                                }
                            }
                        }*/
          }

          /*@media(--viewport-sm-only) {
                        display: none;
                    }*/
        }
      }

      &__search {
        visibility: hidden;

        @media (--viewport-ms-max) {
          display: none;
        }
      }

      &__hamburger {
        @media (--viewport-ms-max) {
          display: flex;
        }
      }

      &__body {
        background-color: transparent;

        &-main {
          /* ESTO SERVIA PARA OCULTAR LOS ITEMS DEL MENU SUPERIOR DESKTOP EN EL MENU MOBILE 
                    &-list {
                        display: none;
                    }*/
          /* ESTO SERVIA PARA OCULTAR IDIOMA EN EL MENU MOBILE 
                    @media(--viewport-ms-max) {
                        &-popups {
                            &-lang {
                                display: none;
                            }
                        }
                    }*/
        }
      }
    }
  }

  &_reducedWithSearchButton {
    .header-fixed {
      &__search {
        visibility: visible;

        @media (--viewport-ms-max) {
          display: flex;
          margin-right: 35px;
        }
      }
    }
  }

  &_reducedWithPopupsList {
    .header-fixed {
      &__popups {
        &-list {
          visibility: visible;

          /* DISPLAY AS FLEX SO IT DOESN'T DISAPPEAR BY REDUCED STYLES */
          @media (--viewport-sm-only) {
            display: flex;
          }
        }
      }
    }
  }

  /* REDUCED VARIANT FOR SIMULATOR PAGE - CTAS ARE INSIDE MOBILE MENU */
  &_reducedForSimulatorPage {
    .header-fixed {
      &__cta {
        display: none;

        /* ONLY VISIBLE IN DESKTOP VIEWS, BECAUSE MOBILE MENU WILL HAVE THEM INSIDE */
        @media (--viewport-sm-min) {
          display: flex;
        }
      }

      &__body {
        &-main {
          &-popups {
            &-list {
              display: none;
            }
          }

          &-footer {
            &-ctaRow {
              display: flex;
            }
          }
        }
      }
    }
  }

  &_reducedWithBodyList {
    .header-fixed {
      &__body {
        @media (--viewport-sm-min) {
          background-color: var(--color-primary);
        }

        &-main {
          &-list {
            display: flex;
          }
        }
      }
    }
  }

  &__wrapper {
    position: relative;
  }

  /* upper header bar */
  &__head {
    display: flex;
    align-items: center;
    background-color: var(--color-primary);
    /*  border-bottom: 1px solid hsl(0deg 0% 51% / 25%);*/
    min-height: 45px;

    /* wrapper organizes logo, main and upper CTA */
    &-wrapper {
      position: relative;
      display: flex;
      background-color: var(--color-primary);

      @media (--viewport-ms-max) {
        height: 65px;
      }

      @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
        padding: 0 20px;
      }
    }

    &-main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;

      /*padding: 28px 0 26px;*/

      /* @media (--viewport-ms-min) {
                padding: 30px 0;
            }

            @media (--viewport-sm-min) {
                padding: 16px 0;
            }*/

      /*@media (--viewport-lg-min) {
                padding: 30px 0;
            }*/

      .header-fixed {
        &__socials,
        &__triggers {
          @media (--viewport-ms-max) {
            display: none;
          }
        }
      }
    }
  }

  &__logoElement {
    display: flex;
    flex-grow: 1;

    &-link {
      display: block;
      max-width: 200px;
      transition: all 0.3s;

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }

    &-img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__info {
    display: none;
    flex-flow: row;
    margin-left: 40px;

    @media (--viewport-sm-min) {
      display: flex;
    }

    /* styles for informative texts related to contacts */
    &-infoText {
      display: flex;
      flex-flow: row;
      align-items: center;

      &-textOne {
        display: block;
        color: #00635b;
        font-size: 14px;
        font-family: 'Jornada Sans Medium';
        margin-right: 5px;

        @media (--viewport-sm-only) {
          display: none;
        }
      }

      &-textTwo {
        display: block;
        color: var(--color-accent);
        font-size: 14px;
        font-family: 'Jornada Sans Medium';
        margin-right: 10px;

        @media (--viewport-sm-only) {
          display: none;
        }
      }

      &-textThree {
        display: block;
        color: var(--color-secondary);
        font-size: 14px;
        font-family: 'Jornada Sans Book';
        margin-right: 10px;
      }
    }

    /* icon of the phone contacts popup */
    &-infoIcon {
      position: relative;

      &-infoBtn {
        display: block;

        @media (--viewport-ms-max) {
          display: none;
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 40px;
          height: 40px;
          content: '';
        }

        &:hover,
        &:focus {
          .icon {
            fill: var(--color-accent-dark);
          }
        }

        .icon {
          width: 14px;
          height: 14px;

          &:hover {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    /* hidden in mobile viewports */
    &.hidden {
      display: none;

      &-xs {
        @media (--viewport-xs-max) {
          display: none;
        }

        &-ms {
          @media (--viewport-ms-max) {
            display: none;
          }
        }
      }
    }
  }

  /* List of popup link items present in header-fixed__head */
  &__popups {
    @media (--viewport-sm-min) {
      margin-left: 10px;
    }

    &-list {
      display: flex;

      @media (--viewport-xs-max) {
        justify-content: space-between;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      text-align: center;

      @media (--viewport-ms-only) {
        margin-right: 60px;
      }

      @media (--viewport-sm-only) {
        margin-right: 20px;
      }

      @media (--viewport-sm-min) {
        position: relative;
        margin-right: 30px;

        @media (--viewport-sm-max) {
          margin-right: 15px;
        }
      }

      @media (--viewport-md-min) {
        margin-right: 40px;
      }

      &:last-child {
        @media (--viewport-sm-min) {
          margin-right: 20px;
        }

        @media (--viewport-md-min) {
          margin-right: 30px;
        }

        @media (--viewport-lg-min) {
          margin-right: 40px;
        }
      }

      a.popup__trigger {
        padding-top: 2px;

        &:hover,
        &:focus,
        &.active {
          color: var(--color-accent);
        }
      }

      /* defines style for links in header-fixed_popups list. Sames as popup__trigger in header.css  */
      .popup__trigger {
        font-size: 14px;
      }

      .popup__trigger {
        font-size: 14px;

        &.active {
          color: var(--color-accent);
        }
      }
    }
  }

  &__search {
    display: block;
    margin-left: 40px;
    margin-right: 40px;

    @media (--viewport-ms-only) {
      margin-left: 50px;
    }

    @media (--viewport-sm-only) {
      margin-left: 30px;
    }

    &-btn {
      position: relative;
      display: flex;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 40px;
        height: 40px;
        content: '';
      }

      &:hover,
      &:focus {
        .icon {
          fill: var(--color-accent);
        }
      }

      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  /* hamburguer button for Mobile viewports, same as Header.css*/
  &__hamburger {
    position: relative;
    display: flex;

    @media (--viewport-ms-min) {
      margin-left: 1px;
    }

    @media (--viewport-sm-min) {
      display: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 40px;
      height: 40px;
      content: '';
    }

    &:hover,
    &:focus {
      .icon {
        fill: var(--color-accent);
      }
    }

    .icon {
      width: 25px;
      height: 25px;
    }
  }

  /* close button for Mobile viewports, same as Header.css */
  &__close {
    display: flex;

    @media (--viewport-ms-max) {
      position: absolute;
      top: 28px;
      right: 20px;
    }

    @media (--viewport-ms-only) {
      right: 28px;
    }

    @media (--viewport-sm-min) {
      display: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 40px;
      height: 40px;
      content: '';
    }

    &:hover,
    &:focus {
      .icon {
        fill: var(--color-accent);
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  /* CTA buttons used in header-fixed__head & header-fixed__body */
  &__cta {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-end;

    &-btn {
      .btn {
        width: 180px;
        transition: none;
        font-size: 16px;
        padding: 13px 25px;
        /*antes sin tamaño de fuente definido*/

        @media (--viewport-sm-min) {
          display: flex;
          justify-content: center;
          height: 100%;
          width: 180px;
        }
      }

      &.sticky {
        display: flex;
        justify-content: flex-end;
        width: auto;

        .btn {
          display: none;

          @media (--viewport-ms-max) {
            display: inline-block;
          }
        }

        &.sticky-clone {
          /*min-width: 252px;*/
          background: none;
          left: auto;
          display: flex;
          width: auto;
          flex-flow: column;

          @media (--viewport-ms-max) {
            flex-flow: row;

            .btn {
              width: auto;
            }
          }

          &.visible {
            .lightbox {
              display: inline-block;
            }

            .btn {
              display: inline-block;
            }
          }

          /*right: calc(var(--gutter-width) - 1px);*/

          @media (--viewport-ms-min) {
            right: calc(var(--gutter-width-ms-min) + 1px);
          }

          @media (--viewport-sm-min) {
            right: calc(var(--gutter-width-ms-min) - 1px);
          }

          @media (--viewport-md-min) {
            /*right: calc(var(--gutter-width-ms-min) + 2px);*/
            right: auto;
            margin-left: -36px;
          }

          @media (--viewport-lg-min) {
            /*right:var(--gutter-width-lg-min);*/
            right: auto;
            margin-left: -36px;
          }
        }

        .btn {
          height: auto;
          font-size: 16px;
          padding-left: 20px;
          padding-right: 20px;
          /*antes 14px y sin padding left ni right definidos*/
          line-height: 24px;

          &:nth-child(3) {
            width: 180px;
          }

          @media (--viewport-ms-max) {
            width: auto;

            &:nth-child(2) {
            }

            &:nth-child(3) {
              width: auto;
              margin-left: 0;
            }
          }
        }

        @media (--viewport-ms-max) {
          display: flex;
        }
      }
    }

    &.hidden {
      display: none;

      &-xs {
        @media (--viewport-xs-max) {
          display: none;
        }

        &-ms {
          @media (--viewport-ms-max) {
            display: none;
          }
        }
      }

      &-ms {
        @media (--viewport-ms-only) {
          display: none;
        }

        &-min {
          @media (--viewport-ms-min) {
            display: none;
          }
        }
      }

      &-sm {
        @media (--viewport-sm-only) {
          display: none;
        }

        &-min {
          @media (--viewport-sm-min) {
            display: none;
          }
        }
      }
    }
  }

  /* lower header bar */
  &__body {
    @media (--viewport-sm-min) {
      display: flex;
      background-color: var(--color-primary);
    }

    &-wrapper {
      border-bottom: solid 1px var(--color-grey3);
      @media (--viewport-xs-max) {
        margin-right: -19px;
        margin-left: -20px;
        text-align: right;
      }

      @media (--viewport-sm-min) {
        position: relative;
        display: flex;
        justify-content: flex-end;
      }
      @media (--viewport-ms-max) {
        border-bottom: 0;
      }
    }

    &.active {
      .header-fixed {
        &__body {
          &-wrapper {
            @media (--viewport-sm-min) {
              background-color: var(--color-primary);
            }
          }

          &-main {
            &-btn {
              @media (--viewport-sm-min) {
                color: var(--color-text);
              }

              .icon {
                @media (--viewport-sm-min) {
                  fill: var(--color-secondary);
                }
              }
            }
          }
        }
      }
    }

    &-main {
      @media (--viewport-ms-max) {
        display: flex;

        flex-direction: column;
        min-height: 100%;
        /*background-color: var(--color-primary);*/
      }

      &-overlay {
        overflow: auto;
        /*flex-grow: 1;*/
        -ms-overflow-style: none;

        @media (--viewport-ms-max) {
          position: absolute;
          z-index: 11;
          top: 0;
          width: 100%;
          height: 100vh;
          transition: transform 0.5s;
          transform: translateX(100%);
          border-bottom: 10px solid var(--color-accent-dark);

          .header-fixed__body-main {
            background-color: var(--color-primary);
          }
        }

        &.mobile-menu-is-open {
          transform: translateX(0);
        }
      }

      &-content {
        /* THIS VIEWPORT DEFINES PADDINGS FOR THE MOBILE HIDDEN MENU */
        @media (--viewport-ms-max) {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          padding: 80px 20px 30px 20px;
        }

        &-nav {
        }

        &-btn {
          width: calc(100%);
          bottom: 0;

          @media (--viewport-xs-max) {
            width: calc(100%);
          }
        }
      }

      &-list {
        display: flex;
        justify-content: flex-end;

        @media (--viewport-sm-max) {
          /*   margin-right: 40px; */
        }

        @media (--viewport-ms-max) {
          flex-direction: column;
        }
      }

      &-item {
        border: none;

        @media (--viewport-sm-min) {
          margin-right: 12px;
        }

        @media (--viewport-md-min) {
          margin-right: 30px;
        }

        @media (--viewport-lg-min) {
          margin-right: 40px;
        }

        /* MOBILE MENU STYLIZATION */
        @media (--viewport-ms-max) {
          border-bottom: 1px solid hsl(0deg 0% 51% / 25%);
          width: 100%;
        }

        &:first-child {
          .header-fixed {
            &__body {
              &-main {
                &-submenu {
                  @media (--viewport-ms-max) {
                    display: none;
                  }
                }
              }
            }
          }
        }

        &:last-child {
          /* margin-right: 40px; */
        }
      }

      &-link {
        &_trigger {
          &.active {
            .header-fixed__body-main-btn {
              .icon {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      &-cta-mobile {
        display: none;

        @media (--viewport-ms-max) {
          display: block;
        }
      }

      &-btn {
        display: flex;
        align-items: center;
        font-family: 'Jornada Sans Book';
        font-size: 16px;
        line-height: 18px;
        color: var(--color-secondary);
        transition: all 0.3s;

        @media (--viewport-sm-only) {
          font-size: 15px;
        }

        @media (--viewport-ms-max) {
          justify-content: space-between;
          width: 100%;
          padding: 15px 0;
          font-size: 16px;
          line-height: 26px;
        }

        @media (--viewport-sm-min) {
          position: relative;
          padding: 16px 0px;
          transition: all 0.1s;

          &::before {
            position: absolute;
            bottom: 11px;
            left: 0;
            display: block;
            width: 0;
            height: 1px;
            background-color: var(--color-accent);
            content: '';
            transition: all 0.1s;
          }

          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
            height: 100%;
            content: '';
          }
        }

        @media (--viewport-lg-min) {
          /*padding: 30px 0;*/

          &::before {
            /*bottom: 25px;*/
          }
        }

        &:hover {
          color: var(--color-accent) !important;

          &::before {
            background-color: var(--color-accent) !important;
          }

          .icon {
            fill: var(--color-accent) !important;
          }
        }

        &.active {
          @media (--viewport-sm-min) {
            color: var(--color-accent) !important;
            border-bottom: 1px solid var(--color-accent);

            &::before {
              width: 100%;
              transition: all 0.2s;
            }
          }

          .icon {
            @media (--viewport-sm-min) {
              transform: rotate(-180deg);
              margin-bottom: 0;
              fill: var(--color-accent) !important;
            }
          }
        }

        .icon {
          display: flex;
          margin: 0 0 4px 8px;
          transition: all 0.4s;

          @media (--viewport-sm-min) {
            transition: all 0.2s;
          }
        }
      }

      &-submenu {
        display: none;
        text-align: left;

        @media (--viewport-sm-min) {
          position: absolute;
          z-index: 10;
          top: 50px;
          left: 0;
          width: 100%;
          background-color: var(--color-primary);

          &::before,
          &::after {
            position: absolute;
            top: 0;
            display: block;
            height: 100%;
            background-color: var(--color-primary);
            content: '';

            @media (--viewport-sm-min) {
              width: calc(29px * 2);
            }

            @media (--viewport-md-min) {
              width: calc(30px * 2);
            }

            @media (--viewport-cont-no-pad-min) {
              width: calc((100vw - 1352px) / 2 + 30px);
            }

            @media (--viewport-lg-min) {
              width: calc((100vw - 1352px) / 2 + 50px);
            }
          }

          &::before {
            left: calc(-29px * 2);

            @media (--viewport-md-min) {
              left: calc(-30px * 2);
            }

            @media (--viewport-cont-no-pad-min) {
              left: calc(-1 * ((100vw - 1352px) / 2 + 30px));
            }

            @media (--viewport-lg-min) {
              left: calc(-1 * ((100vw - 1352px) / 2 + 50px));
            }
          }

          &::after {
            right: calc(-29px * 2);

            @media (--viewport-md-min) {
              right: calc(-30px * 2);
            }

            @media (--viewport-cont-no-pad-min) {
              right: calc(-1 * ((100vw - 1352px) / 2 + 30px));
            }

            @media (--viewport-lg-min) {
              right: calc(-1 * ((100vw - 1352px) / 2 + 50px));
            }
          }
        }

        @media (--viewport-lg-min) {
          top: 50px;
        }

        &.active {
          @media (--viewport-sm-min) {
            display: block;
          }
        }

        .header-fixed-submenu__sidebar-text {
          .title-m {
            font-size: 16px;
          }

          /*Antes esta clase no estaba definida*/
        }
      }

      &-popups {
        &-content {
          .header-fixed__body-main-submenu {
            margin-bottom: 40px;

            @media (--viewport-ms-min) {
              margin-bottom: 60px;
            }

            .popup {
              &__list {
                flex-wrap: wrap;
              }

              &__item {
                margin-right: 40px;
                margin-bottom: 10px;
              }

              &__link {
                display: block;
                padding-bottom: 5px;

                &::before {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: block;
                  width: 0;
                  height: 1px;
                  background-color: var(--color-accent);
                  content: '';
                  transition: all 0.1s;
                }

                &:hover,
                &:focus {
                  color: var(--color-accent) !important;

                  &::before {
                    width: 100%;
                    background-color: var(--color-accent);
                  }
                }

                &_active {
                  &::before {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          flex-direction: column;

          @media (--viewport-ms-min) {
            justify-content: flex-start;
          }

          ul.header-fixed__body-main-popups-list {
            li:last-child {
              /* margin-top: 60px; */
            }
          }
        }

        &-item {
          text-align: left;
          padding-top: 15px;
          padding-bottom: 15px;

          @media (--viewport-ms-max) {
            border-bottom: 1px solid hsl(0deg 0% 51% / 25%);
          }

          &:last-child {
            margin-right: 0;
          }

          .header-fixed__body-main-link {
            &.active {
              .popup {
                &__trigger {
                  &::before {
                    width: 100%;
                  }
                }
              }
            }
          }

          .popup {
            &__trigger {
              display: block;
              position: relative;
              font-size: 16px;
              /*antes sin tamaño de fuente definido*/

              &::before {
                position: absolute;
                bottom: -5px;
                left: 0;
                display: block;
                width: 0;
                height: 0px;
                /* this height defines link border height in px */
                background-color: var(--color-secondary);
                content: '';
                transition: all 0.1s;
              }

              &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 100%;
                height: 40px;
                content: '';
              }

              &:hover,
              &:focus {
                color: var(--color-accent);

                &::before {
                  width: 100%;
                  background-color: var(--color-accent);
                }
              }
            }
          }
        }

        &-lang {
          text-align: left;
          padding-top: 60px;
          padding-bottom: 30px;

          font-weight: bolder;
          width: fit-content;

          &-list {
            margin-top: 10px;

            display: flex;
            flex-direction: row;
            width: fit-content;
          }

          .header-fixed__body-main-link {
            &.active {
              .popup {
                &__trigger {
                  &::before {
                    width: 100%;
                  }
                }
              }
            }
          }

          /* LANG POPUP STYLIZATION FOR MOBILE MENU */
          .popup {
            &__trigger {
              display: block;
              position: relative;
              font-size: 16px;
              /*antes sin tamaño de fuente definido*/

              &::before {
                position: absolute;
                bottom: -5px;
                left: 0;
                display: block;
                width: 50%;
                height: 1px;

                background-color: var(--color-accent);
                content: '';
                transition: all 0.1s;
              }

              &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 100%;
                height: 40px;
                content: '';
              }

              &:hover,
              &:focus {
                color: var(--color-accent);

                &::before {
                  width: 100%;
                  background-color: var(--color-accent);
                }
              }
            }
          }
        }
      }

      &-footer {
        @media (--viewport-ms-max) {
          margin-top: 0;
        }

        .header-fixed {
          &__triggers {
            @media (--viewport-sm-min) {
              display: none;
            }
          }
        }

        &-ctaRow {
          display: none;
          flex-direction: row;
          height: fit-content;
        }

        &-contactTiles {
          display: flex;
          flex-direction: column;
          margin-left: 0px;
          margin-top: 40px;
          margin-bottom: 20px;

          &-head {
            &-title {
              display: block;
              text-align: left;
              color: #00635b;
              font-size: 16px;
              margin-right: 10px;

              &-accent {
                color: var(--color-accent);
              }
            }

            &-schedule {
              display: block;
              text-align: left;
              color: #8b8b8b;
              font-size: 12px;
              font-weight: lighter;
              margin-right: 10px;
              margin-bottom: 5px;
            }
          }

          &-contactsList {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            &-contactTile {
              display: flex;
              flex-direction: column;
              margin-right: 20px;

              &-phone {
                display: block;
                text-align: left;
                color: var(--color-text);
                font-size: 14px;
                font-family: 'Jornada Sans Book';

                &-desc {
                  font-size: 12px;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }

  &__fixFixedPositionIssues {
    height: 100px;
    @media (--viewport-ms-max) {
      height: 55px !important;
    }
  }
}

.header-fixed + .splitter_header-fixed-before {
  @media (--viewport-lg-min) {
    padding-top: 260px;
  }
}
