.quota-simulation-summary {
    position: relative;
    background-color: var(--color-background-accent);
    padding: 36px 40px 46px;

    @media(--viewport-xs-max) {
        padding: 32px 20px 104px;
    }

    &--head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        @media(--viewport-xs-max) {
            .link {
                position: absolute;
                bottom: 40px;
                left: 36px;
            }
        }
    }

    &--content {
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 24px;

        .wrap {
            white-space: nowrap;
            line-height: 0;

            div {
                display: inline;
            }

            .icon {
                fill: var(--color-icon);
                margin-right: 8px;
            }

            @media(--viewport-ms-max) {
                white-space: wrap;
                display: flex;
                align-items: center;
                max-width: 85%;
            }
        }
    }

    &.lite {
        padding: 0;
        background: none;

        .quota-simulation-summary--content {
            grid-column-gap: 16px;
            grid-row-gap: 16px;
        }
    } 
}