body.experience-editor {
  .ee-border-red {
    border: 2px solid red;
    margin-top: 5px;
  }

  .ee-label {
    font-size: 12px;
    color: white;
    padding: 3px;
    position: absolute;

    &.ee-bg {
      &-red {
        background-color: red;
      }
      &-green {
        background-color: green;
      }
      &-blue {
        background-color: blue;
      }
      &-purple {
        background-color: purple;
      }
    }

    &.ee-position {
      &-top-left {
        top: 0;
        left: 0;
      }

      &-top-right {
        top: 0;
        right: 0;
      }
      &-bottom-left {
        bottom: 0;
        left: 0;
      }
      &-bottom-right {
        bottom: 0;
        right: 0;
      }
      &-top-center {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      &-bottom-center {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
