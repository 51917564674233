.hero-dashboard {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    &__content {
        display:flex;
        justify-content: center;
        @media (--viewport-ms-min) {
            margin: 0;
        }
        .wrap-img{
            line-height: 0;
            width: 100%;
            picture{
                img{
                    max-width: 100%;
                    width: 100%;
                    @media (--viewport-xs-max) {
                        max-width: none;
                        margin-left: -10px;
                        margin-right: -10px;
                        width: calc(100% + 20px);
                        min-height: 324px;
                    }
                }
            }
        }
        &.auxiliary {
            width: 100%;
            height:160px;
            min-height: auto;
            @media (--viewport-xs-max) {
                height:120px;
            }
            .wrap-img{
                    width: 100%;
                    height:160px;
                    @media (--viewport-xs-max) {
                        height:120px;
                    }
                picture{
                    img{
                        object-fit: cover;
                        min-width: 100%;
                        height:160px;
                        @media (--viewport-xs-max) {
                            height:120px;
                            min-height: auto;
                        }
                    }
                }
            }
        }
        .wrap-img.bottomless{    
            & + .hero-dashboard {
                &__inner {
                    background: transparent;
                    border:0 none;
                    align-items: flex-end;
                    justify-content:flex-end;
                    top:auto;
                    bottom:var(--gutter-md-vertical);
                    left: var(--gutter-lg-vertical);
                    padding:0;
                    margin-bottom:0;
                    @media (--viewport-ms-max) {
                        bottom:60px;
                        left: 40px;
                    }
                    @media (--viewport-xs-max) {
                        bottom:50px;
                        left: 10px;
                    }
                    .hero-dashboard__title {
                        width: 100%;
                        display:flex;
                        flex-flow: column;  
                        justify-content:flex-start;
                        align-items: flex-start;
                        @media (--viewport-ms-max) {
                        }
                        @media (--viewport-xs-max) {
                        }
                        [class^="title-"], [class*="title-"] {
                            color: var(--color-white);
                        }
                    }
                }
                
            }
            
        }
    }
    
    &__inner {
        border: 1px solid var(--color-grey3);
        position: relative;
        width: 100%;
        display:flex;
        flex-flow: column;  
        justify-content:flex-start;
        align-items: flex-start;
        background: var(--color-white);
        padding:40px 80px;
        row-gap: 40px;
        margin-bottom: 40px;
        margin-top: -310px;
        @media (--viewport-sm-max) {
            margin-top: -213px;
        }
        @media (--viewport-ms-max) {
            padding:29px 40px 33px;
            margin-top: -143px;
        }
        @media (--viewport-xs-max) {
            padding:32px 20px 30px;
            margin-top: -285px;
        }
   
        &__title {
            display: flex;
            flex-flow: column;
            row-gap: 20px;
            @media (--viewport-xs-max) {
                row-gap:10px;
            }
        }
        .icon-home {
            height: 60px;
            width: 60px;
            @media (--viewport-xs-max) {
                display:none;
            }
        }
        .title-m {
            span {
                font-weight:700;
            }
        }
        &.auxiliary{
            position: absolute;
            top: 90px;
            left:auto;
            margin-top: 0;
            @media (--viewport-ms-max) {
                top:80px;
            }
            @media (--viewport-xs-max) {
                top:40px;
            }
        }
    }
    
    .border {
        border: 1px solid var(--color-quinary);
        width:100%;
    }
    &__details {
        display: flex;
        flex-flow: nowrap;
        justify-content: flex-start;
        align-items: center;
        width:100%;
        column-gap: var(--gutter-width-ms-min);
        row-gap: 30px;
        @media (--viewport-sm-max) {
            display: flex;
            flex-wrap: wrap;
        }
        @media (--viewport-xs-max) {
            flex-flow: column;
            align-items: flex-start;
            row-gap: 10px;
        }
        .item {
            display: flex;
            justify-content: flex-start;
            align-items: center; 
            @media (--viewport-ms-max) {
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
                row-gap: 5px;
            }
            @media (--viewport-xs-max) {
                flex-flow: wrap;
                justify-content: flex-start;
                align-items: center;
            }
            span {
                margin-left: calc(var(--half-gutter-width)*.5);
                &.label-m {
                    color:var(--color-grey2);
                }
            }
        } 
        
    }
} 
