.tile {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
        .tile {
            &__btn {
                .btn {
                    background-color: var(--color-tertiary);margin-top:30px;
                }
                .btn_secondary {
                    background-color: transparent;
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        padding: 20px 20px 30px;

        @media (--viewport-ms-min) {
        padding-top: 50px;
        padding-bottom: 75px;
        }

        &.noLink{
            padding-bottom:20px;
        }
    }

    &__pic {
        max-width: 80px;
        margin: 0 auto 20px;
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &__img_filter {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        &:hover,
        &:focus {
            filter: grayscale(15%);
        }
    }

    &__label {
        margin-bottom: 10px;
    }

    &__title {
        margin-bottom: 10px;

        @media (--viewport-sm-min) {
            margin-bottom: 20px;
        }
        h3{display:inline;}
        .imagetitle{display:inline; height:25px; width: auto; left: 5px; top: 5px; position: relative;}
    }

    &__bodycopy {
        margin-bottom: 10px;

        @media (--viewport-sm-min) {
            margin-bottom: 20px;
        }
    }

    &__btn {
        @media (--viewport-ms-min) {
            position: absolute;bottom:0;
            }
    }

    &_bg-color {
        &_yellow {
            background-color: var(--color-accent-light);
        }
        &_accent{
            background-color: var(--color-accent);
            .text-label-m, .title-m{
                color: var(--color-white);
            }
        }
    }
}
