[class*='caption-'] {
    letter-spacing: var(--font-letter-spacing-wide);
}
.caption {
    /* extend class: btn molecule */
    &-call-to-action {
        font-size: var(--font-size-4);
        line-height: var(--font-line-height-3);
        font-family: var(--font-family-sans-mid);
    }
    
	&-placeholder-l, &-placeholder-l[type=text]{
		font-family: var(--font-family__placeholder-l);
		font-size: var(--font-size-10);
		line-height: var(--font-line-height-6);
		font-family: var(--font-family-news-low);

        .access-select__option,
		&::placeholder {
			font-family: var(--font-family__placeholder-l);
			font-size: var(--font-size-10);
			line-height: var(--font-line-height-6);
			font-family: var(--font-family-news-low);
		}
	}

	&-placeholder-m, &-placeholder-m[type=text] {
		font-size: var(--font-size-4);
		line-height: var(--font-line-height-3);
		font-family: var(--font-family-sans-low);

        .access-select__option,
		&::placeholder {
			font-size: var(--font-size-4);
			line-height: var(--font-line-height-3);
			font-family: var(--font-family-sans-low);
		}
	}
   
    /* extend class: label atom */
    &-label {
        font-size: var(--font-size-1);
        line-height: var(--font-line-height-1);
        font-family: var(--font-family-sans-mid);
        letter-spacing: var(--font-letter-spacing-label);
        text-transform: uppercase;
    }

}

