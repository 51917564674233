.access-simulator {
    padding: 50px 0 80px;
    background: center / cover no-repeat;

    @media (--viewport-xs-max) {
        margin-left: -20px;
        margin-right: -19px;
    }

    @media (--viewport-sm-min) {
        padding: 70px 0 100px;
    }

    &__tiles {
        ul{justify-content: center;}
        @media (--viewport-xs-max) {
            padding: 0 20px;
        }

        .tile {
            padding: 60px;
            margin-top: 30px;

            @media (--viewport-sm-min) {
                padding: 60px 20px;
            }

            @media (--viewport-md-min) {
                padding: 60px 40px;
            }

            &__content {
                padding: 0;
                justify-content: center;
            }

            &__title {
                margin-bottom: 30px;
            }
            &__btn {
                @media (--viewport-ms-min) {
                    position:relative;bottom:auto;
                    }
            }
        }
    }
}
