.tile-simple-interest-rate {
    display:flex;
    flex-flow:column;
    justify-content:space-between;
    flex-grow: 1;
    height: 100%;
    padding: 40px 20px 20px;
    background-color: var(--color-accent-light);

    border-top: 4px solid var(--color-accent);
    opacity: 1;

    &__head {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        text-align: center;

        .title-xl {
            color: #191919;
        }
    }

    &__img {
        display: block;
        width: 150px;
        height: auto;
    }

    &__pic {
        display: flex;
        justify-content: center;
        width: 150px;
        margin: 0 auto 35px;
    }


    &__main {
        display: flex;
        flex-flow: column;
        margin-bottom: 20px;
    }

    &__item {
        padding-bottom:15px;

        &:last-child {
            border: none;
        }

        &-title {
            display: flex;
            opacity: 0.9;

            .bodycopy-m {
                line-height: 20px;
            }


            .icon {
                fill: rgb(0, 99, 91);
                width: 20px;
                height: 20px;
                margin-right: 10px;
                opacity: 1;
                font-weight: 800;

            }
        }

    }

    &__footer {
        text-align: center;
    }


}