.dedicated-link {
    flex-grow: 1;

    .link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 29px 50px 28px;
        border: 1px solid var(--color-secondary-light);
        transition: background-color 0.3s;

        &:hover,
        &:focus {
            background-color: var(--color-accent);
            color: var(--color-text-light);

            .icon {
                fill: var(--color-text-light);
            }
        }

        .icon {
            top: 50%;
            right: 30px;
            transform: translate(0, -50%);
        }
    }

    &.border-accentdark{
        .link{
            border-color: var(--color-accent-dark);
        }
    }
}
