.access-product {

    &__header {
        margin-bottom: 10px;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 60px;
        }
    }

    &__title {
        margin-bottom: 20px;
        text-align: center;

        @media (--viewport-ms-min) {
            margin-bottom: 30px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 24px;
        }
    }

    &__products {
        .tile {
            margin-top: 30px;

            @media (--viewport-sm-min) {
                margin-top: 0;
            }

            @media (--viewport-ms-max) {
                margin-top: 15px;
            }

            &.tile_bg-color_yellow{
                margin-bottom: 30px;

                @media (--viewport-ms-max) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .tile {
        &:hover, &:focus{
            background-color: var(--color-accent-light);
        }
        &__content{
            padding-top: 30px;
            padding-bottom: 10px;
        }
        &__btn{
            position: relative;
            .btn{margin-bottom: 30px;}
        }
    }

    &.AccentLight{background-color: var(--color-accent-light); padding: 40px;
    }

    &__link {
        text-align: center;
    }

  /* Swiper Variation*/

  @media (--viewport-ms-max){
    .swiper-wrapper{
      padding-left: 10px;
    }
  }

  .swiper-container{
    padding-bottom: 50px;
    @media (--viewport-ms-max) {
      padding-bottom:40px;
    }
  }

  .swiper-slide{
    /*background-color:var(--color-accent-light);*/
    height: auto;
    width: 80%;
    padding-left:10px;
    padding-right:10px;
  }

  .swiper-navigation{
    display: flex;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    padding: 20px;
    justify-content: center;
    align-items: center;

    .swiper-pagination {
      margin: 0 40px;
    }

    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;

      &::after {
        display: none;
      }

      .icon {
        height: 20px;
        width: 28px;
        fill:var(--color-accent)
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 0;
      width: 28px;
      height: 20px;
      margin-top: 0;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .swiper-pagination-bullet-active {
      background: var(--color-accent);
    }

    @media (--viewport-ms-max) {
      display:flex;
    }
  }
}
