.legal {
    position: relative;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;

    &__checkbox {
        max-width: 35px;

        .field-top {
            display: none;
        }

        .form-checkbox-group {
            position: unset;

            .field-bottom {
                position: absolute;
                width: 100%;
                bottom: -35px;
                left: 0;

                .field-bottom__helper-text {
                    .bodycopy-xs {
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }

    &.showLabel {
        padding-top: 50px;

        .field-top {
            display: flex;
            margin-top: -50px;
        }
    }

    &__bodycopy {
        a {
            color: var(--color-accent-dark);
            text-decoration: underline;
        }
    }

    &__link {
        .tooltip {
            position: relative;
            top: auto;
            bottom: auto;
            right: auto;
            margin-top: 0;
            margin-left: 10px;
            display: inline;

            &.flex {
                position: absolute;
                top: -11px;
                left: -25px;
                display: inline-flex;
            }
        }
    }

    &__tooltip {
        .tooltip {
            position: relative;
            top: 0;
            bottom: 0;
            margin-top: 0;
            margin-right: 10px;
        }
    }
}