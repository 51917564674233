.pagination {
    &__list {
        display: flex;
        align-items: center;
        margin: 0 -12.5px;
    }

    &__item {
        padding: 0 12.5px;

        &:first-child {
            margin-right: 15px;

            .pagination {
                &__link {
                    padding-bottom: 8px;
                }
            }
        }

        &:last-child {
            margin-left: 15px;

            .pagination {
                &__link {
                    padding-bottom: 8px;
                }
            }
        }

        &.active {
            .pagination {
                &__link {
                    &::after {
                        width: 12px;
                    }


                    &:hover,
                    &:focus {
                        color: var(--color-accent);
                    }
                }
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        padding-bottom: 2px;
        font-family: 'Jornada Sans Book';
        font-size: 16px;
        font-weight: normal;
        line-height: 26px;
        letter-spacing: 0;
        color: var(--color-text);
        transition: all 0.3s;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            content: '';
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 0;
            height: 1px;
            background-color: var(--color-accent);
            transition: all 0.3s;
            content: '';
        }

        &:hover,
        &:focus {
            &::after {
                width: 12px;
            }
        }

        .icon {
            fill: var(--color-accent);
        }
    }

    &__prev,
    &__next {
        display: flex;
    }

    &__next {
        .icon {
            transform: rotate(180deg);
        }
    }

    & * {
        user-select: none;
    }
}
