.product {
    position: relative;



    &_reversed {
        .product {
            &__content {
                @media (--viewport-ms-only) {
                    padding: 11px 11px 11px 30px;
                }
            }
        }
    }

    &-swiper {
        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }
    }

    &__support {position: relative;height: 100%; overflow:hidden; /* para evitar espacio bajo la imagen en reversed */line-height: 0;
        img{width:100%; height:100%;object-fit:cover;
            &.isContain{
                height:auto;
                object-fit: contain;
            }
        }

        .video {
            width: 100%;
            height: auto;
            left: 0;
            top: 0;
            overflow: hidden;

            &.isCover {
                height: 100%;
                object-fit: cover;
            }
        }

        .row {
            padding: 40px 20px 34px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            @media (--viewport-ms-min) {
                height: 100%;
                padding: 60px 40px;
            }

            @media (--viewport-sm-min) {
                padding: 60px 82px;
            }

            @media (--viewport-md-min) {
                padding: 60px 74px;
            }

            &-label {
                margin-top: 10px;
            }
        }

        &-label-wrapper {
            position: relative;

            .row {
                padding: 0;
                position: relative;
            }
        }

        &-text {
            position: relative;
        }
    }


    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.3%;
        /* Adecua este valor para controlar la altura del video */
        position: relative;
        height: 0;

        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }

        &.isCover {
            width: 100vw;
            height: 100%;
            padding-bottom: 0;

            /* Adecua este valor para controlar la altura del video */
            iframe {
                top: 50%;
                left: 21%;
                /*left: 70vh;*/
                width: 100vw;
                height: 100%;
                transform: translate(-50%, -50%);

                @media (--viewport-ms-min) {
                    left: 22vw;
                }

                @media only screen and (min-width: 800px) {
                    left: 22vw;
                }

                @media only screen and (min-width: 950px) {
                    left: 23vw;
                }

                @media (--viewport-sm-min) {
                    left: 23vw;
                }

                @media only screen and (min-width: 1100px) {
                    left: 24vw;
                }

                @media (--viewport-md-min) {
                    left: 23vw;
                }

                @media only screen and (min-width: 1900px) {
                    left: 17vw;
                }

                @media (--viewport-lg-min) {
                    left: 16.8vw;
                }

                @media only screen and (min-width: 2100px) {
                    left: 15vw;
                }

                @media (--viewport-xl-min) {
                    left: 13vw;
                }


                @media only screen and (max-width: 1189px) {
                    width: 130vw;
                }

                @media only screen and (max-width: 921px) {
                    width: 160vw;
                }

                @media only screen and (max-width: 800px) {
                    width: 174vw;
                }
            }

            @media (--viewport-xs-max) {
                padding-bottom: 56.3%;
                height: 0;

                iframe {
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    transform: none;
                }
            }
        }
    }

    &__content {
        padding: 20px 20px 30px 20px;

        @media (--viewport-ms-min) {
            padding: 11px 30px 30px 10px;
        }

        @media (--viewport-sm-min) {
            padding: 42px 0;
        }

        @media (--viewport-md-min) {
            padding: 32px 0;
        }
    }

    &__icon {
        margin-bottom: 20px;
    }

    &__label {
        margin-bottom: 20px;
    }

    &__bodycopy {
        margin-top: 20px;
    }

    &__btn,
    &__link {
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        @media (--viewport-ms-min) {
            justify-content: center;
            padding: 0;
        }

        &-back,
        &-forward {
            margin-top: 20px;

            @media (--viewport-ms-min) {
                margin-top: 30px;
            }
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }

    &__bulletList {
        margin-top: 30px;

        li {
            display: flex;
            flex-direction: row;
            margin-bottom: 24px;

            &:last-child {
                @media (--viewport-xs-max) {
                    margin-bottom: 0;
                }
            }

            .icon {
                width: 20px;
                height: 20px;
                margin-right: 16px;
            }
        }
    }

    .row.mobileReverse {
        @media (--viewport-ms-max) {
            flex-direction: column-reverse;
        }
    }

    .bg-color {
        &_yellow {
            background-color: var(--color-accent-light);
        }
    }
}