.title-xxl {
    font-family: 'Jornada News Book';
    font-size: 36px;
    font-weight: normal;
    line-height: 46px;
    letter-spacing: 0;
    color: var(--color-text-quaternary);

    &_color {
        &_light {
            color: var(--color-text-light);
        }
    }
}