.tile-footer-menu {
    &__title {
        margin-bottom: 20px;

        @media (--viewport-xs-max) {
            margin-bottom: 30px;
        }
    }

    &__item {
        margin-bottom: 20px;

        @media (--viewport-ms-min) {
            margin-bottom: 15px;
        }
    }

    &__link {
        .link {
            color: var(--color-txt-secondary);

            &:hover,
            &:focus {
                color: var(--color-txt-tertiary);

                span {
                    &::after {
                        background-color: var(--color-txt-tertiary);
                    }
                }

                .icon {
                    fill: var(--color-txt-tertiary);
                }
            }
        }
    }

    &__contact {
        &-first-col {
            @media (--viewport-xs-max) {
                margin-bottom: 30px;
            }

            @media (--viewport-sm-min) {
                margin-bottom: 20px;
            }
        }

        &-second-col {
            &-item {
                margin-bottom: 20px;

                @media (--viewport-ms-min) {
                    margin-bottom: 15px;
                }

                @media (--viewport-sm-min) {
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;

                    @media (--viewport-ms-min) {
                        margin-top: 30px;
                    }
                }
            }
        }

        &-link {
            .link {
                color: var(--color-txt-secondary);
                span:after{
                    background-color: var(--color-txt-secondary);
                }

                &:hover,
                &:focus {
                    color: var(--color-txt-tertiary);

                    span {
                        &::after {
                            background-color: var(--color-txt-tertiary);
                        }
                    }

                    .icon {
                        fill: var(--color-txt-tertiary);
                    }
                }

                &_second-link {
                    &::before {
                        color: var(--color-txt-secondary);
                    }
                }
            }
            .link_has-icon .icon{
                fill: var(--color-txt-secondary);
            }
        }

        &-info {
            margin-top: 5px;

            .bodycopy-s {
                color: var(--color-txt-secondary);
            }
        }
    }

    &__reduced {
        &-first-col {
            @media (--viewport-xs-max) {
                margin-bottom: 30px;
            }
        }

        &-second-col {
            &-item {
                margin-bottom: 20px;

                @media (--viewport-ms-min) {
                    margin-bottom: 15px;
                }

                @media (--viewport-sm-min) {
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-third-col, &-fourth-col {
            &-item {
                @media (--viewport-xs-max) {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                @media (--viewport-ms-only) {
                    margin-top: 30px;
                }

                .link {
                    color: var(--color-txt-secondary);

                    span {
                        &::after {
                            background-color: var(--color-txt-secondary);
                        }
                    }

                    &:hover,
                    &:focus {
                        color: var(--color-txt-tertiary);

                        span {
                            &::after {
                                background-color: var(--color-txt-tertiary);
                            }
                        }

                        .icon {
                            fill: var(--color-secondary);
                        }
                    }
                }
            }
        }

        &-link {
            .link {
                color: var(--color-txt-secondary);

                &:hover,
                &:focus {
                    color: var(--color-txt-tertiary);

                    span {
                        &::after {
                            background-color: var(--color-txt-tertiary);
                        }
                    }

                    .icon {
                        fill: var(--color-txt-tertiary);
                    }
                }

                &_second-link {
                    &::before {
                        color: var(--color-txt-secondary);
                    }
                }

                svg{fill: var(--color-txt-secondary);}
            }
        }

        &-info {
            margin-top: 5px;

            .bodycopy-s {
                color: var(--color-txt-secondary);
            }
        }
    }
}
