.product-reduced {
    position: relative;

    @media (--viewport-ms-max) {
        .row {
            margin: 0;
        }
    }

    .col.text {
        @media (--viewport-ms-min) {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
        }

        @media (--viewport-ms-max) {
            padding: 0;
        }
    }

    .col.picture {
        @media (--viewport-ms-min) {
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
        }

        @media (--viewport-ms-max) {
            padding: 0;
        }
    }

    .action-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
    }

    &__support {
        position: relative;
        height: 100%;
        overflow: hidden;
        /* para evitar espacio bajo la imagen en reversed */
        line-height: 0;
        display: flex;
        align-items: center;

        @media (--viewport-xs-max) {
            margin: 0 -20px;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }

        .video {
            width: 100%;
            height: auto;
            object-fit: contain;
            left: 0;
            top: 0;
            overflow: hidden;
        }
    }


    .video-responsive {
        overflow: hidden;
        position: relative;
        height: 0;
        width: 100%;
        /*height: 100%;*/
        padding-bottom: 56.3%;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

        }
    }

    &__content {
        padding: 40px 0 40px 40px;

        @media (--viewport-ms-max) {
            padding: 40px 32px;
        }

        @media (--viewport-xs-max) {
            padding: 40px 20px;
        }
    }

    &__label {
        margin-bottom: 24px;
    }

    &__title {
        margin-bottom: 24px;
    }

    &__bodycopy {
        margin-bottom: 24px;

        a {
            color: var(--color-text-link-secondary);
        }
    }

    &_reversed {
        .product-reduced__content {
            padding: 40px 40px 40px 0;

            @media (--viewport-ms-max) {
                padding: 40px 32px 40px 0;
            }

            @media (--viewport-xs-max) {
                padding: 40px 20px 40px 0;
            }
        }

        .col.text {
            @media (--viewport-ms-min) {
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 0;
                padding-left: var(--half-gutter-width-lg-min, 0.5rem);
            }

            @media (--viewport-sm-min) {
                padding-left: var(--half-gutter-width-ms-min, 0.5rem);
            }

            @media (--viewport-md-min) {
                padding-left: var(--half-gutter-width-ms-min, 0.5rem);
            }
            @media (--viewport-ms-max) {
                padding: 0;
            }
        }

        .col.picture {
            @media (--viewport-ms-min) {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: var(--half-gutter-width-lg-min, 0.5rem);
            }

            @media (--viewport-sm-min) {
                padding-right: var(--half-gutter-width-ms-min, 0.5rem);
            }

            @media (--viewport-md-min) {
                padding-right: var(--half-gutter-width-ms-min, 0.5rem);
            }
            @media (--viewport-ms-max) {
                padding: 0;
            }
        }

    }
}