.title-l {
    color: var(--color-text-quaternary);

    &__extented {
        position: absolute;
        margin-left: 5px;
        font-size: 14px;
        margin-top: -8px;

        @media (--viewport-ms-min) {
            font-size: 16px;
            margin-top: -11px;
        }
    
        @media (--viewport-sm-min) {
            font-size: 14px;
            margin-top: -6px;
        }
    
        @media (--viewport-md-min) {
            font-size: 16px;
            margin-top: -11px;
        }
    
        @media (--viewport-lg-min) {
            font-size: 16px;
            margin-top: -11px;
        }
    } 
    
    &__prefix {
        float: left;
        margin-right: 5px;

        font-size: 14px;
        margin-top: -5px;

        @media (--viewport-ms-min) {
            font-size: 16px;
            margin-top: -8px;
        }
    
        @media (--viewport-sm-min) {
            font-size: 14px;
            margin-top: -3px;
        }
    
        @media (--viewport-md-min) {
            font-size: 16px;
            margin-top: -8px;
        }
    
        @media (--viewport-lg-min) {
            font-size: 16px;
            margin-top: -7px;
        }
    } 
    &_color {
        &_light {
            color: var(--color-text-light);
        }
        &_secondary {
            color: var(--color-text-secondary);
        }
        &_dark {
            color: var(--color-text);
        }
        &_accent-dark {
            color: var(--color-accent-dark);
        }
        &_accent {
            color: var(--color-accent);
        }
    }
}
