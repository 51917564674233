.scroll-up {

    /*&-item {
        margin-bottom: 40px;

        .title-l {
            margin-bottom: 15px;
        }
    }*/

    &-splitter {
        display: block;

        &.active {
            display: block;
        }
    }
    &.fractal{
        .go-top{display:block;}
    }

}