.icons-nav {
    overflow-x: scroll;
    scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
    padding-top: 40px;
    text-align: center;
    transition: all 0.3s;

    &::-webkit-scrollbar {
        display: none; /* Hide the scrollbar in Chromium */
    }

    @media (--viewport-ms-min) {
    }

    @media (--viewport-sm-min) {
        padding-top: 30px;
    }

    @media (--viewport-md-min) {
    }

    @media (--viewport-lg-min) {
    }

    &__list {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        @media (--viewport-xs-max){
            flex-wrap:nowrap;
            width: auto;
        }
    }

    &__item {
        width: 20%;
        text-align: center;

        @media (--viewport-xs-max) {
            display:block;
            width:120px;
        }
    }
    

    .sticky-clone {
        padding: 14px 0;
        .icon-button__pic{display:none;}
    }
}
