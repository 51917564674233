.change-password{
    &__head{
        text-align: center;margin-bottom: 40px;
        .bodycopy-m, title-l{
            margin-top: 16px;
        }
    }
    .wrap{
        @media (--viewport-ms-max) {
            flex-direction: column-reverse;
        }
    }
    .list{
        @media (--viewport-sm-min) {
            margin-top: 22px;
        }
        @media (--viewport-ms-max) {
            margin-top: 64px;
        }
    }
    &.first-login{
        .wrap{
            flex-direction: row-reverse;
            @media (--viewport-ms-max) {
                flex-direction: column-reverse;
            }
        }
        .list{
            padding: 40px;
            margin-top: 40px;
            background-color: var(--color-background-accent);
            @media (--viewport-ms-max) {
                margin-top: 64px;
            }
        }
    }
}