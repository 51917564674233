.steps-nav {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    &__head {
        background-color: var(--color-white);
        display: flex;
        flex-flow: column;
        width: 100%;
        max-width: 100%;
        border-bottom: 1px solid var(--color-accent-dark);
        

    }
    /* &.auto-width {
        .steps-nav__head {
            max-width: max-content;
        }
    } */    

    &__body {
        display: flex;
        flex-flow: column;
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
        margin-top:var(--gutter-ms-vertical);
        margin-bottom:var(--gutter-ms-vertical);
        .content-item {
            width: 100%;
            flex-basis:100%;
            color: var(--color-text);
            display:none;
            &.visibility {
                display:flex;
            }
            .detail{width:100%;}
        }
    }
    &.steps-just-for-ms-xs{ /*para forzar el formulario completo y sin pasos en resoluciones superiores a 767*/
        @media (--viewport-sm-min) {
            .steps-nav__body{margin-top: 0;}
            .steps-nav__head{
                display:none;
            }
            .content-item {
                display:block;
                &#content-1{
                    .landing-legal-form{
                        display:none;
                    }
                    .recaptcha, .form-btn{
                        display:none;
                    }
                }
            }
        }
    }
}