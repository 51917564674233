.lightbox {
    &__overlay {
        display: none;
        position: fixed;
        z-index: 12;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        .container {
            @media (--viewport-xs-max) {
                padding-right: 0;
                padding-left: 0;
            }
            & > div > div{
                background-color: var(--color-primary);
            }
        }
    }

    &__close-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 60px;
        padding: 0;
        background-color: var(--color-primary);
        cursor: auto;
    }

    &__window {
        position: relative;
        overflow: hidden auto;
        width: 100%;
        max-height: calc(100vh - 140px);
        padding: 0 20px 60px 20px;
        background-color: var(--color-primary);
        cursor: auto;
        scroll-behavior: smooth !important;

        @media (--viewport-xs-max) {
            max-height: calc(100vh - 60px);
        }

        @media (--viewport-sm-min) {
            padding: 0 0 60px 0;
        }

    }

    &__head {
        margin-bottom: 40px;
        text-align: left;
        ul{/*margin-top: 30px;margin-bottom: 20px;*/
            li{
                &:first-child{flex-wrap: wrap;flex-direction: row;
                    a.link{display:inline-flex;width:auto;
                        span{display:inline;padding-left: 5px;padding-right:0;}
                        &:first-child span{padding-right: 5px;}
                    }
                    div{clear:left;}
                }
            }
        }
        .contact-cards ul li:first-child{flex-direction:column;} /* Excepción para que no interfiera con el contact cards */
        .lightbox__title{margin-bottom:20px;
            &.calculatorLightbox{ margin-bottom: 0;}
        }
    }

    &__description {
        margin-top: 20px;
    }

    &__content {
        &-additional {
            margin-top: 40px;

            &_col {
                &_2 {
                    @media (--viewport-sm-min) {
                        margin-top: 0;
                    }
                }
            }
        }
        &-main{
            &.calculatorLightbox{
                .row{
                    .col-sm-6, .col-lg-6{position: relative;
                        .description-list{
                            /*@media (--viewport-ms-min) {
                                margin-bottom: 32px;
                                dt.total{position: absolute; bottom:0;}
                                dd.total{position: absolute; bottom:0;right: 0;margin-right: var(--half-gutter-width-ms-min);}
                            }*/
                            @media (--viewport-sm-min) {margin-bottom: 32px;
                                dl{
                                    dt.total{position: absolute; bottom:0;}
                                    dd.total{position: absolute; bottom:0;right: 0;margin-right: var(--half-gutter-width-ms-min);}
                                }
                            }
                            @media (--viewport-lg-min) {margin-bottom: 30px;
                                dl{
                                   dt.total{position: absolute; bottom:0;}
                                    dd.total{position: absolute; bottom:0;right: 0;margin-right: var(--half-gutter-width-lg-min);}
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 23px;
        right: 30px;
        display: flex;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 45px;
            height: 45px;
            content: '';
        }

        &:hover,
        &:focus {
            .icon {
                fill: var(--color-accent);
            }
        }

        .icon {
            width: 16px;
            height: 16px;
        }
    }
}
body, html, .lightbox__window {
    scroll-behavior: smooth !important;
}
