.share-APA {
    .title-l {
        @media (--viewport-xs-min) {
            text-align:center;
        }
        @media (--viewport-sm-min) {
            text-align:left;
        }
    }
    .share-qr {
        width:280px;
        height: 280px;
        margin-top:var(--gutter-width-ms-min);
        margin-bottom:var(--gutter-width-ms-min);
    }
    .share-whatsapp {
        color: var(--color-accent);
        max-width:100px;
        margin-top:var(--gutter-width-ms-min);
        margin-bottom:var(--gutter-width-ms-min);
    }
    .btn {
        margin:calc(var(--gutter-vertical)*2) 0;
    }
}