.text-call-to-action {
    font-family: 'Jornada Sans Medium';
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0;
    color: var(--color-text);

    @media (--viewport-sm-only) {
        font-size: 13px;
        line-height: 23px;
    }
}
