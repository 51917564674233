.steps-form-result {
    .col1{
        .tit-group{margin-bottom: 20px;
            .title-xl{
                display:inline;
                span{color:var(--color-accent);}
            }
            img{width: 45px;display:inline;margin:-10px 0 0 10px;}
        }
        .tit-link{margin-bottom: 40px;
            .link{color:var(--color-accent);
                span{color:var(--color-accent);
                    &:after{background-color:var(--color-accent-dark);}
                }
            }
        }
    }
    .col2{
        .resultado{
            background-color: var(--color-accent-light);
            .title-xl{
                padding: 45px 0;
                text-align: center;
                span{color:var(--color-accent);font-weight:bold;text-transform:uppercase;}
            }
        }
        p{padding:20px 0;}
        ul{display: flex; align-items: flex-start; justify-content: flex-start;
            @media (--viewport-xs-max){display:block;}
            li{width:auto;display:inline-block;
                @media (--viewport-xs-max){width: 100%;display:block;
                    a{display:block; width: 100%;}
                }
                &:first-child{margin-right:50px;
                    @media (--viewport-sm-max){margin-right: 10px;}
                    @media (--viewport-xs-max){margin-right:0;margin-bottom:15px;}
                }
            }
        }
    }
}