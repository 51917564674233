.sidebar-content-table {
  position: relative;

  @media (--viewport-sm-min) {
    .row.reversed {
      flex-direction: row-reverse;
    }
  }

  .wrap-aggregator {
    z-index: 12;
    body.active & {
      z-index: auto; /* cuando el menu esta desplegado */
    }

    @media (--viewport-sm-min) {
      position: sticky;
      top: 0;
      /*margin-right: 30px;*/
    }

    @media (--viewport-ms-max) {
      margin-bottom: 40px;
    }

    .tile-aggregator {
      .tile-aggregator__title .icon {
        display: none;
      }
      .tile-aggregator__link {
        .link_has-icon .icon {
          display: none;
        }
      }
    }

    &.scroll {
      @media (--viewport-ms-max) {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin: 0 0 0 -30px;

        .tile-aggregator {
          padding: 30px 30px 40px 30px;
          background-color: var(--color-background);
          box-shadow: 0px -10px 5px 0px #0000000d;

          &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &::after {
              background-color: var(--color-border);
            }

            .icon {
              width: 24px;
              height: 24px;
              display: block;
            }
          }
          .tile-aggregator__link {
            .link_has-icon .icon {
              display: block;
              fill: var(--color-icon-link-primary-disabled);
            }
            &.active {
              .link_has-icon .icon {
                fill: var(--color-icon-accent);
              }
            }
          }
        }
      }

      @media (--viewport-xs-max) {
        margin: 0 0 0 -20px;
      }

      &.collapsed {
        @media (--viewport-ms-max) {
          .tile-aggregator {
            padding: 30px;

            &__title {
              padding: 0;
              margin: 0;

              &::after {
                height: 0;
              }
            }

            &__body {
              display: none;
            }
          }
        }
      }
    }
  }

  .wrap {
    .splitter {
      .container {
        margin: 0;
        padding: 0;

        .splitter__wrapper {
          margin: 0;
          padding: 0;
          background: none;
        }
      }
    }
  }
}
