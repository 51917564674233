.subsidy-result {

    &__data{margin-top: 20px;display:flex; flex-direction: row; flex-wrap: wrap;
        li{ width: 50%; float: left;
            @media (--viewport-ms-max) {
                width: 100%;
            }
            /*&:nth-child(2n+3){
                clear:left;
            }*/
            div{
                display:inline;
                &:first-of-type{color:var(--color-grey2); padding: 0 5px;}
            }
        }
    }

    &__deadline{
        div{
            display:inline;
            &:first-of-type{color:var(--color-grey2); padding: 0 5px;}
        }
        &.withAlert{
            /*.icon:first-of-type{margin-left: 10px;}*/
            .bodycopy-m{color:var(--color-danger);}
            div{
                &:first-of-type{color:var(--color-grey2);
                    .bodycopy-m{color:var(--color-grey2);}
                }
            }
            .alert{margin-left: 5px;
                .bodycopy-m{color:var(--color-danger);}  
            }
        }
    }
    
    &__links{margin-top: 20px;
        a + a{
            margin-left: 20px;
            @media (--viewport-ms-max) {
                margin-left: 0;width:100%;float:left;clear:left; margin-top: 10px;
            }
        }
    }

}