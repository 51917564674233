.form-checkbox-group {
    margin-bottom: 20px;
    position: relative;
    .checkbox-group{
        li{
            .checkbox-label{
                padding-top: 0;
                display:flex;
                align-items: center;
                .text-label-m{padding-top: 4px;}
            }
        }
    }
    &.inline{
        .checkbox-group{
            display: flex;
            flex-direction: row;
            li{margin-right: 20px;margin-bottom: 0;
                .checkbox-label{
                    padding-top: 6px;
                }
            }
        }
    }

    &.bgtransparent {
        .checkbox-group{
            li{
                .checkbox-label{
                    &:before{
                        background-color:transparent;
                    }
                }
            }
        }
    }
    &.bgaccentlight {
        .checkbox-group{
            li{
                .checkbox-label{
                    &:before{
                        background-color: var(--color-accent-light);
                        border-color: var(--color-accent-light);
                    }
                    &:hover,
                    &:focus {
                        &:before{
                            border-color: var(--color-secondary);
                        }
                    }
                }
            }
        }
    }

    .form-list {
        &__helper-text{padding-top:10px;}
    }

    &.error {
        .checkbox .checkbox-label:before {
            border-color: var(--color-danger);
            color: var(--color-danger);

            &:focus {
                color: var(--color-text);
            }
        }

        .field-bottom {
            &__error-message {
                display: block;
                position: relative;
            }
            &__helper-text{display:none;}
        }
    }
    /*.form-list__tooltip-label{
        display: flex; align-items: flex-end; height: 36px;
    }*/
}
