.flap-old {
    width: 100%;
    background-color: var(--color-accent-light);
    padding: 40px;
    @media (--viewport-md-max) {
        padding: 40px 70px;
    }
    @media (--viewport-ms-max) {
        padding: 40px;
    }
    @media (--viewport-xs-max) {
        padding: 30px;
    }

    &.sticky {
        bottom: 0;
        position: fixed;
        max-width: 1314px;
        z-index: 5;
        @media (--viewport-md-max) {
            max-width: 1352px;
        }
        @media (max-width: 1413px){
            max-width: calc(100% - 62px);
        }
        @media (--viewport-sm-max) {
            max-width: calc(100% - 58px);
        }
        @media (--viewport-ms-max) {
            max-width: calc(100% - 62px);
        }
        @media (--viewport-xs-max) {
            max-width: 100%;
            margin-left: -20px;
        }
    }

    &.dark {
        background-color: var(--color-accent-dark);
        color: var(--color-txt-secondary);

        .link {
            color: var(--color-txt-secondary);

            span:after {
                background-color: var(--color-txt-secondary);
            }
        }

        .bodycopy-l {
            color: var(--color-txt-secondary);
        }

        .title-m {
            color: var(--color-txt-secondary)
        }

        .title-l {
            color: var(--color-txt-secondary)
        }
    }

    &.white {
        background-color: var(--color-white);
    }

    &.grey {
        background-color: var(--color-quaternary);
    }

    &.accent {
        background-color: var(--color-accent);
        color: var(--color-white);

        .link {
            color: var(--color-white);

            span:after {
                background-color: var(--color-white);
            }
        }

        .bodycopy-l {
            color: var(--color-white);
        }

        .title-m {
            color: var(--color-white)
        }

        .title-l {
            color: var(--color-white)
        }
    }

    ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (--viewport-ms-max) {
            flex-direction: column;
        }

        li {
            flex-grow: 0;

            &.texto {
                margin-right: 100px;
                @media (--viewport-ms-max) {
                    margin-bottom: 20px;
                    margin-right: 0;
                    text-align: center;
                }
                &.content-img {
                    margin-right:0;
                    .bodycopy-l{
                        display: flex;
                        flex-flow: wrap;
                        @media (--viewport-ms-max) {
                            flex-flow: column;
                        }
                        .list-img {
                            display:flex;
                            justify-content: space-around;
                            margin-left:20px;
                            gap:20px;
                            @media (--viewport-ms-max) {
                                margin-top: 30px;
                            }
                        }
                    }
                    
                    
                    
                
                }
            }
             
            /*&.enlace{ flex-grow: 1;}*/
            /*&:last-child{ flex-grow: 0;}*/
            &:only-child {  
                flex-basis: auto;
                flex-grow: 1;
                text-align: center;
            }

            ul {
                justify-content: flex-end;

                li {
                    padding-left: 40px;
                    white-space: nowrap;

                    &.enlace {
                        @media (--viewport-ms-max) {
                            order: initial;
                        }
                    }
                }

                li+li {
                    @media (--viewport-ms-max) {
                        margin-top: 20px;
                    }
                }
            }

            &.title-img {
                display: flex;
                text-align: left;
                align-items: flex-start;

                img {
                    max-width: 40px;
                    height: auto;
                    margin-right: 30px;
                }

                &:only-child {
                    flex-grow: 0;
                }
            }

            &.posttitle-img {
                display: flex;
                text-align: left;
                align-items: flex-start;

                img {
                    height: 25px;
                    width: auto;
                    left: 5px;
                    top: 5px;
                    position: relative;
                }

                &:only-child {
                    flex-grow: 0;
                }
            }

            &.light {
                .bodycopy-l {
                    color: var(--color-accent-dark);
                }

                .title-m {
                    color: var(--color-accent-dark);
                }

                .title-l {
                    color: var(--color-accent-dark);
                }
            }
        }
    }

    &.alert {
        background-color: var(--color-danger-05);
        border: solid 1px var(--color-danger);
        color: var(--color-secondary);


        .bodycopy-l {
            color: var(--color-secondary);
        }

        .title-m {
            color: var(--color-secondary)
        }

        .title-l {
            color: var(--color-secondary)
        }

        ul {
            li {
                @media (--viewport-ms-max) {
                    flex-direction: column;

                    img {
                        margin: 0 auto 10px;
                    }
                }

                &.texto {
                    margin-right: 0;

                    @media (--viewport-ms-max) {
                        margin-bottom: 0;
                    }
                }

                &.title-img {
                    .title-m {
                        color: var(--color-secondary);
                    }
                }
            }
        }
    }

    @media (--viewport-xs-max) {
        ul {
            flex-direction: column;

            li {
                order: 0;
                text-align: center;
                padding-bottom: 10px;

                /*flex-grow: 0;*/
                .bodycopy-l {
                    padding-bottom: 10px;
                }

                .title-m {
                    padding-bottom: 10px;
                }

                &.enlace {
                    order: 3;
                    padding-bottom: 0;
                }

                &.texto {
                    padding-bottom: 10px;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                ul {
                    li {
                        padding-left: 0;
                        padding-bottom: 20px;

                        &:first-child {
                            padding-bottom: 0;
                        }

                        &:last-child {
                            padding-bottom: 20px;
                        }

                        &:only-child {
                            padding-bottom: 0;
                        }
                    }
                }

                &:only-child {
                    .bodycopy-l {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}