.columns-form {
    &-title {
        padding-bottom: var( --gutter-ms-vertical);text-align: center;
        .title-l {
            padding-bottom: var( --gutter-ms-vertical);
        }
        &.left{text-align: left;}
    }
        
    .checkbox-wrapper{margin-top:30px;}
    
    .legal{margin-top:30px;
        .checkbox-wrapper{margin-top:0;}
        ul li:first-child{padding-right:0;}
    }
    .recaptcha{margin-top:10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-btn {
        text-align: center;
    }
    .col.left{
        .recaptcha{margin-top:10px;
            align-items:flex-start;
        }
        .form-btn {
            text-align: left;
        }
    }

     /* ESTOS ÚLTIMOS ESTILOS SON PARA SALVAR LAS VALIDACIONES DE LOS SITECORE FORMS */
    
     .landing-form-list .form-list__error-message,
     .columns-form-list .form-list__error-message {
         opacity: 1;
     }
     .landing-form-list .field-bottom__error-message,
     .columns-form-list .field-bottom__error-message {
         margin-top: 0;
     }
     .landing-form-list .field-bottom__error-message span.error-message span.field-validation-error span,
     .columns-form-list .field-bottom__error-message span.error-message span.field-validation-error span {
         margin-top: 5px;
     }
}