.calculator-form {
    .entradilla{margin-bottom: 40px;}
    .wrap-bloque{
        padding: 40px 40px 0;
        border: solid 1px var(--color-quinary);
        margin-bottom:20px;
        .title-with-icon{
            margin-bottom: 40px;
        }
        @media (--viewport-ms-max) {padding-bottom: 60px;}
    }
    .form-btn{text-align: center;}
    .results{
        justify-content: center; margin-top:45px;
        @media (--viewport-sm-min) {margin-top: 25px;}
        .wrap-bloque{
            border: 0;
            background-color: var(--color-quaternary);
            padding-bottom: 40px;
            margin-bottom:0;
        }
    }
    .submit{
        .wrap-bloque{
            border: 0;
            background-color: var(--color-quaternary);
            padding-bottom: 40px;
            margin-bottom:0;
            .tile__content{ padding-top: 0; padding-bottom: 50px;
                .tile__pic{max-width: 180px; margin-bottom:0;}
            }
            @media (--viewport-xs-max) {padding-bottom: 0;}
        }
    }
    .features-list__header {
        margin-top: -50px;
        margin-bottom: 0;
        @media (--viewport-sm-max) {margin-top: -55px;}
        @media (--viewport-xs-max) {margin-bottom: -20px;margin-top: -10px;}
    }
    .more-info{
        margin-top: 40px;
    }
}