 .footer-detailed {

     bottom: 0;
     left: 0;
     width: 100%;

     &_reduced {
         .footer-detailed {
             &__menu {
                 &-tiles {
                     @media (--viewport-ms-min) {
                         /*   padding: 40px 0 40px; */
                     }
                 }

                 /*this defines the size of the the legal section*/
                 &-legal {
                     @media (--viewport-ms-min) {
                         padding: 20px 0 5px;
                     }
                 }

             }

             &__main {
                 &-info {
                     @media (--viewport-ms-min) {
                         padding-top: 50px;
                     }
                 }

                 &-share {
                     @media (--viewport-ms-min) {
                         padding-top: 30px;
                     }

                     &-item {
                         @media (--viewport-ms-min) {
                             margin-bottom: 30px;
                         }

                         &:last-child {
                             @media (--viewport-ms-min) {
                                 flex-basis: auto;
                             }
                         }
                     }
                 }

                 &-legal {
                     @media (--viewport-ms-min) {
                         padding: 20px 0 5px;
                     }
                 }
             }
         }

     }

     &__wrapper {

         display: flex;
         flex-flow: column;

         justify-content: flex-start;


         @media(--viewport-md-min) {
             padding: 40px 0px 50px 0px;
         }

         @media(--viewport-sm-only) {
             padding: 40px 0px 50px;
         }

         @media(--viewport-ms-max) {
             padding: 40px 0px 10px;
         }


     }

     &__head {
         display: flex;
         flex-flow: row;
         padding-bottom: 40px;

         @media(--viewport-ms-max) {
             flex-flow: column;
             padding-bottom: 20px;
         }

         &-logoItem {

             display: flex;
             flex-direction: column;
             padding: 0px 40px 0px 0px;



             @media(--viewport-sm-min) {
                 width: 26.4%;
             }

             @media(--viewport-sm-only) {
                 width: 40%;
             }

             &-link {
                 display: block;
                 transition: all 0.3s;

                 &:hover,
                 &:focus {
                     opacity: 0.6;
                 }
             }

             &-img {
                 display: block;
                 max-width: 100%;
                 height: auto;
             }

             &-desc {
                 padding-top: 15px;


                 width: 200px;


                 .bodycopy-xs {

                     &:hover,
                     &:focus {
                         color: var(--color-text);
                     }
                 }
             }

         }

         &-declarativeTextItem {
             display: flex;
             flex-direction: column;
             justify-content: flex-end;

             @media(--viewport-ms-max) {
                 padding-top: 16px;
             }

             .title-m {
                 color: var(--color-text);
             }

         }

     }


     &__menu {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         background-color: var(--color-white);

         padding-bottom: 50px;

         @media(--viewport-ms-max) {
             flex-direction: column;
         }

         &-tiles {
             position: relative;
             display: flex;
             flex-basis: auto;
             flex-wrap: wrap;
             justify-content: flex-start;

             width: 80%;

             @media(--viewport-ms-max) {
                 width: 100%;
             }

             &-tile {

                 display: flex;
                 width: 33%;
                 height: fit-content;

                 padding: 0px 50px 25px 0px;

                 /* ONLY 2 TILES PER ROW */
                 @media(--viewport-sm-only) {
                     width: 50%;
                 }

                 /* MOBILE STYLIZATION */
                 @media(--viewport-ms-max) {
                     padding: 0px 20px 14px 0px;
                     width: fit-content;
                     min-width: 50%;

                     &:last-child {
                         padding-bottom: 40px;
                     }
                 }


             }

         }

         &-ekomiSection {
             display: flex;
             flex-flow: column;
             width: fit-content;

             &-ekomi {
                 height: fit-content
             }

         }

     }

     &__main {
         display: block;
         background-color: var(--color-white);

         &-section {
             display: flex;
             height: fit-content;
             flex-flow: row;

             padding-bottom: 30px;

             border-bottom: 1px solid rgba(0, 0, 0, 0.2);

             @media(--viewport-ms-max) {
                 flex-flow: column;
             }

             &-stacker {
                 display: flex;
                 flex-direction: column;
                 width: 50%;

                 @media(--viewport-ms-max) {
                     width: fit-content;
                     padding-bottom: 38px;
                 }

             }

             &-openLines {
                 display: flex;
                 flex-direction: row;
                 height: fit-content;

                 @media(--viewport-ms-max) {
                     flex-direction: column;
                 }

                 &-openLineTile {
                     display: flex;
                     flex-direction: column;
                     width: fit-content;
                     padding-right: 40px;

                     @media(--viewport-md-min) {
                         padding-right: 55px;
                     }

                     @media(--viewport-ms-max) {
                        padding-right: 0px;
                    }

                     &-openLineForFooter {
                         display: block;
                         text-align: left;
                         color: #00635b;
                         font-family: 'Jornada Sans Book';
                         font-size: 16px;
                         font-weight: normal;
                         line-height: 26px;
                         letter-spacing: 0;

                         &-accent {
                             color: var(--color-accent);
                         }
                     }

                     &-descOpenLine {
                         display: inline-block;
                         text-align: left;
                         color: #8B8B8B;
                         font-family: 'Jornada Sans Book';
                         font-size: 11px;
                         font-weight: lighter;
                         margin-bottom: 5px;
                     }
                 }

                 &-phoneTilesList {
                     display: flex;
                     flex-direction: row;

                     @media(--viewport-ms-max) {
                         padding: 20px 0px;
                     }

                     &-phoneTile {
                         display: flex;
                         flex-direction: column;
                         width: fit-content;
                         padding-right: 40px;

                         @media(--viewport-md-min) {
                             padding-right: 55px;
                         }

                         @media(--viewport-ms-max) {
                             padding-right: 10px;
                         }

                         &-phoneNumber {
                             display: block;
                             text-align: left;
                             font-family: 'Jornada Sans Book';
                             font-size: 16px;
                             font-weight: normal;
                             line-height: 26px;
                             letter-spacing: 0;
                         }

                         &-descPhoneNumber {
                             display: block;
                             text-align: left;
                             color: #191919;
                             font-family: 'Jornada Sans Book';
                             font-size: 11px;
                             font-weight: lighter;
                             margin-bottom: 10px;
                         }
                     }

                 }
             }

             &-socials {
                 display: flex;
                 flex-flow: row;
                 flex-wrap: wrap;
                 align-items: center;

                 padding: 10px 0px 10px 0px;

                 &-social {

                     padding-right: 15px;

                     @media (--viewport-sm-min) {
                         margin-right: 5px;
                     }

                     &:last-child {
                         margin-right: 0;
                     }

                     &-link {
                         position: relative;
                         display: flex;
                         transition: all 0.3s;

                         &::after {
                             position: absolute;
                             top: 50%;
                             left: 50%;
                             transform: translate(-50%, -50%);
                             display: block;
                             width: 40px;
                             height: 40px;
                             content: '';
                         }

                         &:hover,
                         &:focus {
                             opacity: 0.6;
                         }
                     }

                     .icon {
                         width: 16px;
                         height: 16px;
                         fill: var(--color-secondary);
                     }
                 }
             }

             &-certificates {

                 display: flex;
                 flex-direction: row;

                 flex-wrap: wrap;

                 justify-content: flex-end;

                 width: 50%;

                 @media(--viewport-ms-max) {
                     width: 100%;
                     justify-content: flex-start;
                 }


                 &-certificate {
                     display: flex;
                     padding-left: 10px;
                     padding-bottom: 30px;

                     @media(--viewport-ms-max) {
                         padding-left: 0px;
                         padding-right: 20px;
                         padding-bottom: 20px;
                     }

                     &:last-child {
                         padding-right: 0px;
                     }

                     &-link {
                         display: block;
                         transition: all 0.3s;

                         &:hover,
                         &:focus {
                             opacity: 0.6;
                         }
                     }

                     &-img {
                         display: block;
                         width: auto;
                         height: 90px;

                         @media(--viewport-ms-max) {
                             height: 80px;
                         }

                     }
                 }
             }

         }

         &-legal {
             padding: 30px 0 50px;

             @media (--viewport-ms-max) {
                 padding: 30px 0 30px;
             }

             &-list {
                 @media (--viewport-ms-min) {
                     display: flex;
                     flex-wrap: wrap;

                 }

                 @media (--viewport-sm-min) {
                     justify-content: space-between;
                 }
             }

             &-item {
                 margin-bottom: 20px;

                 @media (--viewport-ms-min) {
                     margin-bottom: 15px;
                 }

                 @media (--viewport-ms-only) {
                     flex-basis: 50%;
                 }
             }

             &-link {
                 display: block;
                 transition: all 0.3s;
                 color: var(--color-text);

                 &:hover,
                 &:focus {
                     color: var(--color-accent);
                 }

                 .bodycopy-xs {

                     &:hover,
                     &:focus {
                         color: var(--color-accent);
                     }
                 }


             }
         }
     }
 }