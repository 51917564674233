.title-xl {
    color: var(--color-text-quaternary);
    
    &_color {
        &_light {
            color: var(--color-text-light);
        }
        &_secondary {
            color: var(--color-text-secondary);
        }
    }
}
