.prequalifier-form-steps-on-top {
    width: 100%;
    text-align: right;
    margin-bottom: 50px;
    @media (--viewport-xs-max) {
        margin-left:-10px;
        margin-right:0;
        width: calc(100% + 20px);
    }

    &-list {
        display: inline-flex;

        @media (--viewport-xs-max) {
            display: flex;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        padding: 14px 26px;
        background-color: var(--color-accent-dark)B3;

        &.active {
            background-color: var(--color-accent-dark);

            /*@media (--viewport-xs-max) {
                flex-grow: 1;
            }

            .steps-header__steps-step {
                font-family: 'Jornada Sans Regular';
                font-size: 16px;
                line-height: 24px;
                color: var(--color-text);
            }*/
        }

        /*&:first-child {
            @media (--viewport-xs-max) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }*/
    }

    &-step {
        /*font-family: 'Jornada Sans Regular'; Suprimo esta fuente porque no existe en las variables */
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
        color: var(--color-text-light);
    }
}


div#step1{
    .prequalifier-form-steps-on-top{
        &-item:first-child{background-color: var(--color-accent-dark);
            @media (--viewport-xs-max) {
                flex-grow: 1;
            }
        }
        &-item:nth-child(2n){background-color: var(--color-accent-dark)CC; 
            span{font-size: 0;
                span{font-size: 16px;line-height: 24px; top:2px; position:relative;}
            }
        }
        &-item:nth-child(3n){background-color: var(--color-accent-dark)B3; 
            span{font-size: 0;
                span{font-size: 16px;line-height: 24px; top:2px; position:relative;}
            }
        }
    }
}
div#step2{
    .prequalifier-form-steps-on-top{
        &-item:first-child, &-item:nth-child(3n){background-color: var(--color-accent-dark)CC; 
            span{font-size: 0;
                span{font-size: 16px;line-height: 24px; top:2px; position:relative;}
            }
        }
        &-item:nth-child(2n){background-color: var(--color-accent-dark);
            @media (--viewport-xs-max) {
                flex-grow: 1;
            }
        }
    }
}
div#step3{
    .prequalifier-form-steps-on-top{
        &-item:first-child{background-color: var(--color-accent-dark)B3; 
            span{font-size: 0;
                span{font-size: 16px;line-height: 24px; top:2px; position:relative;}
            }
        }
        &-item:nth-child(2n){background-color: var(--color-accent-dark)CC; 
            span{font-size: 0;
                span{font-size: 16px;line-height: 24px; top:2px; position:relative;}
            }
        }
        &-item:nth-child(3n){background-color: var(--color-accent-dark);
            @media (--viewport-xs-max) {
                flex-grow: 1;
            }
        }
    }
}