.simulator-results {
    &__previous-info {
        margin-bottom: 20px;

        @media (--viewport-sm-min) {
            margin-bottom: 30px;
        }
    }

    &__numbers {

        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }

        &-main {
            @media (--viewport-sm-min) {
            margin-top: 30px;
            margin-bottom:30px;
            }
            li{
                flex-basis: 46%;
                max-width: 46%;
                padding-left: 0;
                padding-right:0;
                margin-left:1.5%;
                margin-right:1.5%;
                background-color: var(--color-accent);
                justify-content: center;
                @media (--viewport-ms-max){
                    flex-basis: 100%;
                    max-width: 100%;
                    margin-left:0;
                    margin-right:0;
                    margin-bottom: 30px;
                }
                &:last-child{
                    background-color: var(--color-accent-light);
                    @media (--viewport-ms-max){margin-bottom: 30px;}
                }
                .simulator-results {
                    &__value-banner {
                        .value-banner {
                            background-color:unset;
                            @media (--viewport-sm-min) {
                                padding: 30px;
                            }
                            @media (--viewport-ms-max) {
                                padding: 30px;
                            }
                        }
                    }
                }
            }
            
        }

        &-additional {
            li{
                flex-basis: 46%;
                max-width: 46%;
                padding-left: 0;
                padding-right:0;
                margin-left:1.5%;
                margin-right:1.5%;
                background-color: var(--color-accent-light);
                @media (--viewport-ms-max){
                    flex-basis: 100%;
                    max-width: 100%;
                    margin-left:0;
                    margin-right:0;
                    margin-bottom: 30px;
                }
                li{
                    flex-basis:auto;
                    max-width:none;
                    margin-left:inherit;
                    margin-right:inherit;
                }
                .simulator-results {
                    &__value-banner {
                        height: 100%;
                        .value-banner{
                            background-color:unset;
                            @media (--viewport-ms-max) {
                                padding: 30px;
                            }
                        }
                    }
                }
            }
            
        }
    }

    &__info {
        &-box {
            padding: 50px 20px;

            @media (--viewport-sm-min) {
                padding: 50px 80px;
            }

            @media (--viewport-ms-max) {
                padding: 30px;
            }

            &-btn {
                margin-top: 22px;
            }
        }
    }

    &.ico{
        @media (--viewport-ms-only) {
            margin: 0 -15px;
            max-width: calc(100% + 30px);
        }
        .simulator-results__previous-info{
            margin-bottom: 40px;
        }
        .simulator-results__info-box{
            @media (--viewport-ms-max) {
                padding: 32px 30px;
            }
        }
        .simulator-results__numbers {
    
            &-main {
                margin-top:0;
                li{
                    background-color: var(--color-accent-light);
                    &:last-child{
                        background-color: var(--color-accent);
                    }
                    .simulator-results__value-banner{
                        .value-banner{
                            padding: 50px 80px;
                            @media (--viewport-ms-max) {
                                padding: 32px 30px;
                            }
                        }
                    }
                }
                
            }

            &-additional {
                li{
                    flex-basis: 95%;
                    max-width: 95%;
                    margin-left:1.5%;
                    margin-right:1.5%;
                    margin-bottom: 30px;
                    @media (--viewport-ms-max){
                        flex-basis: 100%;
                        max-width: 100%;
                        margin-left:0;
                        margin-right:0;
                        margin-bottom: 24px;
                    }
                    li{
                        flex-basis:auto;
                        max-width:none;
                        margin-left:inherit;
                        margin-right:inherit;
                        margin-bottom: 0;
                    }
                }
                
            }
        }
        .legal{
            margin-bottom: 80px;
            @media (--viewport-ms-max) {
                margin-bottom: 64px;
                padding: 0 20px;
            }
        }
    }
}

