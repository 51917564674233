.tooltip-wrapper {
  position: relative;
  display: inline-block;
}
.tooltip {
    position: absolute;
    top: -20px;
    right: -15px;
    .icon{
        cursor:pointer;
    }
    .msg-tooltip{
        z-index:10;
        visibility: hidden;
        opacity:0;
        display: flex;
        position: absolute;
        top:25px;
        left:-98px;
        padding:20px;
        width: 200px;
        background-color: var(--color-white);
        -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        &.contact-info{
            width: 250px;
            left:-113px;
            padding-bottom: 40px;
        }
        .arrow {
            top:-30px;
            left: 79px;
            position: relative;
            background-color: var(--color-white);
            display: inline-block;
            padding: 5px;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }
        &.contact-info{
            width: 250px;
            left:-113px;
            padding-bottom: 40px;
            .arrow {
                left: 94px;
            }
        }
        .wrap{
            position: relative;
            margin-top: -10px;
            .link{margin-top:10px;}
            .title-s{margin-bottom:10px;}
            &.contact-info{display: flex; flex-direction: column;
                .bodycopy-l{
                    color: var(--color-accent);margin-bottom:20px;
                    &::after{
                        display: block;
                        position: relative;
                        top: 15px;
                        left: 0;
                        width: 60px;
                        height: 3px;
                        background-color: var(--color-accent-dark);
                        content: "";
                    }
                }
                .tel{display: flex; flex-direction:row; align-items: flex-end;
                    .bodycopy-l{color: var(--color-secondary);margin-bottom:0; margin-top:20px;
                        &::after{display: none;}
                        a, a:visited, a:focus{color: var(--color-secondary);}
                    }
                    .flag{width: 20px;height: 20px; margin-left: 10px; margin-bottom: 7px;}
                }
                .bodycopy-xs{color:var(--color-disabled);}
            }
        }
        @media (--viewport-xs-max) {
            left:-215px;
            top:-15px;
            .arrow{
                top:-5px;
                left:auto;
                right:-175px;
            }
        }
    }
    &:hover{
        .msg-tooltip{
            display:block;
            opacity:1;
            visibility: visible;
        }
    }
    &.flex{display: flex;flex-direction: column; position: relative; padding:20px;max-width:52px; margin-bottom: -40px; margin-left: -20px;
        .icon{position: absolute;top:-2px;pointer-events: none}
        .msg-tooltip{/* display:flex;*/position:absolute;/*top:10px;*/ top:22px; flex-direction: column; align-items: center;
            left:-74px;
            .arrow {left:0; right: auto;
                bottom: auto;
                top:-25px;
                height: 16px;
                width: 16px;
            }
        }
        .wrap{
            margin-top: -15px;
            width: 100%;
        }
        &:hover{
            .msg-tooltip{
                display: flex;
                opacity:1;
                visibility: visible;
            }
        }
        &.colored{
            .msg-tooltip{
                background-color: var(--color-accent-10);
                -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0);
                -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0);
                box-shadow: 0px 0px 10px 5px rgba(0,0,0,0);
                .arrow {
                    background-color: var(--color-accent-10);
                }
            }
        }
        &.under{flex-direction: column-reverse;
            .msg-tooltip{/*top:-10px;*/flex-direction: column-reverse;position: absolute;top: auto;left: -74px;bottom: auto;margin-bottom: 30px;
                .wrap{
                    margin-top: 0;
                }
                .arrow {
                    top:auto;
                    bottom:-25px;
                }
            }
        }
        &.left{
            .msg-tooltip{align-items:flex-start;left: -6px;
                .arrow{left:4px;}
            }
            &.under{
                .msg-tooltip{left: -6px;}
            }
        }
        &.right{
            .msg-tooltip{align-items:flex-end;left:-141px;right:auto;
                .arrow{left:-5px;}
            }
            &.under{
                .msg-tooltip{left:-141px;}
            }
        }
    }
}
