.application-form {
    &__step-2 {
        display: none;
    }

    &__head {
        margin-bottom: 45px;
    }

    &__legal {
        margin-top: 25px;

        @media (--viewport-ms-min) {
            margin-top: 35px;
        } 
        a{color: var(--color-accent-dark);}
    }

    &__text {
        @media (--viewport-xs-max) {
            margin-top: 100px;
        }

        @media (--viewport-ms-only) {
            margin-top: 120px;
        }

        &_step-2 {
            display: none;
        }
    }
    ul.buttons{
        li{display:inline; margin-right:20px;float:left;
            &:last-child{ margin-right:0;}
            .form-btn{
                .row > .col-xs-12.col-ms-12.col-sm-12.col-md-10.col-lg-10{flex-basis: auto;max-width: none;}
            }
        }
    }
}
