.access-select-net {
    position: relative;
    select {
        position: relative;
        display: block;
        width: 100%;
        padding: 14px 20px 10px 15px;
        outline: none;
        border: 1px solid var(--color-grey3);
        font-family: 'Jornada Sans Book';
        font-size: 14px;
        line-height: 24px;
        color: var(--color-secondary);
        background-color: transparent;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        @media (--viewport-ms-min) {
            padding-right: 30px;
        }

        option {
            color: var(--color-secondary);
        }
        option:first-of-type {
            color: var(--color-grey2);
        }
        option::before {
            content: " ";
            padding: 10px;
            line-height: 100px;
        }
    }
    &::after {
        content: '>';
        font-size: 23px;
        color: #333;
        transform: rotate(90deg);
        right: 20px;
        top: 7px;
        padding: 0 0 2px;
        position: absolute;
        pointer-events: none;
    }

    &.disabled {
        select{
        background-color: var(--color-grey-light);
        border-color: var(--color-quinary);
        }
    }

    &.error {
        select{
            border-color: var(--color-danger);
        }
    }
}