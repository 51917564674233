.card-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    height: fit-content;
    min-height: 250px;
    width: 210px;
  
    overflow: hidden ;

    background-color: var(--color-accent-light);

    &__img {

        display: block;
        width: 100%;
        height: 220px;
        background: center / cover no-repeat;
       

        @media (--viewport-ms-min) {
            height: 116px;
        }

    }

    &__title {
        display: block;
        padding: 10px 10px;
        font-size: 20px; 
        color: var(--color-secondary);
        text-align: center;
        
    }

    &__cta {
        display: block;
        position: relative; z-index: 1;
        
        align-self: center;
        justify-content: center;
        

        @media (--viewport-sm-min) {
            margin-left: 30px;
        }

        @media (--viewport-md-min) {
            margin-left: 40px;
        }

        @media (--viewport-lg-min) {
            margin-left: 60px;
        }

        &-btn {
            /*min-width: 180px;*/

            .btn {
                width: 100%;
                transition:none;
                font-size: 16px; 

                @media (--viewport-sm-min) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 180px;
                }
            }

            &.sticky {
                display: flex;
                justify-content: center;
                align-items: flex-end;
               
                margin-bottom: 20px;
                margin-left: 25px;
                margin-right: 25px;

                .lightbox{display:none;
                    @media (--viewport-ms-max){display:inline-block;}
                }
                
                &.sticky-clone {
                    /*min-width: 252px;*/
                    background: none;
                    left: auto;
                    display: flex;
                    width: auto;
                    @media (--viewport-ms-max){
                        .btn{width: auto;}
                    }
                    &.visible{
                        .lightbox{display:inline-block;}
                        .btn{width: auto;}
                    }
                    /*right: calc(var(--gutter-width) - 1px);*/

                    @media (--viewport-ms-min) {
                        right: calc(var(--gutter-width-ms-min) + 1px);
                    }

                    @media (--viewport-sm-min) {
                        right: calc(var(--gutter-width-ms-min) - 1px);
                    }

                    @media (--viewport-md-min) {
                        /*right: calc(var(--gutter-width-ms-min) + 2px);*/
                        right:auto;
                        margin-left: -36px;
                    }

                    @media (--viewport-lg-min) {
                        /*right:var(--gutter-width-lg-min);*/
                        right: auto;
                        margin-left: -36px;
                    }
                }
                .btn{height: auto;font-size: 16px;padding-left:20px;padding-right:20px;/*antes 14px y sin padding left ni right definidos*/line-height: 24px;
                    &:nth-child(3){width: 180px;}
                    @media (--viewport-ms-max){
                        width: auto;
                        &:nth-child(2){}
                        &:nth-child(3){width: auto;margin-left:0;}
                    }
                }
                @media (--viewport-ms-max){display:flex;}
            }
        }
        

    }
}
