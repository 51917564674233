.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 22;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-primary);

    &::before,
    &::after {
        position: fixed;
        top: -1px;
        display: block;
        width: calc(20px * 2);
        height: 100%;
        background-color: var(--color-primary);
        content: '';

        @media (--viewport-ms-min) {
            width: calc(31px * 2);
        }

        @media (--viewport-sm-min) {
            width: calc(29px * 2);
        }

        @media (--viewport-md-min) {
            width: calc(30px * 2);
        }

        @media (--viewport-cont-no-pad-min) {
            width: calc((100vw - 1352px) / 2 + 30px);
        }

        @media (--viewport-lg-min) {
            width: calc((100vw - 1352px) / 2 + 50px);
        }
    }

    &::before {
        left: calc(-19px - 20px);

        @media (--viewport-ms-min) {
            left: calc(-31px * 2);
        }

        @media (--viewport-sm-min) {
            left: calc(-29px * 2);
        }

        @media (--viewport-md-min) {
            left: calc(-30px * 2);
        }

        @media (--viewport-cont-no-pad-min) {
            left: calc(-1 * ((100vw - 1352px) / 2 + 30px));
        }

        @media (--viewport-lg-min) {
            left: calc(-1 * ((100vw - 1352px) / 2 + 50px));
        }
    }

    &::after {
        right: calc(-19px - 20px);

        @media (--viewport-ms-min) {
            right: calc(-31px * 2);
        }

        @media (--viewport-sm-min) {
            right: calc(-29px * 2);
        }

        @media (--viewport-md-min) {
            right: calc(-30px * 2);
        }

        @media (--viewport-cont-no-pad-min) {
            right: calc(-1 * ((100vw - 1352px) / 2 + 30px));
        }

        @media (--viewport-lg-min) {
            right: calc(-1 * ((100vw - 1352px) / 2 + 50px));
        }
    }

    &.active {
        display: block;
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__label {
        margin-bottom: 20px;
    }

    &__img {
        display: block;
        margin: 0 auto;
    }

    &.as-component {
        position: unset;
        top: unset;
        left: unset;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        &::before,
        &::after {
            position: unset;
            top: unset;
            display: unset;
            width: unset;
            height: unset;
            background-color: unset;
            content: unset;
        }

        .preloader__wrapper {
            position: unset;
            top: unset;
            left: unset;
            transform: unset;
        }
    }
}