.access-highlight {
    position: relative;
    margin-bottom: 50px;
    position: relative;

    @media (--viewport-xs-max) {
        margin-left: -20px;
        margin-right: -19px;
    }

    @media (--viewport-ms-min) {
        margin-bottom: 50px;
    }

    @media (--viewport-sm-min) {
        margin-bottom: 50px;
    }

    @media (--viewport-md-min) {
    }

    &__figure {
        margin-bottom: 40px;

        @media (--viewport-ms-min) {
            margin-bottom: 60px;
        }

        @media (--viewport-sm-min) {
            margin-bottom: 0;
        }
        @media (--viewport-ms-max) {
            position:absolute;
            top:0;
            left:0;
            margin:30px;
        }
    }

    &__number {
        margin-bottom: 10px;
    }

    &__tile {
        margin-bottom: -50px;
    }
    .wrap-img{height:550px;position: absolute;width: 100%;top:0;overflow: hidden;display: flex; align-items:flex-start;justify-content: center;
        @media (--viewport-lg-min) {
            height:510px;
        }
        @media (--viewport-md-max) {
            height:550px;
        }
        @media (--viewport-sm-max) {
            height:550px;
        }
        @media (--viewport-ms-max) {
            position: relative;
            height:auto;
        }
        @media (--viewport-xs-max) {
            height:auto;
        }
        img{width: 100%; margin-bottom: -8px;}
    }
    .content{width: calc(100% + 30px);position: relative;top:0;
        @media (--viewport-ms-min) {
            margin-top:0;
        }
    
        @media (--viewport-sm-min) {
            margin-top: 60px;
        }
    
        @media (--viewport-md-min) {
            margin-top: 155px;
        }
        @media (--viewport-lg-min) {
            margin-top: 112px;
        }
        @media (--viewport-xs-max) {
            margin-right:0;
            margin-left: -10px;
            width: calc(100% + 20px);
            div.line{padding:0; min-height:auto;
                /* Suprimo este estilo porque está generando un problema en el módulo de las landing.
                Si hay que volver a ponerlo se deben revisar*/
                /*.access-highlight__tile{
                    .tile-highlight{
                        .tile-highlight__wrapper{padding:30px 20px;}
                    }
                }*/
            }
        }
        @media (--viewport-ms-max) {
            position: initial;
        }
    }
    .tile-highlight{
        &__title {
            margin-bottom: 10px;
    
            @media (--viewport-ms-min) {
                margin-bottom: 20px;
            }
    
            @media (--viewport-sm-min) {
                margin-bottom: 10px;
            }
        }
    }
}
