.icon-button {
    text-align: center;

    &__link {
        display: block;color: var(--color-accent);

        &:hover,
        &:focus,
        &.active {
            .icon-button__title {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                span,
                div {
                    color: var(--color-accent-dark);
                }
            }
        }
    }

    &__pic {
        width: 47px;
        height: 47px;
        margin: 15px auto 0;
        transition: all 0.3s;
        display: flex;
        justify-content:center;
        align-content: center;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        height: auto;
    }

    &__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        div,
        span {
            transition: all 0.3s;
        }
        margin-top:15px;
        margin-bottom:15px;
    }
}
