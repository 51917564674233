.news {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__pic {
        overflow: hidden;

        &-link {
            display: block;
            width: 100%;
            height: 220px;
            background: center / cover no-repeat;
            transition: all 0.5s;

            @media (--viewport-ms-min) {
                height: 200px;
            }

            &:hover,
            &:focus {
                transform: scale(1.1);
            }
        }
    }

    &__content {
        flex-grow: 1;
        padding: 40px 20px;
        text-align: center;
        background-color: var(--color-accent-light);

        @media (--viewport-ms-min) {
            padding: 30px;
        }

        @media (--viewport-sm-min) {
            padding: 40px 30px;
        }
    }

    &__label {
        margin-bottom: 10px;
    }

    &__title {
        &-link {
            display: block;

            &:hover,
            &:focus {
                .title-m {
                    color: var(--color-accent);
                }
            }

            .title-m {
                transition: color 0.3s;
            }
        }
    }

    &__category {
        margin-top: 20px;

        &-link {
            display: block;

            &:hover,
            &:focus {
                .text-label-s {
                    color: var(--color-accent-dark-tertiary);
                }
            }

            .text-label-s {
                transition: color 0.3s;
            }
        }
    }
}
