.levels {
    margin-top: 18px;
    margin-top: 10px;

    .label-m {
        color: var(--color-text-subtle);
        margin-bottom: 24px;
        display: block;
    }

    &__wrap {
        display: flex;
        flex-direction: row;

    }

    ul {
        display: flex;
        width: 100%;
        gap: 16px;

        li {
            background-color: var(--color-background-accent);
            width: 20%;
            height: 10px;

            &.ok {
                background-color: var(--color-icon-success);
            }

            &.ko {
                background-color: var(--color-icon-error);
            }

            &.medium {
                background-color: var(--color-icon-alert);
            }
        }
    }

    &.ok {
        .label-m:last-of-type {
            color: var(--color-icon-success);
            margin-left: 5px;
        }
    }

    &.ko {
        .label-m:last-of-type{
            color: var(--color-icon-error);
            margin-left: 5px;
        }
    }

    &.medium {
        .label-m:last-of-type{
            color: var(--color-icon-alert);
            margin-left: 5px;
        }
    }

}