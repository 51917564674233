.product-buttons {
    overflow-x: scroll;
    scrollbar-width: none; /* Hide the scrollbar in Mozilla Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
    padding-top: 40px;
    margin-right: -19px;
    text-align: center;
    transition: all 0.3s;

    &::-webkit-scrollbar {
        display: none; /* Hide the scrollbar in Chromium */
    }

    @media (--viewport-ms-min) {
        margin-right: -31px;
    }

    @media (--viewport-sm-min) {
        padding-top: 30px;
    }

    @media (--viewport-md-min) {
        margin-right: -32px;
    }

    @media (--viewport-lg-min) {
        margin-right: -50px;
    }

    &.sticky-clone {
        padding: 14px 0;

        .product-buttons {
            &__item {
                margin-right: 53.5px;

                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                }
            }
        }

        .product-button {
            &__pic {
                max-width: 40px;
                margin-bottom: 13px;
            }

            &__label {
                display: none;
            }

            &__title {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                div,
                span {
                    font-family: 'Jornada Sans Medium';
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 1.2px;

                    @media (--viewport-lg-min) {
                        font-size: 12px;
                        line-height: 22px;
                        letter-spacing: 1.4px;
                    }
                }
            }
        }
    }

    &__list {
        display: inline-flex;
    }

    &__item {
        margin-right: 40px;

        @media (--viewport-sm-min) {
            margin-right: 80px;
        }

        &:last-child {
            padding-right: 19px;
            margin-right: 0;

            @media (--viewport-ms-min) {
                padding-right: 31px;
            }

            @media (--viewport-md-min) {
                padding-right: 32px;
            }

            @media (--viewport-lg-min) {
                padding-right: 50px;
            }
        }
    }
}
