.featured {
    &-swiper {
        @media (--viewport-xs-max) {
            margin-right: -19px;
            margin-left: -20px;
        }
    }

    &__pic {
        overflow: hidden;

        &-link {
            display: block;
            width: 100%;
            height: 220px;
            background: center / cover no-repeat;
            transition: all 0.5s;

            @media (--viewport-ms-min) {
                height: 320px;
            }

            @media (--viewport-sm-min) {
                height: 420px;
            }

            &:hover,
            &:focus {
                transform: scale(1.1);
            }
        }
    }

    &__main {
        @media (--viewport-sm-min) {
            padding-left: 30px;
        }
    }

    &__content {
        @media (--viewport-xs-max) {
            padding: 40px 20px 0;
        }

        @media (--viewport-ms-only) {
            padding: 40px 0 0;
        }
    }

    &__label {
        margin-bottom: 10px;
    }

    &__title {
        &-link {
            display: block;
            color: var(--color-primary-brand);

            &:hover,
            &:focus {
                .title-xl {
                    color: var(--color-accent);
                }
            }

            .title-xl {
                transition: color 0.3s;
            }
        }
    }

    &__category {
        margin-top: 20px;

        &-link {
            display: block;

            &:hover,
            &:focus {
                .text-label-s {
                    color: var(--color-accent-dark-tertiary);
                }
            }

            .text-label-s {
                color: var(--color-txt-cuaternary);
                transition: color 0.3s;
            }
        }
    }

    &__nav {
        display: flex;

        @media (--viewport-xs-max) {
            justify-content: space-between;
            padding: 0 20px;
        }

        &-back,
        &-forward {
            margin-top: 30px;
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }
}
