.requirements-banner {
    width: 100%;
    height: 100%;
    padding: 80px 80px;
    background-color: var(--color-background-accent);
    @media (--viewport-ms-max) {
        padding: 32px 30px;
    }
    .title-m{
        margin-bottom: 30px;
        display: block;
    }
    .link{
        margin-top: 14px;
    }
    .list.twocols ul li {
        padding: 0 0 24px;
        @media (--viewport-ms-max) {
            width: 100%;
            padding: 0 0 16px;
        }
    }
}