.slide-highlight {
    position: relative;
        margin-bottom:50px;
        margin-top:0;
    
        @media (--viewport-ms-max) {
            display:flex;
            flex-direction: column;
        }
        @media (--viewport-xs-max) {
            margin-bottom:0;
        }
    
        .wrap-img{/*min-height:450px;*/position: relative;width: 100%;top:0;overflow: hidden;display: flex; align-items: flex-start;justify-content: center;
            @media (--viewport-ms-max) {
                min-height: auto;
                align-items: flex-end;
            }
            @media (--viewport-xs-max) {
                top:0;
            }
            picture{
                line-height: 0;
                img{width: 100%; height: auto;}
            }
        }
        .video {
            width: 100%;
            height:auto;
            left: 0;
            top: 0;
            overflow: hidden;
            display:none;
            &.video-desktop{
                @media (min-width: 1025px) {
                    display:block;
                }
            }
            &.video-1024{
                @media (max-width: 1024px) and (min-width: 769px) {
                    display:block;
                }
            }
            &.video-768{
                @media (max-width: 768px) and (min-width: 501px) {
                    display:block;
                }
            }
            &.video-mobile{
                @media (max-width: 500px) {
                    display:block;
                }
            }
        }
    
    &__tile {position: absolute;bottom:0;width: 100%;margin: 0 0 -50px 0;
        @media (--viewport-ms-max) {
            position:relative;
            margin-top:0;
            margin-right:0;
            padding-right:0;
        }
        @media (--viewport-xs-max) {
            margin-bottom:0;
        }
        .tile-highlight{
            &__content{
                @media (--viewport-xs-max) {
                    justify-content: flex-start;
                }
                &__description{
                    @media (--viewport-xs-max) {min-height:120px;}
                }
            }
        }
        &_reversed {
            .row{
                justify-content:flex-end;
            }
        }
        &_none {display:none;
            @media (--viewport-ms-max) {
                display:block;
            }
        }
        &_light{
            .tile-highlight__wrapper {
                background-color:var(--color-accent-light);
                .tile-highlight__content{
                    .title-xl_color_light{
                        color:var(--color-accent);
                    }
                    .bodycopy-l.bodycopy_color_light, 
                    .bodycopy-l_color_light, 
                    .bodycopy-m.bodycopy_color_light, 
                    .bodycopy-m.bodycopy_color_light .bodycopy__link, 
                    .bodycopy-m_color_light,
                    .text-label-m_color_light{color:var(--color-accent);}
                }
            }
        }
        &_accent-dark{
            .tile-highlight__wrapper {
                background-color:var(--color-accent-dark);
            }
        }
    }
}