.value-banner {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    background-color: var(--color-quaternary);

    .icon-wrap{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 24px;
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        &-trigger {
            margin-left: 8px;
            transition: all 0.3s;

            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
    }

    &__chosen {
        display: flex;
        justify-content: center;
        align-items: center;

        span.title-l{
            text-align: center;
            color: var(--color-txt);
        }

        &-value {
            font-family: 'Jornada News Book';
            font-size: 26px;
            font-weight: normal;
            line-height: 1.2;
            letter-spacing: 0;
            color: var(--color-txt-cuaternary);
            text-align: right;
            padding-right: 5px;


            @media (--viewport-sm-min) {
                font-size: 30px;
            }

            @media (--viewport-md-min) {
                font-size: 40px;
            }
        }

        &-label{
            .number-m {
            color: var(--color-txt-cuaternary);
            }
        }
    }

    &__slider {
        margin-top: 27px;
        height: 4px;

        @media (--viewport-sm-min) {
            margin-top: 28px;
        }

        .noUi {
            &-connects {
                border: none;
                background-color: var(--color-secondary-light);
                cursor: pointer;
            }

            &-connect {
                background-color: var(--color-accent);
            }

            &-handle {
                top: -9px;
                width: 20px;
                height: 20px;
                border: 6px solid var(--color-white);
                border-radius: 50%;
                box-shadow: none;
                outline: none;
                background-color: var(--color-accent-dark);
                cursor: ew-resize;
                transition: all 0.3s;

                &::before,
                &::after {
                    content: none;
                }

                &:hover,
                &:focus {
                    border-color: var(--color-accent);
                    background-color: var(--color-primary);
                }
            }
        }
    }

    &__legend {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 17px;

        @media (--viewport-sm-min) {
            margin-top: 15px;
        }
    }

    &__additional {
        margin-top: 20px;
    }
}
