.prequalifier-form-steps {margin-bottom: 40px;
    ul{display: flex;flex-direction: row;flex-wrap: nowrap;
        li{background-color:var(--color-quinary);width: 33%; height:5px; margin: 10px 0 10px 20px;
            &.active{background-color:var(--color-accent-dark);}
            &:first-child{margin-left: 0;}
        }
    }
}
div#step1{
    .prequalifier-form-steps {
        ul{
            li:first-child{background-color:var(--color-accent-dark);
            }
        }
    }  
}
div#step2{
    .prequalifier-form-steps {
        ul{
            li:nth-child(2n){background-color:var(--color-accent-dark);
            }
        }
    }  
}
div#step3{
    .prequalifier-form-steps {
        ul{
            li:nth-child(3n){background-color:var(--color-accent-dark);
            }
        }
    }  
}